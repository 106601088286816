import React, { useState, useEffect } from 'react';
import MediaNavBar from './components/medianavbar';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import LinkComponent from '../link/link.component';
import { getUrlFromEnglish } from '../../services/i18n/i18n.url.service';
import { useMedia } from '../../stores/selectors/media.selector';
import ReactPlayer from 'react-player';
import { displayName, getThubmVideo, formatURL } from './media.service';
import { usePrismic } from '../../hooks/prismic.hook';
import { useRemoteConfiguration } from '../../stores/selectors/remote-configuration.selector';
import PreviewComponent from './components/preview.component';
import DownloadComponent from './components/download.component';

export default function Media(): JSX.Element {
  const {
    status: { screenshots, videos, wallpapers, conceptart, documents, pinned },
  } = useMedia();

  const {
    remoteConfig: { ASSETS_MEDIA_PREFIX },
  } = useRemoteConfiguration();

  const {
    result: {
      main_title,
      cat_screenshoots,
      cat_wallpapers,
      cat_videos,
      cat_concept_art,
      cat_documents,
      see_all,
      playing,
    },
  } = usePrismic('media-page');

  const [categoryPreviewList, setCategoryPreviewList] = useState(['']);
  const [indexImageSelected, setIndexImageSelected] = useState(0);
  const [listDownload, setListDownload] = useState(['']);
  const [imageBig, setImageBig] = useState('');
  const [imageModal, setimageModal] = useState('');
  const closeModal = (): void => {
    setimageModal('');
  };
  const responsive = {
    desktop: {
      breakpoint: { max: 4000, min: 480 },
      items: 2,
    },

    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1,
    },
  };
  const responsiveVideo = {
    desktop: {
      breakpoint: { max: 4000, min: 480 },
      items: 5,
    },

    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1,
    },
  };
  const responsiveWallArt = {
    desktop: {
      breakpoint: { max: 4000, min: 480 },
      items: 1.4,
    },

    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1,
    },
  };

  const firstVideoPlaylist = videos?.find((elem: string) => {
    return elem.includes('playlist') && !elem.includes('thumbnail');
  });
  const getPlaylistByName = (name: string): string[] => {
    const results = videos?.filter((elem: string) => {
      return elem.includes(name) && !elem.includes('thumbnail');
    });
    return results;
  };
  const getPlaylistsNames = (): string[] => {
    let results: string[] = videos
      ?.filter((elem: string) => {
        return elem.includes('playlist');
      })
      .map((elem: string) => {
        return elem.split('/')[3];
      });
    if (results?.length === 0) {
      results = videos
        ?.filter((elem: string) => {
          return !elem.includes('Trailer');
        })
        .map((elem: string) => {
          return elem.split('/')[2];
        });
    }
    const resultsUnique = results?.filter((item, index) => {
      return results.indexOf(item) === index && item !== '';
    });
    resultsUnique?.splice(0, 0, 'Trailer');
    return resultsUnique;
  };
  const [playlistActive, setPlayListActive] = useState(['']);
  const [indexCurrentPlaylist, setIndexCurrentPlaylist] = useState(0);
  const [indexLeftBar, setIndexLeftBar] = useState(0);
  const screenshootsSmall = screenshots?.filter((elem: string) => {
    return !elem.includes('-');
  });
  const conceptArtSmall = conceptart?.filter((elem: string) => {
    return !elem.includes('-');
  });
  const wallpapersSmall = wallpapers?.filter((elem: string) => {
    return !elem.includes('-');
  });
  const videosNoThumb = videos?.filter((elem: string) => {
    return !elem.includes('thumbnail');
  });

  const documentsNoThumb = documents?.filter((elem: string) => {
    return !elem.includes('thumbnail');
  });
  useEffect(() => {
    if (videosNoThumb) {
      setPlayListActive(
        videosNoThumb?.filter((elem: any) => {
          return elem.includes(getPlaylistsNames()[0]);
        }),
      );
    }
  }, [videos]);
  const getThumbnailCategory = (category: string): string => {
    return videos?.find((elem: string) => {
      return elem.includes(category) && elem.includes('section');
    });
  };
  const [isPlaying, setIsPlaying] = useState(false);
  const renderPlaylists = (): JSX.Element[] | JSX.Element => {
    const results = getPlaylistsNames()?.map((item, key) => {
      return (
        <div
          className={indexLeftBar === key ? 'image selected' : 'image'}
          key={key}
          onClick={(): void => {
            setPlayListActive(getPlaylistByName(item));
            setIndexCurrentPlaylist(0);
            setIndexLeftBar(key);
            setIsPlaying(true);
          }}
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(12, 19, 21, 0) 32%, #0c1315),url(${ASSETS_MEDIA_PREFIX.concat(
              getThumbnailCategory(item),
            )})`,
          }}
        >
          <div className="playlist-count">{`${getPlaylistByName(item).length} Videos`}</div>
          <div className="playlist-title">
            {indexLeftBar === key && (
              <>
                <div className="playlist-playing-icon"></div>
                <p className="playlist-playing">{playing}</p>
              </>
            )}
            <p>{item}</p>
          </div>
        </div>
      );
    });
    return results;
  };

  const renderVideos = (): JSX.Element[] | JSX.Element => {
    const result = playlistActive
      ?.filter((elem: string) => {
        return !elem.includes('thumbnail');
      })
      ?.map(
        (elem: string, index: number): JSX.Element => {
          return (
            <div
              className={indexCurrentPlaylist === index ? 'videoCarouselSmall selected' : 'videoCarouselSmall'}
              key={index}
            >
              <div
                className="carousel-image"
                style={{
                  backgroundImage: `url(${getThubmVideo(getPlaylistByName(elem)[0], videos, ASSETS_MEDIA_PREFIX)})`,
                }}
                onClick={(): void => {
                  setIndexCurrentPlaylist(index);
                }}
              ></div>
            </div>
          );
        },
      );
    return result;
  };
  const renderVideosIntro = (): JSX.Element[] | JSX.Element => {
    const items = pinned
      ?.filter((elem: string) => {
        return !elem.includes('thumbnail');
      })
      ?.map((elem: string, index: number): JSX.Element[] | JSX.Element => {
        if (elem.includes('.jpg') || elem.includes('.png'))
          return (
            <div className="videoCarousel">
              <div
                className="image"
                style={{
                  backgroundImage: `url(${ASSETS_MEDIA_PREFIX.concat(formatURL(elem))})`,
                }}
              ></div>
            </div>
          );
        return (
          <div className="videoCarousel" key={index}>
            <video title={'1'} poster={getThubmVideo(elem, pinned, ASSETS_MEDIA_PREFIX)} controls>
              <source src={ASSETS_MEDIA_PREFIX.concat(elem)} type="video/webm"></source>
              <track default kind="captions" srcLang="en" src="" />
            </video>
          </div>
        );
      });
    return items;
  };
  const renderScreenshots = (): JSX.Element[] | JSX.Element => {
    const results = screenshootsSmall?.slice(0, 7)?.map((result: string, index: number) => {
      return (
        <div className={index === 3 ? 'screenshoots-complete' : 'screenshoots-three'} key={index}>
          {screenshots && (
            <div
              className={index === 3 ? '' : 'imageContainer'}
              onClick={(): void => {
                setCategoryPreviewList(screenshootsSmall);
                setIndexImageSelected(index);
                setimageModal('preview');
                const bigImages = screenshots?.filter((elem: string) => {
                  return elem.includes(displayName(result)) && elem.includes('-');
                });
                bigImages?.length > 0 ? setImageBig(formatURL(bigImages[0])) : setImageBig(formatURL(result));
              }}
            >
              <div
                className="image"
                style={{
                  backgroundImage: `url(${ASSETS_MEDIA_PREFIX.concat(formatURL(result))})`,
                }}
              ></div>
            </div>
          )}
        </div>
      );
    });
    return results;
  };
  const renderWallpapers = (): JSX.Element[] | JSX.Element => {
    const results = wallpapers
      ?.filter((elem: string) => {
        return !elem.includes('-');
      })
      ?.map((result: string, key: number) => {
        return (
          <>
            <div
              className={'imageContainer'}
              onClick={(): void => {
                setimageModal('wallpapers');
                setListDownload(wallpapersSmall);
                setIndexImageSelected(key);
              }}
              key={key}
            >
              <div
                className="image"
                style={{
                  backgroundImage: `url(${ASSETS_MEDIA_PREFIX.concat(formatURL(result))})`,
                }}
              ></div>
            </div>
            <div className="statement">
              <div className={'line'} />
              <p>{displayName(result)}</p>
            </div>
          </>
        );
      });
    return results;
  };
  const renderConceptArt = (): JSX.Element[] | JSX.Element => {
    const results = conceptArtSmall?.map((result: string, index: number) => {
      return (
        <>
          <div
            className={'imageContainer'}
            onClick={(): void => {
              setCategoryPreviewList(conceptArtSmall);
              setIndexImageSelected(index);
              setimageModal('preview');
              const bigImages = conceptArtSmall?.filter((elem: string) => {
                return elem.includes(displayName(result)) && elem.includes('-');
              });
              bigImages?.length > 0 ? setImageBig(formatURL(bigImages[0])) : setImageBig(formatURL(result));
            }}
            key={index}
          >
            <div
              className="image-concept"
              style={{
                backgroundImage: `url(${ASSETS_MEDIA_PREFIX.concat(formatURL(result))})`,
              }}
            ></div>
          </div>
          <div className="statement">
            <div className={'line'} />

            <p>{displayName(result)}</p>
          </div>
        </>
      );
    });
    return results;
  };
  const renderDocuments = (): JSX.Element[] | JSX.Element => {
    const results = documentsNoThumb?.map((result: string, key: number) => {
      return (
        <div className={'document'} key={key}>
          <div
            className="container"
            onClick={(): void => {
              setimageModal('documents');
              setListDownload(documentsNoThumb);
              setIndexImageSelected(key);
            }}
            key={key}
            style={{
              backgroundImage: `url(${getThubmVideo(result, documents, ASSETS_MEDIA_PREFIX)})`,
            }}
          />
          <div className={'header'}>
            <p>{displayName(result)}</p>
          </div>
        </div>
      );
    });
    return results;
  };
  const renderOptions = (): JSX.Element[] | JSX.Element => {
    const results = wallpapers
      ?.filter((elem: any) => elem.includes('-') && elem.includes(displayName(wallpapersSmall[indexImageSelected])))
      .map((result: string, key: number) => {
        return (
          <a
            key={key}
            href={`${ASSETS_MEDIA_PREFIX.concat(formatURL(result))}`}
            target="_blank"
            rel="noreferrer"
            download
          >
            {result.split('-')[1].split('.')[0]}
          </a>
        );
      });
    if (results?.length === 0)
      return (
        <a
          href={`${ASSETS_MEDIA_PREFIX.concat(formatURL(wallpapersSmall[indexImageSelected]))}`}
          target="_blank"
          rel="noreferrer"
        >
          {displayName(wallpapersSmall[indexImageSelected])}
        </a>
      );
    return results;
  };

  return (
    <div className="media-container">
      <MediaNavBar />
      <PreviewComponent
        imageBig={imageBig}
        imageModal={imageModal}
        categoryPreviewList={categoryPreviewList}
        closeModal={closeModal}
        indexImageSelected={indexImageSelected}
        setImageBig={setImageBig}
        setIndexImageSelected={setIndexImageSelected}
      />
      <DownloadComponent
        imageModal={imageModal}
        listDownload={listDownload}
        closeModal={closeModal}
        indexImageSelected={indexImageSelected}
        documents={documents}
        renderOptions={renderOptions}
      />
      <div className="mediaCarousel">
        <h1 className={'mediaHeader'}>{main_title}</h1>
        <div className={'carouselSection'}>
          {pinned && (
            <Carousel
              infinite={true}
              autoPlay={false}
              autoPlaySpeed={5000}
              transitionDuration={1000}
              keyBoardControl={true}
              responsive={responsive}
              itemClass="carousel-item-padding"
            >
              {renderVideosIntro()}
            </Carousel>
          )}
        </div>
      </div>
      <div className="screenShotSection">
        <div className="mediaHeader">
          <p className="left">{cat_screenshoots}</p>
          <div className="rightSection">
            <LinkComponent link={`${getUrlFromEnglish('/media/screenshots')}`} tabIndex={0}>
              <p className="right">
                {see_all}
                {screenshootsSmall && `(${screenshootsSmall?.length})`}
              </p>
              <div className="submit"> </div>
            </LinkComponent>
          </div>
        </div>
        <div className="screenshots-grid">{renderScreenshots()}</div>
      </div>
      <div className="videoSection">
        <div className="mediaHeader">
          <p className="left">{cat_videos}</p>
          <div className="rightSection">
            <LinkComponent link={`${getUrlFromEnglish('/media/videos')}`} tabIndex={0}>
              <p className="right">
                {see_all} {videosNoThumb && `(${videosNoThumb?.length})`}
              </p>
              <div className="submit"> </div>
            </LinkComponent>
          </div>
        </div>
        <div className="videoBar">
          <div className="videoPlayList">{renderPlaylists()}</div>
          <div className={'videoContainer'}>
            {videos && (
              <ReactPlayer
                className="react-player"
                playing={isPlaying}
                url={
                  playlistActive[0]
                    ? ASSETS_MEDIA_PREFIX.concat(playlistActive[indexCurrentPlaylist])
                    : `${ASSETS_MEDIA_PREFIX.concat(firstVideoPlaylist)}`
                }
                width="100%"
                height="100%"
                // muted={true}
                controls={true}
                onEnded={(): void => {
                  setIndexCurrentPlaylist(indexCurrentPlaylist + 1);
                  if (indexCurrentPlaylist === playlistActive?.length - 1) {
                    setIndexCurrentPlaylist(0);
                  }
                }}
              />
            )}
          </div>
        </div>

        {playlistActive[0] && (
          <div className={'carouselVideoSection'}>
            <Carousel
              infinite={true}
              autoPlay={false}
              autoPlaySpeed={5000}
              transitionDuration={1000}
              keyBoardControl={true}
              responsive={responsiveVideo}
              itemClass="carousel-item-padding"
            >
              {playlistActive[0] && renderVideos()}
            </Carousel>
          </div>
        )}
      </div>
      <div className="wallPaperConceptArt">
        <div className="padding">
          <div className="mediaHeader">
            <p className="left">{cat_wallpapers}</p>
            <div className="rightSection">
              <LinkComponent link={`${getUrlFromEnglish('/media/wallpapers')}`} tabIndex={0}>
                <p className="right">
                  {see_all} {wallpapersSmall && `(${wallpapersSmall?.length})`}
                </p>
                <div className="submit"> </div>
              </LinkComponent>
            </div>
          </div>
        </div>
        {wallpapers && (
          <>
            <div className="carouselScroll">
              <Carousel
                infinite={true}
                autoPlay={false}
                autoPlaySpeed={5000}
                transitionDuration={1000}
                keyBoardControl={true}
                responsive={responsiveWallArt}
                itemClass="carousel-item"
              >
                {renderWallpapers()}
              </Carousel>
            </div>
          </>
        )}
      </div>
      <div className="wallPaperConceptArt conceptArt">
        <div className="padding">
          <div className="mediaHeader">
            <p className="left">{cat_concept_art}</p>
            <div className="rightSection">
              <LinkComponent link={`${getUrlFromEnglish('/media/conceptart')}`} tabIndex={0}>
                <p className="right">
                  {see_all} {conceptArtSmall && `(${conceptArtSmall?.length})`}
                </p>
                <div className="submit"> </div>
              </LinkComponent>
            </div>
          </div>
        </div>
        {conceptart && (
          <div className="carouselScroll">
            <Carousel
              infinite={true}
              autoPlay={false}
              autoPlaySpeed={5000}
              transitionDuration={1000}
              keyBoardControl={true}
              responsive={responsiveWallArt}
              itemClass="carousel-item"
            >
              {renderConceptArt()}
            </Carousel>
          </div>
        )}
      </div>
      <div className="documents">
        <div className="mediaHeader">
          <p className="left">{cat_documents}</p>
          <div className="rightSection">
            <LinkComponent link={`${getUrlFromEnglish('/media/document')}`} tabIndex={0}>
              <p className="right">
                {see_all} {documentsNoThumb && `(${documentsNoThumb?.length})`}
              </p>
              <div className="submit"> </div>
            </LinkComponent>
          </div>
        </div>
        <div className="documentGrid">{renderDocuments()}</div>
      </div>
    </div>
  );
}
