import Cookies from 'universal-cookie';
import config from '../../../config';

declare global {
  interface Window {
    waitlist: Array<any>;
  }
}

export enum ServiceType {
  Required = 'required',
  Performance = 'performance',
  Functional = 'functional',
  Targeting = 'targeting',
  Social = 'social',
}

export enum Consent {
  Agree = 'agree',
  Decline = 'decline',
  Unknown = 'not_defined',
}

export interface ConsentState {
  // NB: you cannot opt-out of strictly necessary cookies
  [ServiceType.Performance]?: Consent;
  [ServiceType.Functional]?: Consent;
  [ServiceType.Targeting]?: Consent;
  [ServiceType.Social]?: Consent;
  showSimplePopup?: boolean;
  showDetailedPopup?: boolean;
}

export function saveToCookie(state: ConsentState): void {
  const data = {
    [ServiceType.Performance]: state[ServiceType.Performance],
    [ServiceType.Functional]: state[ServiceType.Functional],
    [ServiceType.Targeting]: state[ServiceType.Targeting],
    [ServiceType.Social]: state[ServiceType.Social],
  };
  const flags = { path: '/', maxAge: 60 * 60 * 24 * 395, secure: !config.local, sameSite: true };
  new Cookies().set(config.consentCookieName, data, flags);
  unblockServices(state);
}

export function loadFromCookie(): ConsentState {
  const cookie = new Cookies().get(config.consentCookieName) || {};
  const state: ConsentState = {
    [ServiceType.Performance]: cookie[ServiceType.Performance] || Consent.Unknown,
    [ServiceType.Functional]: cookie[ServiceType.Functional] || Consent.Unknown,
    [ServiceType.Targeting]: cookie[ServiceType.Targeting] || Consent.Unknown,
    [ServiceType.Social]: cookie[ServiceType.Social] || Consent.Unknown,
  };
  state.showSimplePopup = false;
  state.showDetailedPopup = false;
  unblockServices(state);
  return state;
}

export function unblockServices(state: ConsentState): void {
  if (state[ServiceType.Performance] === Consent.Agree) {
    unblock('consent/performance');
  }
  if (state[ServiceType.Functional] === Consent.Agree) {
    unblock('consent/functional');
  }
  if (state[ServiceType.Targeting] === Consent.Agree) {
    unblock('consent/targeting');
  }
  if (state[ServiceType.Social] === Consent.Agree) {
    unblock('consent/social');
  }
}

function unblock(mimeType: string) {
  // Enable script and iframe tags with the correct type
  for (const element of window.document.querySelectorAll(`*[type="${mimeType}"]`)) {
    // Create new <script> element
    const replacement = document.createElement(element.tagName);
    // Copy attributes
    for (const attr of element.attributes) {
      if (attr.name === 'data-src') {
        replacement.setAttribute('src', attr.value);
      } else if (attr.name !== 'type') {
        replacement.setAttribute(attr.name, attr.value);
      }
    }
    // Replace old element with the new one
    element.replaceWith(replacement);
  }

  // Execute registered scripts with the correct type
  for (const [type, callback] of window.waitlist) {
    if (type === mimeType) {
      callback();
    }
  }

  // Remove them from the list too
  window.waitlist = window.waitlist.filter(([type]) => type !== mimeType);
}
