import React, { useEffect } from 'react';
import RichText, { asText } from '../../../services/prismic/prismic.richtext.service';
import { useReleases } from '../../../stores/selectors/releases.selector';
import { getPageID, getUrlFromEnglish } from '../../../services/i18n/i18n.url.service';
import { getSlug } from '../../news/news.service';
import { Link, useLocation, useParams } from 'react-router-dom';
import { goto } from '../../../stores/selectors/router.selector';
import { usePrismic } from '../../../hooks/prismic.hook';

const PatchComponent = (): JSX.Element => {
  const {
    contents: { releasesStories },
  } = useReleases();
  const { uid } = useParams();
  const { pathname } = useLocation();
  const {
    result: { releases },
  } = usePrismic('launcher_page');
  useEffect(() => {
    document.onkeydown = function (e) {
      // keycode for F5 function
      if (e.keyCode === 116) {
        return false;
      }
    };
  }, []);
  if (!releasesStories) return <></>;
  const result = releasesStories?.find((r: any) => getSlug(r) === uid) || releasesStories[0];

  if (getPageID(pathname) === 'launcher-patch' && releasesStories[0]) {
    goto(`${getUrlFromEnglish('/launcher/patch')}/${getSlug(releasesStories[0])}`);
  }

  const myPosts: JSX.Element[] = releasesStories.map((result: any, key: number) => {
    return (
      <Link
        className={getSlug(result) === uid ? 'selected' : ''}
        to={`${getUrlFromEnglish('/launcher/patch')}/${getSlug(result)}`}
        key={key}
      >
        {asText(result?.title)}
      </Link>
    );
  });

  return (
    <div className="patch-container">
      <div className="patch-left-section">
        <RichText className="patch-section-title" render={result?.title} />
        <div className="patch-section-dec">
          <RichText render={result?.description} />
        </div>
        <p className="patch-section-date">{result?.date?.toString()}</p>
        <div className="patch-section-content">
          <RichText render={result?.content} />
        </div>
      </div>
      <div className="patch-right-section">
        <h3 className="releases">{releases}</h3>
        {myPosts}
      </div>
    </div>
  );
};
export default PatchComponent;
