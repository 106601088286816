import { store } from '../../stores/app.store.config';

export const getUser = (): any => {
  const state = store.getState();
  const { user } = state.authBranch;
  return user;
};

export function getToken(): any {
  const user = getUser();
  const token = user ? (user.token ? user.token : user.signInUserSession.idToken.jwtToken) : null;
  return { token, refreshToken: token ? user.refreshToken : null };
}
