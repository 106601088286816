import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import subscribeToNewsletter from '../../../services/authentication/newsletter.service';
import { usePrismic } from '../../../hooks/prismic.hook';
import PrismicRichText from '../../../services/prismic/prismic.richtext.service';

const BlockNewsLetterSignInComponent = (props: any): JSX.Element => {
  const { handleSubmit, register, errors } = useForm();
  const [notice, setNotice] = useState({ type: '', message: '' });

  const { contents } = usePrismic('news_page');

  if (!contents) {
    return <></>;
  }

  const {
    results: [
      {
        data: { popup_disclaimer, popup_header },
      },
    ],
  } = contents;

  const onSubmit = async (values: Record<string, any>): Promise<void> => {
    const { email } = values;
    try {
      await subscribeToNewsletter(email);
      setNotice({ type: 'success', message: 'Please check your inbox to confirm' });
    } catch (error: any) {
      setNotice({
        type: 'failure',
        message: error?.response?.data?.message || 'There was a problem with the registration',
      });
    }
    cleanNotice();
  };
  const cleanNotice = () => {
    setTimeout(() => {
      setNotice({ type: '', message: '' });
    }, 3000);
  };

  return (
    <>
      <div className="newsletter-modal">
        <div className="box">
          <div className="wrapper">
            <div
              onClick={() => {
                if (props?.setPopup) props?.setPopup(false);
                window.document.body.style.overflowY = 'scroll';
              }}
              className="exit"
            />
            <PrismicRichText render={popup_header} />
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="input-icons">
                <div className="icon-container">
                  <div className="icon icon-email"></div>
                </div>
                <input
                  type={'email'}
                  name={'email'}
                  className="util-input-text"
                  placeholder={'mail@mail.com'}
                  ref={register({
                    required: 'Requires Email',
                  })}
                />
                <div className="icon-container">
                  <div className={!errors.email ? 'icon icon-check' : 'icon icon-loading'}></div>
                </div>
              </div>
              <button type="submit" className="submit theme-button">
                <div className="icon"></div> <span>Sign Up</span>
              </button>
              {notice?.type && (
                <div className={notice.type.includes('success') ? 'notice success' : 'notice failure'}>
                  {notice.message}
                </div>
              )}
              {errors?.email && <div className="notice failure">{errors.email.message}</div>}
              <div className="policy">
                <PrismicRichText render={popup_disclaimer} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default BlockNewsLetterSignInComponent;
