import axios from 'axios';
import { getUser } from '../../services/authentication/attributes.service';
import { getEnglishRouteByID, getShortLocale, getUrlFromEnglish } from '../../services/i18n/i18n.url.service';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { triggerActionGTM, Action } from '../../services/optimization/ecommerce.service';
import { goto } from '../selectors/router.selector';
import { addAcquisition } from '../../services/optimization/acquisition.service';
import { getLp } from '../../services/optimization/landingpagecheck';
import { store } from '../app.store.config';
import { Order } from '../../models/purchase/purchase.model';

let paymentToken: {
  data: {
    token: string;
    sandbox: boolean;
    order: Order;
  };
};

export const makePurchase = createAsyncThunk(
  'purchase/makePurchase',
  async ({
    campaignId,
    productId,
    pricingId,
    discount,
  }: {
    campaignId: string;
    productId: string;
    pricingId: string;
    discount: number;
  }): Promise<any> => {
    const locale = getShortLocale();
    const { id: userId, token } = getUser();
    if (paymentToken == null) {
      paymentToken = await axios.post(
        `/api/orders/payment/token?lang=${locale}`,
        {
          customerId: userId,
          campaignId: campaignId,
          basket: [
            {
              quantity: 1,
              productId,
              pricingId,
            },
          ],
          discount,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );
    }

    const { order, token: access_token, sandbox } = paymentToken.data;
    return new Promise((resolve, reject) => {
      const widget = (window as any).XPayStationWidget;
      widget.init({
        access_token,
        sandbox,
        lightbox: {
          closeByClick: false,
          closeByKeyboard: false,
        },
      });
      widget.open();

      const { LOAD, STATUS_TROUBLED, STATUS_DONE, CLOSE } = widget.eventTypes;

      widget.on(LOAD, () => {
        triggerActionGTM(Action.PaymentsMethodShown);
      });
      widget.on(STATUS_TROUBLED, () => {
        // only triggerred when the secure 3d number is wrong. Is NOT triggered when the credit card number is wrong.
        reject();
      });

      widget.on(CLOSE, () => {
        if (!(window as any).purchaseCompleted) {
          window.location.reload();
        }
      });

      widget.on(STATUS_DONE, () => {
        addAcquisition(getUser()).then(() => {
          triggerActionGTM(Action.Purchase, { order, productId });

          (window as any).purchaseCompleted = true;

          (window as any).document.querySelector('body').className = 'widgetHidden';

          const lp = getLp() || '';
          let url = '';
          const state = store.getState();
          const { experiment, variant } = state.analyticsBranch;
          if (lp) {
            const path = getEnglishRouteByID(experiment ? 'buy-thankyou-lp-exp' : 'buy-thankyou-lp');
            url = getUrlFromEnglish(path);
          } else {
            url = getUrlFromEnglish(getEnglishRouteByID('buy-thankyou'));
          }
          url = url.replace(':lp', lp);
          url = url.replace(':experiment', experiment);
          url = url.replace(':variant', variant);
          console.log(`${url}${window.location.search}`);
          goto(`${url}${window.location.search}`);

          resolve(order);
        });
      });
    });
  },
);
