import React, { useState, useEffect } from 'react';
import { usePrismic } from '../../../hooks/prismic.hook';
import AccountBetaGiftCodes from './account-beta-gift-codes.component';
import { useAuth } from '../../../stores/selectors/auth.selector';
import { activateKey } from '../../../stores/actions/beta-keys.action';
import { useAppDispatch } from '../../../stores/app.store.config';
import { refreshToken } from '../../../stores/actions/auth.action';
import { getSubscriptions } from '../../../stores/actions/subscriptions.actions';
import { useSubscriptions } from '../../../stores/selectors/subscriptions.selector';
import PrismicRichText, { asText } from '../../../services/prismic/prismic.richtext.service';
import { useBetaKeys } from '../../../stores/selectors/beta-keys.selector';
import LoadingComponent from '../../loading/loading.component';
import { ErrorComponent } from '../../error/error.component';
import { resetSuccess } from '../../../stores/slices/betaKeys.slice';
import { goto } from '../../../stores/selectors/router.selector';
import qs from 'qs';
import { displayDate, getPrismicError } from '../../../services/utils/utils.service';
import { getPurchasedProducts } from '../../../services/plans/purchase.utils';
import AccountProductsModalsComponent from './account-products.modals.component';
import { getUrlFromEnglish } from '../../../services/i18n/i18n.url.service';
import { givePurchaseGameTime } from '../../../services/api/identity/steam-service';
import { useLocation } from 'react-router-dom';

const AccountProducts = (props: any): JSX.Element | null => {
  const { user } = useAuth();

  const dispatch = useAppDispatch();

  const [code, setCode] = useState('');

  const [ownership, setOwnership] = useState('');

  const [errorSteam, setErrorSteam] = useState('');

  const { loading, success: gameTimeCodeSuccess, error } = useBetaKeys();

  const { contents: contentErrors } = usePrismic('errors');

  const activateKeyClick = async (): Promise<void> => {
    dispatch(activateKey(code));
    setTimeout(() => {
      dispatch(refreshToken());
    }, 1000);
  };
  const {
    result: {
      page_title,
      success_message,
      game_time_code_label,
      add_button,
      active_message,
      months_message,
      continue_playing,
      expired_message,
    },
    data: { redeem_gametime_title, redeem_gametime_desc, redeem_gametime_button },
    contents,
  } = usePrismic('account_products');

  useEffect(() => {
    dispatch(resetSuccess());
  }, []);
  useEffect(() => {
    const steam = document?.getElementById('steam');
    if (steam) steam?.scrollIntoView({ behavior: 'smooth' });
  }, [props?.elegibility]);
  useEffect(() => {
    if (!ownership) setOwnership(user?.details?.ownership);
  }, [ownership]);

  const { search } = useLocation();
  const { code: codeInURL } = qs.parse(search.slice(1));

  useEffect(() => {
    if (codeInURL) {
      setCode(String(codeInURL));
    }
  }, [codeInURL]);

  const { subscription } = useSubscriptions();

  const purchasedProducts = getPurchasedProducts(user, subscription, months_message);

  const isCancelled = (product: any): boolean =>
    ['NonRenewing', 'Canceled'].indexOf(product?.subscription?.status) !== -1;
  const hasLifetimeSub = (): boolean => purchasedProducts[0]?.product?.name?.includes('Lifetime');

  useEffect(() => {
    dispatch(getSubscriptions());
  }, [dispatch, gameTimeCodeSuccess]);

  console.log(purchasedProducts);
  const redeemGametimeSteam = async () => {
    try {
      await givePurchaseGameTime();
      props?.setElegibility(false);
      dispatch(refreshToken());
    } catch (error: any) {
      setErrorSteam(getPrismicError(errors, error.response.data));
    }
  };
  // const productPurchasedText = user?.allPlans
  //   ?.map((obj: Record<string, any>) => obj?.products?.[0]?.product?.name)
  //   .join(', ');
  const location = useLocation();
  const success = Boolean(location.search.includes('user_id'));

  if (!contents || !contentErrors) return <></>;

  const {
    results: [
      {
        data: { no_products_message, sucess_message_postpone, page_desc_steam },
      },
    ],
  } = contents;

  const {
    results: [
      {
        data: { errors },
      },
    ],
  } = contentErrors;

  return (
    <div className="account-prod-container">
      <h1 className="account-title">{page_title}</h1>
      {ownership?.includes('steam') && (
        <div className="account-desc">
          <PrismicRichText render={page_desc_steam} />
        </div>
      )}
      {Boolean(success || gameTimeCodeSuccess) && (
        <>
          <div className="green-box">
            <div className="green-box-icon"></div>
            {purchasedProducts.length < 2 && <p>{success_message}</p>}
            {purchasedProducts.length > 1 && (
              <div className="green-box-content">
                <PrismicRichText render={sucess_message_postpone} />
              </div>
            )}
          </div>
        </>
      )}
      {purchasedProducts && purchasedProducts.length
        ? purchasedProducts.map((product: Record<string, any>, index: number) => (
            <>
              {!(hasLifetimeSub() && isCancelled(product)) && (
                <div className="row-option" key={index}>
                  <div className="row-img">
                    <div></div>
                  </div>
                  <div className="row-text">
                    <h2 key={index} className="text-title">
                      {product.name}
                    </h2>
                    {product.subscription && new Date(product?.subscription?.dateNextCharge) >= new Date() && (
                      <p className="text-subtitle">
                        {active_message} {displayDate(product.subscription.dateNextCharge)}
                      </p>
                    )}
                    {product.subscription && new Date(product?.subscription?.dateNextCharge) < new Date() && (
                      <p className="text-subtitle">
                        {expired_message} {displayDate(product.subscription.dateNextCharge)}
                      </p>
                    )}
                    {Boolean(!product.subscription && product.gameTime) && new Date(product?.gameTime) >= new Date() && (
                      <p className="text-subtitle">
                        {active_message} {displayDate(product.gameTime)}
                      </p>
                    )}
                    {Boolean(!product.subscription && product.gameTime) && new Date(product?.gameTime) < new Date() && (
                      <p className="text-subtitle">
                        {expired_message} {displayDate(product.gameTime)}
                      </p>
                    )}
                  </div>
                  {new Date(product?.subscription?.dateNextCharge) >= new Date() && (
                    <div className="row-button">
                      <AccountProductsModalsComponent product={product} ownership={ownership} />
                    </div>
                  )}
                  {(new Date(product?.subscription?.dateNextCharge) < new Date() ||
                    (product.gameTime && new Date(product?.gameTime) < new Date())) && (
                    <div className="row-button">
                      <button
                        onClick={() => {
                          goto(getUrlFromEnglish('/buy'));
                        }}
                      >
                        <span> {continue_playing}</span>
                      </button>
                    </div>
                  )}
                </div>
              )}
            </>
          ))
        : !user?.details?.hasMyDUAccess && (
            <div className="notice">
              <PrismicRichText render={no_products_message} />
            </div>
          )}
      {user?.details?.hasMyDUAccess && (
        <div className="row-option">
          <div className="row-img">
            <div></div>
          </div>
          <div className="row-text">
            <h2 className="text-title">MyDU Purchase</h2>
          </div>
        </div>
      )}
      {props?.elegibility && (
        <>
          <div className="line" id="steam" />
          <div className="account-security-container animated bounce">
            <div className="section-twofa">
              <div className="section-corners">
                <div className="section-padding">
                  <div className="section-psw-title">
                    <PrismicRichText render={redeem_gametime_title} />
                  </div>
                  <div className="section-psw-desc">
                    <PrismicRichText render={redeem_gametime_desc} />
                  </div>

                  <button type="button" className={'theme-button-spec-positive steam'} onClick={redeemGametimeSteam}>
                    <div className="theme-button-container">
                      <div />
                      <span>{asText(redeem_gametime_button)}</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
            {errorSteam && <p className="error">{errorSteam}</p>}
          </div>
        </>
      )}
      <div className="line" />
      <div className="input-group-code">
        <label className="">{game_time_code_label}</label>
        <div className="input-row">
          <div className="input-box">
            <div className="icon code-icon" />
            <input
              type="text"
              placeholder="1234567890987654"
              value={code}
              onChange={({ target: { value } }): void => setCode(value)}
            />
          </div>
          <button className="theme-button" onClick={activateKeyClick}>
            <div className="theme-button-container">
              <div className="send" />
              <span>{add_button}</span>
            </div>
          </button>
        </div>
        <LoadingComponent loading={loading} />
        {error && ['fetchBetaKeys', 'activateKey'].includes(error.from) && (
          <div className="error">
            <ErrorComponent error={error} />
          </div>
        )}
      </div>
      <AccountBetaGiftCodes />
    </div>
  );
};

export default AccountProducts;
