import { useEffect } from 'react';
import { useAppDispatch } from '../../stores/app.store.config';
import { setReleases } from '../../stores/slices/releases.slice';
import { getFormattedDate } from '../utils/utils.service';
import { usePrismic } from '../../hooks/prismic.hook';

const useRelease = (): void => {
  const dispatch = useAppDispatch();

  const { contents: releaseStories } = usePrismic('launcher_release_notes');

  useEffect(() => {
    const fetchNews = async (): Promise<void> => {
      dispatch(
        setReleases({
          releasesStories: (releaseStories.results.slice() || [])
            .sort((a: any, b: any) => Date.parse(b.data.date) - Date.parse(a.data.date))
            .map((obj: Record<string, any>) => {
              const { date } = obj.data;
              return { ...obj.data, date: getFormattedDate(date) };
            }),
        }),
      );
    };
    if (releaseStories) {
      fetchNews();
    }
  }, [dispatch, releaseStories]);
};

export default useRelease;
