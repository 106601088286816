import React, { useEffect, useState } from 'react';
import LanguageMenu from './navbarsubcomponents/languagemenu';
import LoginModal from './navbarsubcomponents/loginmodal';
import ProfileSubMenu from './navbarsubcomponents/profilemenu';
import { useI18n } from '../../stores/selectors/i18n.selector';
import { useAuth } from '../../stores/selectors/auth.selector';
import NavBarMobile from './navbarsubcomponents/navbarmobile';
import Media from 'react-media';
import { usePrismic } from '../../hooks/prismic.hook';
import LeftNavBar from './navbarsubcomponents/leftnavbar';
import LinkComponent from '../link/link.component';
import { getPageID } from '../../services/i18n/i18n.url.service';
import { useLocation } from 'react-router-dom';

export default function NavBarComponent({
  menuClicked,
  setMenuClicked,
}: {
  menuClicked: any;
  setMenuClicked: any;
}): JSX.Element {
  const { locale } = useI18n();
  const { pathname } = useLocation();
  const menuPrismic= usePrismic('menu')
  const {
    result: { login, play, logo, wishlist, wishlist_link },
    contents,
  } = menuPrismic;

  const { user, isAuthenticated } = useAuth();
  const [icon, iconToggle] = useState(1);
  let key = 0;
  const displayName = user?.displayName?.length > 9 ? user?.displayName?.slice(0, 9) + '...' : user?.displayName;

  useEffect(() => {
    const overlay = document.getElementById('popup-overlay-nav');
    if (overlay) {
      overlay.addEventListener('click', function () {
        setMenuClicked(-1 * Math.sign(menuClicked));
        iconToggle(-1 * Math.sign(icon));
      });
    }
  });

  if (!contents) return <div></div>;

  const {
    results: [
      {
        data: { body },
      },
    ],
  } = contents;

  if (body) {
    const newArray = body.map((obj: any, index: any, arr: any) => {
      const object: Record<string, any> = { name: obj.primary.name, link: obj.primary.link };
      if (obj.primary.name && !obj.primary.link) {
        for (let i = index + 1; i < arr.length; i++) {
          if (arr[i].slice_type === 'navbar_categories') {
            const key = arr[i].primary.headername;

            object[key] = arr[i].items;
          } else {
            break;
          }
        }
      }
      return object;
    });
    const renderSwitch = (language: any): string => {
      switch (language) {
        case 'fr-fr':
          return 'navbar navbar-fr';
        case 'de-de':
          return 'navbar navbar-de';
        default:
          return 'navbar';
      }
    };
    const pageID = getPageID(pathname);
    const filteredArray: JSX.Element[] = newArray.filter((obje: Record<string, any>) => obje.name);

    return (
      <>
        <Media
          queries={{
            small: '(max-width: 479px)',
            large: '(min-width: 480px)',
          }}
        >
          {(matches): JSX.Element => (
            <>
              {matches.small && <NavBarMobile filteredArray={filteredArray} logo={logo} menuPrismic={menuPrismic} />}

              {matches.large && (
                <section className={renderSwitch(locale)}>
                  <div className={'leftBar'}>
                    <div className={'navbar-item-logo'}>
                      <LinkComponent
                        label=""
                        tabIndex={0}
                        className="navbar-logo"
                        style={{
                          backgroundImage: `url(${logo})`,
                        }}
                        link={'/'}
                        onClick={() => {
                          setMenuClicked(-1);
                        }}
                      />
                    </div>
                    <div className={'navbar-item-icon'}>
                      <LinkComponent tabIndex={0} className="navbar-icon" label="" link="/" />
                      <LeftNavBar
                        filteredArray={filteredArray}
                        icon={icon}
                        setIcon={iconToggle}
                        menuClicked={menuClicked}
                        setMenuClicked={setMenuClicked}
                        menuPrismic={menuPrismic}
                      />
                    </div>
                  </div>
                  <div className={'middleBar'}>
                    <div className={'navbar-item'}>
                      <LinkComponent
                        tabIndex={0}
                        className="navbar-play"
                        id="navbar-play-fr"
                        link="/buy"
                        onClick={() => {
                          setMenuClicked(-1);
                        }}
                      >
                        <span>{play}</span>
                      </LinkComponent>
                    </div>
                    {wishlist_link && (
                      <div className={'navbar-item'}>
                        <LinkComponent
                          tabIndex={0}
                          className="navbar-play wishlist"
                          id="navbar-play-fr"
                          link={wishlist_link}
                          onClick={() => {
                            setMenuClicked(-1);
                          }}
                        >
                          <div />
                          <span>{wishlist}</span>
                        </LinkComponent>
                      </div>
                    )}
                  </div>
                  <div className={'rightBar'}>
                    {menuClicked > 0 && !pageID?.includes('login') && (
                      <div id="popup-overlay-nav" className={'popup-overlay-nav'}></div>
                    )}
                    <div className={'navbar-item first'}>
                      {menuClicked === 6 && (
                        <div className="line" style={{ height: '2px', backgroundColor: 'transparent' }}></div>
                      )}
                      <a
                        tabIndex={0}
                        className={'language'}
                        onClick={() => {
                          key = 5;
                          setMenuClicked(-(key + 1) * Math.sign(menuClicked));
                        }}
                      >
                        <div>
                          <i></i>
                        </div>
                        {locale.substring(0, 2)}
                        {menuClicked !== 6 ? <div className="menuIcon" /> : <div className="menuIcon menuIconClose" />}
                      </a>
                      {menuClicked === 6 && (
                        <div className="line" style={{ height: '2px', backgroundColor: 'white' }}></div>
                      )}

                      {menuClicked === 6 && <LanguageMenu />}
                    </div>
                    <div className={'navbar-item navbar-item-login'}>
                      {menuClicked === 7 && (
                        <div className="line" style={{ height: '2px', backgroundColor: 'transparent' }}></div>
                      )}
                      <a
                        className={`userlogin`}
                        tabIndex={0}
                        onClick={() => {
                          key = 6;
                          setMenuClicked(-(key + 1) * Math.sign(menuClicked));
                          if (pageID?.includes('login')) setMenuClicked(-1);
                        }}
                      >
                        <div>
                          <i></i>
                        </div>
                        <p>{isAuthenticated && displayName ? displayName : login}</p>
                        {menuClicked !== 7 && !pageID?.includes('login') ? (
                          <div className="menuIcon" />
                        ) : (
                          <div className="menuIcon menuIconClose" />
                        )}
                      </a>
                      {menuClicked === 7 && !pageID?.includes('login') && (
                        <div className="line" style={{ height: '2px', backgroundColor: 'white' }}></div>
                      )}
                      {menuClicked === 7 && isAuthenticated && (
                        <ProfileSubMenu setMenuClicked={setMenuClicked} menuClicked={menuClicked} />
                      )}
                      {/* {menuClicked === 7 && !isAuthenticated && <LoginMenu />} */}
                      {menuClicked === 7 && !pageID?.includes('login') && !isAuthenticated && (
                        <LoginModal setMenuClicked={setMenuClicked} menuClicked={menuClicked} />
                      )}
                    </div>
                  </div>
                </section>
              )}
            </>
          )}
        </Media>
      </>
    );
  } else {
    return <> </>;
  }
}
