import PrismicRichText, { asText } from '../../../services/prismic/prismic.richtext.service';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { AuthState } from '../../../models/authentication/auth.model';
import { useAuth } from '../../../stores/selectors/auth.selector';
import { usePrismic } from '../../../hooks/prismic.hook';
import { useAppDispatch } from '../../../stores/app.store.config';
import { refreshToken } from '../../../stores/actions/auth.action';
import { displayDate, getPrismicError } from '../../../services/utils/utils.service';
import AsyncSelect from 'react-select/async';
import ReactPaginate from 'react-paginate';
import Media from 'react-media';

const AccountDacs = (): JSX.Element | null => {
  const { register, handleSubmit, reset } = useForm();
  const { user }: AuthState = useAuth();
  const [amount, setAmount] = useState({ quantity: null });
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [dacsEnabled, setDacsEnabled] = useState(false);
  const [notice, setNotice] = useState({ type: '', message: '' });
  const [selectedOption, setSelectedOption] = useState(null);

  const dispatch = useAppDispatch();
  const handleChange = (selectedOption: any) => {
    setSelectedOption(selectedOption);
  };

  const {
    data: {
      page_title,
      page_description,
      dacs_disabled,
      dacs_disabled_description,
      dac_image_label,
      dac_image,
      section_convert_title,
      section_convert_desc,
      section_convert_label,
      section_convert_button_label,
      section_convert_disclaimer,
      section_transfer_title,
      section_transfer_desc,
      section_transfer_label_amount,
      section_transfer_label_whom,
      section_transfer_button_label,
      section_transfer_disclaimer,
      section_history_title,
      table_column_1,
      table_column_2,
      table_column_3,
      table_column_4,
    },
  } = usePrismic('account_dacs');

  const { contents: prismicContent } = usePrismic('errors');

  useEffect(() => {
    getDacsStatus();
    getNumberOfDacs();
  }, []);

  useEffect(() => {
    getUserHistory(null, null, null);
    getUserHistory(1, 5, null);
  }, [amount]);

  const handlePageClick = (event: any) => {
    getUserHistory(event.selected + 1, 5, null);
  };

  const getNumberOfDacs = async () => {
    try {
      const response = await axios.get(`/api/inventory/dualaccesscoupon/count/me`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setAmount(response.data);
    } catch (err: any) {}
  };
  const getDacsStatus = async () => {
    try {
      const response = await axios.get(`/api/inventory/settings/dac-enabled`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setDacsEnabled(response.data);
    } catch (err: any) {}
  };
  const getUserHistory = async (pageNumber: any, pageSize: any, offset: any) => {
    try {
      const response = await axios.get(`/api/inventory/dualaccesscoupon/user-history/me`, {
        params: { pageNumber, pageSize, offset },
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      if (pageNumber) setCurrentItems(response.data);
      if (!pageNumber) setPageCount(Math.ceil((response.data || []).length / 5));
    } catch (err: any) {}
  };
  const onSubmitCovert = async (values: Record<string, any>): Promise<void> => {
    try {
      if (values.dacsNumber) {
        const response = await axios.post(
          `/api/inventory/dualaccesscoupon/convert`,
          { ownerUserId: parseInt(user.id, 10), quantity: parseInt(values.dacsNumber, 10) },
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          },
        );
        setAmount(response.data);
        dispatch(refreshToken());
        setNotice({
          type: 'success',
          message: `You have converted ${parseInt(values.dacsNumber, 10)}DACs into gametime `,
        });
        cleanNotice();
        reset();
      }
    } catch (err: any) {
      setNotice({ type: 'error', message: getPrismicError(errorsPrismic, err.response.data) });
      cleanNotice();
    }
  };
  const onSubmitTranfer = async (values: Record<string, any>): Promise<void> => {
    if (!selectedOption) {
      setNotice({ type: 'error', message: 'You must select a user to send a dac' });
      cleanNotice();
      return;
    }
    try {
      if (values.dacsNumberTranfer) {
        const response = await axios.post(
          `/api/inventory/dualaccesscoupon/transfer`,
          {
            ownerUserId: parseInt(user.id, 10),
            receiverUserId: selectedOption['value'],
            quantity: parseInt(values.dacsNumberTranfer, 10),
          },
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          },
        );
        setAmount(response.data);
        dispatch(refreshToken());
        setNotice({
          type: 'success',
          message: `You have transferred ${parseInt(values.dacsNumberTranfer, 10)}DACs to user ${
            selectedOption['name']
          } `,
        });
        cleanNotice();
        reset();
        setSelectedOption(null);
      }
    } catch (err: any) {
      setNotice({ type: 'error', message: getPrismicError(errorsPrismic, err.response.data) });
      cleanNotice();
    }
  };
  const cleanNotice = () => {
    setTimeout(() => {
      setNotice({ type: '', message: '' });
    }, 4000);
  };
  const searchByDisplayName = async (displayname: string) => {
    try {
      if (displayname && displayname.length > 2) {
        const response = await axios.get(`/api/users/search-displayname/${displayname}`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        const transformData = response.data.map((item: any) => {
          return { name: item.displayName, value: item.id.toString() };
        });
        return transformData;
      }
    } catch (error: any) {
      console.log(error);
    }
  };
  const customStyles = {
    menuList: (provided: any) => ({
      ...provided,
      backgroundColor: '#141f23',
    }),
    menu: (provided: any) => ({
      ...provided,
      border: '1px solid #999999',
      color: 'white',
    }),
    option: (provided: any, state: any) => {
      const color = 'white';
      const backgroundColor = state.isFocused ? '#252B2D' : 'transparent';
      return {
        ...provided,
        color,
        backgroundColor,
      };
    },
    control: () => ({
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'transparent',
      border: '1px solid #999999',
      borderLeft: '0',
      width: '13vw',
      height: '3.38542vw',
    }),
    singleValue: (provided: any) => {
      const opacity = 1;
      const transition = 'opacity 300ms';
      const color = 'white';
      return { ...provided, opacity, transition, color };
    },
  };
  const customStylesMobile = {
    menuList: (provided: any) => ({
      ...provided,
      backgroundColor: '#141f23',
    }),
    menu: (provided: any) => ({
      ...provided,
      border: '1px solid #999999',
      color: 'white',
    }),
    option: (provided: any, state: any) => {
      const color = 'white';
      const backgroundColor = state.isFocused ? '#252B2D' : 'transparent';
      return {
        ...provided,
        color,
        backgroundColor,
      };
    },
    control: () => ({
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'transparent',
      border: '1px solid #999999',
      borderLeft: '0',
      width: '61vw',
      height: '13.38542vw',
    }),
    singleValue: (provided: any) => {
      const opacity = 1;
      const transition = 'opacity 300ms';
      const color = 'white';
      return { ...provided, opacity, transition, color };
    },
  };
  if (!page_title || !prismicContent) return <></>;

  const {
    results: [
      {
        data: { errors: errorsPrismic },
      },
    ],
  } = prismicContent;

  // const canConvert = (): boolean => {
  //   const roles = user?.role;
  //   const forbiddenRoles = ['AlphaAccess', 'BetaAccess', 'Subscriber', 'Emergency', 'Former Subscriber'];
  //   for (const elem1 in forbiddenRoles) {
  //     if (typeof roles !== 'string') {
  //       for (const elem2 in roles) if (forbiddenRoles[elem1] === roles[elem2]) return false;
  //     } else {
  //       if (forbiddenRoles[elem1] === roles) return false;
  //     }
  //   }
  //   return true;
  // };
  return (
    <div className="account-dacs-container">
      {notice?.type && (
        <div className={notice.type?.includes('success') ? 'account-notice' : 'account-notice error'}>
          <div />
          <p>{notice.message}</p>
        </div>
      )}
      {!dacsEnabled && (
        <div className="account-dacs-offline">
          <div className="account-dacs-text-container">
            <div className="dacs-offline-content">
              <div className="account-dacs-title">
                <PrismicRichText render={page_title} />
              </div>
              <div className="account-dacs-image" style={{ backgroundImage: `url(${dac_image?.url})` }}></div>
              <div className="account-dacs-main-msg">
                <PrismicRichText render={dacs_disabled} />
              </div>
              <div className="account-dacs-desc">
                <PrismicRichText render={dacs_disabled_description} />
              </div>
            </div>
          </div>
        </div>
      )}
      {dacsEnabled && (
        <>
          <div className="account-dacs-initial-content">
            <div className="account-dacs-text-container">
              <div className="account-dacs-title">
                <PrismicRichText render={page_title} />
              </div>
              <div className="account-dacs-desc">
                <PrismicRichText render={page_description} />
              </div>
            </div>
            <div className="account-dacs-image-container">
              <div className="box-corners">
                <div className="account-dacs-image" style={{ backgroundImage: `url(${dac_image?.url})` }}></div>
                <div className="account-dacs-subtext">
                  <p>
                    {asText(dac_image_label)} <i> {amount.quantity} DACS.</i>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="account-convert-dacs">
            <div className="section-corners">
              <div className="section-padding">
                <div className="section-psw-title">
                  <PrismicRichText render={section_convert_title} />
                </div>
                <div className="section-psw-desc">
                  <PrismicRichText render={section_convert_desc} />
                </div>
                <form
                  className="dacs-convert"
                  id="dacs-convert"
                  onSubmit={handleSubmit(onSubmitCovert)}
                  autoComplete="off"
                >
                  <div className="input-container">
                    <label htmlFor="dacsNumber">{asText(section_convert_label)}</label>
                    <div className="input-container__row">
                      <div className="iconWrapper dac"></div>
                      <input
                        className="input-field"
                        name="dacsNumber"
                        id="dacsNumber"
                        type={'number'}
                        ref={register({})}
                        min={1}
                        defaultValue={1}
                      />
                      <i className="iconWapper iconWapperRight">
                        <span className="icon" />
                      </i>
                    </div>
                  </div>
                  <button type="submit" form="dacs-convert" className="theme-button-spec-positive">
                    <div className="theme-button-container">
                      <div />
                      <span>{asText(section_convert_button_label)}</span>
                    </div>
                  </button>
                  <div className="disclamer">
                    <PrismicRichText render={section_convert_disclaimer} />
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="account-tranfer-dacs">
            <div className="section-corners">
              <div className="section-padding">
                <div className="section-psw-title">
                  <PrismicRichText render={section_transfer_title} />
                </div>
                <div className="section-psw-desc">
                  <PrismicRichText render={section_transfer_desc} />
                </div>
                <form
                  className="dacs-transfer"
                  id="dacs-transfer"
                  onSubmit={handleSubmit(onSubmitTranfer)}
                  autoComplete="off"
                >
                  <div className="input-container">
                    <label htmlFor="dacsNumberTranfer">{asText(section_transfer_label_amount)}</label>
                    <div className="input-container__row">
                      <div className="iconWrapper dac"></div>
                      <input
                        className="input-field"
                        name="dacsNumberTranfer"
                        id="dacsNumberTranfer"
                        type="number"
                        defaultValue={1}
                        min={1}
                        ref={register({})}
                      />
                      <i className="iconWapper iconWapperRight">
                        <span className="icon" />
                      </i>
                    </div>
                  </div>
                  <div className="input-container">
                    <label htmlFor="dacsNumber">{asText(section_transfer_label_whom)}</label>
                    <div className="input-container__row">
                      <div className="iconWrapper user"></div>
                      <Media
                        queries={{
                          small: '(max-width: 479px)',
                          large: '(min-width: 480px)',
                        }}
                      >
                        {(matches): JSX.Element => (
                          <>
                            {matches.large && (
                              <AsyncSelect
                                placeholder="Type to search name"
                                styles={customStyles}
                                value={selectedOption}
                                onChange={handleChange}
                                cacheOptions
                                defaultOptions
                                loadOptions={searchByDisplayName}
                                getOptionLabel={(e) => (e || { name: '' }).name}
                                getOptionValue={(e) => (e || { value: '' }).value}
                              />
                            )}
                            {matches.small && (
                              <AsyncSelect
                                placeholder="Type to search name"
                                styles={customStylesMobile}
                                value={selectedOption}
                                onChange={handleChange}
                                cacheOptions
                                defaultOptions
                                loadOptions={searchByDisplayName}
                                getOptionLabel={(e) => (e || { name: '' }).name}
                                getOptionValue={(e) => (e || { value: '' }).value}
                              />
                            )}
                          </>
                        )}
                      </Media>
                    </div>
                  </div>

                  <button type="submit" form="dacs-transfer" className="theme-button-spec-positive">
                    <div className="theme-button-container">
                      <div />
                      <span>{asText(section_transfer_button_label)}</span>
                    </div>
                  </button>
                  <div className="disclamer">
                    <PrismicRichText render={section_transfer_disclaimer} />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="account-dacs-history">
            <div className="account-dacs-history-title ">
              <PrismicRichText render={section_history_title} />
            </div>
            <div className="table">
              <div className="row">
                <div className="column yellow">{asText(table_column_1)}</div>
                <div className="column yellow">{asText(table_column_2)}</div>
                <div className="column yellow">{asText(table_column_3)}</div>
                <div className="column yellow">{asText(table_column_4)}</div>
              </div>

              {(currentItems || []).map((item: any, index) => {
                return (
                  <div className="row" key={index}>
                    <div className="column">{displayDate(item.operationDate)}</div>
                    <div className="column">
                      {item.operationType === 0 ? 'Creation' : item.operationType === 1 ? 'Transfer' : 'Conversion'}
                    </div>
                    <div className="column">{item.quantity}</div>
                    <div className="column">
                      {item.operationType === 0
                        ? 'Creation'
                        : item.operationType === 1
                        ? item.sentToDisplayName
                          ? `Transferred to user ${item.sentToDisplayName}`
                          : `Received from user ${item.receivedFromDisplayName}`
                        : `Consumed to gain ${item.quantity} month of game time`}
                    </div>
                  </div>
                );
              })}
              {currentItems.length > 0 && (
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="next >"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  // marginPagesDisplayed={2}
                  pageCount={pageCount}
                  previousLabel="< previous"
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AccountDacs;
