import axios from 'axios';
import config from '../../../config';

export const getSeed2FA = async (token: string): Promise<any> => {
  try {
    const result = await axios.get('/api/me/enable-mfa', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (err) {
    if (config.local) {
      console.log('error getting the content from 2FA', err);
    }
  }
};
export const enable2FA = async (token: string, code: string): Promise<any> => {
  try {
    const result = await axios.post(
      '/api/me/enable-mfa',
      { code },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return result.data;
  } catch (err) {
    if (config.local) {
      console.log('error enabling 2FA', err);
    }
  }
};
export const disable2FA = async (token: string, password: string): Promise<any> => {
  try {
    const result = await axios.post(
      '/api/me/disable-mfa',
      { password },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return result.data;
  } catch (err) {
    if (config.local) {
      console.log('error enabling 2FA', err);
    }
  }
};
