import React, { useState } from 'react';
import { usePrismic } from '../../hooks/prismic.hook';
import RichText, { asText } from '../../services/prismic/prismic.richtext.service';
import { getMessageCookies, setMessageCookies } from '../../services/authentication/authentication.cookies.service';
const MessagePopUp = (): JSX.Element => {
  const [noticePopup, setNoticePopup] = useState(true);
  const { contents } = usePrismic('alert');

  if (!contents || !contents.results || !contents.results[0]) return <></>;

  const {
    results: [
      {
        data: { messages },
      },
    ],
  } = contents;

  const renderMessages = (): JSX.Element[] | JSX.Element => {
    const messagesFiltered = messages
      .filter((result: any) => result.message_shown !== false && !getMessageCookies(asText(result.message_content)))
      .map((result: any, key: number) => {
        return <RichText key={key} render={result.message_content}></RichText>;
      });
    if (messagesFiltered.length === 0) setNoticePopup(false);
    return messagesFiltered;
  };
  return (
    <>
      {noticePopup && messages.length > 0 && (
        <div className="message-component">
          <div className="message-component-title">{renderMessages()}</div>
          <div
            className="message-component-close"
            onClick={(): void => {
              setNoticePopup(false);
              messages
                .filter((result: any) => result.message_shown !== false)
                .map((result: any) => {
                  setMessageCookies(asText(result.message_content));
                });
            }}
          ></div>
        </div>
      )}
    </>
  );
};
export default MessagePopUp;
