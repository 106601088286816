import { useEffect } from 'react';
import { useAppDispatch } from '../../stores/app.store.config';
import { useRemoteConfiguration } from '../../stores/selectors/remote-configuration.selector';
import { setExperiment } from '../../stores/slices/analytics.slice';

export const useAnalyticsOptimizeService = (): void => {
  const dispatch = useAppDispatch();

  const { remoteConfig } = useRemoteConfiguration();

  const propertyId = remoteConfig.GOOGLE_ANALYTICS_PROPERTY_ID;

  useEffect(() => {
    const dataLayer = (window as any).dataLayer;
    if (dataLayer) {
      dataLayer.push({ event: 'optimize.activate' });
    }
    const intervalId = setInterval(() => {
      const { google_optimize, gaData } = window as any;
      if (google_optimize && gaData && gaData[propertyId]) {
        clearInterval(intervalId);
        if (gaData[propertyId].experiments) {
          for (const experiment in gaData[propertyId].experiments) {
            const variant = google_optimize.get(experiment);
            dispatch(setExperiment({ experiment, variant }));
          }
        }
      }
    }, 100);
  }, [dispatch]);
};

export default useAnalyticsOptimizeService;
