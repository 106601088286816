import LoginModal from '../../../features/navbar/navbarsubcomponents/loginmodal';
import React, { useEffect } from 'react';
import { getUrlFromEnglish } from '../../../services/i18n/i18n.url.service';
import { useAuth } from '../../../stores/selectors/auth.selector';
import { goto } from '../../../stores/selectors/router.selector';

const AccountLoginPageComponent = (): JSX.Element | null => {
  const { isAuthenticated } = useAuth();
  useEffect(() => {
    if (isAuthenticated) {
      goto(getUrlFromEnglish('/account/products'));
    }
  }, [isAuthenticated]);

  return (
    <div className="account-resend-confirmation account-login-page">
      <div className="container">
        <LoginModal />
      </div>
    </div>
  );
};

export default AccountLoginPageComponent;
