import React, { useState } from 'react';
import { usePrismic } from '../../../hooks/prismic.hook';
const PressNavigationComponent = (): JSX.Element | null => {
  const {
    result: { download, contact, press_releases },
  } = usePrismic('legal-conte');
  const [showoptions, setshowoptions] = useState(false);
  return (
    <div className="press-navigation-block">
      <div className="navbar-mobile">
        <button
          onClick={(): void => {
            setshowoptions(!showoptions);
          }}
        >
          <p>{contact}</p>
          <div></div>
        </button>
      </div>
      {showoptions && (
        <div className="navbar-mobile-options">
          <div>
            <a href="#releases">{press_releases}</a>
            <a href="#download">{download}</a>
          </div>
        </div>
      )}
    </div>
  );
};
export default PressNavigationComponent;
