import React, { useEffect } from 'react';
import { useAppDispatch } from '../../../stores/app.store.config';

import config from '../../../../config';
import { usePrismic } from '../../../hooks/prismic.hook';
import PrismicRichText from '../../../services/prismic/prismic.richtext.service';
import { usePlansAvailable } from '../../../stores/selectors/plans-available.selector';
import { useConsent } from '../../../stores/selectors/consent.selector';
import { acceptAll, refuseAll, openDetailedPopup, openSimplePopup } from '../../../stores/slices/consent.slice';
import CookiesDetails from './cookiesDetails.component';
import { Consent } from '../../../models/consent/consent.model';

const CookiesPopUp = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const consentStata = useConsent();

  const prismic = usePrismic('cookie_consent');
  const getRawText = (id: string): any => prismic.result[id];
  const getRichText = (id: string): any => prismic.contents?.results[0].data[id];

  const { contents } = usePlansAvailable();
  useEffect(() => {
    if (Object.values(consentStata).some((c) => c === Consent.Unknown)) {
      if (contents?.ipData?.country_code) {
        if (!config.europeanCountries.includes(contents.ipData.country_code)) {
          dispatch(acceptAll());
        } else {
          dispatch(openSimplePopup());
        }
      }
    }
  }, [contents]);

  return (
    <>
      <div className={consentStata.showDetailedPopup ? 'popup-overlay' : ''}></div>
      {consentStata.showSimplePopup && (
        <div className="boxParent">
          <div className="cookieParent">
            <a className="cookie-customize" onClick={() => dispatch(openDetailedPopup())}>
              {getRawText('btn_open_details')}
            </a>
            <div className="cookiesDescription">
              <PrismicRichText render={getRichText('popup_description')} />
            </div>
            <div className="cookie-group-button">
              <button className="decline-cookie-consent" onClick={() => dispatch(refuseAll())}>
                <div className="flex-container">
                  <span>{getRawText('btn_decline_all')}</span>
                </div>
              </button>
              <button className="accept-cookie-consent" onClick={() => dispatch(acceptAll())}>
                <div className="flex-container">
                  <span>{getRawText('btn_agree_all')}</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      )}
      <CookiesDetails prismic={prismic}/>
    </>
  );
};

export default CookiesPopUp;
