import { createSlice } from '@reduxjs/toolkit';
import { AuthState, initialAuthState } from '../../models/authentication/auth.model';
import { signIn, ghostSignUp, refreshToken } from '../actions/auth.action';
import { resetTokenCookies } from '../../services/authentication/authentication.cookies.service';
import { getGameAccess } from '../../services/authentication/authentication.gameaccess.service';

const slice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    resetError: (state: AuthState): void => {
      state.error = null;
    },
    persistLogin: (state, { payload }): void => {
      state.user = payload;
      state.loading = false;
      state.error = null;
      state.isAuthenticated = Boolean(payload);
      state.initialized = true;
    },
    updateToken: (state, { payload }): void => {
      state.user = payload;
      state.user.gameTimeMonths = getGameAccess(payload.token);
      state.isAuthenticated = true;
      state.initialized = true;
    },
    updateEmail: (state, { payload }): void => {
      state.email = payload;
    },
    signOut: (state): void => {
      resetTokenCookies();
      state.user = null;
      state.isAuthenticated = false;
      state.initialized = false;
      state.email = '';
      state.lastPathName = '';
      state.error = null;
    },
    setLastPathName: (state: AuthState, { payload }): void => {
      const { value } = payload;
      state.lastPathName = value;
    },
  },
  extraReducers: {
    [refreshToken.fulfilled.type]: (state, { payload }): void => {
      state.user = payload;
      state.loading = false;
      state.error = null;
      state.isAuthenticated = true;
    },
    [refreshToken.pending.type]: (state): void => {
      state.loading = true;
      state.error = null;
    },
    [refreshToken.rejected.type]: (state, { error }): void => {
      state.user = null;
      state.loading = false;
      state.error = error;
      state.isAuthenticated = false;
      state.initialized = true;
    },

    [signIn.fulfilled.type]: (state, { payload }): void => {
      state.signup = false;
      state.user = payload;
      state.loading = false;
      state.error = null;
      state.isAuthenticated = true;
    },
    [signIn.pending.type]: (state): void => {
      state.loading = true;
      state.error = null;
    },
    [signIn.rejected.type]: (state, { payload }): void => {
      state.loading = false;
      state.error = { ...payload, from: 'signIn' };
    },

    [ghostSignUp.fulfilled.type]: (state, { payload }): void => {
      if (payload.error) {
        state.user = null;
        state.loading = false;
        state.error = payload.error;
        state.signup = true;
      } else {
        state.user = payload;
        state.loading = false;
        state.error = null;
        state.signup = true;
      }
    },
    [ghostSignUp.pending.type]: (state): void => {
      state.loading = true;
      state.status = '';
      state.error = null;
    },
    [ghostSignUp.rejected.type]: (state, obj): void => {
      state.loading = false;
      state.error = obj;
    },
  },
});

export const authSliceReducers = slice.reducer;
export const { resetError, persistLogin, updateToken, updateEmail, signOut, setLastPathName } = slice.actions;
