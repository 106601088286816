import React from 'react';
import { usePrismic } from '../../hooks/prismic.hook';
import RichText, { asText } from '../../services/prismic/prismic.richtext.service';
import LinkComponent from '../link/link.component';
const PartnerComponent = (): JSX.Element => {
  const { contents } = usePrismic('partner-page');
  const contentPartner = contents?.results[0]?.data;
  const categoriesData = contentPartner?.body;

  const renderCategoryElems = (category: string): JSX.Element[] | JSX.Element => {
    const elems = categoriesData
      ?.find((elem: any) => {
        return asText(elem?.primary?.category_name) === category;
      })
      .items?.map((elem: Record<string, any>, key: number) => {
        return (
          <LinkComponent key={key} className="partner-content-box" link={asText(elem.partner_link)}>
            <div className="partner-content-box-corners"></div>

            <div
              className="partner-content-box-image"
              style={{
                backgroundImage: `url(${elem.image.url})`,
              }}
            ></div>
            <div className="partner-info">
              <p className="partner-name">{asText(elem.partner_name)}</p>
              <p className="partner-link">{asText(contentPartner?.visit)}</p>
            </div>
          </LinkComponent>
        );
      });
    return elems;
  };
  const renderCategories = categoriesData?.map((elem: Record<string, any>, key: number):
    | JSX.Element[]
    | JSX.Element => {
    return (
      <div key={key}>
        <h2 className="partner-content-category">{asText(elem?.primary?.category_name)}</h2>
        <div className="partner-content-elements">{renderCategoryElems(asText(elem?.primary?.category_name))}</div>
      </div>
    );
  });

  return (
    <div className="partner-container">
      <div className="partner-intro">
        <RichText className="partner-intro-title" render={contentPartner?.title}></RichText>
        <RichText className="partner-intro-desc" render={contentPartner?.description}></RichText>
      </div>
      <div className="partner-content">{renderCategories}</div>
    </div>
  );
};
export default PartnerComponent;
