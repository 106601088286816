import React from 'react';
import Modal from 'react-modal';
import { usePrismic } from '../../../hooks/prismic.hook';

const UploadModal = ({
  isOpen,
  onClose,
  onConfirm,
}: {
  isOpen: boolean;
  onClose: any;
  onConfirm: any;
}): JSX.Element => {
  const {
    result: { delete_image, delete_image_warning },
  } = usePrismic('account_image_upload');

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="modal-content" overlayClassName="modal-overlay">
      <div className="modal-custom-pad">
        <button onClick={onClose} className="x-button close-button" />
        <h1>{delete_image}</h1>
        <p>{delete_image_warning}</p>
        <button className="util-button-cancel" onClick={onConfirm}>
          <div />
          <span>{delete_image}</span>
        </button>
      </div>
    </Modal>
  );
};

export default UploadModal;
