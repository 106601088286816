import React, { useEffect } from 'react';
import config from '../../../../config';
import axios from 'axios';
import { useAppDispatch } from '../../../stores/app.store.config';
import { updateToken } from '../../../stores/slices/auth.slice';
import { goto } from '../../../stores/selectors/router.selector';
import { getUrlFromEnglish } from '../../../services/i18n/i18n.url.service';
import { useLocation, useParams } from 'react-router-dom';
import qs from 'qs';

const activateAccount = async (activationKey: string): Promise<any> => {
  try {
    const url = `/api/register/activate/${activationKey}`;
    const response = await axios.post(
      url,
      {},
      {
        headers: { 'Content-Type': 'application/json' },
      },
    );
    return response.data;
  } catch (err) {
    if (config.local) {
      console.log(err);
    }
    return null;
  }
};

const ActivateComponent = (): JSX.Element | null => {
  const { activationKey } = useParams();
  const { search } = useLocation();
  const { source } = qs.parse(search.slice(1));
  const dispatch = useAppDispatch();

  const handleActivate = async (activationKey: string): Promise<void> => {
    const data = await activateAccount(activationKey);
    if (data?.token) {
      dispatch(updateToken(data));
      setTimeout(() => {
        source === 'contest' ? goto(getUrlFromEnglish('/lottery')) : goto(getUrlFromEnglish('/account/products'));
        window.location.reload();
      }, 1000);
    } else {
      goto(getUrlFromEnglish('/'));
    }
  };

  useEffect(() => {
    handleActivate(activationKey || '').catch((err) => {
      if (config.local) {
        console.log(err);
      }
    });
  }, []);

  return <div></div>;
};

export default ActivateComponent;
