import React from 'react';
import Popup from 'reactjs-popup';
import { displayName, formatURL } from '../media.service';
import { useRemoteConfiguration } from '../../../stores/selectors/remote-configuration.selector';

interface Props {
  imageBig: string;
  imageModal: string;
  categoryPreviewList: string[];
  closeModal(): void;
  indexImageSelected: number;
  setImageBig(image: string): void;
  setIndexImageSelected(image: number): void;
}
const PreviewComponent = (props: Props): JSX.Element => {
  const {
    imageBig,
    imageModal,
    categoryPreviewList,
    closeModal,
    indexImageSelected,
    setIndexImageSelected,
    setImageBig,
  } = props;
  const {
    remoteConfig: { ASSETS_MEDIA_PREFIX },
  } = useRemoteConfiguration();
  return (
    <>
      <Popup modal closeOnDocumentClick={false} open={imageModal === 'preview'}>
        <div className="popup-component-preview">
          <div className="popup-container">
            <div className="popup-exit-icon" onClick={closeModal} />
            {categoryPreviewList && (
              <div
                className="popup-image"
                style={{
                  backgroundImage: `url(${ASSETS_MEDIA_PREFIX.concat(formatURL(imageBig))})`,
                }}
              ></div>
            )}
            <div className="popup-image-name">
              <div className="popup-image-name-line"></div>
              {categoryPreviewList && <p>{displayName(categoryPreviewList[indexImageSelected])}</p>}
            </div>
            <div className="popup-image-position">{`${indexImageSelected + 1}/${categoryPreviewList?.length}`}</div>
            {indexImageSelected - 1 >= 0 && (
              <div
                className="popup-arrow-left"
                onClick={(): void => {
                  setIndexImageSelected(indexImageSelected - 1);
                  const bigImages = categoryPreviewList?.filter((elem: string) => {
                    return (
                      elem.includes(displayName(categoryPreviewList[indexImageSelected - 1])) && elem.includes('-')
                    );
                  });
                  bigImages?.length > 0
                    ? setImageBig(bigImages[0])
                    : setImageBig(categoryPreviewList[indexImageSelected - 1]);
                }}
              ></div>
            )}
            {indexImageSelected + 1 < categoryPreviewList.length && (
              <div
                className="popup-arrow-right"
                onClick={(): void => {
                  setIndexImageSelected(indexImageSelected + 1);
                  const bigImages = categoryPreviewList?.filter((elem: string) => {
                    return (
                      elem.includes(displayName(categoryPreviewList[indexImageSelected + 1])) && elem.includes('-')
                    );
                  });
                  bigImages?.length > 0
                    ? setImageBig(bigImages[0])
                    : setImageBig(categoryPreviewList[indexImageSelected + 1]);
                }}
              ></div>
            )}
          </div>
        </div>
      </Popup>
    </>
  );
};
export default PreviewComponent;
