import React, { useState } from 'react';
import { useAppDispatch } from '../../../stores/app.store.config';
import { useForm } from 'react-hook-form';
 
import PrismicRichText from '../../../services/prismic/prismic.richtext.service';
import { Consent, ServiceType } from '../../../models/consent/consent.model';
import { useConsent } from '../../../stores/selectors/consent.selector';
import { closePopups, setMultipleConsent } from '../../../stores/slices/consent.slice';

interface OptionProps {
  id: ServiceType;
  name: string;
  description: string;
  details: string;
  initialConsent: Consent;
  order: number;
  register: () => any;
  prismic:any
}

const Option = (props: OptionProps): JSX.Element => {
  const {
    result: { btn_show_details, btn_hide_details },
  } = props.prismic;

  const [showDetails, setShowDetails] = useState(false);
  return (
    <div className={props.order % 2 == 0 ? 'option alternate' : 'option'}>
      <div className="content">
        <h5>{props.name}</h5>
        <div className={props.id === ServiceType.Required ? 'long-content' : ''}>
          <PrismicRichText render={props.description} />
        </div>
        <a className={showDetails ? 'up' : 'down'} onClick={() => setShowDetails((show) => !show)}>
          {showDetails ? btn_hide_details : btn_show_details}
        </a>
        {showDetails && (
          <p className={props.id === ServiceType.Required ? 'details long-content' : 'details'}>
            <PrismicRichText render={props.details} />
          </p>
        )}
      </div>
      {props.id !== ServiceType.Required && (
        <a className="checkboxcontainer">
          <input
            id={props.id}
            name={props.id}
            type="checkbox"
            ref={props.register()}
            defaultChecked={props.initialConsent === Consent.Agree}
          />
        </a>
      )}
    </div>
  );
};

const CookiesDetails = (props: any): JSX.Element => {
  const dispatch = useAppDispatch();
  const consentState = useConsent();

  const { register, handleSubmit, getValues, setValue } = useForm();
  const onSubmit = (data: Record<string, any>) => {
    dispatch(
      setMultipleConsent([
        { type: ServiceType.Performance, value: data[ServiceType.Performance] ? Consent.Agree : Consent.Decline },
        { type: ServiceType.Functional, value: data[ServiceType.Functional] ? Consent.Agree : Consent.Decline },
        { type: ServiceType.Targeting, value: data[ServiceType.Targeting] ? Consent.Agree : Consent.Decline },
        { type: ServiceType.Social, value: data[ServiceType.Social] ? Consent.Agree : Consent.Decline },
      ]),
    );
    dispatch(closePopups());
  };

  const selection = (option: boolean) => {
    const names = getValues();
    for (const item in names) {
      setValue(item, option);
    }
  };

  const { result, contents } = props.prismic;
  const getDetailsData = (id: string): any => contents?.results[0].data[id];
  const options = [
    {
      id: ServiceType.Required,
      name: result.required_cookies_name,
      description: getDetailsData('required_cookies_desription'),
      details: getDetailsData('required_cookies_details'),
      initialConsent: Consent.Agree,
    },
    {
      id: ServiceType.Performance,
      name: result.performance_cookies_name,
      description: getDetailsData('performance_cookies_desription'),
      details: getDetailsData('performance_cookies_details'),
      initialConsent: consentState[ServiceType.Performance],
    },
    {
      id: ServiceType.Functional,
      name: result.functional_cookies_name,
      description: getDetailsData('functional_cookies_desription'),
      details: getDetailsData('functional_cookies_details'),
      initialConsent: consentState[ServiceType.Functional],
    },
    {
      id: ServiceType.Targeting,
      name: result.targeting_cookies_name,
      description: getDetailsData('targeting_cookies_desription'),
      details: getDetailsData('targeting_cookies_details'),
      initialConsent: consentState[ServiceType.Targeting],
    },
    {
      id: ServiceType.Social,
      name: result.social_cookies_name,
      description: getDetailsData('social_cookies_desription'),
      details: getDetailsData('social_cookies_details'),
      initialConsent: consentState[ServiceType.Social],
    },
  ];

  return (
    <>
      {consentState.showDetailedPopup && (
        <div className="consentDetails">
          <div className="cookieParent">
            <div className="introduction">
              <PrismicRichText render={getDetailsData('details_description')} />
            </div>
            <div className="selection-group">
              <a className="selection" onClick={() => selection(true)}>
                Select All
              </a>
              <a className="selection" onClick={() => selection(false)}>
                Deselect All
              </a>
            </div>
            <form id="preferences-form" onSubmit={handleSubmit(onSubmit)}>
              {options.map((opt: any, key: number) => (
                <Option key={key} order={key} register={register} {...opt} prismic={props.prismic}/>
              ))}
              <button type="submit" className="theme-button-spec-positive">
                <div className="theme-button-container">
                  <span>{result.btn_confirm_details}</span>
                </div>
              </button>
              <div className="gradient"></div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default CookiesDetails;
