import PrismicRichText, { asText } from '../../../services/prismic/prismic.richtext.service';
import React from 'react';
import { setCookies, cookieDeviceConfig } from '../../../services/authentication/authentication.cookies.service';
import { goto } from '../../../stores/selectors/router.selector';
import { usePrismic } from '../../../hooks/prismic.hook';

const TwoFAReminderModal = ({
  menuClicked,
  setMenuClicked,
  setOpenReminder,
}: {
  menuClicked: any;
  setMenuClicked: any;
  setOpenReminder: any;
}): JSX.Element => {
  const {
    data: { reminder_popup_title, reminder_popup_desc, reminder_button_label },
  } = usePrismic('account_security');
  if (!reminder_popup_title) return <></>;

  return (
    <>
      <div className="popup-overlay-reminder"></div>
      <div className="login-modal-container reminder-container">
        <div className="corners">
          <div className="reminder-title">
            <PrismicRichText render={reminder_popup_title} />
          </div>
          <div className="reminder-desc">
            <PrismicRichText render={reminder_popup_desc} />
          </div>
          <button
            className="theme-button"
            onClick={() => {
              setOpenReminder(false);
              if (menuClicked !== -1) setMenuClicked(-1 * Math.sign(menuClicked));
              goto('/account/security');
            }}
          >
            <div className="theme-button-container">
              <div></div>
              <span>{asText(reminder_button_label)}</span>
            </div>
          </button>
        </div>
        <div
          className="icon-close-reminder"
          onClick={() => {
            setCookies('reminder2FA', 'enabled', cookieDeviceConfig);
            setOpenReminder(false);
          }}
        ></div>
      </div>
    </>
  );
};
export default TwoFAReminderModal;
