import { createSlice } from '@reduxjs/toolkit';
import { initialContentState } from '../../models/content/content.model';
import { fetchContents } from '../actions/content.action';

const slice = createSlice({
  name: 'content',
  initialState: initialContentState,
  reducers: {},
  extraReducers: {
    [fetchContents.fulfilled.type]: (state, { payload }): void => {
      const { result, type } = payload;
      state.contents[type] = result;
      state.loading = false;
      state.hasErrors = false;
    },
    [fetchContents.pending.type]: (state): void => {
      state.loading = true;
      state.hasErrors = false;
    },
    [fetchContents.rejected.type]: (state, { error }): void => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error;
    },
  },
});

export const contentSliceReducers = slice.reducer;
// export const { } = slice.actions;
