import React from 'react';

export const SnackBarComponent = ({
  message,
  type, 
  setOpenSnack,
}: {
  message: any;
  type: string;  
  setOpenSnack?: (item: any) => void;
}): JSX.Element => {
  return (
    <div
      className={
        type.includes('success')
          ? 'snackbar-container snackbar-success'
          : type.includes('failure')
          ? 'snackbar-container snackbar-failure'
          : 'snackbar-container'
      }
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div className={type.includes('success') ? 'icon-desc' : 'icon-desc icon-failure'}></div>
        <p>{message}</p>
      </div>
      <div className="icon-close" onClick={()=>{if(setOpenSnack)setOpenSnack({status:false,message,type})}}></div>
    </div>
  );
};
