import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { CSRFToken } from '../../models/forms/csrf.model';

export const fetchCSRFToken = createAsyncThunk(
  'csrf/fetchCSRF',
  async (url: string): Promise<CSRFToken> => {
    const csrfCode = await axios.get(url);
    return csrfCode.data[0].id;
  },
);
