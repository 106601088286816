import { useEffect } from 'react';
import { PrismicService } from '../../services/prismic/prismic.service';
import { linkResolver } from '../../../_configurations/prismic-configuration';
import { goto } from '../../stores/selectors/router.selector';
import qs from 'qs';
import { getUrlFromEnglish } from '../i18n/i18n.url.service';
import { useRemoteConfiguration } from '../../stores/selectors/remote-configuration.selector';
import { useLocation } from 'react-router-dom';

const usePreview = (): boolean => {
  const { pathname, search } = useLocation();

  const { remoteConfig } = useRemoteConfiguration();

  useEffect(() => {
    if (remoteConfig.PRISMIC_ENDPOINT) {
      const prismicService = new PrismicService(remoteConfig);
      const { token, documentId } = qs.parse(search.slice(1));
      if (token && prismicService.client) {
        prismicService.client
          .getPreviewResolver(String(token), String(documentId))
          .resolve(linkResolver, '/')
          .then((url) => {
            goto(`${getUrlFromEnglish(url)}/preview`);
          });
      }
    }
  }, [search, remoteConfig]);

  const preview = pathname.includes('/preview');
  return preview;
};

export default usePreview;
