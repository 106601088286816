import { User } from '../../models/authentication/auth.model';
import { findCorrespondingSubscription } from '../api/orders/order.subscriptions.service';

export const getPurchasedProducts = (user: User, subscription: any, months_message = ''): any[] => {
  let products: any[] = [];
  for (const plan of user?.allPlans || []) {
    //get all plans
    products = products.concat(plan.products || []);
  }

  const plans = (
    user.gameTimeMonths ? [{ products: [{ product: { name: `${user.gameTimeMonths} ${months_message}` } }] }] : []
  )
    .concat(products)
    .map((obj: any) => ({
      ...obj,
      name: obj.product?.name,
      campaignId: obj.product?.campaignId,
      gameTime: obj.product?.gameTime,
      date: obj.createdAt?.seconds,
    }))
    .map((product: any) => ({
      ...product,
      subscription: findCorrespondingSubscription(product, subscription),
    }));
  return plans;
};
