export interface AnalyticsState {
  experiment: string;
  variant: string;
  loading: boolean;
  error: any;
}

export const initialAnalyticsState: AnalyticsState = {
  experiment: '',
  variant: '',
  loading: false,
  error: null,
};
