import { useEffect } from 'react';
import { useAppDispatch } from '../../stores/app.store.config';
import { plansAvailableResponse } from '../../stores/actions/plans-available.action';
import { useI18n } from '../../stores/selectors/i18n.selector';
import config from '../../../config';
import { useLandingBuyHome, useLandingPageService } from '../optimization/landingpagecheck';
import { getLocation } from '../api/products/products.service';
import { usePrismic } from '../../hooks/prismic.hook';

const useFetchPlans = (): void => {
  const dispatch = useAppDispatch();

  const { locale } = useI18n();

  const landingPage = useLandingPageService();

  const landingBuyPage = useLandingBuyHome();

  const { contents, loading } = usePrismic('buy_page');

  const results = contents?.results || [];

  useEffect(() => {
    const fetchPlans = async (): Promise<void> => {
      let correctResults;
      if (landingPage && results) {
        correctResults = results.filter(
          (obj: { data: { landing_buy_page_id: string } }) => obj.data.landing_buy_page_id == (landingBuyPage || 'buy'),
        );
      } else {
        correctResults = results.filter(
          (obj: { data: { landing_page_id: string } }) => obj?.data?.landing_page_id === 'main',
        );
      }

      const usefulData: any = correctResults[0]?.data;

      if (!usefulData && config.local) {
        console.log('error retrieving usefulData', locale, { results: correctResults });
      }

      const ipData = await getLocation();

      dispatch(plansAvailableResponse({ ipData, usefulData, currency: '' }));
    };

    if (!loading && locale && results?.length) {
      fetchPlans();
    }
  }, [dispatch, locale, results, loading]);
};

export default useFetchPlans;
