import React from 'react';
import FaqLineComponent from './partials/faq-line.component';

export default function FaqComponent({ items }: { items: any[] }): JSX.Element | null {
  return (
    <section className="content-creator-block-faq">
      {items &&
        items.map((element: any, key: number) => {
          return <FaqLineComponent key={key} line={element} id={key} />;
        })}
    </section>
  );
}
