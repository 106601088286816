import React from 'react';
import ReferalRewardBlockComponent from './reward-block.component';

export default function ReferalLineComponent({
  lines: { container_main_title, container_main_timeframe },
  rewards,
  countRewards,
  obtained,
}: any): JSX.Element {
  return (
    <section className="referral-block-line">
      <div className="referral-block-line-header">
        <div>
          <div className="util-block-base">
            <div className="icon-logo"></div>
          </div>
          <h3>{container_main_title}</h3>
        </div>
        <div>
          <h3>{container_main_timeframe}</h3>
        </div>
      </div>
      <div className="referral-block-line-container">
        {rewards &&
          rewards.map((block: any, key: number) => {
            const show = (parseInt(container_main_title?.split(' ')[1]) - 1) * 4 + key < countRewards?.length;
            return <ReferalRewardBlockComponent key={key} block={block} show={show} obtained={obtained} />;
          })}
      </div>
    </section>
  );
}
