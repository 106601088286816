import { createSlice } from '@reduxjs/toolkit';
import { initialState } from '../../models/remote-configuration/remote-configuration.model';
import { fetchRemoteConfiguration } from '../actions/remote-configuration.actions';

const slice = createSlice({
  name: 'remoteConfiguration',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchRemoteConfiguration.fulfilled.type]: (state, { payload }): void => {
      state.remoteConfig = payload;
      state.loading = false;
      state.loaded = true;
      state.error = false;
    },
    [fetchRemoteConfiguration.pending.type]: (state): void => {
      state.loading = true;
      state.loaded = false;
      state.error = null;
    },
    [fetchRemoteConfiguration.rejected.type]: (state, { error }): void => {
      state.remoteConfig = initialState.remoteConfig;
      state.loading = false;
      state.loaded = false;
      state.error = error;
    },
  },
});

export const remoteConfigurationReducer = slice.reducer;
export { fetchRemoteConfiguration };
