import React from 'react';
import { usePrismic } from '../../../hooks/prismic.hook';
import { displayCurrency } from '../../../services/utils/currency.utils';
import { usePlan } from '../../../stores/selectors/plan.selector';
import BuyPageTotal from './buypage-total';
const BuyPageYourSelection = ({
  nbMonths,
  amount,
  currency,
}: {
  nbMonths: string;
  amount: number;
  currency: string;
}): JSX.Element => {
  const nbMonthsNumber = nbMonths.split(' ')[0];
  const {
    result: { product_title = '', selection_title },
  } = usePrismic('buy_page');
  const productTitle = !nbMonthsNumber?.includes('MyDU')
    ? product_title.replace('{NB}', nbMonthsNumber)
    : nbMonthsNumber;

  const { contents } = usePlan();
  const { planName = '' } = contents || {};

  const classes: Record<string, string> = {
    '1': 'buyPage-logo-1',
    '3': 'buyPage-logo-2',
    '6': 'buyPage-logo-3',
    '12': 'buyPage-logo-4',
    MyDU: 'buyPage-logo-5',
  };
  const logoClassName = classes[planName.split(' ')[0]];

  return (
    <div className={'buypage-yourselection'}>
      <h3 className="title">{selection_title}</h3>

      <div className="box-container">
        <div className="corners-up">
          <div className="image-container box pr-0">
            <div className="image">
              <div className={`visibleBorder ${logoClassName}`} />
            </div>
          </div>
          <div className="text-container box box-with-border">
            <h3 className={'yellow'}>{productTitle}</h3>
            {/* <p>{productDescription}</p> */}
            <h3 className="currency">{displayCurrency(amount, currency)}</h3>
          </div>

          {/* <div className="box">
                    <LinkComponent link="/buy">
                        <button className="x-button buypagetwobutton" />
                    </LinkComponent>
                </div> */}
        </div>
      </div>
      <BuyPageTotal amount={amount} currency={currency} />

      {/* <div className="total-container">
                <h4>{result.total}</h4>
                <h5>{displayCurrency(amount, currency)}</h5>
            </div> */}
    </div>
  );
};

export default BuyPageYourSelection;
