import { findCurrentRoute } from '../../services/optimization/landingpagecheck';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../stores/app.store.config';
import { makeRoutes } from '../../../_configurations/routes';
import { getUrlFromEnglish } from '../../services/i18n/i18n.url.service';
import { useAuth } from '../../stores/selectors/auth.selector';
import { goto } from '../../stores/selectors/router.selector';
import { setLastPathName } from '../../stores/slices/auth.slice';
import ScrollToTop from './router.scroll-to-top.component';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';

export default function RouterComponent(props: any): JSX.Element {
  const { isAuthenticated, initialized, lastPathName } = useAuth();
  const { pathname, search } = useLocation();

  const dispatch = useAppDispatch();
  

  const [routes, setRoutes] = useState<any[]>([]);

  useEffect(() => {
    if (isAuthenticated && lastPathName && !findCurrentRoute()?.id?.includes('buy')) {
      goto(lastPathName);
      dispatch(setLastPathName({ value: '' }));
    }
  }, [isAuthenticated, lastPathName]);

  useEffect(() => {
    if (initialized && !routes.length) {
      setRoutes(makeRoutes());
    }
  }, [initialized]);

  if (!initialized || !routes.length) return <></>;

  const route = routes.find((r) => r.path === pathname);
  if (route && !route.public && !isAuthenticated && lastPathName !== pathname) {
    dispatch(setLastPathName({ value: search ? pathname + search : pathname }));
  }
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/:url*(/+)" element={<Navigate to={pathname.slice(0, -1)} replace />} />
        {routes.map((route) =>
          isAuthenticated || route.public ? (
            route.redirect ? (
              <Route path={route.path} key={route.path} element={<Navigate to={route.redirect} replace />} />
            ) : (
              <Route key={route.path} path={route.path} element={route.component ? <route.component menuclicked={props.menuClicked} setMenuClicked={props.setMenuClicked}  /> : <></>} />
            )
          ) : (
            <Route path={route.path} key={route.path} element={<Navigate to={getUrlFromEnglish('/login')} replace />} />
          ),
        )}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  );
}
