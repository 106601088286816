import { RichTextBlock, PrismicDate } from 'prismic-reactjs';

export interface ReleaseStory {
  title: RichTextBlock[];
  date: PrismicDate;
  description: RichTextBlock[];
  content: RichTextBlock[];
}
export interface ReleaseState {
  contents: { releasesStories: ReleaseStory[] };
  hasErrors: boolean;
  loading: boolean;
  error: Error | null;
}

export const initialReleaseState: ReleaseState = {
  contents: { releasesStories: [] },
  hasErrors: false,
  loading: false,
  error: null,
};
