import React from 'react';
import { getUrlFromEnglish } from '../i18n/i18n.url.service';
import LinkComponent from '../../features/link/link.component';
import { usePrismic } from '../../hooks/prismic.hook';

const useLegal = (): any => {
  const { contents = { results: [{ data: { pages: [] } }] } } = usePrismic('legal_page');
  const { contents: contentsPages = { results: [{ data: { pages: [] } }] } } = usePrismic('legal');

  const {
    results: [
      {
        data: { pages },
      },
    ],
  } = contents;

  const legalPages = contentsPages.results
    .map((r: any) => r.data)
    .sort((a: any, b: any) => {
      return pages.findIndex((p: any) => p.page.slug === a.slug) - pages.findIndex((p: any) => p.page.slug === b.slug);
    });

  const legallinks =
    legalPages.map((l: any = {}) => ({
      ...l,
      link: `/legal/${l.slug}`,
      title: l?.title?.map((t: any) => t.text).join(' '),
    })) || [];

  const renderLegalLinks = (pathname: string): JSX.Element =>
    legallinks.map(({ link, title }: { link: string; title: string }, key: number) => {
      const className = pathname === getUrlFromEnglish(link) ? 'underlineTextcurrent' : 'underlineText';
      return (
        <LinkComponent className={className} key={key} link={link}>
          {title}
        </LinkComponent>
      );
    });

  return {
    legalPages,
    legallinks,
    renderLegalLinks,
  };
};

export default useLegal;
