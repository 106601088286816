export interface CpmstarState {
  content: Record<string, any>;
  loading: boolean;
  error: any;
}

export const initialCpmstarState: CpmstarState = {
  content: {},
  loading: false,
  error: false,
};
