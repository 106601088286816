import axios from 'axios';
import { getUser } from '../../../services/authentication/attributes.service';

export const getGametimeEligibility = async (): Promise<boolean> => {
  const user = getUser();
  if (user) {
    const result = await axios.get(`/api/steam/gametime-eligibility`, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
    return result.data;
  }
  return false;
};
export const givePurchaseGameTime = async (): Promise<void> => {
  const user = getUser();
  if (user) {
    await axios.post(
      `/api/steam/give-purchase-gametime`,
      {},
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      },
    );
  }
};
