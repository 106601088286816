import React from 'react';
import { usePrismic } from '../../hooks/prismic.hook';
import PrismicRichText, { asText } from '../../services/prismic/prismic.richtext.service';
import LinkComponent from '../link/link.component';
import FaqComponent from './faq.component';

const ContentCreatorComponent = (): JSX.Element | null => {
  const {
    result: { page_title, page_description, why_title, perks_title, faq_title },
    contents,
  } = usePrismic('content_creators');

  if (!contents) return <></>;

  const {
    results: [
      {
        data: { why_items, perks_items, faq_items },
      },
    ],
  } = contents;

  const displayWhyItem = ({ title, description, image }: any, key: number): JSX.Element => (
    <div className={key > -1 ? 'column' : 'row-complete'} key={key}>
      <div className="square">
        <div className="img-container" style={{ backgroundImage: `url('${image.url}')` }} />
        <h5 className="title">{asText(title)}</h5>
        <div className="description">
          <PrismicRichText render={description} />
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className="content-creator-container">
        <div className="content-intro">
          <h1 className="intro-title">{page_title}</h1>
          <p className="intro-desc">{page_description}</p>
        </div>
        <div className="why-stream-bg"></div>
        <div className="why-stream">
          <h2 className="why-title">{why_title}</h2>
          <div className="row">
            {why_items.slice(0, why_items.length - 1).map((item: any, key: number) => displayWhyItem(item, key))}
          </div>
          <div className="row-complete">
            <div className="square">
              <div className="img-container"></div>
              <div className="content-column">
                <h5 className="title">{asText(why_items[why_items.length - 1].title)}</h5>
                <div className="description">
                  <PrismicRichText render={why_items[why_items.length - 1].description} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="perks-program">
          <h2 className="perks-title">{perks_title}</h2>
          <div className="row-perks">
            {perks_items.map(({ title, description, image, button_link, button_label }: any, key: number) => (
              <div className="row-item" key={key}>
                <div className="img-container" style={{ backgroundImage: `url('${image.url}')` }} />
                <h5>{asText(title)}</h5>
                <div>
                  <PrismicRichText render={description} />
                </div>
                <LinkComponent link={button_link.url}>
                  <button className="theme-button">
                    <div className="theme-button-container">
                      <div className={key === 0 ? 'dual' : key === 1 ? 'next' : 'discord'} />
                      <span>{asText(button_label)}</span>
                    </div>
                  </button>
                </LinkComponent>
              </div>
            ))}
          </div>
        </div>
        <div className="frequent-questions-bg" />
        <div className="frequent-questions">
          <h2>{faq_title}</h2>
          <FaqComponent items={faq_items} />
        </div>
      </div>
    </>
  );
};

export default ContentCreatorComponent;
