import axios from 'axios';
import config from '../../../config';
import { getShortLocale } from '../i18n/i18n.url.service';

export const resendConfirm = async (email: string): Promise<any> => {
  try {
    const lang = getShortLocale();
    const url = `/api/users/resend-activation?lang=${lang}`;
    const response = await axios.post(url, { email });
    return response;
  } catch (err: any) {
    if (config.local) {
      console.log('This is the error ', err.response);
      console.log('There was an error resending the email');
    }
  }
  return null;
};

export default resendConfirm;
