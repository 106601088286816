import { useEffect } from 'react';
import { useAppDispatch } from '../../stores/app.store.config';
import { usePrismic } from '../../hooks/prismic.hook';
import { setLauncherNews } from '../../stores/slices/launcher-news.slice';
import { getFormattedDate } from '../utils/utils.service';

const useLauncherNews = (): void => {
  const dispatch = useAppDispatch();
  const { contents: launcherStories } = usePrismic('launcher_news');

  useEffect(() => {
    const fetchNews = async (): Promise<void> => {
      dispatch(
        setLauncherNews({
          newsStoriesLauncher: (launcherStories.results.slice() || [])
            .sort((a: any, b: any) => Date.parse(b.data.date) - Date.parse(a.data.date))
            .map((obj: Record<string, any>) => {
              const { date } = obj.data;

              return { ...obj.data, date: getFormattedDate(date), dateModified: obj.last_publication_date };
            }),
        }),
      );
    };
    if (launcherStories) {
      fetchNews();
    }
  }, [dispatch, launcherStories]);
};

export default useLauncherNews;
