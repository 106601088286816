import React from 'react';

const BlockNewsLetterComponent = ({ popUpChange }: { popUpChange: any }): JSX.Element => {
  return (
    <>
      <div className="newsletter-content">
        <h2>NewsLetter</h2>
        <p>Sign Up for the Newsletter to stay to date on all things of Dual Universe</p>
        <button
          id="button"
          onClick={(): void => {
            popUpChange();
            window.document.body.style.overflowY = 'hidden'
          }}
        >
          <div className="wrapper-signup">
            <i className="icon-email"></i>
            <span>Sign Up</span>
          </div>
        </button>
      </div>
    </>
  );
};
export default BlockNewsLetterComponent;
