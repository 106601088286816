import { asText } from '../../services/prismic/prismic.richtext.service';

const asTextConditional = (obj: any): string => (typeof obj === 'string' ? obj : asText(obj));

export const getSlug = (newsItem: any): string => {
  if (newsItem?.slug) {
    return asTextConditional(newsItem.slug);
  }
  const value = String(asTextConditional(newsItem?.title) || '')
    .replace(/ /g, '-')
    .replace(/\n/g, '')
    .replace(/(?!-)\W/g, '')
    .toLowerCase();
  return value;
};
