import React, { useEffect } from 'react';
import { usePrismic } from '../../../hooks/prismic.hook';
import { useRemoteConfiguration } from '../../../stores/selectors/remote-configuration.selector';
const CommunityComponent = (): JSX.Element => {
  const {
    result: { community_title, community_join, forum_title, forum_join, discord_title, discord_join, follow_us },
  } = usePrismic('launcher_page');

  const { remoteConfig } = useRemoteConfiguration();
  useEffect(() => {
    document.onkeydown = function (e) {
      // keycode for F5 function
      if (e.keyCode === 116) {
        return false;
      }
    };
  }, []);
  return (
    <div className="community-container">
      <div className="community-link-container">
        <a href={`${remoteConfig.COMMUNITY_URL}`} target="_blank" rel="noopener noreferrer">
          <div className="connect-comunity">
            <div className="corners"> </div>
            <div className="real-content">
              <div className="image-users"></div>
              <div className="text-wrapper">
                <p className="title">{community_title}</p>
                <p className="subtitle">{community_join}</p>
              </div>
            </div>
          </div>
        </a>
        <a href="https://board.dualthegame.com" target="_blank" rel="noopener noreferrer">
          <div className="connect-comunity">
            <div className="corners"> </div>
            <div className="real-content">
              <div className="image-users image-users-forum"></div>
              <div className="text-wrapper">
                <p className="title">{forum_title}</p>
                <p className="subtitle">{forum_join}</p>
              </div>
            </div>
          </div>
        </a>
        <a href="https://discord.gg/dualuniverse" target="_blank" rel="noopener noreferrer">
          <div className="connect-comunity">
            <div className="corners"> </div>
            <div className="real-content">
              <div className="image-users image-users-discord"></div>
              <div className="text-wrapper">
                <p className="title">{discord_title}</p>
                <p className="subtitle">{discord_join}</p>
              </div>
            </div>
          </div>
        </a>
      </div>
      <div className="community-follow-us">
        <h1>{follow_us}</h1>
        <div className="social-icons">
          <div className="row-one">
            <a
              draggable="false"
              href="https://twitter.com/dualuniverse"
              className="icon twitter"
              target="_blank"
              rel="noopener noreferrer"
            ></a>
            <a
              draggable="false"
              className="icon instagram"
              href="https://www.instagram.com/dualthegame/"
              target="_blank"
              rel="noopener noreferrer"
            ></a>
            <a
              draggable="false"
              className="icon facebook"
              href="https://facebook.com/dualuniverse"
              target="_blank"
              rel="noopener noreferrer"
            ></a>
          </div>
          <div className="row-two">
            <a
              draggable="false"
              className="icon reddit"
              href="https://www.reddit.com/r/DualUniverse/"
              target="_blank"
              rel="noopener noreferrer"
            ></a>
            <a
              draggable="false"
              className="icon twitch"
              href="https://twitch.tv/dualuniverse"
              target="_blank"
              rel="noopener noreferrer"
            ></a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CommunityComponent;
