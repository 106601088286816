import jwt from 'jsonwebtoken';
import { getCertificate } from './authentication.certificate.service';

export const decodeJWT = async (token: string): Promise<any | null> => {
  const certificate = await getCertificate();
  let decoded = null;
  try {
    decoded = jwt.verify(token, certificate, { algorithms: ['RS256'], clockTolerance: 60 });
  } catch (err) {
    decoded = null;
  }
  return decoded;
};
