import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../stores/app.store.config';
import { useAuth } from '../../../stores/selectors/auth.selector';
import { signOut } from '../../../stores/slices/auth.slice';
import LinkComponent from '../../link/link.component';
import { usePrismic } from '../../../hooks/prismic.hook';
import { asText } from '../../../services/prismic/prismic.richtext.service';
import { delCookies } from '../../../services/authentication/authentication.cookies.service';
import { useKickstarter } from '../../../stores/selectors/kickstarter.selector';
interface Props {
  setMenuClicked?: (item: number) => void;
  menuClicked?: number;
}
const ProfileSubmenu = (props: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const { user } = useAuth();
  const displayName = user?.displayName;
  const { hasAccess } = useKickstarter();
  const { contents } = usePrismic('login');
  const [ownership, setOwnership] = useState('');
  const signout = (): void => {
    delCookies('accept-exp');
    dispatch(signOut());
    if (props.setMenuClicked) props.setMenuClicked(-1);
  };
  useEffect(() => {
    if (user) {
      setOwnership(user?.details?.ownership);
    }
  }, [user]);
  if (!contents) return <></>;

  const {
    results: [
      {
        data: { menu_links, logout },
      },
    ],
  } = contents;

  return (
    <div className={'profilemenucontainer'}>
      <div className={'profileheader'}>Your Profile</div>
      <div className={'profilesubmenu'}>
        <div className="intro">
          <p className="intro-tag">NOVEAN ID:</p>
          <p className="intro-username">{displayName}</p>
        </div>
        {menu_links
          .filter((l: any) => !ownership?.includes('steam') || !l.is_download)
          .filter((l: any) => hasAccess || !l.label[0].text.includes('Backer'))
          .map(({ label, link}: any, key: number) => (
            <div className="link-container-profile" key={key}>
              <LinkComponent
                label={asText(label)}
                link={link.url}
                onClick={() => {
                  if (props.setMenuClicked) {
                    props.setMenuClicked(-1);
                  }
                }}
              />
            </div>
          ))}
        <button className="util-button-text" onClick={signout}>
          <div />
          <span> {asText(logout)}</span>
        </button>
      </div>
    </div>
  );
};

export default ProfileSubmenu;
