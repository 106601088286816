import { createSlice } from '@reduxjs/toolkit';
import { initialReleaseState } from '../../models/releases/releases.model';

const slice = createSlice({
  name: 'releases',
  initialState: initialReleaseState,
  reducers: {
    setReleases: (state, { payload }): void => {
      state.contents = payload;
    },
  },
});

export const releasesReducer = slice.reducer;
export const { setReleases } = slice.actions;
