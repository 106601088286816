import React from 'react';
import { RichText, RichTextBlock } from 'prismic-reactjs';
import PrismicDOM from 'prismic-dom';
import { v1 as uuidv1 } from 'uuid';

import LinkComponent from '../../features/link/link.component';
import SocialMediaWrapper from '../../features/consentManagement/socialMediaWrapper.component';

const Elements = PrismicDOM.RichText.Elements;

const serializeHyperlink = (type: any, element: any, content: any): JSX.Element => {
  const { url, target } = element?.data || {};
  const key = uuidv1();
  return (
    <LinkComponent link={url} key={key} target={target}>
      {content}
    </LinkComponent>
  );
};

const htmlSerializer = (type: any, element: any, content: any, children: any[]): JSX.Element | null => {
  const key = uuidv1();

  switch (type) {
    case Elements.heading1:
      return <h1 key={key}>{children}</h1>;
    case Elements.heading2:
      return (
        <h2 key={key}>
          {children}
          <span className="anchor" id={element.id} />
        </h2>
      );
    case Elements.heading3:
      return <h3 key={key}>{children}</h3>;
    case Elements.heading4:
      return <h4 key={key}>{children}</h4>;
    case Elements.heading5:
      return <h5 key={key}>{children}</h5>;
    case Elements.heading6:
      return <h6 key={key}>{children}</h6>;
    case Elements.paragraph:
      return (
        <p key={key} className={element.class}>
          {children}
        </p>
      );
    case Elements.preformatted:
      return <pre key={key}>{children}</pre>;
    case Elements.strong:
      return <strong key={key}>{children}</strong>;
    case Elements.em:
      return <em key={key}>{children}</em>;
    case Elements.listItem:
      return <li key={key}>{children}</li>;
    case Elements.oListItem:
      return <li key={key}>{children}</li>;
    case Elements.list:
      return <ul key={key}>{children}</ul>;
    case Elements.oList:
      return <ol key={key}>{children}</ol>;
    case Elements.image:
      const imageLinkUrl = element.linkTo ? PrismicDOM.Link.url(element.linkTo, module.exports.linkResolver) : null;
      const linkTarget =
        element.linkTo && element.linkTo.target ? { target: element.linkTo.target, rel: 'noopener' } : {};
      const wrapperClassList = [element.label || '', 'block-img'];
      const img = (
        <img
          key={key}
          src={element.url}
          alt={element.alt || ''}
          // copyright={element.copyright || ''}
        />
      );
      return (
        <p key={key} className={wrapperClassList.join(' ')}>
          {imageLinkUrl ? (
            <a {...linkTarget} href={imageLinkUrl}>
              {img}
            </a>
          ) : (
            img
          )}
        </p>
      );
    case Elements.embed:
      return (
        <SocialMediaWrapper type="youtube" size={''}>
          <div
            key={key}
            data-oembed="{element.oembed.embed_url}"
            data-oembed-type="{element.oembed.type}"
            data-oembed-provider="{element.oembed.provider_name}"
          >
            <div dangerouslySetInnerHTML={{ __html: element.oembed.html }} />
          </div>
        </SocialMediaWrapper>
      );
    case Elements.hyperlink:
      return serializeHyperlink(type, element, content);
    case Elements.label:
      const label = element.data.label ? { className: element.data.label } : {};
      return (
        <span key={key} {...label}>
          {children}
        </span>
      );
    case Elements.span:
      return content;
    default:
      return null;
  }
};

const contentIsHeading = (content: any): boolean => {
  const title = 'heading';
  return content?.type && content.type.substr(0, title.length) === title;
};

const generateHeadingNumbers = (contents: any[], startingindex: number, className = 'introP'): RichTextBlock[] => {
  const contentsWithNumbers = JSON.parse(JSON.stringify(contents));
  let i = startingindex;

  for (let index = 0; index < contentsWithNumbers.length; index++) {
    if (contentIsHeading(contentsWithNumbers[index])) {
      i++;
      contentsWithNumbers[index].id = `article-${i}`;
    }
    contentsWithNumbers[index].class = className;
  }

  return contentsWithNumbers;
};

const PrismicRichText = ({
  render,
  startingindex,
  className,
}: {
  render: any;
  startingindex?: number;
  className?: string;
}): JSX.Element => {
  if (!render) return <></>;
  const contentsWithNumbers: any = generateHeadingNumbers(render, startingindex || 0, className);
  return <RichText render={contentsWithNumbers} htmlSerializer={htmlSerializer} />;
};

export const getHeadings = (contents: any[], startingindex: number): any[] =>
  generateHeadingNumbers(contents, startingindex).filter((c) => contentIsHeading(c));

export default PrismicRichText;

export const asText = (render: RichTextBlock[]): string => {
  if (typeof render === 'string') return render;
  if (!render) return '';
  return RichText.asText(render);
};
