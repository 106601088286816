import React from 'react';
import { useNews } from '../../../stores/selectors/news.selector'; 
import { getSlug } from '../news.service';
import { getUrlFromEnglish } from '../../../services/i18n/i18n.url.service';
import LinkComponent from '../../link/link.component';
import { asText } from '../../../services/prismic/prismic.richtext.service';
 
export default function NewsIntroComponent(props: any): JSX.Element | null {
  const { contents: newsStories, } = useNews();
 
  if (!newsStories) return null;
 
  return (
    <section
      className={'news-container-intro'}
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(12, 19, 21, 0) 81%, #0c1315),url(${newsStories[0].image.url})`,
      }}
    >
      <div className="section-type">
        <p>{newsStories[0].type}</p>
      </div>
      <h1 className="section-title">{asText(newsStories[0].title)}</h1>
      <LinkComponent link={`${getUrlFromEnglish('/news')}/${getSlug(newsStories[0])}`}>
        <button className="theme-button">
          <div className="theme-button-container">
            <div className="icon"></div> <span>{props.read}</span>
          </div>
        </button>
      </LinkComponent>
    </section>
  );
}
