import React from 'react';
import { asText } from '../../../services/prismic/prismic.richtext.service';
import SocialMediaComponent from './news-small-sections/news-social-media.component';

const BlockComponent = ({ result, showContent,prismic }: any): JSX.Element => {
  return (
    <div className="news-content-detail">
      {showContent && (
        <>
          <div className="linebefore"></div>
          {result.title ? <p className="content">{asText(result.title)}</p> : <></>}
        </>
      )}
      <SocialMediaComponent result={result} prismic={prismic} />
    </div>
  );
};
export default BlockComponent;
