import { FetchContentsArgs } from '../../models/prismic-api-query.model';
import { RootState } from '../../models/_root-state.model';
import { store } from '../../stores/app.store.config';
import { PrismicService } from './prismic.service';

export const searchDocuments = async ({
  search,
  type,
  sort = 'last_publication_date',
}: FetchContentsArgs): Promise<any> => {
  const state: RootState = store.getState();
  const remoteConfig = state.remoteConfigurationBranch?.remoteConfig;
  const locale = state.i18nBranch.locale;
  const prismicService = new PrismicService(remoteConfig);
  const result = await prismicService.prismicQuery({ search, locale, type, sort });
  return result.results.map((r: any) => r.data);
};
