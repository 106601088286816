import React from 'react';
import PrismicRichText, { asText } from '../../../services/prismic/prismic.richtext.service';
import { usePrismic } from '../../../hooks/prismic.hook';
import LinkComponent from '../../link/link.component';
import { useAuth } from '../../../stores/selectors/auth.selector';

const AccountDownload = (): JSX.Element => {
  const { user } = useAuth();
  const {
    data: { account_download_title, account_download_desc, du_image, du_title, du_desc, du_link, du_button_label },
  } = usePrismic('account_download');

  return (
    <div className="download-container">
      <h2> {asText(account_download_title)}</h2>
      <div className="download-description">
        <PrismicRichText render={account_download_desc} />
      </div>
      {!user?.details?.ownership?.includes('steam') && (
        <div className="download-box">
          <div className="download-box-corners">
            <div className="download-box-padding">
              <div className="download-box-du-image" style={{ backgroundImage: `url(${du_image?.url})` }}></div>
              <div className="download-box-du-title">
                <PrismicRichText render={du_title} />
              </div>
              <div className="download-box-du-desc">
                <PrismicRichText render={du_desc} />
              </div>
              <LinkComponent className="button-container" link={du_link?.url}>
                <button className="theme-button">
                  <div className="theme-button-container">
                    <div></div>
                    <span>{asText(du_button_label)}</span>
                  </div>
                </button>
              </LinkComponent>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountDownload;
