import { createAsyncThunk } from '@reduxjs/toolkit';
import { ServerStatusModel } from '../../models/server-status/server-status.model';
import { serverStatusService } from '../../services/server-status/server-status.service';

export const getServerStatus = createAsyncThunk(
  'liveops/getServerStatus',
  async (): Promise<ServerStatusModel> => {
    return serverStatusService.GetServerStatus();
  },
);
