import { createSlice } from '@reduxjs/toolkit';
import { BetaKeysInitialState } from '../../models/beta-keys/keys.model';
import { sendAvailableBetaKey, fetchBetaKeys, cancelBetaKey, activateKey } from '../actions/beta-keys.action';

const slice = createSlice({
  name: 'beta-keys',
  initialState: BetaKeysInitialState,
  reducers: {
    resetSuccess: (state): void => {
      state.keys = BetaKeysInitialState.keys;
      state.totals = BetaKeysInitialState.totals;
      state.success = BetaKeysInitialState.success;
      state.loading = BetaKeysInitialState.loading;
      state.error = BetaKeysInitialState.error;
    },
  },
  extraReducers: {
    [fetchBetaKeys.fulfilled.type]: (state, { payload }): void => {
      if (payload) {
        state.keys = payload.keys;
        state.totals = payload.totals;
      }
      state.loading = false;
      state.error = null;
    },
    [fetchBetaKeys.pending.type]: (state): void => {
      state.loading = true;
      state.error = null;
    },
    [fetchBetaKeys.rejected.type]: (state, { payload }): void => {
      state.loading = false;
      state.error = { ...payload, from: 'fetchBetaKeys' };
    },
    [sendAvailableBetaKey.fulfilled.type]: (state, { payload }): void => {
      if (payload) {
        state.keys = payload.keys;
        state.totals = payload.totals;
      }
      state.loading = false;
      state.error = null;
    },
    [sendAvailableBetaKey.pending.type]: (state): void => {
      state.loading = true;
      state.error = null;
    },
    [sendAvailableBetaKey.rejected.type]: (state, { payload }): void => {
      state.loading = false;
      state.error = { ...payload, from: 'sendAvailableBetaKey' };
    },
    [cancelBetaKey.fulfilled.type]: (state, { payload }): void => {
      if (payload) {
        state.keys = payload.keys;
        state.totals = payload.totals;
      }
      state.loading = false;
      state.error = null;
    },
    [cancelBetaKey.pending.type]: (state): void => {
      state.loading = true;
      state.error = null;
    },
    [cancelBetaKey.rejected.type]: (state, { payload }): void => {
      state.loading = false;
      state.error = { ...payload, from: 'cancelBetaKey' };
      state.keys = payload.keys;
      state.totals = payload.totals;
    },
    [activateKey.fulfilled.type]: (state): void => {
      state.loading = false;
      state.success = state.success + 1;
      state.error = null;
    },
    [activateKey.pending.type]: (state): void => {
      state.loading = true;
      state.error = null;
    },
    [activateKey.rejected.type]: (state, { payload }): void => {
      state.loading = false;
      state.error = { ...payload, from: 'activateKey' };
    },
  },
});

export const betaKeysReducer = slice.reducer;

export const { resetSuccess } = slice.actions;
