import axios from 'axios';
import config from '../../../config';

export const addAcquisition = async (user: any): Promise<void> => {
  if (!user) return;
  try {
    const data = [
      {
        op: 'add',
        path: '/acquisitionGaUtm',
        value: window.location.search,
      },
    ];
    await axios.patch('/api/users/me/acquisition', data, {
      headers: { Authorization: `Bearer ${user.token}` },
    });
  } catch (err) {
    if (config.local) {
      console.log('error with addAcquisition', err);
    }
  }
};
