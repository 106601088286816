import React, { useState, useEffect } from 'react';
import { usePrismic } from '../../../hooks/prismic.hook';
import PrismicRichText, { asText } from '../../../services/prismic/prismic.richtext.service';
import Newsletter from '../../newsletter/newsletter.component';
import Popup from 'reactjs-popup';
import { goto } from '../../../stores/selectors/router.selector';
import { getUrlFromEnglish } from '../../../services/i18n/i18n.url.service';
import LinkComponent from '../../link/link.component';

const BuildingComponent = (): JSX.Element | null => {
  const {
    data: {
      title,
      description,
      video_intro_link,
      btn_label,
      side_bar_text,
      side_bar,
      help_title,
      help_image,
      help_description,
      side_bar_btn,
      btn_link,
      image_placeholder,
      ship_label,
    },
  } = usePrismic('pillar_building');
  const [offset, setOffset] = useState(0);
  const [open, setOpen] = useState(false);
  const closeModal = (): void => {
    setOpen(false);
  };
  useEffect(() => {
    function handleScroll() {
      setOffset(window.pageYOffset);
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  if (!video_intro_link) return <></>;
  const renderShip = (speed: number): JSX.Element | null => {
    return (
      <div
        className="ship-label"
        style={{
          transform: `translateY(-${(offset * speed) / 1920}vw)`,
        }}
      >
        <div />
        <p className="label-text">{asText(ship_label)}</p>
      </div>
    );
  };
  return (
    <>
      <div
        className="image-pillar image-planet"
        style={{
          transform: `translateY(${(offset * 8) / 1920}vw)`,
        }}
      />
      <div
        className="image-pillar image-ship-one"
        style={{
          transform: `translateY(-${(offset * 8) / 1920}vw)`,
        }}
      />
      {renderShip(8)}
      <div
        className="image-pillar image-sat"
        style={{
          transform: `translateY(${(offset * 5) / 1920}vw)`,
        }}
      />
      {renderShip(5)}
      <div
        className="image-pillar image-planet-medium"
        style={{
          transform: `translateY(-${(offset * 8) / 1920}vw)`,
        }}
      />
      <div
        className="image-pillar image-ship-two"
        style={{
          transform: `translateY(${(offset * 3) / 1920}vw) translateX(-${(offset * 3) / 1920}vw)`,
        }}
      />
      {renderShip(3)}
      <div
        className="image-pillar image-planet-small"
        style={{
          transform: `translateY(${(offset * 10) / 1920}vw)`,
        }}
      />
      <div
        className="image-pillar image-ship-three"
        style={{
          transform: `translateY(-${(offset * 8) / 1920}vw)`,
        }}
      />
      {renderShip(8)}
      <div
        className="image-pillar image-ship-multiple"
        style={{
          transform: `translateY(${(offset * 5) / 1920}vw)`,
        }}
      />
      {renderShip(8)}
      <div className="building-section">
        <Popup modal closeOnDocumentClick={false} open={open}>
          <div className={'popUpContainer'}>
            <div className={'exitIcon'} onClick={closeModal} />
            <video controls autoPlay controlsList="nodownload">
              <source src={btn_link?.url} type="video/mp4" />
              <track default kind="captions" srcLang="en" src="" />
            </video>
          </div>
        </Popup>
        <div className="building-section-presentation">
          <div className="opacity"> </div>
          <video className="background-video" poster={image_placeholder?.url} loop autoPlay muted>
            <source src={video_intro_link?.url} type="video/mp4" />
          </video>
          <h1 className={'building-presentation-title'}>{asText(title)}</h1>
          <h2 className={'building-presentation-description'}>{asText(description)}</h2>
          <button
            className="theme-button"
            onClick={() => {
              setOpen(true);
            }}
          >
            <div className="theme-button-container">
              <div />
              <span>{asText(btn_label)}</span>
            </div>
          </button>
        </div>
        <div className="building-line" />

        <div className="building-sidebar">
          <div
            className="building-stars"
            // style={{
            //   transform: `translateY(-${offset * 0.05}px)`,
            // }}
          />

          <div className="side_bar_title">
            <PrismicRichText render={side_bar_text} />
          </div>
          <div className="building-sidebar-items">
            {side_bar?.map((item: any, key: number) => {
              return (
                <div key={key}>
                  <div className="item-content">
                    <PrismicRichText render={item.content} />
                  </div>
                  {item?.image?.url && (
                    <div className="item-image" style={{ backgroundImage: `url(${item?.image?.url})` }}>
                      <LinkComponent link={item?.link?.url || ''}>
                        <div className="item-header-bottom">
                          <div className="small-line" />
                          <p>{asText(item.header_bottom)}</p>
                          <div className="arrow" />
                        </div>
                      </LinkComponent>
                    </div>
                  )}
                </div>
              );
            })}
            <button
              className="theme-button-spec-positive"
              onClick={() => {
                goto(getUrlFromEnglish('/buy'));
              }}
            >
              <div className="theme-button-container">
                <div />
                <span>{asText(side_bar_btn)}</span>
              </div>
            </button>
          </div>
        </div>
        <div className="building-form-container">
          <div
            className="building-form"
            style={{
              backgroundImage: `linear-gradient(
                            120deg,
                            #162024 50%,
                            rgba(12, 19, 21, 0.64) 30%,
                            rgba(12, 19, 21, 0.1) 63%,
                            rgba(12, 19, 21, 0) 66%
                        ),url(${help_image?.url})`,
            }}
          >
            <div
              className="building-form-img-mobile"
              style={{
                backgroundImage: ` url(${help_image?.url})`,
              }}
            ></div>
            <div className="building-form-title">
              <PrismicRichText render={help_title} />
            </div>
            <div className="building-form-description">
              <PrismicRichText render={help_description} />
            </div>
            <Newsletter title=" " />
          </div>
        </div>
      </div>
    </>
  );
};
export default BuildingComponent;
