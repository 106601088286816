import React, { useEffect, useRef } from 'react';
import { usePrismic } from '../../hooks/prismic.hook';

import TutorialSearchComponent from './tutorials-search.component';
import TutorialsOtherComponent from './tutorials-other.component';
import TutorialsSideNavigation from './tutorials-side-navigation.component';
import { useTutorials } from '../../hooks/tutorials.hook'; 
import { goto } from '../../stores/selectors/router.selector';
import { getUrlFromEnglish } from '../../services/i18n/i18n.url.service';
import { Tutorial, TutorialCategory, TutorialLink } from '../../models/tutorials/tutorials.model';
import YoutubeVideo from '../partials/video-youtube.partial-component';
import PrismicRichText, { asText } from '../../services/prismic/prismic.richtext.service';
import LinkComponent from '../link/link.component';
import SocialLinks from '../partials/social-links.partial.component';
import { getRandomInt } from '../../services/utils/utils.service';
import TutorialsAboutComponent from './tutorials-about.component';
import { useParams } from 'react-router-dom';

const TutorialsNavBar = ({
  tutorial,
  tutorials,
  refNeedMoreHelp,
}: {
  tutorial: Tutorial | null;
  tutorials: Tutorial[];
  refNeedMoreHelp: any;
}): JSX.Element => {
  if (!tutorial) return <></>;
  const {
    data: { dual_universe_tutorials, currently_reading, tutorial_index, random_tutorial, need_more_help },
  } = usePrismic('tutorials_page');

  const randomTutorial = tutorials[getRandomInt(tutorials.length)];

  const onClick = (): void => {
    if (refNeedMoreHelp?.current) {
      window.scrollTo(0, refNeedMoreHelp?.current?.offsetTop);
    }
  };

  return (
    <div className="top-menu">
      <div className="left">
        <span className="item main">{asText(dual_universe_tutorials)}</span>
        <span className="item">
          <span className="currently">{asText(currently_reading)}</span>&nbsp;{asText(tutorial.title)}
        </span>
      </div>
      <div className="right">
        <LinkComponent link={getUrlFromEnglish('/tutorials')} className="item">
          {asText(tutorial_index)}
        </LinkComponent>
        <LinkComponent link={`${getUrlFromEnglish('/tutorials')}/${randomTutorial.slug}`} className="item">
          {asText(random_tutorial)}
        </LinkComponent>
        <a className="item" href="#need_more_help" onClick={onClick}>
          {asText(need_more_help)}
        </a>
      </div>
    </div>
  );
};

const TutorialsDetails = ({
  tutorial,
  category,
}: {
  tutorial: Tutorial | null;
  category: TutorialCategory | null;
}): JSX.Element => {
  if (!tutorial || !category) return <></>;
  const prismic = usePrismic('news_page');

  const videoURL = tutorial.video_link.url || '';

  const { next_tutorials = [] } = tutorial;

  const {
    data: { you_might_want_to_read },
  } = usePrismic('tutorials_page');

  return (
    <div>
      <PrismicRichText render={category?.category_title} />
      <PrismicRichText render={tutorial.title} />
      {!tutorial?.only_text && (
        <>
          <YoutubeVideo videoURL={videoURL} size={''} />
          <SocialLinks margin prismic={prismic} />
        </>
      )}
      {tutorial?.only_text && (
        <>
          {tutorial?.image_intro?.url && (
            <div className="image-intro" style={{ backgroundImage: `url(${tutorial.image_intro.url})` }}></div>
          )}
          {tutorial?.description && asText(tutorial.description) && (
            <div className="description-text">
              <PrismicRichText render={tutorial.description} />
            </div>
          )}
          <div className="content-text">
            <PrismicRichText render={tutorial.content} />
          </div>
          <SocialLinks margin prismic={prismic} />
        </>
      )}
      {next_tutorials?.length > 0 && next_tutorials[0]?.tutorial && (
        <>
          <PrismicRichText render={you_might_want_to_read} />
          <ul className="next-tutorials">
            {next_tutorials.map((t: TutorialLink, key: number) => (
              <li key={key}>
                <LinkComponent link={`${getUrlFromEnglish('/tutorials')}/${t.tutorial_link.slug}`}>
                  <PrismicRichText render={t?.tutorial?.title} />
                </LinkComponent>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

const TutorialsDetailsPageComponent = (): JSX.Element => {
  const { slug } = useParams(); // inside your component
  const { loading, categories, tutorial, tutorials, category } = useTutorials(slug);

  useEffect(() => {
    if (!loading && !tutorial) {
      return goto(getUrlFromEnglish('/tutorials'));
    }
  }, [slug, tutorial, loading]);

  const {
    data: { background_image },
  } = usePrismic('tutorials_page');

  const refNeedMoreHelp = useRef<any>(null);

  return (
    <div
      className="tutorials-page tutorials-details-page"
      style={{ backgroundImage: `url('${background_image?.url}')` }}
    >
      <TutorialsNavBar tutorial={tutorial} tutorials={tutorials} refNeedMoreHelp={refNeedMoreHelp} />

      <div className="container">
        <div className="left">
          <TutorialSearchComponent />
          <TutorialsDetails tutorial={tutorial} category={category} />
        </div>
        <div className="right">
          <TutorialsSideNavigation categories={categories} tutorial={tutorial} />
        </div>
      </div>

      <div id="need_more_help" ref={refNeedMoreHelp}>
        <TutorialsOtherComponent />
      </div>
      <TutorialsAboutComponent />
    </div>
  );
};

export default TutorialsDetailsPageComponent;
