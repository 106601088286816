import React from 'react';
import { usePrismic } from '../../../hooks/prismic.hook';
interface Props {
  server: { name: string; status: number };
  setServer: (server: any) => void;
  serverStatusData: any;
}
const ServicesStatusComponent = ({ server, setServer, serverStatusData }: Props): JSX.Element | null => {
  const {
    result: { status_title, server_up_letter, server_issue_letter, server_down_letter, server_maintenance_letter },
  } = usePrismic('server_status');

  return (
    <section className="services-status-section">
      <div className={'block-top'}>
        <div>{status_title}</div>
      </div>
      {serverStatusData && (
        <div className={'block-content'}>
          {serverStatusData.services?.map((service: any, key: number) => {
            return (
              <div
                key={key}
                className={'main-container-line'}
                onClick={() => {
                  setServer(service);
                }}
              >
                <div className={'main-container-line-content'}>
                  <div className="content-icon-desc">
                    <div
                      className={
                        service.name !== server.name
                          ? 'content-icon icon-du main-line-icon'
                          : 'content-icon icon-du main-line-icon icon-yellow'
                      }
                    />
                    <p className={service.name !== server?.name ? 'main-line-desc' : 'main-line-desc yellow'}>
                      {service.name}
                    </p>
                  </div>

                  <div>
                    {service.status === 1 && (
                      <div className={'main-line-status'}>
                        <div className={'main-line-status-txt status-up'}>{server_up_letter}</div>
                      </div>
                    )}
                    {service.status === 2 && (
                      <div className={'main-line-status'}>
                        <div className={'main-line-status-txt status-issue'}>{server_issue_letter}</div>
                      </div>
                    )}
                    {service.status === 3 && (
                      <div className={'main-line-status'}>
                        <div className={'main-line-status-txt status-down'}>{server_down_letter}</div>
                      </div>
                    )}
                    {service.status === 4 && (
                      <div className={'main-line-status'}>
                        <div className={'main-line-status-txt status-maintenance'}>{server_maintenance_letter}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className={
                    service.name !== server?.name ? 'main-container-border' : 'main-container-border border-yellow'
                  }
                />
              </div>
            );
          })}
          <div className={'block-footer'} />
        </div>
      )}
    </section>
  );
};

export default ServicesStatusComponent;
