export interface SubscriptionState {
  subscription: any;
  loading: boolean;
  error: any;
}

export const initialSubscriptionsState: SubscriptionState = {
  subscription: new Object(),
  loading: false,
  error: null,
};
