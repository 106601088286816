import React, { useState } from 'react';
import subMenuFunction from '../submenu.service';
import Media from 'react-media';
import LinkComponent from '../../link/link.component';
import { asText } from '../../../services/prismic/prismic.richtext.service';

const SimpleSubmenu = ({
  data,
  simpleMenuCase,
  iconToggle,
  icon = 1,
  setMenuClicked,
  menuPrismic,
}: {
  data: Record<string, any>;
  simpleMenuCase: number;
  iconToggle?(icon: number): void;
  icon?: number;
  setMenuClicked: any;
  menuPrismic: any;
}): JSX.Element => {
  const simpleMenu = subMenuFunction(data, setMenuClicked);

  const [menuOpens, setMenuOpens]: any = useState('');
  const {
    data: { version_image, version_logo, version_link, version_button_label },
  } = menuPrismic;

  const subMenuFunctionMobile = (data: Record<string, any>): JSX.Element[] => {
    const megaMenu = [];
    for (const [key, arr] of Object.entries(data)) {
      if (key !== 'link' && key !== 'name') {
        const megaMenuInput: JSX.Element = (
          <div key={`megamenu_${key}`}>
            <div className={'submenublock'}>
              <a
                className={'submenuheader'}
                key={key}
                tabIndex={0}
                onClick={(): void => {
                  setMenuOpens(key);
                }}
              >
                <div className={'header'}> {key}</div>
                <div className={'submenu-open'} />
              </a>
              {menuOpens === key && (
                <div className={'submenucontent'}>
                  {arr.map((obj: any, key: any) => {
                    return (
                      <LinkComponent
                        tabIndex={0}
                        link={obj.headerlink}
                        label={obj.headercontent}
                        confirm={obj.confirm_external_link}
                        key={key}
                        onClick={(): void => {
                          if (iconToggle) iconToggle(-icon);
                        }}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        );
        megaMenu.push(megaMenuInput);
      }
    }

    return megaMenu;
  };

  const simpleMenuMobile = subMenuFunctionMobile(data);

  return (
    <Media
      queries={{
        small: '(max-width: 479px)',
        large: '(min-width: 480px)',
      }}
    >
      {(matches): JSX.Element => (
        <>
          {matches.large && (
            <div className={`simplesubmenucontainer-${simpleMenuCase}`}>
              <div className={'simplesubmenu'}>{simpleMenu}</div>
              {simpleMenuCase === 1 && (
                <div
                  className="menu-update-image"
                  style={{
                    backgroundImage: `url(${version_image.url})`,
                  }}
                >
                  <div
                    className="update-logo"
                    style={{
                      backgroundImage: `url(${version_logo.url})`,
                    }}
                  />
                  <LinkComponent
                    link={version_link?.url}
                    className="update-button-container"
                    onClick={() => {
                      setMenuClicked(-1);
                    }}
                  >
                    <button className="update-button">
                      <div />
                      <span>{asText(version_button_label)}</span>
                    </button>
                  </LinkComponent>
                </div>
              )}
            </div>
          )}
          {matches.small && (
            <div className={'mobilesubmenucontainer'}>
              <div className={'mobilesubmenu'}>{simpleMenuMobile}</div>
            </div>
          )}
        </>
      )}
    </Media>
  );
};

export default SimpleSubmenu;
