import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

declare global {
  interface Window {
    ga?: any;
    gtag?:any;
  }
}
// eslint-disable-next-line react/display-name
export const withRouter = (WrappedComponent: any) => (props: any) => {
  return <WrappedComponent {...props} navigate={useNavigate()} />;
};
function useLocationEffect(callback: (location?: any) => any) {
  const location = useLocation();
  useEffect(() => {
    return callback(location);
  }, [location, callback]);
}
const ScrollToTop = (): any => {
  useLocationEffect((location: Location) => {
    if (window.ga) {
      window.ga('set', 'page', location.pathname);
      window.ga('send', 'pageview');
    }
    const { hash } = location;
    if (!hash && !location.pathname.includes('lottery')) window.scrollTo(0, 0);
    else {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) element.scrollIntoView();
    }
  });

  return null;
};

export default withRouter(ScrollToTop);
