import React from 'react';
import { useAppDispatch } from '../../stores/app.store.config';

import { usePrismic } from '../../hooks/prismic.hook';
import { useConsent } from '../../stores/selectors/consent.selector';
import { Consent, ServiceType } from '../../models/consent/consent.model';
import { setConsent, openDetailedPopup } from '../../stores/slices/consent.slice';

interface Props {
  children: React.ReactChild | React.ReactChild[];
  type: string;
  size: string;
}

/**
 * This component wraps a social media to prevent loading if user did not accept social media cookies.
 * If consent is not given, the user has the option to accept social media cookies instead.
 */
const SocialMediaWrapper = (props: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const consentState = useConsent();

  const { result } = usePrismic('cookie_consent');

  if (consentState[ServiceType.Social] === Consent.Agree) {
    return <>{props.children}</>;
  }

  return (
    <div
      className={
        props.type?.includes('twitter') || props.size.includes('long')
          ? 'consent-required-message'
          : 'consent-required-message consent-youtube'
      }
    >
      <h2>{result.social_content_blocked}</h2>
      <p>{props.type?.includes('twitter') ? result.twitter_cookies_required : result.youtube_cookies_required}</p>
      <button
        className="accept"
        onClick={() => dispatch(setConsent({ type: ServiceType.Social, value: Consent.Agree }))}
      >
        <div className="flex-container">
          <span>{result.btn_accept_social_cookies}</span>
        </div>
      </button>
      <button className="decline" onClick={() => dispatch(openDetailedPopup())}>
        <div className="flex-container">
          <span>{result.btn_open_details}</span>
        </div>
      </button>
    </div>
  );
};

export default SocialMediaWrapper;
