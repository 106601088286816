import React from 'react';
import Popup from 'reactjs-popup';
import { getThubmVideo, formatURL } from '../media.service';
import { useRemoteConfiguration } from '../../../stores/selectors/remote-configuration.selector';
import { usePrismic } from '../../../hooks/prismic.hook';

interface Props {
  imageModal: string;
  listDownload: string[];
  closeModal(): void;
  indexImageSelected: number;
  documents: string[];
  renderOptions(): JSX.Element[] | JSX.Element;
}
const DownloadComponent = (props: Props): JSX.Element => {
  const { imageModal, listDownload, closeModal, indexImageSelected, documents, renderOptions } = props;
  const {
    remoteConfig: { ASSETS_MEDIA_PREFIX },
  } = useRemoteConfiguration();
  const {
    result: { download_doc, download_wallpaper },
  } = usePrismic('media-page');
  return (
    <>
      <Popup
        modal
        closeOnDocumentClick={false}
        open={imageModal.includes('wallpaper') || imageModal.includes('documents')}
      >
        <div className="popup-component-download">
          <div className="popup-container">
            <div className="popup-container-corners"></div>
            <div className="popup-exit-icon" onClick={closeModal} />
            {listDownload && (
              <div
                className="popup-image"
                style={{
                  backgroundImage: `url('${
                    imageModal.includes('wallpaper')
                      ? ASSETS_MEDIA_PREFIX.concat(formatURL(listDownload[indexImageSelected]))
                      : getThubmVideo(listDownload[indexImageSelected], documents, ASSETS_MEDIA_PREFIX)
                  }')`,
                }}
              ></div>
            )}
            <div className="popup-download-options">
              {imageModal.includes('wallpapers') && <h5 className="download-options-title">{download_wallpaper}</h5>}
              {imageModal.includes('documents') && <h5 className="download-options-title">{download_doc}</h5>}
              {imageModal.includes('wallpapers') && listDownload && (
                <div className="download-options-list">{renderOptions()}</div>
              )}
              {imageModal.includes('documents') && (
                <div className="download-options-list">
                  <a
                    href={`${ASSETS_MEDIA_PREFIX.concat(formatURL(listDownload[indexImageSelected]))}`}
                    target="_blank"
                    rel="noreferrer"
                    download
                  >
                    {listDownload[indexImageSelected]?.split('/')[3]}
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </Popup>
    </>
  );
};
export default DownloadComponent;
