import React, { useState } from 'react';
import MediaNavBar from './medianavbar';
import { useMedia } from '../../../stores/selectors/media.selector';
import { displayName, subcategoriesList, formatURL } from '../media.service';
import { usePrismic } from '../../../hooks/prismic.hook';
import { InfiniteScrollComponent } from './infiniteScroll.component';
import { useRemoteConfiguration } from '../../../stores/selectors/remote-configuration.selector';
import PreviewComponent from './preview.component';

const ScreenshotComponent = (): JSX.Element => {
  const [imageModal, setimageModal] = useState('');
  const [indexImageSelected, setIndexImageSelected] = useState(0);
  const [imageBig, setImageBig] = useState('');
  const closeModal = (): void => {
    setimageModal('');
  };
  const {
    status: { screenshots },
  } = useMedia();
  const {
    result: { cat_screenshoots, media },
  } = usePrismic('media-page');
  const {
    remoteConfig: { ASSETS_MEDIA_PREFIX },
  } = useRemoteConfiguration();

  const screenshootsThumb = screenshots?.filter((elem: string) => {
    return !elem.includes('-');
  });
  const [amountDisplayed, setamountDisplayed] = useState(1);

  const renderScrenshoots = (subcategory: string): JSX.Element | JSX.Element => {
    const items = screenshootsThumb
      .filter((elem: string) => {
        return elem.includes(subcategory);
      })
      ?.map((result: string, key: number) => {
        return (
          <div className="categories-three" key={key}>
            <div
              className="imageContainer"
              onClick={(): void => {
                setimageModal('preview');
                setIndexImageSelected(screenshootsThumb?.indexOf(result));
                const bigImages = screenshots?.filter((elem: string) => {
                  return elem.includes(displayName(result)) && elem.includes('-');
                });
                bigImages?.length > 0 ? setImageBig(formatURL(bigImages[0])) : setImageBig(formatURL(result));
              }}
            >
              <div
                className="image"
                style={{
                  backgroundImage: `url(${ASSETS_MEDIA_PREFIX.concat(formatURL(result))})`,
                }}
              ></div>
              <div className="header">
                <p>{displayName(result)}</p>
              </div>
            </div>
          </div>
        );
      });
    return items;
  };

  const renderSubcategories = (): JSX.Element[] | JSX.Element => {
    const categories = subcategoriesList(screenshootsThumb)?.map((elem: string, key: number) => {
      // const currentcategory = screenshootsThumb.filter((elem: string) => {
      //   return elem.includes(elem);
      // });
      return (
        <div className={key % 2 === 0 ? 'categories-content' : 'categories-content alternate-bg'} key={key}>
          {key < amountDisplayed && (
            <>
              <div className="mediaHeader">
                <p className="category-name">{elem}</p>
              </div>
              <div className="categories-grid">{renderScrenshoots(elem)}</div>
            </>
          )}
        </div>
      );
    });
    return categories;
  };
  return (
    <div className="categories-container-custom">
      {screenshootsThumb && (
        <PreviewComponent
          imageBig={imageBig}
          imageModal={imageModal}
          categoryPreviewList={screenshootsThumb}
          closeModal={closeModal}
          indexImageSelected={indexImageSelected}
          setImageBig={setImageBig}
          setIndexImageSelected={setIndexImageSelected}
        />
      )}
      <MediaNavBar />
      <div className="categories-intro">
        <h2 className="categories-intro-title">{media}</h2>
        <h2 className="categories-intro-title-section">{cat_screenshoots}</h2>
      </div>
      {renderSubcategories()}
      {amountDisplayed < screenshootsThumb?.length && (
        <InfiniteScrollComponent
          renderSubcategories={renderSubcategories}
          amountDisplayed={amountDisplayed}
          setamountDisplayed={setamountDisplayed}
        />
      )}
    </div>
  );
};
export default ScreenshotComponent;
