import React from 'react';
import config from '../../../config';

export const AnalyticsComponent = ({ type }: { type: 'buy' | 'confirm' }): JSX.Element => {
  return (
    <img
      alt=""
      width="1"
      height="1"
      style={{ position: 'absolute' }}
      src={config.analytics.cpmStar.trackingPixelImage[type]}
    />
  );
};
