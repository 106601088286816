import { ConfirmState } from '../../models/confirm/confirm.model';
import { useSelector } from 'react-redux';
import { RootState } from '../../models/_root-state.model';
import { store } from '../app.store.config';
import { setConfirmSlice } from '../slices/confirm.slice';

export const useConfirm = (): ConfirmState => useSelector((state: RootState) => state.confirmBranch);

export const setConfirm = (show: boolean): void => {
  store.dispatch(setConfirmSlice(show));
};
