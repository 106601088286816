import React from 'react';
import PrismicRichText, { asText } from '../../../services/prismic/prismic.richtext.service';

export default function FaqLineComponent({ line: { question, answer }, id }: any): JSX.Element {
  const name = 'rd' + id;
  return (
    <section className="faq-line-creator">
      <div className="faq-line-creator-container">
        <input type="checkbox" id={name} name={name} />
        <label className="faq-line-creator-container-label" htmlFor={name}>
          <h5>{asText(question)}</h5>
          <div />
        </label>
        <div className="faq-line-creator-container-content">
          <PrismicRichText render={answer} />
        </div>
      </div>
    </section>
  );
}
