import qs from 'qs';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { asText } from '../../../services/prismic/prismic.richtext.service';

import { useAnalytics } from '../../../stores/slices/analytics.slice';
import { MenuBlock } from './home.block.component';

export const generateID = (menu: string): string => menu.replace(/ /g, '_').toLowerCase();

export const HomeMenuComponent = ({ menus }: { menus: any[] }): JSX.Element => {
  const { search } = useLocation();
  const { alt } = qs.parse(search.slice(1));

  const { variant } = useAnalytics();

  return (
    <div className={`home-menu-component ${alt !== undefined || variant === '1' ? 'alt' : ''}`}>
      {menus.map((block: MenuBlock, key: number) => (
        <a key={key} href={`#${generateID(asText(block.menu_title))}`} className="menu-item">
          <h3 className="title">{asText(block.menu_title)}</h3>
          <h4 className="sub-title">{asText(block.menu_sub_title)}</h4>
        </a>
      ))}
    </div>
  );
};

export default HomeMenuComponent;
