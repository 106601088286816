import React from 'react';
import { usePrismic } from '../../../hooks/prismic.hook';
import PrismicRichText, { asText } from '../../../services/prismic/prismic.richtext.service';

export default function ReferalHowComponent(): JSX.Element | null {
  const prismic = usePrismic('recruit_a_friend');

  if (!prismic.items.recruit_a_friend) return <></>;

  const {
    result: { how_title, how_disclaimer },
    items: {
      recruit_a_friend: { how_items },
    },
  } = prismic;

  return (
    <section className="referral-block-how">
      <h2>{how_title}</h2>
      <div className="content-container">
        {how_items &&
          how_items.map(
            ({ title, description, icon_id }: { title: any; description: any; icon_id: string }, index: number) => {
              return (
                <div key={index} className="content-column">
                  <div className={`content-icon ${icon_id}`} />
                  <h4>{asText(title)}</h4>
                  <PrismicRichText render={description}></PrismicRichText>
                </div>
              );
            },
          )}
      </div>
      <p className="paragraph_disclaimer">{how_disclaimer}</p>
    </section>
  );
}
