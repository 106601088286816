import React, { useState } from 'react';
import { useAuth } from '../../../stores/selectors/auth.selector';
import ProfileSubMenu from './profilemenu';
import LanguageSubMenu from './languagemenu';
import LinkComponent from '../../link/link.component';
import LeftNavBar from './leftnavbar';
import LoginModal from './loginmodal';

const NavBarMobile = ({
  filteredArray,
  logo,
  menuPrismic,
}: {
  filteredArray: JSX.Element[];
  logo: string;
  menuPrismic: any;
}): JSX.Element => {
  const { isAuthenticated } = useAuth();

  const [menu, changeMenu] = useState('main');

  const [icon, iconToggle] = useState(1);
  // const [scrollToggle, setScrollToggle] = useState(false);

  return (
    <section className={icon === 1 ? 'navbar-mobile-main' : 'navbar-mobile-main full'}>
      <div className={'mobile-bar-one'}>
        <div className={'navbar-item-logo-mobile'}>
          <LinkComponent
            link="/"
            tabIndex={0}
            className="navbar-logo-mobile"
            style={{
              backgroundImage: `url(${logo})`,
            }}
          />
        </div>
        <div className={'navbar-item-icon'}>
          {icon === 1 && (
            <a
              tabIndex={0}
              className={'navbar-icon'}
              onClick={(): void => {
                iconToggle(-icon);
                window.document.body.style.overflow = 'hidden';
              }}
            />
          )}
          {icon === -1 && (
            <a
              tabIndex={0}
              className={'navbar-icon navbar-icon-close'}
              onClick={(): void => {
                iconToggle(-icon);
                changeMenu('main');
                window.document.body.style.overflow = 'scroll';
              }}
            />
          )}
        </div>
      </div>
      {icon === -1 && (
        <div className={'navbar-content-menu'}>
          <div className={'navbar-content-one'}>
            {menu === 'main' && (
              <>
                <LeftNavBar filteredArray={filteredArray} icon={icon} setIcon={iconToggle} menuPrismic={menuPrismic} />
                <div className={'navbar-item-play'}>
                  <LinkComponent
                    tabIndex={0}
                    className={'navbar-play'}
                    link="/buy"
                    onClick={() => {
                      iconToggle(-icon);
                      window.document.body.style.overflow = 'scroll';
                    }}
                  >
                    Play Now
                  </LinkComponent>
                </div>
              </>
            )}

            {menu === 'user' &&
              (isAuthenticated ? <ProfileSubMenu /> : <LoginModal icon={icon} setIcon={iconToggle} />)}
            {menu === 'language' && <LanguageSubMenu />}
            {menu === 'search' && <></>}
          </div>

          <div className={'mobile-bar'}>
            <div className={menu === 'main' ? 'nav-item selected' : 'nav-item'}>
              <a
                tabIndex={0}
                className={'main'}
                onClick={(): void => {
                  changeMenu('main');
                }}
              />
            </div>
            <div className={menu === 'user' ? 'nav-item selected' : 'nav-item'}>
              <a
                className={'userlogin'}
                tabIndex={0}
                onClick={(): void => {
                  changeMenu('user');
                }}
              />
            </div>
            <div className={menu === 'language' ? 'nav-item selected' : 'nav-item'}>
              <a
                tabIndex={0}
                className={'language'}
                onClick={(): void => {
                  changeMenu('language');
                }}
              />
            </div>
            {/* <a
                            className={'search'}
                            tabIndex={0}
                            onClick={(): void => {
                                changeMenu('search');
                            }}
                        /> */}
          </div>
        </div>
      )}
    </section>
  );
};

export default NavBarMobile;
