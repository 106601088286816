import { makeRoutes } from '../../../_configurations/routes';
import { RouteModel } from '../../models/router/route.model';

const convertPathToRegExp = (path: string): RegExp => {
  return RegExp(
    `^${path
      .split('/')
      .map((chunk) => (chunk[0] === ':' ? '.*' : chunk))
      .join('/')}$`,
  );
};

export const findCurrentRoute = (): RouteModel | undefined => {
  const routes = makeRoutes();
  const { pathname } = window.location;
  const found = routes.find((r) => convertPathToRegExp(r.path).test(pathname));
  return found;
};

export const getLp = (): string | undefined => {
  return findCurrentRoute()?.lp;
};

export const useLandingPageService = (): string | undefined => {
  return getLp();
};

export const useLandingPageCheck = (): boolean => {
  return Boolean(getLp());
};
export const useLauncherCheck = (): boolean | undefined => {
  return findCurrentRoute()?.id?.includes('launcher');
};
export const useLandingBuyHome = (): string | undefined => {
  return findCurrentRoute()?.buy;
};

export const getBuyPageUrl = (): string => {
  const lp = getLp();

  return lp ? `/play/${lp}/buy` : '/buy';
};

export const getConfirmPageUrl = (): string => {
  return `/play/${getLp()}/confirm`;
};
