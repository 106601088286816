import React from 'react'; 
import { asText } from '../../../services/prismic/prismic.richtext.service';
import SocialMediaComponent from './news-small-sections/news-social-media.component';

const BlockBigComponent = ({ result, prismic }: { result: any; prismic: any }): JSX.Element | null => {
  const {
    result: { read },
  } = prismic;
  return (
    <div className="block-big-content">
      <p className="type">{result.type}</p>
      <h2>{asText(result.title)}</h2>
      <button className="read">
        <span>{asText(read)}</span>
      </button>
      <div className="news-content-detail-big">
        <SocialMediaComponent result={result} prismic={prismic} />
      </div>
    </div>
  );
};
export default BlockBigComponent;
