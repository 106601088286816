import { useEffect } from 'react';
import { useAppDispatch } from '../../stores/app.store.config';
import { setNews } from '../../stores/slices/news.slice';
import { getFormattedDate } from '../utils/utils.service';

import { usePrismic } from '../../hooks/prismic.hook';

const useFetchNews = (): void => {
  const dispatch = useAppDispatch();

  const { contents } = usePrismic('news');

  useEffect(() => {
    if (contents) {
      const news = contents ? contents.results.map((n: any) => n.data) : null;
      dispatch(
        setNews(
          news
            .sort((a: any, b: any) => Date.parse(b.date) - Date.parse(a.date))
            .map((news: any) => {
              return { ...news, date: getFormattedDate(news.date) };
            }),
        ),
      );
    }
  }, [dispatch, contents]);
};

export default useFetchNews;
