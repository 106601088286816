import React from 'react';
import { usePrismic } from '../../../hooks/prismic.hook';
import { asText } from '../../../services/prismic/prismic.richtext.service';
const classesForStatus: any = {
  1: 'border-up',
  2: 'border-issue',
  3: 'border-down',
  4: 'border-maintenance',
};
interface Props {
  server: any;
  maintenanceHistory: any;
}
export default function ServerShardStatusComponent({ server: service, maintenanceHistory }: Props): JSX.Element {
  const {
    result: {
      single_shard_description,
      server_maintenance_short,
      server_maintenance,
      server_up_short,
      server_up,
      server_issue_short,
      server_down_short,
      server_down,
      issues_title,
      issues_link,
      issues_button,
      start,
      end,
    },
  } = usePrismic('server_status');
  const {
    data: { body },
  } = usePrismic('server_status_logs');
  const activeElement = body?.filter((elem: any) => {
    return elem.primary.server_type === service?.name;
  })[0];
  const sharedContent = (): JSX.Element => {
    return (
      <div className={'main-container-sub-line'}>
        <div className="probleme">{issues_title}</div>
        <a href={issues_link}>
          <button className="theme-button">
            <div className="theme-button-container">
              <div className="icon-support" />
              <span>{issues_button}</span>
            </div>
          </button>
        </a>
      </div>
    );
  };
  return (
    <div className="server-shard-status-container">
      <section className={'server-shard-status-section ' + String(classesForStatus[service?.status] || 'border-down')}>
        <div className={'top-container'}>
          <div className={'content-icon icon-du main-line-icon'} />
          <div>
            <p className={'main-line-desc-title'}>{service?.name}</p>
            <p className={'main-line-desc'}>{single_shard_description}</p>
          </div>
        </div>
        <div className={'main-container'}>
          <div className={'main-container-line'}>
            {service?.status === 1 && (
              <>
                <div className={'main-line-status'}>
                  <div className={'main-line-status-txt status-up'}>{server_up_short}</div>
                </div>
                <div className={'main-line-status-desc'}>{server_up}</div>
              </>
            )}
            {service?.status === 2 && (
              <>
                <div className={'main-line-status'}>
                  <div className={'main-line-status-txt status-issue'}>{server_issue_short}</div>
                </div>
                {maintenanceHistory?.map((history: any, key: number) => (
                  <div className={'main-line-status-desc'} key={key}>
                    {start}
                    {new Date(history.startDate.seconds * 1000).toTimeString()}
                    {end}
                    {new Date(history.endDate.seconds * 1000).toTimeString()}
                  </div>
                ))}
              </>
            )}
            {service?.status === 3 && (
              <>
                <div className={'main-line-status'}>
                  <div className={'main-line-status-txt status-down'}>{server_down_short}</div>
                </div>
                <div className={'main-line-status-desc'}> {server_down}</div>
              </>
            )}
            {service?.status === 4 && (
              <>
                <div className={'main-line-status'}>
                  <div className={'main-line-status-txt status-maintenance'}>{server_maintenance_short}</div>
                </div>
                <div className={'main-line-status-desc'}> {server_maintenance}</div>
              </>
            )}
          </div>
          <div className={'main-container-maintenance-line'}>
            {maintenanceHistory?.map((maintenance: any, key: number) => {
              const dateStart = new Date(maintenance.startDate.seconds * 1000);
              return (
                <div className={'container-sub-line-maintenance'} key={key}>
                  <div className={'sub-line-maintenance-date'}>{dateStart.getDate() + '/' + dateStart.getMonth()}</div>
                  <div className={'sub-line-maintenance-time'}>
                    {dateStart.getHours() + ':' + dateStart.getMinutes()}
                  </div>
                  <div className={'sub-line-maintenance-desc'}>{maintenance.message}</div>
                </div>
              );
            })}
          </div>

          {service?.status === 1 && <>{sharedContent()}</>}
          {service?.status === 3 && (
            <>
              {asText(activeElement?.primary?.server_down_description) !== '' && (
                <div className="description">{asText(activeElement?.primary?.server_down_description)}</div>
              )}
              {asText(activeElement?.primary?.server_down_description) === '' && <>{sharedContent()}</>}
            </>
          )}
          {service?.status === 4 && (
            <>
              {asText(activeElement?.primary?.server_maintenance_description) !== '' && (
                <div className="description">{asText(activeElement?.primary?.server_maintenance_description)}</div>
              )}
              {asText(activeElement?.primary?.server_maintenance_description) === '' && <>{sharedContent()}</>}
            </>
          )}
        </div>
      </section>
    </div>
  );
}
