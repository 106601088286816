export interface CustomFormFunctions {
  previousPage: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
}

export interface FormInputs {
  input: string | number;
  label: string | undefined;
  type: string | undefined;
  meta: { touched: boolean; error: string };
  myClass?: any;
  array?: [];
}

export type FormResponseInterface = [];

export interface FormResponceState {
  response: FormResponseInterface;
  loading: boolean;
  hasErrors: boolean;
  error: any;
}

export const initialFormResponseState: FormResponceState = {
  response: [],
  hasErrors: false,
  loading: false,
  error: null,
};
