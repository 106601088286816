import React, { useState } from 'react';
import { useI18n } from '../../../../stores/selectors/i18n.selector';
import Popup from 'reactjs-popup';
import LinkComponent from '../../../link/link.component';
import { asText } from '../../../../services/prismic/prismic.richtext.service';
import { replaceLink } from '../../../../services/utils/utils.service';
import { usePrismic } from '../../../../hooks/prismic.hook';
import { getUrlFromEnglish } from '../../../../services/i18n/i18n.url.service';
import { getBuyPageUrl } from '../../../../services/optimization/landingpagecheck';

export default function HomePageStart(): JSX.Element {
  const { locale } = useI18n();

  const {
    result: { title_statement, title_quote, intro_video, intro_video_button, play_button },
  } = usePrismic('home_page');
  const [open, setOpen] = useState(false);
  const closeModal = (): void => {
    document.body.classList.remove('modal-open');
    setOpen(false);
  };
  const renderSwitch = (language: any): string => {
    switch (language) {
      case 'fr-fr':
        return 'explanation explanation-fr';
      case 'de-de':
        return 'explanation explanation-de';
      default:
        return 'explanation';
    }
  };

  const buyPageURL = getUrlFromEnglish(getBuyPageUrl());

  const BuyButton = (): JSX.Element => (
    <LinkComponent link={buyPageURL}>
      <button className="theme-button-spec-positive joinBeta">
        <div className="theme-button-container">
          <div />
          <span>{play_button}</span>
        </div>
      </button>
    </LinkComponent>
  );

  return (
    <div className={'homepagestartlandingone'}>
      <div className={'dualUniverseLogo'} />
      <p className={'title'}>{title_statement}</p>
      <p className={renderSwitch(locale)}>{title_quote}</p>
      <div className={'actionButtons'}>
        <button
          className="theme-button"
          onClick={(): void => {
            document.body.classList.add('modal-open');
            setOpen(true);
          }}
        >
          <div className="theme-button-container learnMore">
            <div />
            <span>{intro_video_button}</span>
          </div>
        </button>
        <Popup modal closeOnDocumentClick={false} open={open}>
          <div className={'popUpContainer'}>
            <div className={'exitIcon'} onClick={closeModal} />
            <video controls autoPlay controlsList="nodownload">
              <source src={replaceLink(asText(intro_video))} type="video/mp4" />
              <track default kind="captions" srcLang="en" src="" />
            </video>
          </div>
        </Popup>
        <BuyButton />
      </div>
    </div>
  );
}
