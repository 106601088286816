import React from 'react';
import { usePrismic } from '../../../hooks/prismic.hook';
import { usePlan } from '../../../stores/selectors/plan.selector';
import Popup from 'reactjs-popup';

const BuyPageEnterCode = (): JSX.Element => {
  const {
    result: { code_description, recruit_tooltip, code_placeholder },
  } = usePrismic('buy_page');

  const {
    result: { email_placeholder },
  } = usePrismic('common');

  const { contents } = usePlan();

  return contents && contents.referallCode ? (
    <div className="buypage-entercode">
      <h6>{code_description}</h6>

      <div className="util-input">
        <div />
        <input
          type={'text'}
          className="util-input-text"
          maxLength={36}
          minLength={36}
          placeholder={email_placeholder}
          value={contents.referallCode}
        />
        <div />
      </div>
    </div>
  ) : (
    <div className="buypage-entercode">
      <div className="tooltip-container">
        <h6>{code_description}</h6>
        <Popup
          trigger={(): JSX.Element => (
            <div className="tooltip-box">
              <span className="buttoncustom">i</span>
            </div>
          )}
          position="top center"
          on="hover"
          closeOnDocumentClick
        >
          <span>{recruit_tooltip}</span>
        </Popup>
      </div>
      <div className="util-input">
        <div />
        <input type={'text'} className="util-input-text" placeholder={code_placeholder} />
        <div />
      </div>
    </div>
  );
};

export default BuyPageEnterCode;
