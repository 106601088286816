import { createAsyncThunk } from '@reduxjs/toolkit';
import { getUploads, deleteFile, uploadFile } from '../../services/api/assets/assets.service';
import { getUser } from '../../services/authentication/attributes.service';

export const fetchUploads = createAsyncThunk('assets/fetchUploads', async (): Promise<{ uploads: any[] }> => {
  const user = getUser();
  return getUploads({ user });
});

export const uploadAsset = createAsyncThunk(
  'assets/uploadAsset',
  async (files: any[], { rejectWithValue }): Promise<any> => {
    const user = getUser();

    if (!files || !files.length) {
      throw new Error('error_generic');
    }
    try {
      for (const file of files) {
        await uploadFile(file, user, rejectWithValue);
      }
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error);
    }
    return getUploads({ user });
  },
);

export const deleteUpload = createAsyncThunk(
  'assets/deleteUpload',
  async (assetKey: string): Promise<{ uploads: any[] }> => {
    const user = getUser();
    await deleteFile(assetKey, user);
    return getUploads({ user });
  },
);
