import axios from 'axios';
import config from '../../../config';

export class ServerStatusService {
  public async GetServerStatus(): Promise<any> {
    try {
      const status = await axios.get(`/liveops/serverstatus`);
      return status.data;
    } catch (err) {
      if (config.local) {
        console.log('error with axiosLiveOpsApiInstance', err);
      }
    }
  }
  public async GetUserPreferences(email: string, token: string): Promise<any> {
    try {
      const status = await axios.post(
        `/api/mailing/`,
        { email },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        },
      );
      return status.data;
    } catch (err) {
      if (config.local) {
        console.log('error with axiosLiveOpsApiInstance', err);
      }
    }
  }
  public async GetAllPreferences(token: string): Promise<any> {
    try {
      const status = await axios.get(`/api/mailing/categories`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      return status.data;
    } catch (err) {
      if (config.local) {
        console.log('error with axiosLiveOpsApiInstance', err);
      }
    }
  }
  public async UpdatePreferences(email: any, token: string): Promise<any> {
    try {
      const status = await axios.put(
        `/api/mailing/`,
        { email },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        },
      );
      return status.data;
    } catch (err) {
      if (config.local) {
        console.log('error with axiosLiveOpsApiInstance', err);
      }
    }
  }
}

export const serverStatusService = new ServerStatusService();
