export interface RemoteConfig {
  DISCORD_CLIENT_ID: string;
  COMMUNITY_URL: string;
  CAPI_CERTIFICATE_B64: string;
  PRISMIC_ENDPOINT: string;
  PRISMIC_TOKEN: string;
  ENV: string;
  ROOT_URL: string;
  SENTRY_DNS: string;
  GOOGLE_ANALYTICS_PROPERTY_ID: string;
  ASSETS_MEDIA_PREFIX: string;
}

export interface RemoteConfigurationState {
  remoteConfig: RemoteConfig;
  loading: boolean;
  loaded: boolean;
  error: any;
}

export const initialState: RemoteConfigurationState = {
  remoteConfig: {
    DISCORD_CLIENT_ID:'',
    COMMUNITY_URL: '',
    CAPI_CERTIFICATE_B64: '',
    PRISMIC_ENDPOINT: '',
    PRISMIC_TOKEN: '',
    ENV: '',
    ROOT_URL: '',
    SENTRY_DNS: '',
    GOOGLE_ANALYTICS_PROPERTY_ID: '',
    ASSETS_MEDIA_PREFIX: '',
  },
  loading: false,
  loaded: false,
  error: null,
};
