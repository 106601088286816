import React from 'react';
import Popup from 'reactjs-popup';
import ConfirmLeave from './confirmleave';
import { setConfirm } from '../../../stores/selectors/confirm.selector';
const PopupConfirm = (): JSX.Element => {
  const closeModal = (): void => {
    setConfirm(false);
  };
  return (
    <Popup modal closeOnDocumentClick={false} open={true}>
      <div className="popup-container-leaving">
        <div className="exit-icon" onClick={closeModal} />
        <ConfirmLeave onClick={closeModal} />
      </div>
    </Popup>
  );
};
export default PopupConfirm;
