import Prismic from 'prismic-javascript';
import { FetchContentsArgs, FetchContentsArgsDocument } from '../../models/prismic-api-query.model';
import { PrismicResponse } from '../../models/prismic-api-response.model';
import config from '../../../config';
import { RemoteConfig } from '../../models/remote-configuration/remote-configuration.model';
import { DefaultClient } from 'prismic-javascript/types/client';
import ResolvedApi from 'prismic-javascript/types/ResolvedApi';

export class PrismicService {
  debug = false;

  client: DefaultClient;
  api: Promise<ResolvedApi>;

  constructor(remoteConfig: RemoteConfig) {
    const apiEndpoint = remoteConfig.PRISMIC_ENDPOINT;
    const accessToken = remoteConfig.PRISMIC_TOKEN;
    this.client = Prismic.client(apiEndpoint, { accessToken });
    this.api = Prismic.getApi(apiEndpoint, { accessToken });
  }

  prismicQuery({ search, type, locale, sort }: FetchContentsArgs): Promise<PrismicResponse> {
    const predicates = [Prismic.Predicates.at('document.type', type)];
    if (search) {
      predicates.push(Prismic.Predicates.fulltext('document', search));
    }
    return this.api.then(function (api) {
      return api.query(predicates, {
        lang: locale,
        orderings: `[${sort}]`,
        pageSize: 100,
      });
    });
  }

  prismicQueryDocument(args: FetchContentsArgsDocument): Promise<PrismicResponse> {
    return this.api.then(function (api) {
      return api.query(Prismic.Predicates.at(`my.${args.type}.landing_page_id`, `${args.document}`), {
        lang: args.locale,
        orderings: `[${args.sort}]`,
        pageSize: 100,
      });
    });
  }

  translateData(contents: any): Record<string, any> {
    //this.debug = type === 'ref_program_faq_block';
    this.log('Translate Data Called');
    this.log('Start filtering of results :');
    let resultLine: Record<string, any> = {};

    contents.results.forEach((result: any) => {
      this.log('Content result');
      this.log(result);

      const key = Object.keys(result.data).find((key) => key.substr(0, 4) === 'body');

      let body = null;
      if (key) {
        body = result.data[key];
      }

      if (body !== null) {
        this.log('Slices detected !');
        // there are slices
        resultLine = this.returnRecordGlobal(result.data);
        body.forEach((slice: any) => {
          //if slice exist or not :
          this.log('check if SliceExist !');
          this.log(resultLine[slice.slice_type]);
          if (resultLine[slice.slice_type] === undefined) {
            this.log('init slice type !');
            resultLine[slice.slice_type] = [];
            this.log(resultLine[slice.slice_type]);
          }

          this.log('Get text record of  resultLine:');
          this.log(resultLine);

          this.log('Slice !');
          let sliceTranslated: any;

          this.log('Slice Items :');
          this.log(slice.items);
          const itemList: any = {};
          slice.items.forEach((item: Record<string, any>) => {
            let itemRecord = this.returnRecord(item);
            itemRecord = this.returnTextRecord(itemRecord);
            this.log('itemRecord :');
            this.log(Object.keys(itemRecord));
            if (!sliceTranslated) {
              sliceTranslated = {};
            }

            if (Object.keys(itemRecord).length === 1) {
              Object.keys(itemRecord).forEach((value: any) => {
                if (!itemList[value]) {
                  itemList[value] = [];
                }
                itemList[value].push(itemRecord[value]);
              });
              //resultLine[slice.slice_type] .push(itemList)
              sliceTranslated = itemList;
            } else {
              this.log('itemRecord test  :');
              this.log(itemRecord);
              this.log(Object.keys(itemRecord).length !== 0);
              if (Object.keys(itemRecord).length !== 0) {
                if (resultLine[slice.slice_type].push) {
                  resultLine[slice.slice_type].push(itemRecord);
                }
              }
            }

            //
          });

          this.log('Slice Items after  :');
          this.log(sliceTranslated);
          this.log('Slice primary :');
          this.log(slice.primary);
          this.log('Slice sliceTranslated before primary :');
          this.log(sliceTranslated);
          this.log(Object.keys(slice.primary).length);

          if (slice.primary && Object.keys(slice.primary).length !== 0) {
            this.log('translating primary');
            this.log(slice.primary);
            const itemRecord = this.returnRecordGlobal(slice.primary);
            this.log('translation primary itemRecord');
            this.log(itemRecord);

            Object.keys(itemRecord).forEach((value: any) => {
              if (sliceTranslated) sliceTranslated[value] = itemRecord[value];
              else {
                sliceTranslated = {};
              }
            });
            //   sliceTranslated.push( itemRecord);
          }
          this.log('Slice sliceTranslated after primary :');
          this.log(sliceTranslated);
          this.log('Slice Translation done !');
          this.log(sliceTranslated);

          this.log('Push slice into ResultLine!');
          this.log(slice.slice_type);
          this.log(Object.keys(sliceTranslated).length);
          //if only one simple slice :
          if (Object.keys(sliceTranslated).length !== 1) {
            if (Object.keys(sliceTranslated).length !== 0) {
              if (resultLine[slice.slice_type].push) {
                resultLine[slice.slice_type].push(sliceTranslated);
              }
            }
          } else {
            resultLine[slice.slice_type] = sliceTranslated;
          }

          this.log(resultLine);
          this.log('End of Slice management!');
        });
        this.log('Check resultLine : ');
        this.log(resultLine);
      } else {
        resultLine = this.returnRecordGlobal(result.data);
        this.log('Get text record of  resultLine:');
        this.log(resultLine);
      }
      this.log('End of result Filtering');
      this.log(resultLine);
    });

    this.log('finalResult : ');
    this.log(resultLine);
    return resultLine;
  }

  returnRecord(data: any): Record<string, any> {
    const result: Record<string, any> = {};

    for (const key in data) {
      if (key.substr(0, 4) !== 'body' && data[key]) {
        result[key] = data[key][0] || data[key];
      }
    }
    return result;
  }

  returnRecordGlobal(data: any): Record<string, any> {
    const result: Record<string, any> = {};

    for (const key in data) {
      //remove body attribute from the equation
      if (key.substr(0, 4) !== 'body' && data[key]) {
        if (data[key][0]) {
          result[key] = data[key][0].text;
        } else if (data[key].url) {
          result[key] = data[key].url;
        } else {
          result[key] = data[key][0];
        }
      }
    }
    return result;
  }

  returnTextRecord(data: any): any {
    const result: Record<string, any> = {};

    for (const key in data) {
      if (data[key]) {
        result[key] = typeof data[key] === 'boolean' ? data[key] : data[key].text || data[key].url;
      } else {
        // if no value --> stay undefined
        result[key] = 'null';
      }
    }

    return result;
  }

  getItems(contents: any): Record<string, any> {
    const obj: any = {};
    for (const content of contents?.results || []) {
      obj[content.type] = content.data;
    }
    return obj;
  }

  log(anystuff: any): void {
    if (this.debug && config.local) {
      console.log('prismic', anystuff);
    }
  }
}
