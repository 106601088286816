import qs from 'qs';
import React from 'react';
import SocialMediaWrapper from '../consentManagement/socialMediaWrapper.component';

const YoutubeVideo = ({ videoURL, size }: { videoURL: string; size: string }): JSX.Element => {
  const youtubeID = qs.parse(videoURL.split('?')[1]).v;
  return (
    <SocialMediaWrapper type="youtube" size={size}>
      <iframe
        className="video-youtube"
        title={`Youtube Video ID ${youtubeID}`}
        src={`https://www.youtube.com/embed/${youtubeID}?autoplay=1`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{ width: '100%' }}
      ></iframe>
    </SocialMediaWrapper>
  );
};

export default YoutubeVideo;
