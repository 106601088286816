import React, { useEffect, useState } from 'react';
import ServicesStatusComponent from './server-status-blocks/services-status.component';
import ServerShardStatusComponent from './server-status-blocks/server-shard-status.component';
import ServerMaintenanceLogsComponent from './server-status-blocks/server-maintenance-logs';
import NeedAssistanceComponent from './server-status-blocks/need-assistance-component';
import { usePrismic } from '../../hooks/prismic.hook';
import NewsStatusComponent from './server-status-blocks/news-status.component';
import { serverStatusService } from '../../services/server-status/server-status.service';

export default function ServerStatusComponent(): JSX.Element {
  const {
    result: {
      page_title,
      page_description,
      server_up_letter,
      server_maintenance_letter,
      server_issue_letter,
      server_down_letter,
    },
  } = usePrismic('server_status');

  const [serverStatusData, setServerStatusData] = useState(Object);
  const [server, setServer] = useState({ name: 'Game', status: 1 });

  useEffect(() => {
    serverStatusService.GetServerStatus().then((data) => {
      setServer(data?.services[0]);
      setServerStatusData(data);
    });
  }, []);

  const [showoptions, setshowoptions] = useState(false);

  const statusClass = (status: number): { class: string; label: string } => {
    switch (status) {
      case 1:
        return { class: 'status-up', label: server_up_letter };
      case 2:
        return { class: 'status-issue', label: server_issue_letter };
      case 3:
        return { class: 'status-down', label: server_down_letter };
      default:
        return { class: 'status-maintenance', label: server_maintenance_letter };
    }
  };
  return (
    <section className="server-status-section">
      <div className="navbar-mobile">
        <a
          onClick={(): void => {
            setshowoptions(!showoptions);
          }}
        >
          <div className="navbar-mobile-current-content">
            <p>{server?.name}</p>
            <div className={`main-line-status-txt ${statusClass(server?.status)?.class}`} />
            <span>{statusClass(server?.status)?.label}</span>
          </div>
          <div className={!showoptions ? 'navbar-mobile-left-icon' : 'navbar-mobile-right-icon'} />
        </a>
      </div>
      {showoptions && (
        <div className="navbar-mobile-options">
          <div>
            {serverStatusData?.services
              ?.filter((elem: any) => {
                return !elem.name.includes(server.name);
              })
              ?.map((service: any, key: number) => {
                return (
                  <a
                    key={key}
                    onClick={(): void => {
                      setServer(service);
                    }}
                  >
                    {service.name}
                    <div className="d-flex">
                      <div className={`main-line-status-txt ${statusClass(service.status).class}`} />
                      <span>{statusClass(service.status).label}</span>
                    </div>
                  </a>
                );
              })}
          </div>
        </div>
      )}

      <div className="img-mobile" />
      <section className="content">
        <div className="title">
          <h1>{page_title}</h1>
        </div>
        <div className="description">{page_description}</div>
      </section>

      <section className="server-status-subsection">
        <ServerShardStatusComponent server={server} maintenanceHistory={serverStatusData?.maintenanceHistory} />
        <ServicesStatusComponent server={server} setServer={setServer} serverStatusData={serverStatusData} />
        <ServerMaintenanceLogsComponent server={server} />
      </section>
      <NewsStatusComponent />
      <NeedAssistanceComponent />
    </section>
  );
}
