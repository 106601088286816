import { createSlice } from '@reduxjs/toolkit';
import { initialLauncherNewsState } from '../../models/launcher-news/launcher-news.model';

const slice = createSlice({
  name: 'launcher-news',
  initialState: initialLauncherNewsState,
  reducers: {
    setLauncherNews: (state: Record<string, any>, { payload }): void => {
      state.contents = payload;
    },
  },
});

export const launchernewsReducer = slice.reducer;
export const { setLauncherNews } = slice.actions;
