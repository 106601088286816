import React from 'react';
import config from '../../../config';
import { usePrismic } from '../../hooks/prismic.hook';
import PrismicRichText, { asText } from '../../services/prismic/prismic.richtext.service';
import LinkComponent from '../link/link.component';
import TutorialSearchComponent from './tutorials-search.component';
import TutorialsOtherComponent from './tutorials-other.component';
import { useTutorials } from '../../hooks/tutorials.hook';
import { TutorialCategory } from '../../models/tutorials/tutorials.model';
import TutorialsAboutComponent from './tutorials-about.component';
import { getUrlFromEnglish } from '../../services/i18n/i18n.url.service';

const TutorialsPageComponent = (): JSX.Element => {
  const {
    data: { page_title, page_description, background_image, header_image },
  } = usePrismic('tutorials_page');

  const { categories } = useTutorials();

  return (
    <div className="tutorials-page">
      <div
        className="tutorials-bg"
        style={{
          backgroundImage: `url('${header_image?.url}')`,
        }}
      ></div>
      <div className="tutorials-fg" style={{ backgroundImage: `url('${background_image?.url}')` }}>
        {config.nodeEnv === 'test' && <div data-testid="tutorials-title">{asText(page_title)}</div>}
        <PrismicRichText render={page_title} />
        <div className="description">
          <PrismicRichText render={page_description} />
        </div>

        <TutorialSearchComponent />
        <div className="tutorial-categories">
          {categories
            .filter((c) => c.tutorials.length)
            .map((category: TutorialCategory, key: number) => (
              <LinkComponent
                className="item"
                link={`${getUrlFromEnglish('/tutorials')}/${category.tutorials[0].slug}`}
                key={key}
              >
                <div className="square">
                  <div className="corners"></div>
                  <img className="icon" src={category.icon?.url} alt="" />
                  <PrismicRichText render={category.category_title} />
                </div>
              </LinkComponent>
            ))}
        </div>
        <TutorialsAboutComponent />
        <TutorialsOtherComponent />
      </div>
    </div>
  );
};

export default TutorialsPageComponent;
