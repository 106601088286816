import routesLocal from './routesLocal';
import { store } from '../app/stores/app.store.config';
import { RemoteConfig } from '../app/models/remote-configuration/remote-configuration.model';

import NewsComponent from '../app/features/news/news.component';
import NewsArchivesComponent from '../app/features/news/news-sections/news-archives.component';
import NewsDetailComponent from '../app/features/news/news-sections/news-detail.component';
import PressContactComponent from '../app/features/presscontact/presscontact.component';
import LegalComponent from '../app/features/legal/legal.component';
import ContentCreatorComponent from '../app/features/contentcreator/contentcreator.component';
import BuyPageOne from '../app/features/buypage/buypage-One.component';
import ReferalHomeComponent from '../app/features/referal/referal-home.component';
import HomePage from '../app/features/homepage/homepage';
import Account from '../app/features/account/account';
import AccountCreationComponent from '../app/features/account/account-creation/account-creation.component';
import ActivateComponent from '../app/features/account/activate/account.activate.component';
//import AccountForgotComponent from '../app/features/account/account-creation/modals/account-forgot.component';
import AccountForgotComponent from '../app/features/account/account-forgot-psw/forgot-psw.component';
import LandingOneHome from '../app/features/landingpageone/homepage/landingone.homepage';
import LandingOneBuyOne from '../app/features/landingpageone/buypage-One.landingone.component';
import LandingOneBuyTwo from '../app/features/landingpageone/buypage-two-blocks/buypage-two.landingone';
import BuyPageThankyou from '../app/features/buypage/buypage-three-blocks/buypage-thankyou';
import ServerStatusComponent from '../app/features/server-status/server-status.component';
import LauncherComponent from '../app/features/launcher/launcher.component';
import LauncherDetailComponent from '../app/features/launcher/sections/launcher-detail.component';
import CommunityComponent from '../app/features/launcher/sections/community.component';
import PatchComponent from '../app/features/launcher/sections/patch.component';
import Media from '../app/features/media/media.component';
import ScreenshotComponent from '../app/features/media/components/screenshot.component';
import WallpaperComponent from '../app/features/media/components/wallpaper.component';
import VideoComponent from '../app/features/media/components/video.component';
import ConceptArtComponent from '../app/features/media/components/concept-art.component';
import DocumentComponent from '../app/features/media/components/document.component';
import AccountResendConfirmationComponent from '../app/features/account/account-resend-confirmation/account.resend-confirmation';
import AccountLoginPageComponent from '../app/features/account/account-login-page/account.login.page.component';
import PartnerComponent from '../app/features/partner/partner.component';
import SitemapPageComponent from '../app/features/site-map/site-map.page.component';
import TutorialsPageComponent from '../app/features/tutorials/tutorials.page.component';
import TutorialsDetailsPageComponent from '../app/features/tutorials/tutorials-details.page.component';
import BuildingComponent from '../app/features/pillars/building/building.component';
import BuildersComponent from '../app/features/pillars/builders/builders.component';
import UpdatesComponent from '../app/features/updates/updates.component';
import LandingComponent from '../app/features/landingTemplate/landing.component';
import ContestComponent from '../app/features/contest/contest.component';
import ContestThankYouComponent from '../app/features/contest/subcomponent/thankyou.component'

const components: Record<string, any> = {
  NewsComponent,
  NewsArchivesComponent,
  NewsDetailComponent,
  PressContactComponent,
  LegalComponent,
  ContentCreatorComponent,
  BuyPageOne,
  BuyPageThankyou,
  ReferalHomeComponent,
  HomePage,
  Account,
  AccountCreationComponent,
  ActivateComponent,
  AccountForgotComponent,
  LandingOneHome,
  LandingOneBuyOne,
  LandingOneBuyTwo,
  ServerStatusComponent,
  LauncherComponent,
  LauncherDetailComponent,
  PatchComponent,
  CommunityComponent,
  Media,
  ScreenshotComponent,
  WallpaperComponent,
  VideoComponent,
  ConceptArtComponent,
  DocumentComponent,
  AccountResendConfirmationComponent,
  AccountLoginPageComponent,
  PartnerComponent,
  SitemapPageComponent,
  TutorialsPageComponent,
  TutorialsDetailsPageComponent,
  BuildingComponent,
  BuildersComponent,
  UpdatesComponent,
  LandingComponent,
  ContestComponent,
  ContestThankYouComponent
};

export const buildRoutesWithComponents = (): any[] => {
 
  const state = store.getState();
  const remoteConfig: RemoteConfig = state.remoteConfigurationBranch.remoteConfig;
  const env = remoteConfig.ENV;

  const alwaysShow = env !== 'prod';

  const routes = routesLocal
    .filter((r) => alwaysShow || !r?.hideOnProd)
    .map((r) => ({ ...r, Component: r.Component ? components[r.Component] : null })); // .filter((r) => alwaysShow || !r?.hideOnProd)
  return routes;
};
