import React from 'react';
import { IntlProvider } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { useI18n } from '../../stores/selectors/i18n.selector';

export const Message = ({ id, defaultMessage = ' ' }: { id: string; defaultMessage?: string }): JSX.Element => {
  const { loading } = useI18n();
  return loading ? <span>...</span> : <FormattedMessage id={id} defaultMessage={defaultMessage} />;
};

export default function withIntl(ChildComp: React.ComponentType<unknown | string>) {
  return function Component(): JSX.Element {
    const { locale } = useI18n();

    return (
      <IntlProvider locale={locale}>
        <ChildComp />
      </IntlProvider>
    );
  };
}
