import React from 'react';
import BuyPageYourSelection from './buypage-yourselection';
import BuyPageAboutYou from './buypage-aboutyou';
import BuyPageActions from './buypage-actions';
import { usePlan } from '../../../stores/selectors/plan.selector';
import { Navigate } from 'react-router-dom';
import Media from 'react-media';
import { usePrismic } from '../../../hooks/prismic.hook';
import RichText from '../../../services/prismic/prismic.richtext.service';
import { AnalyticsComponent } from '../../analytics/analytics.component';

const BuyPageTwo = (): JSX.Element => {
  const { contents } = usePlan();
  const {
    result: { confirm_page_title },
    contents: contentsRaw,
  } = usePrismic('buy_page');

  const { warning } = contentsRaw?.results?.[0]?.data || {};
  if (!contents) {
    return <Navigate to="/buy" replace/>;
  }

  const { planName, price, currency } = contents;

  const nbMonths = planName;
  const amount = price;

  return (
    <>
      <AnalyticsComponent type="confirm" />
      <Media
        queries={{
          small: '(max-width: 479px)',
          large: '(min-width: 480px)',
        }}
      >
        {(matches): JSX.Element => (
          <>
            {matches.small && (
              <div className="buypage-validation">
                <div className="util-main-padding">
                  <h2>{confirm_page_title}</h2>
                  {/* <div className={'textButtonContainer'}>
                    <p className="bread-item">{choose_pack}</p>
                    <p className="bread-item active">{confirm_pack}</p>
                    <p className="bread-item">{checkout}</p>
                  </div> */}
                  <div className="cols">
                    <div>
                      <BuyPageAboutYou />
                    </div>
                    <div>
                      <div className="selection-box">
                        <BuyPageYourSelection currency={currency} nbMonths={nbMonths} amount={amount} />
                        {/* <BuyPageEnterCode /> */}
                        {/* <BuyPageTotal amount={amount} currency={currency} /> */}
                      </div>
                      <BuyPageActions />
                    </div>
                  </div>
                  <div className={'disclamer'}>{warning && <RichText render={warning} />}</div>
                </div>
              </div>
            )}

            {matches.large && (
              <div className="buypage-validation">
                <div className="util-main-padding">
                  <h2>{confirm_page_title}</h2>
                  {/* <div className={'textButtonContainer'}>
                    <p className="bread-item">{choose_pack}</p>
                    <p className="bread-item active">{confirm_pack}</p>
                    <p className="bread-item">{checkout}</p>
                  </div> */}
                  <div className="cols">
                    <div>
                      <BuyPageAboutYou />
                    </div>
                    <div>
                      <div className="selection-box">
                        <BuyPageYourSelection currency={currency} nbMonths={nbMonths} amount={amount} />
                        {/* <BuyPageEnterCode /> */}
                        {/* <BuyPageTotal amount={amount} currency={currency} /> */}
                      </div>
                      <BuyPageActions />
                    </div>
                  </div>
                  <div className={'disclamer'}>{warning && <RichText render={warning} />}</div>
                </div>
              </div>
            )}
          </>
        )}
      </Media>
    </>
  );
};

export default BuyPageTwo;
