import React from 'react';
import Media from 'react-media';
import { usePrismic } from '../../../hooks/prismic.hook';

const BuyPageInfo = (): JSX.Element => {
  const {
    loading,
    hasErrors,
    result: { why_header, why_message },
  } = usePrismic('buy_page');

  if (loading || hasErrors) return <div></div>;

  return (
    <Media
      queries={{
        small: '(max-width: 479px)',
        large: '(min-width: 480px)',
      }}
    >
      {(matches): JSX.Element => (
        <>
          {matches.large && (
            <div className={'buypage-block-landingone'}>
              <div className="content-container">
                <div className={'content-block'}>
                  <h3>{why_header}</h3>
                  <p>{why_message}</p>
                </div>
                <div className="content-image" />
              </div>
            </div>
          )}
          {matches.small && (
            <div className={'buypage-block-landingone'}>
              <div className="content-container">
                <div className="content-image" />
                <div className={'content-block'}>
                  <h3>{why_header}</h3>
                  <p>{why_message}</p>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </Media>
  );
};

export default BuyPageInfo;
