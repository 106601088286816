import React from 'react';
import PrismicRichText, { asText } from '../../../services/prismic/prismic.richtext.service';
import { usePrismic } from '../../../hooks/prismic.hook';
import LinkComponent from '../../link/link.component';
const AccountPTS = (): JSX.Element => {
  const {
    data: { title, description, description_under, button_text, button_link },
  } = usePrismic('account_pts');

  return (
    <div className="pts-container">
      <h2> {asText(title)}</h2>
      <div className="pts-description">
        <PrismicRichText render={description} />
      </div>
      <LinkComponent className="button-container" link={asText(button_link)}>
        <button className="theme-button">
          <div className="theme-button-container">
            <div></div>
            <span>{asText(button_text)}</span>
          </div>
        </button>
      </LinkComponent>
      <div className="pts-description pts-description-under">
        <PrismicRichText render={description_under} />
      </div>
    </div>
  );
};
export default AccountPTS;
