import React from 'react';
import LinkComponent from '../link/link.component';

const SubMenuFunction = (data: Record<string, any>, setMenuClicked: any): JSX.Element[] => {
  const megaMenu = [];
  for (const [key, arr] of Object.entries(data)) {
    if (key !== 'link' && key !== 'name') {
      const megaMenuInput: JSX.Element = (
        <div className={'submenublock'} key={key}>
          {key && key !== 'null' && <div className={'submenuheader'}>{key}</div>}
          <div className={'submenucontent'}>
            {arr.map((obj: any, key: any) => {
              return (
                <LinkComponent
                  link={obj.headerlink}
                  key={key}
                  tabIndex={0}
                  confirm={obj.confirm_external_link}
                  label={obj.headercontent}
                  onClick={() => {
                    setMenuClicked(-1);
                  }}
                />
              );
            })}
          </div>
        </div>
      );
      megaMenu.push(megaMenuInput);
    }
  }

  return megaMenu;
};

export const subMenuFunctionMobile = (data: Record<string, any>): JSX.Element[] => {
  const megaMenu = [];

  for (const [key, arr] of Object.entries(data)) {
    if (key !== 'link' && key !== 'name') {
      const megaMenuInput: JSX.Element = (
        <div>
          <div className={'submenublock'}>
            <span className={'button submenuheader'} key={key} tabIndex={0}>
              <div className={'header'}> {key}</div>
              <div className={'submenu-open'} />
            </span>
            <div className={'submenucontent'}>
              {arr.map((obj: any, key: number) => (
                <LinkComponent key={key} tabIndex={0} link={obj.headerlink} label={obj.headercontent} />
              ))}
            </div>
          </div>
        </div>
      );
      megaMenu.push(megaMenuInput);
    }
  }

  return megaMenu;
};

export default SubMenuFunction;
