import React, { useState } from 'react';
import Media from 'react-media';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Popup from 'reactjs-popup';
import { replaceLink } from '../../../../services/utils/utils.service';
import { asText } from '../../../../services/prismic/prismic.richtext.service';
import { usePrismic } from '../../../../hooks/prismic.hook';
import { HomeBlockComponent, MenuBlock } from '../../../homepage/homepagesections/home.block.component';
import HomeMenuComponent from '../../../homepage/homepagesections/home.menu.component';

export default function HomePageMiddle(): JSX.Element {
  const {
    result: { reviews },
    contents,
  } = usePrismic('home_page');

  const {
    results: [
      {
        data: { body },
      },
    ],
  } = contents;

  const home_blocks = (body.find((b: any) => b.slice_type === 'home_blocks') || { items: [] }).items;

  const [modalVideo, setModalVideo] = useState('');

  const openModal = (link: string): void => {
    if (document.body.classList && document.body.classList.add) {
      document.body.classList.add('modal-open');
    }
    setModalVideo(replaceLink(link));
  };

  const closeModal = (): void => {
    if (document.body.classList && document.body.classList.remove) {
      document.body.classList.remove('modal-open');
    }
    setModalVideo('');
  };

  const reviewsDisplayed = (reviews || []).map(
    ({ title, description }: { title: string; description: string }, index: number): JSX.Element => (
      <div className="contentbox" key={index}>
        <div className="squarebox">
          <div className="realcontent"> </div>
          <p className="title">{title}</p>
          <p className="description">{description}</p>
        </div>
      </div>
    ),
  );

  const SmallBlock = ({ block }: { block: MenuBlock }): JSX.Element => (
    <div className={'content'}>
      <p className={'title'}>{asText(block.block_title)}</p>
      <p className={'description'}>{asText(block.description)}</p>
    </div>
  );

  return (
    <div className={'homePageMiddleLandingOne'}>
      {modalVideo && (
        <Popup modal closeOnDocumentClick={false} open={true}>
          <div className={'popUpContainer'}>
            <div className={'exitIcon'} onClick={closeModal} />
            <video controls autoPlay controlsList="nodownload">
              <source src={modalVideo} type="video/mp4" />
              <track default kind="captions" srcLang="en" src="" />
            </video>
          </div>
        </Popup>
      )}

      <div className={'spacer'} />

      <Media
        queries={{
          small: '(max-width: 479px)',
          large: '(min-width: 480px)',
        }}
      >
        {(matches: any): JSX.Element => (
          <>
            {matches.large && (
              <>
                <HomeMenuComponent menus={home_blocks} />
                {home_blocks.map((block: MenuBlock, key: number) => (
                  <HomeBlockComponent key={key} index={key} block={block} openModal={openModal} prismic />
                ))}
                <div className="partOne part-one-bottom">
                  <div className={'scrollSection'}>
                    <Carousel
                      autoPlay={true}
                      autoPlaySpeed={5000}
                      transitionDuration={1000}
                      keyBoardControl={true}
                      infinite={true}
                      responsive={{
                        desktop: {
                          breakpoint: { max: 4000, min: 480 },
                          items: 3,
                        },
                      }}
                    >
                      {reviewsDisplayed}
                    </Carousel>
                  </div>
                </div>
              </>
            )}

            {Boolean(home_blocks.length && matches.small) && (
              <>
                <div className={'partOne'}>
                  <div className={'scrollSection'}>{reviewsDisplayed}</div>
                  <div className={'square'}>
                    <div className={'squareLineOne'} />
                    <div className={'squareLineTwo'} />
                  </div>
                  <SmallBlock block={home_blocks[0]} />
                </div>
                <div className={'partMobileHome'}>
                  {home_blocks.slice(1).map(
                    (block: MenuBlock, key: number): JSX.Element => (
                      <React.Fragment key={key}>
                        <div className="imageOne" style={{ backgroundImage: block.image.url }} />
                        <SmallBlock block={block} />
                      </React.Fragment>
                    ),
                  )}
                </div>
              </>
            )}
          </>
        )}
      </Media>
    </div>
  );
}
