import { useEffect } from 'react';
import { useAppDispatch } from '../../stores/app.store.config';
import { useAuth } from '../../stores/selectors/auth.selector';
import { persistLogin, updateToken } from '../../stores/slices/auth.slice';
import config from '../../../config';
import axios from 'axios';
import { decodeJWT } from './authentication.jwt.utils';
import { getTokenCookies, setTokenCookies, resetTokenCookies } from './authentication.cookies.service';
import { retrieveGameTime } from './authentication.gametime.service';
import { getToken } from './attributes.service';
import { retriveUser } from '../retrieval/user.retrieval';

export const getProductsWithRole = (decoded: any, gameTime = ''): any[] => {
  const roles = (typeof decoded?.role === 'string' ? [decoded.role] : decoded?.role) || [];
  const products = [];

  for (const role of roles) {
    if (config.rolesWithAccess.includes(role)) {
      const name = role.replace('Access', ' Access').replace('LifetimeSubscriber', 'Lifetime Subscriber');
      products.push({
        product: {
          name,
          gameTime: name === 'Subscriber' ? gameTime : '',
        },
        state: 2,
      });
    }
  }

  return products;
};

const getOrders = async (decoded: any, token: string): Promise<any[]> => {
  try {
    const allPlans = await axios.get(`/api/orders/byuser/${decoded.id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    const response = allPlans.data;

    const filteredResponse = response.filter((x: Record<string, any>) => x.status === 2);
    return filteredResponse;
  } catch (err) {
    return [];
  }
};

export const getAllPlans = async (decoded: any, token: string): Promise<any[]> => {
  const rawOrders = await getOrders(decoded, token); // contain every order the player has includes, myDU, steam purchases, pledges and subscriptions
  const gameTime = await retrieveGameTime(decoded, token);
  const products = getProductsWithRole(decoded, gameTime); // get roles the user has to show them as a product (subscriber, lifetime)

  if (!rawOrders.length && !products.length) return [];
  if (!rawOrders.length) {
    return [{ products, createdAt: '' }];
  }

  let orders = JSON.parse(JSON.stringify(rawOrders));
  let subscriptions = orders.filter((o: any) => o.externalSubscriptionId > 0);

  orders = orders.filter((o: any) => o.externalSubscriptionId === null);
  const sortFn = (a: any, b: any) => (new Date(b.paidAt).getTime() - new Date(a.paidAt).getTime() < 0 ? -1 : 1);
  orders.sort(sortFn);
  subscriptions.sort(sortFn);

  orders = orders.splice(0, 1);
  subscriptions = subscriptions.splice(0, 1);

  if (orders.length === 0 && subscriptions.length === 0) return [];

  orders = orders.concat(subscriptions);

  for (const o of orders) {
    o.products = [];

    for (const p of o.basket) {
      o.products.push(p);
    }
    o.products = o.products.map((p: any) => ({
      ...p,
      order: { ...o },
    }));
  }

  orders[0].products = getProductsWithRole(decoded, gameTime).concat(orders[0]?.products || []);
  return orders;
};

export type TokenType = {
  token: string;
  refreshToken: string;
};

export const doRefreshToken = async (token: string, refreshToken: string): Promise<TokenType> => {
  try {
    const url = '/api/auth/refresh';
    const response = await axios.post(
      url,
      { token: refreshToken },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    return response.data;
  } catch (err) {
    console.log('error refresh token');
    return { token: '', refreshToken: '' };
  }
};

const retrieveLogin = async (auth: any, dispatch: any): Promise<void> => {
  const { token, refreshToken } = getTokenCookies();
  if (!token && auth?.user && !auth.signup) {
    setTokenCookies(auth.user);
  }
  if (auth.initialized) return;
  if (token) {
    const decoded = await decodeJWT(token);

    if (Boolean(decoded)) {
      //not expired token
      const userInfo = {
        token,
        refreshToken,
        ...decoded,
      };

      const allPlans = await getAllPlans(decoded, token);
      const details = await retriveUser(decoded?.id, token);
      dispatch(updateToken({ ...userInfo, allPlans, details }));
      return;
    }

    let error = false;
    let newDecoded, userInfo;
    const { token: newToken, refreshToken: newRefreshToken } = await doRefreshToken(token, refreshToken);

    if (newToken && newRefreshToken) {
      setTokenCookies({ token: newToken, refreshToken: newRefreshToken });
      newDecoded = await decodeJWT(newToken);
      error = !Boolean(newDecoded);
      userInfo = {
        token: newToken,
        refreshToken: newRefreshToken,
        ...newDecoded,
      };
    } else {
      error = true;
      if (config.local) {
        console.log('could not refresh token, so logging out user');
      }
      dispatch(persistLogin(null));
      resetTokenCookies();
      // window.location.reload();
    }

    if (!error) {
      const allPlans = await getAllPlans(newDecoded, newToken);
      const details = await retriveUser(newDecoded?.id, newToken);
      dispatch(updateToken({ ...userInfo, allPlans, details }));
    }
  } else {
    dispatch(persistLogin(null));
  }
};

const useSignIn = (): any => {
  const dispatch = useAppDispatch();
  const auth = useAuth();

  useEffect((): any => {
    retrieveLogin(auth, dispatch);
  }, [dispatch, auth]);
};

export const updatePurchases = async (dispatch: any): Promise<void> => {
  try {
    const { token, refreshToken } = getToken();

    const { token: newToken, refreshToken: newRefreshToken } = await doRefreshToken(token, refreshToken);
    setTokenCookies({ token: newToken, refreshToken: newRefreshToken });

    const decoded = await decodeJWT(newToken);
    const allPlans = await getAllPlans(decoded, newToken);
    const details = await retriveUser(decoded?.id, newToken);

    const userInfo = {
      token: newToken,
      refreshToken: newRefreshToken,
      ...decoded,
    };
    dispatch(updateToken({ ...userInfo, allPlans, details }));
  } catch (err) {
    if (config.local) {
      console.log('error with updatePurchases', err);
    }
  }
};

export default useSignIn;
