import { createSlice } from '@reduxjs/toolkit';
import { initialPurchaseState } from '../../models/purchase/purchase.model';
import { makePurchase } from '../actions/purchase.actions';

const slice = createSlice({
  name: 'beta-keys',
  initialState: initialPurchaseState,
  reducers: {
    killOrder: (state): void => {
      state.order = null;
      state.loading = false;
      state.error = false;
    },
  },
  extraReducers: {
    [makePurchase.fulfilled.type]: (state, { payload }): void => {
      state.order = payload;
      state.loading = false;
      state.error = false;
    },
    [makePurchase.pending.type]: (state): void => {
      state.order = null;
      state.loading = true;
      state.error = null;
    },
    [makePurchase.rejected.type]: (state, { error }): void => {
      state.order = null;
      state.loading = false;
      state.error = error;
    },
  },
});

export const purchaseReducer = slice.reducer;
export const { killOrder } = slice.actions;
