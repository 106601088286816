import React, { useState, useEffect } from 'react';
import { usePrismic } from '../../../hooks/prismic.hook';
import { useBetaKeys } from '../../../stores/selectors/beta-keys.selector';
import { useAppDispatch } from '../../../stores/app.store.config';
import { sendAvailableBetaKey, fetchBetaKeys, cancelBetaKey } from '../../../stores/actions/beta-keys.action';
import { filterGiftedKeys, filterPendingKeys } from '../../../services/api/inventory/keys.service';
import { Key } from '../../../models/beta-keys/keys.model';
import { ErrorComponent } from '../../error/error.component';

export default function AccountBetaGiftCodes(): JSX.Element | null {
  const {
    result: { gift_title, available_title, pending_title, gifted_title, gift_label, send_button, cancel_button },
  } = usePrismic('account_products');

  const {
    result: { email_placeholder },
  } = usePrismic('common');

  const dispatch = useAppDispatch();

  const { keys, totals, error } = useBetaKeys();

  const nbCode = totals.available + totals.gifted + totals.pending;

  const [Email, setEmail] = useState<string>('');

  const sendCode = (): void => {
    dispatch(sendAvailableBetaKey({ Email }));
    setEmail('');
  };

  useEffect(() => {
    dispatch(fetchBetaKeys());
  }, [dispatch]);

  if (!nbCode) return <div></div>;

  return (
    <div className="account-prod-container">
      <div className="input-group"></div>
      <h1 className="account-title">
        {gift_title} ({nbCode})
      </h1>
      <h2 className="available">
        {available_title} ({totals.available})
      </h2>
      {Boolean(totals.available) && (
        <div className="input-group-code small-input-group">
          <label className="">{gift_label}</label>
          <div className="input-row">
            <div className="input-box">
              <div className="icon email-icon"></div>
              <input
                type="text"
                placeholder={email_placeholder}
                value={Email}
                onChange={({ target: { value } }): void => setEmail(value)}
              />
            </div>
            <button className="theme-button" onClick={sendCode} disabled={!totals.available && Boolean(Email)}>
              <div className="theme-button-container">
                <div className="send" />
                <span>{send_button}</span>
              </div>
            </button>
          </div>
        </div>
      )}
      {error && error.from === 'sendAvailableBetaKey' && (
        <div className="error error-position-fixed">
          <ErrorComponent error={error} />
        </div>
      )}
      <h2 className="available">
        {pending_title} ({totals.pending})
      </h2>
      {error && error.from === 'cancelBetaKey' && (
        <div className="error">
          <ErrorComponent error={error} />
        </div>
      )}
      <div className="pending-group">
        {filterPendingKeys(keys).map(({ id, recipient }: Key, key: number) => (
          <div className="pending-row" key={key}>
            <p>{recipient}</p>
            <button onClick={(): any => dispatch(cancelBetaKey(id))}>
              <div></div>
              <span>{cancel_button}</span>
            </button>
          </div>
        ))}
      </div>
      <h2 className="available">
        {gifted_title} ({totals.gifted})
      </h2>
      <div className="pending-group">
        {filterGiftedKeys(keys).map(({ usedBy }: Key, key: number) => (
          <div key={key} className="pending-row">
            <p>{usedBy}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
