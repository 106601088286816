import PrismicRichText, { asText } from '../../../services/prismic/prismic.richtext.service';
import React from 'react';
import { goto } from '../../../stores/selectors/router.selector';
import { usePrismic } from '../../../hooks/prismic.hook';
import { refreshToken } from '../../../stores/actions/auth.action';
import { useAppDispatch } from '../../../stores/app.store.config';

const BackToGameModal = ({
  setIsElegible,
  menuClicked,
  setMenuClicked,
}: {
  setIsElegible: any;
  menuClicked: any;
  setMenuClicked: any;
}): JSX.Element => {
  const {
    data: { back_game_popup_title, back_game_popup_desc, back_game_popup_button },
  } = usePrismic('login');
  const dispatch= useAppDispatch()
  if (!back_game_popup_title) return <></>;

  return (
    <>
      <div className="popup-overlay-reminder"></div>
      <div className="login-modal-container reminder-container">
        <div className="corners">
          <div className="reminder-title">
            <PrismicRichText render={back_game_popup_title} />
          </div>
          <div className="reminder-desc">
            <PrismicRichText render={back_game_popup_desc} />
          </div>
          <button
            className="theme-button"
            onClick={() => {
              dispatch(refreshToken());
              if (menuClicked !== -1) setMenuClicked(-1 * Math.sign(menuClicked));
              goto('/account/products');
              setIsElegible(false);
            }}
          >
            <div className="theme-button-container">
              <div></div>
              <span>{asText(back_game_popup_button)}</span>
            </div>
          </button>
        </div>
        <div
          className="icon-close-reminder"
          onClick={() => {
            setIsElegible(false);
          }}
        ></div>
      </div>
    </>
  );
};
export default BackToGameModal;
