import React, { useEffect, useState } from 'react';
import { useNews } from '../../../stores/selectors/news.selector';
// import TwitterFeed from './twitterFeed.component';
import { getSlug } from '../../news/news.service';
import { getUrlFromEnglish } from '../../../services/i18n/i18n.url.service';
import RichText, { asText } from '../../../services/prismic/prismic.richtext.service';
import LinkComponent from '../../link/link.component';
import Carousel from 'react-multi-carousel';
import PrismicRichText from '../../../services/prismic/prismic.richtext.service';
import Popup from 'reactjs-popup';
import { replaceLink } from '../../../services/utils/utils.service';
import Media from 'react-media';
import YoutubeVideo from '../../partials/video-youtube.partial-component';
import ScrollAnimation from 'react-animate-on-scroll';
import { ParallaxBanner } from 'react-scroll-parallax';
import rocks from '../../../../assets/images/homepage-actual/rocks.png';
import planet from '../../../../assets/images/homepage-actual/planet.png';

export default function HomePageBetaSocial(props: any): JSX.Element {
  const { contents: contentsnews } = useNews();
  const responsive = {
    desktop: {
      breakpoint: { max: 4000, min: 480 },
      items: 3,
    },

    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1,
    },
  };
  const {
    result: { news_title, news_description, news_button },
    contents,
  } = props.prismic;
  const {
    results: [
      {
        data: { body },
      },
    ],
  } = contents;
  const [modalVideo, setModalVideo] = useState('');
  const game_updates = body.find((b: any) => b.slice_type === 'game_updates');
  if (!game_updates) return <></>;
  const {
    items: {},
  } = game_updates;
  const {
    primary: {
      released,
      game_updates_title,
      game_updates_description,
      beta_update_button_label,
      beta_update_button_link,
      roadmap_button_label,
      road_map_image,
      button_left,
      button_right,
      swipe_right,
      coming_soon_label,
    },
  } = game_updates;

  const renderGameUpdates = (): JSX.Element[] | JSX.Element => {
    const result = game_updates?.items?.map((item: any, key: number) => {
      return (
        <ScrollAnimation
          animateIn="fadeInUp"
          className="filter"
          animateOnce={true}
          key={key}
          delay={(key * 500) / 2}
          duration={0.5}
        >
          <div className="game-update-box" key={key}>
            <div className="game-update-box-corners-container">
              <div className="game-update-box-corners"> </div>
            </div>
            {!item.poster && (
              <div className="game-update-box-content">
                <div
                  className="game-update-box-image-intro"
                  style={{ backgroundImage: `url('${item.game_update_image_intro.url}')` }}
                ></div>
                <div className="game-update-box-detail">
                  <p className="game-update-box-version">{asText(item.version)}</p>
                  <div className="game-update-box-title">
                    <PrismicRichText render={item.game_update_title}></PrismicRichText>
                  </div>
                  <p className={item.release_date ? 'game-update-box-release' : 'game-update-box-release hidden'}>
                    {asText(released)}
                    {asText(item.release_date)}
                  </p>
                  <div className="game-update-box-separator"></div>
                  <div
                    className="game-update-box-small"
                    style={{ backgroundColor: `${asText(item.image_group_color_1)}` }}
                  >
                    <div className="game-update-box-small-label label-even">
                      <PrismicRichText render={item.image_group_label_1}></PrismicRichText>
                    </div>
                    <div
                      className="game-update-box-small-image image-even"
                      style={{ backgroundImage: `url('${item.image_group_1.url}')` }}
                    ></div>
                  </div>
                  <div
                    className="game-update-box-small"
                    style={{ backgroundColor: `${asText(item.image_group_color_2)}` }}
                  >
                    <div className="game-update-box-small-label label-odd">
                      <PrismicRichText render={item.image_group_label_2}></PrismicRichText>
                    </div>
                    <div
                      className="game-update-box-small-image image-odd"
                      style={{ backgroundImage: `url('${item.image_group_2.url}')` }}
                    ></div>
                  </div>
                  <div
                    className="game-update-box-small"
                    style={{ backgroundColor: `${asText(item.image_group_color_3)}` }}
                  >
                    <div className="game-update-box-small-label label-even">
                      <PrismicRichText render={item.image_group_label_3}></PrismicRichText>
                    </div>
                    <div
                      className="game-update-box-small-image image-even"
                      style={{ backgroundImage: `url('${item.image_group_3.url}')` }}
                    ></div>
                  </div>
                  <div
                    className="game-update-box-small"
                    style={{ backgroundColor: `${asText(item.image_group_color_4)}` }}
                  >
                    <div className="game-update-box-small-label label-odd">
                      <PrismicRichText render={item.image_group_label_4}></PrismicRichText>
                    </div>
                    <div
                      className="game-update-box-small-image image-odd"
                      style={{ backgroundImage: `url('${item.image_group_4.url}')` }}
                    ></div>
                  </div>
                </div>
              </div>
            )}
            {item.poster && (
              <div
                className="game-update-box-content poster"
                style={{ backgroundImage: `url('${item.game_update_image_intro.url}')` }}
              >
                <div className="game-update-box-title-container">
                  <div
                    className="game-update-box-logo"
                    style={{ backgroundImage: `url('${item.image_group_1.url}')` }}
                  ></div>
                  <div className="game-update-box-title">
                    <PrismicRichText render={item.game_update_title}></PrismicRichText>
                  </div>
                </div>
              </div>
            )}
            <div
              className={
                item?.watch_trailer_link1?.url && item?.learn_more_link?.url
                  ? 'game-update-box-buttons'
                  : 'game-update-box-buttons center'
              }
            >
              {item?.watch_trailer_link1?.url && (
                <button
                  onClick={() => {
                    openModal(item?.watch_trailer_link1?.url);
                  }}
                >
                  <span> {asText(button_left)}</span>
                </button>
              )}
              {item?.learn_more_link?.url && (
                <a href={item?.learn_more_link?.url}>
                  <button>
                    <span>{asText(button_right)}</span>
                  </button>
                </a>
              )}
              {!item?.learn_more_link?.url && !item?.watch_trailer_link1?.url && (
                <p className="game-update-box-buttons-soon">{asText(coming_soon_label)}</p>
              )}
            </div>
          </div>
        </ScrollAnimation>
      );
    });
    return result;
  };
  const openModal = (link: string): void => {
    setModalVideo(replaceLink(link));
  };

  const closeModal = (): void => {
    setModalVideo('');
  };
  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        closeModal();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);
  return (
    <div className={'homepagebetasocial'}>
      {modalVideo && (
        <Popup modal closeOnDocumentClick={false} open={true}>
          <div className={'popUpContainer'}>
            <div className={'exitIcon'} onClick={closeModal} />
            {modalVideo?.includes('http') && (
              // <video controls autoPlay controlsList="nodownload">
              //   <source src={modalVideo} type="video/mp4" />
              //   <track default kind="captions" srcLang="en" src="" />
              // </video>

              <YoutubeVideo videoURL={modalVideo} size={'long'} />
            )}
            {road_map_image?.url && !modalVideo?.includes('http') && (
              <div className="road-map-img" style={{ backgroundImage: `url(${road_map_image?.url})` }}></div>
            )}
          </div>
        </Popup>
      )}
      <ParallaxBanner
        layers={[{ image: rocks, speed: -5, style: { backgroundSize: 'contain' } }]}
        className="rocks"
      ></ParallaxBanner>
      <ParallaxBanner
        layers={[
          {
            image: planet,
            speed: 15,
            style: { backgroundSize: '40%', backgroundPosition: 'left', backgroundRepeat: 'no-repeat' },
          },
        ]}
        className="planet"
      ></ParallaxBanner>
      <div className="game-updates-bg"></div>
      <div className="game-updates">
        <div className="game-update-content">
          <div>
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={100}>
              <h2>{asText(game_updates_title)}</h2>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={200}>
              <p>{asText(game_updates_description)}</p>
            </ScrollAnimation>
          </div>
          <div className="btn-group">
            {beta_update_button_link?.url && (
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={300}>
                <button
                  className="theme-button left"
                  onClick={() => {
                    openModal(beta_update_button_link?.url);
                  }}
                >
                  <div className="theme-button-container">
                    <div />
                    <span>{asText(beta_update_button_label)}</span>
                  </div>
                </button>
              </ScrollAnimation>
            )}
            {road_map_image?.url && (
              <ScrollAnimation className="fullWidth" animateIn="fadeInUp" animateOnce={true} delay={400}>
                <button
                  className="theme-button right"
                  onClick={() => {
                    openModal('image');
                  }}
                >
                  <div className="theme-button-container">
                    <div />
                    <span>{asText(roadmap_button_label)}</span>
                  </div>
                </button>
              </ScrollAnimation>
            )}
          </div>
        </div>
        <p className="game-update-swipe">{asText(swipe_right)}</p>
        <div className="game-update-carousel">
          <Media
            queries={{
              small: '(max-width: 479px)',
              large: '(min-width: 480px)',
            }}
          >
            {(matches): JSX.Element => (
              <>
                {matches.large && (
                  <Carousel
                    autoPlay={false}
                    autoPlaySpeed={5000}
                    transitionDuration={1000}
                    keyBoardControl={true}
                    responsive={responsive}
                    itemClass="carousel-item-padding"
                  >
                    {renderGameUpdates()}
                  </Carousel>
                )}
                {matches.small && renderGameUpdates()}
              </>
            )}
          </Media>
          ;
        </div>
      </div>

      <div className="planets">
        <div className="left"></div>
      </div>
      <div className="news-twitter-bg"></div>
      <div className="homepageNewsTwitter-container">
        <div className="news-twitter-intro-text">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <h2 className="news-twitter-title">{asText(news_title)}</h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <p className="news-twitter-desc">{asText(news_description)}</p>
          </ScrollAnimation>
        </div>
        <div className="news-twitter-button">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <LinkComponent link="/news">
              <button className="theme-button">
                <div className="theme-button-container">
                  <div />
                  <span> {news_button}</span>
                </div>
              </button>
            </LinkComponent>
          </ScrollAnimation>
        </div>
      </div>
      <div className="homepageNewsTwitter">
        <div className="newsContent">
          {/* <p className={'titleNews'}>{news_title}</p> */}
          <div className="newsGrid">
            {contentsnews &&
              contentsnews
                .filter((content: any) => content.type !== 'press release')
                .slice(0, 3)
                .map((content: any, key: number) => {
                  return (
                    <ScrollAnimation
                      animateIn="fadeInUp"
                      animateOnce={true}
                      key={key}
                      delay={(key * 200) / 2}
                      duration={0.3}
                      offset={0}
                    >
                      <div key={key} className="container">
                        <div className="square"></div>
                        <LinkComponent
                          link={`${getUrlFromEnglish('/news')}/${getSlug(content)}`}
                          className={'newsContentBox'}
                        >
                          <div className={'image'} style={{ backgroundImage: `url(${content.image.url})` }}></div>
                          <div className={'content'}>
                            <p className={'title'} id="title">
                              {content.type}
                            </p>
                            <p className={'explanation'}>{asText(content.title)}</p>
                            {content?.title?.length < 50 && (
                              <div className="decShort">
                                <RichText render={content?.description} />
                              </div>
                            )}

                            <p className={'date'}>{content.date}</p>
                          </div>
                        </LinkComponent>
                      </div>
                    </ScrollAnimation>
                  );
                })}
          </div>
        </div>
        <div className="news-content-button">
          <LinkComponent link="/news">
            <button className="theme-button">
              <div className="theme-button-container">
                <div />
                <span> {news_button}</span>
              </div>
            </button>
          </LinkComponent>
        </div>
        {/* <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
          <TwitterFeed />
        </ScrollAnimation> */}
      </div>
    </div>
  );
}
