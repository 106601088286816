import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getSubscriptionsService,
  cancelSubscriptionService,
  renewSubscriptionService,
} from '../../services/api/orders/order.subscriptions.service';

export const getSubscriptions = createAsyncThunk(
  'subscriptions/getSubscriptions',
  async (): Promise<any> => {
    return getSubscriptionsService();
  },
);

export const cancelSubscription = createAsyncThunk(
  'subscriptions/cancelSubscription',
  async (order: any): Promise<any> => {
    await cancelSubscriptionService(order);
    return getSubscriptionsService();
  },
);

export const renewSubscription = createAsyncThunk(
  'subscriptions/renewSubscription',
  async (order: any): Promise<any> => {
    await renewSubscriptionService(order);
    return getSubscriptionsService();
  },
);
