import { useEffect } from 'react';
import { useAppDispatch } from '../../stores/app.store.config';
import { getAssets } from '../../stores/actions/media.actions';

const useGetAssets = (): void => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAssets());
  }, [dispatch]);
};

export default useGetAssets;
