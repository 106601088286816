import { createAsyncThunk } from '@reduxjs/toolkit';
import { FetchContentsArgs } from '../../models/prismic-api-query.model';
import { PrismicService } from '../../services/prismic/prismic.service';
import { store } from '../app.store.config';

export const fetchContents = createAsyncThunk(
  'content/fetchContents',
  async ({
    locale,
    type,
    preview,
    document,
    buyone,
    sort = 'last_publication_date',
  }: FetchContentsArgs): Promise<any> => {
    const state = store.getState();
    const remoteConfig = state?.remoteConfigurationBranch?.remoteConfig;
    const prismicService = new PrismicService(remoteConfig);

    let result;
    if (document && buyone && !['footer', 'alert', 'cookie_notice', 'legal_page', 'common'].includes(type)) {
      result = await prismicService.prismicQueryDocument({ locale, type, sort, preview, document });
      const results: any = result.results;
      const correctResults = results.filter(
        (obj: { data: { landing_buy_page_id: string } }) => obj.data.landing_buy_page_id === buyone,
      );

      result.results = correctResults;
    } else if (document) {
      if (type === 'footer' || type === 'home_page' || type === 'seo_meta_landing') {
        result = await prismicService.prismicQueryDocument({ locale, type, sort, preview, document });
      } else {
        result = await prismicService.prismicQuery({ locale, type, sort, preview });
        const results: any = result.results;

        const correctResults = results.filter(
          (obj: { data: { landing_page_id: string } }) => obj?.data?.landing_page_id === 'main',
        );

        if (correctResults.length > 0) {
          result.results = correctResults;
        }
      }
    } else {
      result = await prismicService.prismicQuery({ locale, type, sort, preview });
      const results: any = result.results;

      const correctResults = results.filter(
        (obj: { data: { landing_page_id: string } }) => obj?.data?.landing_page_id === 'main',
      );

      if (correctResults.length > 0) {
        result.results = correctResults;
      }
    }
    return { result, type };
  },
);
