import jwt_decode from 'jwt-decode';
import config from '../../../config';
const staticGroups: Record<string, number> = {
  influencergroup: 3,
  markeedragon3group: 3,
  markeedragon6group: 6,
  markeedragon12group: 12,
  intelgroup: 3,
  contentplatformarsenalgroup: 3,
  contentplatformunpaidgroup: 3,
  contentplatformpaidgroup: 3,
  journalistgroup: 3,
  giveawaysinfluencersgroup: 3,
  generalmarketinggroup: 3,
};

export const getGameAccess = (token: string): number => {
  try {
    const decoded: Record<string, any> = jwt_decode(token);
    const roles = (typeof decoded?.role === 'string' ? decoded.role : decoded?.role) || [];
    for (const role of roles) {
      if (config.rolesWithAccess.includes(role)) return 0;
      if (staticGroups[role.toLowerCase()]) return staticGroups[role.toLowerCase()];
    }
  } catch (err) {
    if (config.local) {
      console.log('error with getGameAccess', err);
    }
  }
  return 0;
};
