import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { AnalyticsState, initialAnalyticsState } from '../../models/analytics/analytics.model';
import { RootState } from '../../models/_root-state.model';

const slice = createSlice({
  name: 'analytics',
  initialState: initialAnalyticsState,
  reducers: {
    setExperiment: (state, { payload: { experiment, variant } }): void => {
      state.experiment = experiment;
      state.variant = variant;
    },
  },
});

export const analyticsReducer = slice.reducer;
export const setExperiment = slice.actions.setExperiment;

export const useAnalytics = (): AnalyticsState => useSelector((state: RootState) => state.analyticsBranch);
