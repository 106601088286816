import axios from 'axios';
// import config from '../../../config';
import { Action, triggerActionGTM } from '../optimization/ecommerce.service';
// import { addAcquisition } from '../acquisition/acquisition.service';
// import { getUser } from './attributes.service';

export const subscribeToNewsletter = async (email: string): Promise<void> => {
  await axios.post('/api/newsletter', { email });
  triggerActionGTM(Action.NewsletterSubscribe);
  // await addAcquisition(getUser()); // temporarilly removed until backend is fixed
};

export default subscribeToNewsletter;
