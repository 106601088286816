import React from 'react';
import { usePrismic } from '../../../hooks/prismic.hook';
import PrismicRichText, { asText } from '../../../services/prismic/prismic.richtext.service';
import { usePlansAvailable } from '../../../stores/selectors/plans-available.selector';
import Popup from 'reactjs-popup';
interface Props {
  setOpenModal?: (open: boolean) => void;
}
const BuyPageSpecs = (props: Props): JSX.Element => {
  const {
    data: { bubble_windows11 },
    result: { specs_header },
  } = usePrismic('buy_page');

  const {
    data: { nvidia_notice, nvidia_notice_link_label },
  } = usePrismic('nvidia');
  const { contents } = usePlansAvailable();
  const contentStyle = { background: '#ffd255', width: '350px', padding: '20px' };
  const overlayStyle = { background: 'transparent' };
  const arrowStyle = { color: '#ffd255' };

  const commun = (obj: Record<string, any>, key: number): JSX.Element => {
    return (
      <React.Fragment key={key}>
        <p className={'heading'}>{asText(obj.type)}</p>
        {obj.description[0]?.spans?.length === 0 && key !== 1 && (
          <p className={'description'}>{asText(obj.description)}</p>
        )}
        {key === 1 && <p className={'description windows'}>{asText(obj.description)}</p>}
        {obj.description[0]?.spans?.length > 0 && (
          <p className={'description windows'}>
            {asText(obj.description[0]?.text?.slice(0, obj.description[0]?.spans[0]?.start))}

            {obj.description[0]?.spans?.length > 0 && (
              <Popup
                trigger={(): JSX.Element => (
                  <span className={'description popup-desc'}>
                    {obj.description[0]?.text?.slice(
                      obj.description[0]?.spans[0]?.start,
                      obj.description[0]?.spans[0]?.end,
                    )}
                  </span>
                )}
                position="top center"
                on="hover"
                {...{ contentStyle, overlayStyle, arrowStyle }}
                closeOnDocumentClick
              >
                <div className="popup-content-spec">
                  <PrismicRichText render={bubble_windows11} />
                </div>
              </Popup>
            )}
          </p>
        )}
      </React.Fragment>
    );
  };
  const minSpecs: JSX.Element[] =
    contents && contents.minSpecs
      ? contents.minSpecs.map((obj: Record<string, any>, key: number) => commun(obj, key))
      : [];
  const goodSpecs: JSX.Element[] =
    contents && contents.minSpecs
      ? contents.goodSpecs.map((obj: Record<string, any>, key: number) => commun(obj, key))
      : [];

  return (
    <div className={'buypage-specs'} id="spec">
      <div className="specsHeader-group">
        <p className={'specsHeader'}>{specs_header}</p>
        <p className="nvidia-spec">
          {asText(nvidia_notice)}
          <span
            onClick={(): void => {
              if (props.setOpenModal) props.setOpenModal(true);
            }}
          >
            {asText(nvidia_notice_link_label)}
          </span>
        </p>
      </div>
      <div className={'specContainer'}>
        <div className={'minimal'}>
          <p className={'childHeader'}>{asText(contents.minSpecsHeader)}</p>
          <div className={'gridContainer'}>{minSpecs}</div>
        </div>

        <div className={'recommended'}>
          <p className={'childHeader'}>{asText(contents.goodSpecsHeader)}</p>
          <div className={'gridContainer'}>{goodSpecs}</div>
        </div>
      </div>
    </div>
  );
};

export default BuyPageSpecs;
