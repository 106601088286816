import React, { useEffect } from 'react';
import { useAppDispatch } from '../../../stores/app.store.config';
import { useNews } from '../../../stores/selectors/news.selector';
import { useI18n } from '../../../stores/selectors/i18n.selector';
import { fetchContents } from '../../../stores/actions/content.action';
import usePreview from '../../../services/prismic/prismic.preview.service';
import BlockComponent from './news-block.component';
import NavigationComponent from './news-small-sections/news-navigation.component';
import { getSlug } from '../news.service';
import PrismicRichText, { asText } from '../../../services/prismic/prismic.richtext.service';
import { getUrlFromEnglish } from '../../../services/i18n/i18n.url.service';
import SeoMeta from '../../../services/optimization/seo.meta.partial';
import LinkComponent from '../../link/link.component';
import { usePrismic } from '../../../hooks/prismic.hook';
import { findCurrentRoute } from '../../../services/optimization/landingpagecheck';
import SocialLinks from '../../partials/social-links.partial.component';
import { useParams } from 'react-router-dom';

const NewsDetailComponent = (
 ): JSX.Element => {
  const dispatch = useAppDispatch();
  const { uid } = useParams(); // inside your component 
  const { contents: newsStories } = useNews();
  const prismic = usePrismic('news_page');
  const {
    result: { previous_article, next_article },
  } = prismic;

  const preview = usePreview();
  const { locale } = useI18n();
  useEffect(() => {
    if (preview) {
      dispatch(fetchContents({ locale, type: 'post', sort: 'last_publication_date', preview }));
    }
  }, [dispatch, locale, uid, preview]);

  let direction = '/news';
  let results;

  if (findCurrentRoute()?.id?.includes('contact')) {
    direction = '/contact';
    results = newsStories?.filter((result: any) => {
      return result.type?.includes('press release');
    });
  } else {
    results = newsStories?.filter((result: any) => {
      return !result.type?.includes('press release');
    });
  }

  if (!results) return <></>;

  const result = results.find((r: any) => getSlug(r) === uid);
  const index = results.findIndex((r: any) => getSlug(r) === uid);

  if (!result) return <></>;

  return (
    <>
      <SeoMeta pageTitle={asText(result.title)} />
      <div className="news-detail">
        <NavigationComponent />
        <div className="news-detail-intro">
          <div className="intro-content">
            <div className="type-wrapper">
              <p className="type">{result.type}</p>
            </div>
            <PrismicRichText render={result.title} />
            <div className="social-media">
              {result.date && <p className="date">{result.date.toString()}</p>}
              <SocialLinks margin prismic={prismic} />
            </div>
          </div>
        </div>
        <article>
          <header
            className="img-article"
            style={{ backgroundImage: `url(${result.image_text?.url || result.image.url})` }}
          ></header>
          <div className="content-article">
            <PrismicRichText render={result.content} />
          </div>
          <div className="social-media">
            <SocialLinks prismic={prismic} />
          </div>
        </article>
        <div className="follow-options">
          {results[index - 1] && (
            <LinkComponent link={`${getUrlFromEnglish(`${direction}`)}/${getSlug(results[index - 1])}`}>
              <p className="directions before">{previous_article}</p>
              <div
                className="news-block"
                style={{
                  backgroundImage: `linear-gradient(to bottom, rgba(12, 19, 21, 0) 28%, #0c1315 67%),url(${
                    results[index - 1].image.url
                  })`,
                }}
              >
                <div className="news-block-corners"></div>
                <p className="type">{results[index - 1].type}</p>
                <BlockComponent limit={60} info="Previous Article" result={results[index - 1]} showContent={true} />
              </div>
            </LinkComponent>
          )}
          {!results[index - 1] && <div></div>}
          {results[index + 1] && (
            <LinkComponent link={`${getUrlFromEnglish(`${direction}`)}/${getSlug(results[index + 1])}`}>
              <p className="directions next">{next_article}</p>
              <div
                className="news-block"
                style={{
                  backgroundImage: `linear-gradient(to bottom, rgba(12, 19, 21, 0) 28%, #0c1315 67%),url(${
                    results[index + 1].image.url
                  })`,
                }}
              >
                <div className="news-block-corners"></div>
                <p className="type">{results[index + 1].type}</p>
                <BlockComponent
                  limit={60}
                  info="Next Article"
                  route={`/news/${results[index + 1]}`}
                  result={results[index + 1]}
                  showContent={true}
                />
              </div>
            </LinkComponent>
          )}
        </div>
      </div>
    </>
  );
};
export default NewsDetailComponent;
