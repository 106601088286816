import React from 'react';
import config from '../../../config';
import { Link } from 'react-router-dom';
import { getUrlFromEnglish } from '../../services/i18n/i18n.url.service';
import { setConfirm } from '../../stores/selectors/confirm.selector';
import { replaceLink } from '../../services/utils/utils.service';

export const LinkComponent = ({
  label,
  link,
  confirm,
  children,
  forceLocale,
  target,
  ...others
}: {
  label?: string;
  link: string;
  confirm?: boolean;
  children?: any;
  forceLocale?: boolean;
  target?: string;
  className?: string;
  tabIndex?: number;
  style?: any;
  id?: string;
  onClick?: any;
}): JSX.Element => {
  const isExternal = !link?.includes(config.prodRootURL) && link?.includes('http');
  const href = replaceLink(link);
  const linkLocalized = forceLocale ? href : getUrlFromEnglish(href);
  if (isExternal) {
    return (
      <a href={href} target={target || '_blank'} rel="noopener noreferrer" {...others}>
        {children || label}
      </a>
    );
  }
  if (!isExternal && target) {
    return (
      <a href={href} target={target} rel="noopener noreferrer" {...others}>
        {children || label}
      </a>
    );
  } else {
    return !confirm ? (
      <Link rel="canonical" tabIndex={0} to={`${linkLocalized}${window.location.search}`} {...others}>
        {children || label}
      </Link>
    ) : (
      <div
        className="confirm-link"
        onClick={(): void => {
          setConfirm(true);
        }}
      >
        {label}
      </div>
    );
  }
};

export default LinkComponent;
