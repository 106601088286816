import { createSlice } from '@reduxjs/toolkit';
import { initialNewsState } from '../../models/news/news.model';

const slice = createSlice({
  name: 'news',
  initialState: initialNewsState,
  reducers: {
    setNews: (state, { payload }): void => {
      state.contents = payload;
    },
  },
});

export const newsReducer = slice.reducer;
export const { setNews } = slice.actions;
