import { createSlice } from '@reduxjs/toolkit';
import { plansAvailableResponse } from '../actions/plans-available.action';
import { initialPlansAvailableState } from '../../models/plans-available/plans-available.model';

const slice = createSlice({
  name: 'plans-available',
  initialState: initialPlansAvailableState,
  reducers: {},
  extraReducers: {
    [plansAvailableResponse.fulfilled.type]: (state, { payload }): void => {
      state.contents = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    [plansAvailableResponse.pending.type]: (state): void => {
      state.loading = true;
      state.hasErrors = false;
    },
    [plansAvailableResponse.rejected.type]: (state, { error }): void => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error;
    },
  },
});

export const PlansAvailableReducer = slice.reducer;
