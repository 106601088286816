import React from 'react';
import SocialLinks from '../../../partials/social-links.partial.component';

const SocialMediaComponent = ({ result , prismic }: any): JSX.Element => {
  const onclickSocial = (e: any): void => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  return (
    <div className="social-media-group">
      <p className="date">{result.date}</p>

      <div className="social-btn" onClick={onclickSocial}>
        <SocialLinks prismic={prismic}/>
        <div className="icon social-media-image">
          <div></div>
        </div>
      </div>
    </div>
  );
};
export default SocialMediaComponent;
