import React from 'react';
import { asText } from '../../../../services/prismic/prismic.richtext.service';

export default function FaqLineComponent({
  id,
  slice,
  line: { question_title, question_content },
}: {
  id: number;
  slice: number;
  line: {
    question_title: any;
    question_content: any;
  };
}): JSX.Element {
  const name = 'rd' + id + slice;
  return (
    <section className="faq-line">
      <div className="faq-line-container">
        <input type="checkbox" id={name} name={name} />
        <label className="faq-line-container-label" htmlFor={name}>
          <h5>{asText(question_title)}</h5>
          <div />
        </label>
        <div className="faq-line-container-content">
          <p>{asText(question_content)}</p>
        </div>
      </div>
    </section>
  );
}
