import React from 'react';
import { usePrismic } from '../../../hooks/prismic.hook';

export default function NeedAssistanceComponent(): JSX.Element {
  const {
    result: { assistance_title, assistance_description, assistance_link, assistance_button },
  } = usePrismic('server_status');

  return (
    <section className="need-assistance-section">
      <div className="need-assistance-section-content">
        <h1 className="main-title-need-assistance">{assistance_title}</h1>
        <p className="sub-title-need-assistance">{assistance_description}</p>
        <a target="_blank" rel="noopener noreferrer" href={assistance_link}>
          <button className="theme-button">
            <div className="theme-button-container">
              <div className="icon-support" />
              <span>{assistance_button}</span>
            </div>
          </button>
        </a>
      </div>
    </section>
  );
}
