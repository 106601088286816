import React, { useEffect, useState, useMemo } from 'react';
import { usePrismic } from '../../../hooks/prismic.hook';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../../stores/selectors/auth.selector';
import { AuthState } from '../../../models/authentication/auth.model';
import PrismicRichText, { asText } from '../../../services/prismic/prismic.richtext.service';
import axios from 'axios';
import countryList from 'react-select-country-list';
import { useI18n } from '../../../stores/selectors/i18n.selector';
import { refreshToken } from '../../../stores/actions/auth.action';
import { useAppDispatch } from '../../../stores/app.store.config';
import { errorHandled } from './error-service';
import Modal from 'react-modal';
import { useParams } from 'react-router-dom';

import config from '../../../../config';

const AccountManagement = (): JSX.Element | null => {
  const { register, handleSubmit, watch, reset } = useForm();
  const { user }: AuthState = useAuth();
  const [userFull, setUserFull] = useState(Object);
  const { locale } = useI18n();
  const { key } = useParams();
  const dispatch = useAppDispatch();
  const [confirmModal, setConfirmModal] = useState(false);

  useEffect(() => {
    setUserFull(user.details);
    countryList().setEmpty('Select a Country').getLabel('');
  }, [user]);

  useEffect(() => {
    updateEmail();
  }, []);

  const options = useMemo(() => countryList().getData(), []);

  const {
    data: {
      title,
      description,
      firstname,
      lastname,
      display_name,
      email_label,
      resend_link,
      shipping,
      state,
      country,
      zip_code,
      city,
      policy,
      button_save,
      notice_success,
      notice_success_simple,
      notice_current_psw,
      confirm,
      cancel,
    },
  } = usePrismic('account_profile');
  const { contents: feedback_content } = usePrismic('account_creation_feedback');

  const [notice, setNotice] = useState({ type: '', message: '' });

  const firstNameWatch = watch('firstName');
  const lastNameWatch = watch('lastName');
  const displayNameWatch = watch('displayName');
  const emailWatch = watch('email');
  const shippingWatch = watch('addressStreet');
  const stateWatch = watch('addressState');
  const cityWatch = watch('addressCity');
  const countryWatch = watch('addressCountry');
  const pswWatch = watch('password');
  const pswretypeWatch = watch('pswretype');
  const confirmPswWatch = watch('confirmPassword');
  const zip_codeWatch = watch('addressPostcode');

  const {
    data: { errors },
  } = usePrismic('account_management_errors');
  const updateEmail = async () => {
    if (key) {
      try {
        await axios({
          method: 'post',
          url: '/api/users/me/update-email',
          data: {
            key,
          },
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
          params: {
            lang: locale?.split('-')[0],
          },
        });
        dispatch(refreshToken());
        setNotice({ type: 'success', message: 'Your email has been validated' });
        cleanNotice();
      } catch (err) {
        setNotice({ type: 'error', message: 'There was a problem with your email confirmation' });
        cleanNotice();
      }
    }
  };
  const cleanNotice = () => {
    setTimeout(() => {
      setNotice({ type: '', message: '' });
    }, 4000);
  };
  const { feedback } = feedback_content?.results?.[0]?.data || {};

  if (!feedback) return <></>;

  const onSubmit = async (values: Record<string, any>): Promise<void> => {
    const body = [];
    for (const input in values) {
      if (values[input] !== userFull[input]) {
        if (!(input.includes('email') && userFull?.emailChangeIsPending))
          body.push({ op: 'replace', path: `/${input}`, value: `${values[input]}` });
      }
    }
    if (body.length === 0 && !values.password && !key) return;

    try {
      await axios.patch('/api/users/me/edit-profile', body, {
        params: {
          lang: locale?.split('-')[0],
        },
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      dispatch(refreshToken());
      if (values.email !== userFull.email && !userFull?.emailChangeIsPending) {
        setNotice({ type: 'success', message: `${asText(notice_success)}` });
        cleanNotice();
      } else {
        setNotice({ type: 'success', message: `${asText(notice_success_simple)}` });
        cleanNotice();
      }
    } catch (err: any) {
      setNotice(errorHandled(err.response.data.errors, errors));
      cleanNotice();
    }
    window.scrollTo(0, 0);
  };

  const renderColumn = (
    name: string,
    label: string,
    defaultValue: string,
    inputWatch: string,
    icon: string,
    type: string,
    maxLength: number,
    minLength: number,
  ): JSX.Element => {
    return (
      <>
        <div className="tooltip-container">
          <label className="label none" htmlFor={name}>
            {label}
          </label>
        </div>
        <div className="input-container">
          <div className="input-container__row">
            <i className="iconWapper">
              <span className={icon}></span>
            </i>
            <input
              className="input-field"
              name={name}
              id={name}
              type={type}
              minLength={minLength}
              maxLength={maxLength}
              ref={register({
                // required: `${label} ${asText(error_required)}`,
              })}
              defaultValue={defaultValue}
            />
            {!name.includes('display') ? (
              <i className="iconWapper iconWapperRight">
                {!inputWatch ? <span className="icon-loading" /> : <span className="icon-validate" />}
              </i>
            ) : (
              <i className="iconWapper iconWapperRight">
                {!inputWatch || !inputWatch.match(new RegExp(config.validation.displayName)) ? (
                  <span className="icon-loading" />
                ) : (
                  <span className="icon-validate" />
                )}
              </i>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="account-management-container">
      <div className="account-management-intro">
        <h2>{asText(title)}</h2>
        <button type="submit" form="edit-form" className="theme-button-spec-positive">
          <div className="theme-button-container">
            <div />
            <span>{asText(button_save)}</span>
          </div>
        </button>
        <Modal
          isOpen={confirmModal}
          onRequestClose={() => {
            setConfirmModal(false);
          }}
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <div className="modal-container-edition modal-content">
            <div className="corners">
              <label htmlFor="pswretype">{asText(notice_current_psw)}</label>
              <input
                className="input-field"
                type="password"
                name="pswretype"
                id="pswretype"
                ref={register({
                  // required: `${label} ${asText(error_required)}`,
                })}
              ></input>
              <div className="group-button">
                <button
                  type="button"
                  className="util-button-cancel"
                  onClick={() => {
                    setConfirmModal(false);
                  }}
                >
                  <div />
                  <span>{asText(cancel)}</span>
                </button>
                <button
                  className="util-button-continue"
                  type="button"
                  form="edit-form"
                  onClick={() => {
                    if (pswretypeWatch) {
                      setConfirmModal(false);
                      onSubmit({
                        firstName: firstNameWatch,
                        lastName: lastNameWatch,
                        email: emailWatch,
                        addressStreet: shippingWatch,
                        password: pswWatch,
                        confirmPassword: confirmPswWatch,
                        addressCity: cityWatch,
                        addressState: stateWatch,
                        addressCountry: countryWatch,
                        addressPostcode: zip_codeWatch,
                        currentPassword: pswretypeWatch,
                      });
                      reset({ pswretype: '' });
                    }
                  }}
                >
                  <div />
                  <span>{asText(confirm)}</span>
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
      <div className="account-management-desc">
        <PrismicRichText render={description} />
      </div>
      {notice?.type && (
        <div className={notice.type?.includes('success') ? 'account-notice' : 'account-notice error'}>
          <div />
          <p>{notice.message}</p>
        </div>
      )}
      <div className="account-management-error"></div>
      <form className="registration-form" id="edit-form" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="row">
          <div className="col col-6">
            {renderColumn(
              'firstName',
              asText(firstname),
              userFull?.firstName,
              firstNameWatch,
              'icon-profile',
              'text',
              30,
              0,
            )}
          </div>

          <div className="col col-6">
            {renderColumn(
              'lastName',
              asText(lastname),
              userFull?.lastName,
              lastNameWatch,
              'icon-profile',
              'text',
              30,
              0,
            )}
          </div>
        </div>
        <div className="row">
          <div className="col col-6">
            {renderColumn('email', asText(email_label), userFull?.email, emailWatch, 'icon-email', 'email', 254, 0)}
          </div>
          <div className="col col-6">
            <button
              type="button"
              className="theme-button"
              disabled={!userFull.emailChangeIsPending}
              onClick={async () => {
                try {
                  await axios({
                    method: 'patch',
                    url: '/api/users/me/edit-profile',
                    data: [{ op: 'replace', path: '/email', value: userFull?.pendingEmail }],
                    params: {
                      lang: locale?.split('-')[0],
                    },
                    headers: {
                      Authorization: `Bearer ${user.token}`,
                    },
                  });
                  setNotice({ type: 'success', message: `${asText(notice_success)}` });
                  cleanNotice();
                  dispatch(refreshToken());
                } catch (err: any) {
                  errorHandled(err.response.data.errors, errors);
                }
              }}
            >
              <div className="theme-button-container">
                <div />
                <span>{asText(resend_link)}</span>
              </div>
            </button>
          </div>
        </div>
        <input className="DummyUsername" type="text" />
        <input className="DummyPassword" type="password" />
        <div className="row">
          <div className="col col-6">
            {renderColumn(
              'displayName',
              asText(display_name),
              userFull?.displayName,
              displayNameWatch,
              'icon-profile',
              'text',
              30,
              0,
            )}
          </div>
        </div>

        <div className="row">
          <div className="col col-12">
            {renderColumn(
              'addressStreet',
              asText(shipping),
              userFull?.addressStreet,
              shippingWatch,
              'icon-address',
              'text',
              254,
              0,
            )}
          </div>
        </div>
        <div className="row">
          <div className="col col-6">
            {renderColumn(
              'addressCity',
              asText(city),
              userFull?.addressCity,
              cityWatch,
              'icon-address',
              'text',
              254,
              0,
            )}
          </div>
          <div className="col col-6">
            {renderColumn(
              'addressPostcode',
              asText(zip_code),
              userFull?.addressPostcode,
              zip_codeWatch,
              'icon-address',
              'text',
              254,
              0,
            )}
          </div>
        </div>
        <div className="row">
          <div className="col col-6">
            {renderColumn(
              'addressState',
              asText(state),
              userFull?.addressState,
              stateWatch,
              'icon-address',
              'text',
              254,
              0,
            )}
          </div>
          <div className="col col-6">
            <label className="label none" htmlFor="country">
              {asText(country)}
            </label>
            <div className="input-container">
              <div className="input-container__row">
                <i className="iconWapper">
                  <span className="icon-address"></span>
                </i>
                <select
                  className="select-field"
                  name="addressCountry"
                  id="addressCountry"
                  ref={register({
                    // required: error_required,
                  })}
                  // defaultValue={userFull?.addressCountry}
                >
                  {options.map(({ value, label }: { value: string; label: string }, key: number) => (
                    <option selected={value === userFull?.addressCountry} key={key} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="account-management-policy">
        <PrismicRichText render={policy} />
      </div>
    </div>
  );
};
export default AccountManagement;
