import React, { useState } from 'react';
import MediaNavBar from './medianavbar';
import { useMedia } from '../../../stores/selectors/media.selector';
import { displayName, subcategoriesList, formatURL } from '../media.service';
import { usePrismic } from '../../../hooks/prismic.hook';
import { InfiniteScrollComponent } from './infiniteScroll.component';
import { useRemoteConfiguration } from '../../../stores/selectors/remote-configuration.selector';
import DownloadComponent from './download.component';

const WallpaperComponent = (): JSX.Element => {
  const [imageModal, setimageModal] = useState('');
  const closeModal = (): void => {
    setimageModal('');
  };
  const [indexImageSelected, setIndexImageSelected] = useState(0);
  const [amountDisplayed, setamountDisplayed] = useState(1);
  const {
    remoteConfig: { ASSETS_MEDIA_PREFIX },
  } = useRemoteConfiguration();

  const {
    status: { wallpapers },
  } = useMedia();
  const wallpapersSmall = wallpapers?.filter((elem: string) => {
    return !elem.includes('-');
  });
  const {
    result: { cat_wallpapers, media },
  } = usePrismic('media-page');
  const renderOptions = (): JSX.Element[] | JSX.Element => {
    const results = wallpapers
      ?.filter((elem: any) => elem.includes(displayName(wallpapersSmall[indexImageSelected])) && elem.includes('-'))
      .map((result: string, key: number) => {
        return (
          <a
            key={key}
            href={`${ASSETS_MEDIA_PREFIX.concat(formatURL(result))}`}
            target="_blank"
            rel="noreferrer"
            download
          >
            {result.split('-')[1].split('.')[0]}
          </a>
        );
      });
    if (results?.length === 0)
      return (
        <a
          href={`${ASSETS_MEDIA_PREFIX.concat(formatURL(wallpapersSmall[indexImageSelected]))}`}
          target="_blank"
          rel="noreferrer"
          download
        >
          {displayName(wallpapersSmall[indexImageSelected])}
        </a>
      );
    return results;
  };

  const renderWalpapers = (subcategory: string): JSX.Element[] | JSX.Element => {
    const items = wallpapers
      ?.filter((elem: string): boolean => {
        return !elem.includes('-') && elem.includes(subcategory);
      })
      ?.map((result: string, key: number) => {
        return (
          <div className="categories-three" key={key}>
            <div
              className="imageContainer"
              onClick={(): void => {
                setimageModal('wallpapers');
                setIndexImageSelected(key);
              }}
            >
              <div
                className="image"
                style={{
                  backgroundImage: `url(${ASSETS_MEDIA_PREFIX.concat(formatURL(result))})`,
                }}
              ></div>
              <div className="header">{<p>{displayName(result)}</p>}</div>
            </div>
          </div>
        );
      });
    return items;
  };
  const renderSubcategories = (): JSX.Element[] | JSX.Element => {
    const categories = subcategoriesList(wallpapers)?.map((elem: string, key: number) => {
      return (
        <div className={key % 2 === 0 ? 'categories-content' : 'categories-content alternate-bg'} key={key}>
          {key < amountDisplayed && (
            <>
              <div className="mediaHeader">
                <p className="category-name">{elem}</p>
              </div>
              <div className="categories-grid">{renderWalpapers(elem)}</div>
            </>
          )}
        </div>
      );
    });
    return categories;
  };
  return (
    <div className="categories-container-custom">
      <DownloadComponent
        imageModal={imageModal}
        listDownload={wallpapersSmall}
        closeModal={closeModal}
        indexImageSelected={indexImageSelected}
        documents={[]}
        renderOptions={renderOptions}
      />
      <MediaNavBar />
      <div className="categories-intro">
        <h2 className="categories-intro-title">{media}</h2>
        <h2 className="categories-intro-title-section">{cat_wallpapers}</h2>
      </div>
      {renderSubcategories()}
      {amountDisplayed < wallpapers?.length && (
        <InfiniteScrollComponent
          renderSubcategories={renderSubcategories}
          amountDisplayed={amountDisplayed}
          setamountDisplayed={setamountDisplayed}
        />
      )}
    </div>
  );
};
export default WallpaperComponent;
