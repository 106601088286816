export interface PlansAvailableState {
  contents: any[];
  hasErrors: boolean;
  loading: boolean;
  error: Error | null;
}

export const initialPlansAvailableState: PlansAvailableState = {
  contents: [],
  hasErrors: false,
  loading: false,
  error: null,
};
