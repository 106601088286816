import { Link, RichTextBlock } from 'prismic-reactjs';
import React from 'react'; 
import { getUrlFromEnglish } from '../../../services/i18n/i18n.url.service';
import { getBuyPageUrl } from '../../../services/optimization/landingpagecheck';
import PrismicRichText, { asText } from '../../../services/prismic/prismic.richtext.service';
import LinkComponent from '../../link/link.component';
import { generateID } from './home.menu.component';
import ScrollAnimation from 'react-animate-on-scroll';
import { ParallaxBanner } from 'react-scroll-parallax';
export interface MenuBlock {
  menu_title: RichTextBlock[];
  menu_sub_title: RichTextBlock[];
  block_title: RichTextBlock[];
  description: RichTextBlock[];
  video_link: Link;
  pillar_link: Link;
  blade: RichTextBlock[];
  image: Link;
}

export const HomeBlockComponent = ({
  block: { menu_title, block_title, description, blade, video_link, pillar_link, image },
  index,
  openModal,
  reviewsDisplayed,
  prismic
}: {
  block: MenuBlock;
  index: number;
  openModal: any;
  reviewsDisplayed?: JSX.Element;
  prismic: any;
}): JSX.Element => {
  const {
    result: { watch_button, get_started_button },
  } = prismic;

  const buyPageURL = getUrlFromEnglish(getBuyPageUrl());
  const BuyButton = (): JSX.Element => (
    <LinkComponent link={pillar_link?.url || buyPageURL}>
      <button className="theme-button-spec-positive joinBeta green-gradient-btn">
        <div className="theme-button-container">
          <div />
          <span>{get_started_button}</span>
        </div>
      </button>
    </LinkComponent>
  );

  let containerClass = index % 2 !== 0 ? 'partThree' : 'partTwo';
  if (reviewsDisplayed) {
    containerClass = index % 2 !== 0 ? 'partOne' : 'partTwo';
  }

  return (
    <>
      <ParallaxBanner
        layers={[{ image: image.url, speed: -15, style: { backgroundSize: 'contain' } }]}
        className="bg-partOne"
        style={
          containerClass.includes('partOne')
            ? { backgroundImage: `url(${image.url})` }
            : { backgroundImage: `none`, display: 'none' }
        }
      ></ParallaxBanner>

      <ParallaxBanner
      layers={[{ image: image.url, speed: -15, style: { backgroundSize: 'contain' } }]}
        className={containerClass}
        id={generateID(asText(menu_title))}
        style={
          !containerClass.includes('partOne') ? { backgroundImage: `url(${image.url})` } : { backgroundImage: `none` }
        }
      >
        <div className="content">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true} offset={50}>
            <div className="title">
              <PrismicRichText render={block_title} />
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true} offset={50}>
            <div className="description">
              <PrismicRichText render={description} />
            </div>
          </ScrollAnimation>

          <div className="video-link">
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true} offset={50}>
              {video_link?.url && (
                <button
                  className="theme-button"
                  onClick={(): void => {
                    if (video_link?.url) openModal(video_link?.url);
                  }}
                >
                  <div className="theme-button-container">
                    <div className="icon" />
                    <span>{watch_button}</span>
                  </div>
                </button>
              )}
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true} offset={50}>
              <BuyButton />
            </ScrollAnimation>
          </div>
        </div>

        <ScrollAnimation
          className={'moveBlade'}
          animateIn={index % 2 !== 0 ? 'fadeInLeft' : 'fadeInRight'}
          animateOnce={true}
          offset={150}
          duration={1}
        >
          <div className="identifier">
            <h3>{asText(blade)}</h3>
          </div>
        </ScrollAnimation>
      </ParallaxBanner>
    </>
  );
};
