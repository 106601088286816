import React, { useState } from 'react';
import RichText from '../../services/prismic/prismic.richtext.service';
import { useForm } from 'react-hook-form';
import { usePrismic } from '../../hooks/prismic.hook';
import subscribeToNewsletter from '../../services/authentication/newsletter.service';

const NewsletterComponent = ({ title }: { title?: string }): JSX.Element => {
  const { handleSubmit, register } = useForm();
  const {
    result: { newsletter_title },
    contents,
  } = usePrismic('common');
  const {
    result: { newsletter_success },
   
  } = usePrismic('footer');

  const [newsletterstate, setnewsletterstate] = useState('normal');
  const onSubmit = async (values: Record<string, any>): Promise<void> => {
    const { email } = values;
    try {
      await subscribeToNewsletter(email);
      setnewsletterstate('validated');
    } catch (error: any) {
      console.log('There was a problem with the newsletter subscription' + error);
      setnewsletterstate('invalidated');
    }
    cleanNotice();
  };
  const cleanNotice = () => {
    setTimeout(() => {
      setnewsletterstate('normal');
    }, 3000);
  };

  if (!contents) {
    return <></>;
  }

  const {
    results: [
      {
        data: { newsletter_disclaimer },
      },
    ],
  } = contents;

  return (
    <form className="newsletter" onSubmit={handleSubmit(onSubmit)}>
      {(title || newsletter_title) && <p className={'title'}>{title || newsletter_title}</p>}

      {newsletterstate === 'normal' && (
        <div className="util-input">
          <div className="first"></div>
          <input
            type={'email'}
            name={'email'}
            className="util-input-text"
            placeholder={'Email Address'}
            ref={register({
              required: 'Requires Email',
            })}
          />
          <button type="submit">
            <div className="last"></div>
          </button>
        </div>
      )}
      {(newsletterstate === 'validated' || newsletterstate === 'invalidated') && (
        <div className={!newsletterstate?.includes('invalidated') ? 'util-input validated' : 'util-input invalidated'}>
          <div className="first"></div>
          <input
            type={'email'}
            name={'email'}
            readOnly
            className="util-input-text"
            placeholder={
              !newsletterstate?.includes('invalidated') ? newsletter_success : 'There was a problem.'
            }
            ref={register({
              required: 'Requires Email',
            })}
          />
          <button type="submit">
            <div className={!newsletterstate?.includes('invalidated') ? 'last validated' : 'last invalidated'}></div>
          </button>
        </div>
      )}
      <div className={'disclaimer'}>
        <RichText render={newsletter_disclaimer} />
      </div>
    </form>
  );
};

export default NewsletterComponent;
