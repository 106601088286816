export const displayName = (uri: string): string => {
  return uri?.split('/')[3]?.split('.')[0];
};

export const subcategoriesList = (section: string[]): string[] => {
  const results = section?.map((elem: string) => {
    return elem?.split('/')[2];
  });
  const resultsUnique = results?.filter((item: string, index: number) => {
    return results.indexOf(item) === index && item !== '';
  });
  return resultsUnique;
};

export const displayNamePlaylist = (uri: string): string => {
  return uri?.split('/')[3]?.split('.')[0];
};

export const getPlaylistsNames = (videos: string[]): string[] => {
  const results: string[] = videos
    ?.filter((elem: string) => {
      return elem.includes('playlist');
    })
    .map((elem: string) => {
      return elem.split('/')[3];
    });
  const resultsUnique = results?.filter((item, index) => {
    return results.indexOf(item) === index && item !== '';
  });
  return resultsUnique;
};

export const formatURL = (elem: string): string => elem?.replace(/ /g, '%20');

export const getThubmVideo = (uri: string, list: string[], ASSETS_MEDIA_PREFIX: string): string => {
  const result = list?.find((elem) => {
    return elem.includes(uri?.split('.')[0]) && elem.includes('thumbnail');
  });
  if (!result) return '';

  return ASSETS_MEDIA_PREFIX.concat(formatURL(result));
};
