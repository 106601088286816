import React, { useState } from 'react';
import LinkComponent from '../../link/link.component';
import { getUrlFromEnglish } from '../../../services/i18n/i18n.url.service';
import SimpleSubmenu from './simplesubmenu';
import { useLocation } from 'react-router-dom'; 
interface Props {
  filteredArray: JSX.Element[];
  icon?: number;
  setIcon?(input: number): void;
  menuClicked?: number;
  setMenuClicked?(input: number): void;
  menuPrismic:any
}
const LeftNavBar = ({ filteredArray, icon, setIcon, menuClicked, setMenuClicked, menuPrismic }: Props): JSX.Element => {
  const [subIcon, setSubIcon]: any = useState(1);
  let simpleMenuCase = 0;
  const { pathname } = useLocation();

  const leftnavbar = filteredArray.map((obj: any, key: number) => {
    if (!obj.link) {
      simpleMenuCase += 1;
      return (
        <div key={key} className={'navbar-item'}>
          {menuClicked === key + 1 && (
            <div className="line" style={{ height: '2px', backgroundColor: 'transparent' }}></div>
          )}
          <a
            tabIndex={0}
            className={'navbar-SubMenuGame'}
            onClick={(): void => {
              if (setMenuClicked && menuClicked) setMenuClicked(-(key + 1) * Math.sign(menuClicked));
              //  if (setIcon && icon) setIcon(-1 * Math.sign(icon));
              setSubIcon(-(key + 1) * Math.sign(subIcon));
              window.document.body.style.overflowY = 'scroll';
            }}
          >
            {obj.name}
            {subIcon !== -(key + 1) ? <div className="mobileSubIcon" /> : <div className="mobileSubIconClose" />}
            {menuClicked !== key + 1 ? <div className="menuIcon" /> : <div className="menuIcon menuIconClose" />}
          </a>
          {menuClicked === key + 1 && <div className="line" style={{ height: '2px', backgroundColor: 'white' }}></div>}
          {menuClicked === key + 1 && (
            <SimpleSubmenu
              data={obj}
              simpleMenuCase={simpleMenuCase}
              iconToggle={setIcon}
              icon={icon}
              setMenuClicked={setMenuClicked}
              menuPrismic={menuPrismic}
            />
          )}
          {!menuClicked && subIcon < 0 && (
            <SimpleSubmenu
              data={obj}
              simpleMenuCase={simpleMenuCase}
              iconToggle={setIcon}
              icon={icon}
              setMenuClicked={setMenuClicked}
              menuPrismic={menuPrismic}
            />
          )}
        </div>
      );
    } else {
      const to = getUrlFromEnglish(obj.link);
      return (
        <div key={key} className={'navbar-item'}>
          <LinkComponent
            label={obj.name}
            tabIndex={0}
            className={`navbar-SubMenu ${pathname === to ? 'current' : ''}`}
            link={to}
            onClick={(): void => {
              if (setIcon && icon) setIcon(-icon);
              if (setMenuClicked && menuClicked) setMenuClicked(-1);
              window.document.body.style.overflowY = 'scroll';
            }}
          />
        </div>
      );
    }
  });
  return <>{leftnavbar}</>;
};
export default LeftNavBar;
