export interface RouterState {
  location: {
    pathname: string;
    search: string;
    hash: string;
    key: string;
    query: Record<string, string>;
  };
  action: string;
}

export interface RouteComponentProps {
  match: { params: Record<string, string> };
}

export const initialRouterState: RouterState = {
  location: {
    pathname: '/',
    search: '',
    hash: '',
    key: '',
    query: {},
  },
  action: 'POP',
};
