import { combineReducers } from 'redux';
import { i18nSliceReducers } from './slices/i18n.slice';
import { formResponseReducers } from './slices/form.slice';
import { contentSliceReducers } from './slices/content.slice';
import { authSliceReducers } from './slices/auth.slice';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { csrfSliceReducer } from '../stores/slices/csrf.slice';
import { PlanReducer } from './slices/plan.slice';
import { PlansAvailableReducer } from './slices/plans-available.slice';
import { newsReducer } from './slices/news.slice';
import { launchernewsReducer } from './slices/launcher-news.slice';
import { forgotPasswordReducer } from './slices/forgot-password.slice';
import { releasesReducer } from './slices/releases.slice';
import { betaKeysReducer } from './slices/betaKeys.slice';
import { assetsReducer } from './slices/assets.slice';
import { purchaseReducer } from './slices/purchase.slice';
import { consentReducer } from './slices/consent.slice';
import { liveopsReducer } from './slices/liveops.slice';
import { mediaReducer } from './slices/media.slice';
import { cpmstarReducer } from './slices/cpmstar.slice';
import { subscriptionsReducer } from './slices/subscriptions.slice';
import { confirmReducer } from './slices/confirm.slice';
import config from '../../config';
import { kickstarterReducer } from './slices/kickstarter.slice';
import { analyticsReducer } from './slices/analytics.slice';
import { remoteConfigurationReducer } from './slices/remote-configuration.slice';
import { defaultStore } from '../models/_default-store.model';
import configureMockStore from 'redux-mock-store';

import { useDispatch } from 'react-redux';
const reducer = combineReducers({
  i18nBranch: i18nSliceReducers,
  formResponse: formResponseReducers,
  contentBranch: contentSliceReducers,
  authBranch: authSliceReducers,
  consent: consentReducer,
  csrfToken: csrfSliceReducer,
  plansAvailable: PlansAvailableReducer,
  planSelected: PlanReducer,
  news: newsReducer,
  launcherNews: launchernewsReducer,
  releases: releasesReducer,
  betaKeysBranch: betaKeysReducer,
  assetsBranch: assetsReducer,
  purchaseBranch: purchaseReducer,
  liveopsBranch: liveopsReducer,
  cpmstar: cpmstarReducer,
  subscriptionsBranch: subscriptionsReducer,
  confirmBranch: confirmReducer,
  mediaBranch: mediaReducer,
  kickstarterBranch: kickstarterReducer,
  analyticsBranch: analyticsReducer,
  remoteConfigurationBranch: remoteConfigurationReducer,
  forgotPasswordBranch: forgotPasswordReducer,
});

export const middleware = [...getDefaultMiddleware({serializableCheck: false})];

let store1: any = configureStore({
  reducer,
  middleware,
  devTools: config.local,
});

if (config.nodeEnv === 'test') {
  const mockStore = configureMockStore(middleware);
  const m = mockStore(defaultStore);
  store1 = m;
}

export const store = store1;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
