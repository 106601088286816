import PrismicRichText, { asText } from '../../../services/prismic/prismic.richtext.service';
import React from 'react';
import { usePrismic } from '../../../hooks/prismic.hook';
import LinkComponent from '../../../features/link/link.component';

const BuyPageUnavailableModal = ({ setOpenReminder }: { setOpenReminder: any }): JSX.Element => {
  const {
    data: { notice_disabled_title, notice_disabled_desc, notice_disabled_button, notice_disabled_button_link },
  } = usePrismic('buy_page');
  if (!notice_disabled_title) return <></>;

  return (
    <>
      <div className="popup-overlay-reminder"></div>
      <div className="login-modal-container buyPage-notice">
        <div className="corners">
          <div className="reminder-title">
            <PrismicRichText render={notice_disabled_title} />
          </div>
          <div className="reminder-desc">
            <PrismicRichText render={notice_disabled_desc} />
          </div>
          <LinkComponent link={notice_disabled_button_link?.url}>
            <button className="theme-button steam">
              <div className="theme-button-container">
                <div></div>
                <span>{asText(notice_disabled_button)}</span>
              </div>
            </button>
          </LinkComponent>
        </div>
        <div
          className="icon-close-reminder"
          onClick={() => {
            setOpenReminder(false);
          }}
        ></div>
      </div>
    </>
  );
};
export default BuyPageUnavailableModal;
