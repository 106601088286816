import { createSlice } from '@reduxjs/toolkit';
import { initialFormResponseState } from '../../models/forms/forms.model';
import { formResponse } from './../actions/form.action';

const slice = createSlice({
  name: 'forms',
  initialState: initialFormResponseState,
  reducers: {},
  extraReducers: {
    [formResponse.fulfilled.type]: (state, { payload }): void => {
      state.response = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    [formResponse.pending.type]: (state): void => {
      state.loading = true;
      state.hasErrors = false;
    },
    [formResponse.rejected.type]: (state, { error }): void => {
      state.loading = false;
      state.hasErrors = true;
      state.error = error;
    },
  },
});

export const formResponseReducers = slice.reducer;
