import React, { useEffect, useRef, useState } from 'react';
import { useNews } from '../../../stores/selectors/news.selector';
import NavigationComponent from './news-small-sections/news-navigation.component';
import { getSlug } from '../news.service';
import { getUrlFromEnglish } from '../../../services/i18n/i18n.url.service';
import { usePrismic } from '../../../hooks/prismic.hook';
import InfiniteScroll from 'react-infinite-scroll-component';
import LinkComponent from '../../link/link.component';
import { asText } from '../../../services/prismic/prismic.richtext.service';
import { useParams } from 'react-router-dom';

const NewsArchivesComponent = (): JSX.Element | null => {
  const { uid } = useParams(); // inside your component

  const { contents: newsStories, loading, hasErrors } = useNews();

  const prismic = usePrismic('news_page');

  const {
    result: { loading_items, category_placeholder, update_filter, reset_filter, error, archives },
  } = prismic;

  // const [filterType, setFilterType] = useState<string>(category_placeholder);

  const [filterOptions, setFilterOptions] = useState<JSX.Element[]>([]);

  const [results, setResults] = useState<any>([]);

  const [applyFilter, setApplyFilter] = useState<boolean | string>(false);

  const [amountDisplayed, setamountDisplayed] = useState(1);

  const [maxLength, setMaxLength] = useState(0);

  const [showCategories, setShowCategories] = useState(false);

  const [currentcategories, setCurrentCategories] = useState('all categories');

  const nodeDropdown = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;

  const handleClick = (e: any): void => {
    if (nodeDropdown?.current?.contains(e.target)) {
      setShowCategories(true);
      return;
    }
    setShowCategories(false);
  };

  const filterOptionsList = (listNewsClean: any): JSX.Element[] => {
    const allPostTypes: string[] = listNewsClean
      .filter((r: any) => !uid || getSlug(r) === uid)
      .map((r: Record<string, string>) => r.type);

    const uniquePostTypes = allPostTypes.filter((v, i, a) => a.indexOf(v) === i);
    return uniquePostTypes.map((value: string, index: number) => (
      <div
        className="box-container"
        key={index}
        onClick={(): void => {
          setCurrentCategories(value);
        }}
      >
        <div className={currentcategories === value ? 'box selected' : 'box'} />
        <p> {value?.replace(/^\w/, (c) => c.toUpperCase())}</p>
      </div>
    ));
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return (): void => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  useEffect(() => {
    const resultsData = Array(0);

    for (const content in newsStories) {
      if (!newsStories[content]?.type?.includes('press release')) resultsData.push(newsStories[content]);
    }
    const filterOptions = filterOptionsList(resultsData);
    setFilterOptions(filterOptions);

    setResults(resultsData);
    const length = resultsData.length;
    setMaxLength(length);
  }, [newsStories, currentcategories]);

  const isInitialMount = useRef(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      const resultsData = Array(0);
      for (const content in newsStories) {
        if (!newsStories[content]?.type?.includes('press release')) resultsData.push(newsStories[content]);
      }

      const filterOptions = filterOptionsList(resultsData);
      let newResults;

      if (applyFilter !== 'none') {
        newResults = resultsData.filter((obj: Record<string, any>) => obj.type == currentcategories);
      } else {
        newResults = resultsData;
      }

      setFilterOptions(filterOptions);
      setResults(newResults);
      const length = newResults.length;
      setMaxLength(length);
    }
  }, [applyFilter]);

  if (loading) {
    return <p>{loading_items}</p>;
  }

  if (hasErrors) {
    return <p>{error}</p>;
  }

  const renderHeader = (): JSX.Element[] | JSX.Element => {
    return (
      <>
        <NavigationComponent />
        <div className="news-archiver-container"></div>
        <div className="news-archives">
          {filterOptions && (
            <div className="filter-by-categories">
              <div className="categories">
                <div
                  className="categories-default-value"
                  onClick={(): void => {
                    setShowCategories(!showCategories);
                  }}
                >
                  <p>{currentcategories}</p>

                  <div />
                </div>
                {showCategories && (
                  <div className="categories-dropbox" ref={nodeDropdown}>
                    <div className="corners">
                      <div className="content">{filterOptions}</div>
                    </div>
                  </div>
                )}
              </div>
              <div className="buttom-group">
                <button
                  className="theme-button-spec-positive archiveButton"
                  onClick={(): void => {
                    if (!currentcategories.includes('all') && currentcategories !== category_placeholder)
                      setApplyFilter(currentcategories);
                  }}
                >
                  <div className="theme-button-container">
                    <div className="validate" />
                    <span>{update_filter}</span>
                  </div>
                </button>
                <button
                  className="theme-button-spec-positive archiveButton archiveButton--right"
                  onClick={(): void => {
                    setCurrentCategories(category_placeholder);
                    setApplyFilter('none');
                  }}
                >
                  <div className="theme-button-container">
                    <div className="close" />
                    <span>{reset_filter}</span>
                  </div>
                </button>
              </div>
            </div>
          )}
          <div className="block-title-options">
            <div>
              <a className={'archive'}>{archives}</a>
              {(!currentcategories || applyFilter === 'none' || applyFilter === false) && <a>All</a>}
              {currentcategories && applyFilter !== 'none' && applyFilter && <a>{currentcategories}</a>}
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderPost = (amountDisplayed: any): JSX.Element[] | JSX.Element => {
    if (loading) return <p>{loading_items}</p>;
    if (hasErrors) return <p>{error}</p>;

    if (results.length > 0 && results[0]?.image) {
      const myPost: JSX.Element[] = [];

      for (let i = 0; i < amountDisplayed; i++) {
        const result = results[i];
        if (!result) {
          break;
        }

        const slugData = getSlug(result) || '';
        const dataPoint = (
          <div key={i} className="news-archivesBlock">
            <LinkComponent link={`${getUrlFromEnglish('/news')}/${slugData}`}>
              <div className="news-block">
                <div className="corners"></div>
                <div className="real-content">
                  <div className="box-image" style={{ backgroundImage: `url(${result.image.url})` }}></div>
                  <div className="content-archive">
                    <div className="type">
                      <p>{result.type}</p>
                    </div>
                    <p className="description">{asText(result.title)}</p>
                    <p className="date">{result.date}</p>
                  </div>
                </div>
              </div>
            </LinkComponent>
          </div>
        );
        myPost.push(dataPoint);
      }
      return myPost;
    } else return <></>;
  };
  return (
    <>
      {renderHeader()}
      {renderPost(amountDisplayed)}
      {newsStories?.length && amountDisplayed < maxLength && (
        <div className="news-load-container">
          <InfiniteScroll
            dataLength={amountDisplayed}
            next={(): void => {
              const newsLeft = newsStories.length - amountDisplayed;

              if (newsLeft >= 5) {
                setamountDisplayed(amountDisplayed + 5);
              } else {
                setamountDisplayed(amountDisplayed + newsLeft);
              }
            }}
            hasMore={true}
            loader={
              <a>
                <i className="load-more"></i>
                <p>{loading_items}</p>
              </a>
            }
          >
            <></>
          </InfiniteScroll>
        </div>
      )}
    </>
  );
};
export default NewsArchivesComponent;
