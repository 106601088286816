import React, { useEffect, useState } from 'react';
import PrismicRichText, { asText } from '../../../services/prismic/prismic.richtext.service';
import axios from 'axios';
import { useAuth } from '../../../stores/selectors/auth.selector';
import { AuthState } from '../../../models/authentication/auth.model';
import { usePrismic } from '../../../hooks/prismic.hook';
import { useRemoteConfiguration } from '../../../stores/selectors/remote-configuration.selector';
import { v1 as uuidv1 } from 'uuid';
import {
  setCookies,
  getCookies,
  cookieMessageConfig,
  delCookies,
} from '../../../services/authentication/authentication.cookies.service';
import LoadingComponent from '../../../features/loading/loading.component';
import { goto } from '../../../stores/selectors/router.selector';
import { getUrlFromEnglish } from '../../../services/i18n/i18n.url.service';
import qs from 'qs';
import {
  getSteamLoginURL,
  linkSteamAccount,
  unlinkSteamAccount,
} from '../../../services/api/orders/order.subscriptions.service';
import { getPrismicError } from '../../../services/utils/utils.service';
import { useLocation } from 'react-router-dom';

const AccountLinked = (): JSX.Element | null => {
  const { search } = useLocation();
  const { code, state, error } = qs.parse(search.slice(1));
  const queryparams = qs.parse(search.slice(1));
  const { user }: AuthState = useAuth();
  const [notice, setNotice] = useState({ type: '', message: '' });
  const [steamUrl, setSteamUrl] = useState('');
  const {
    data: {
      page_title,
      page_description,
      box_title,
      box_description,
      button_label_linked,
      button_label_not_linked,
      steam_box_title,
      steam_box_description,
      steam_refund_notice,
      steam_button_label_linked,
      steam_button_label_not_linked,
      success_linking_message,
      success_unlinking_message,
      failure_state_message,
    },
  } = usePrismic('account_link');
  const { contents } = usePrismic('errors');
  const [userFull, setUserFull] = useState('');
  const [discordState, setDiscordState] = useState('');
  const [loading, setLoading] = useState(false);
  const { remoteConfig } = useRemoteConfiguration();
  const [steamUser, setSteamUser] = useState('');
  const [steamOwnership, setSteamOwnership] = useState(false);
  const [refundEndDate, setRefundEndDate] = useState('');

  useEffect(() => {
    if (code) {
      if (state?.toString() === getCookies('discordState')) {
        if (success_linking_message) setlinkAccount();
      } else {
        setNotice({ type: 'error', message: asText(failure_state_message) });
        cleanNotice();
        setTimeout(() => {
          goto(getUrlFromEnglish('/account/linked'));
        }, 4000);
      }
      setLoading(false);
    }
  }, [success_linking_message]);

  useEffect(() => {
    if (!code) {
      const device = uuidv1();
      setCookies('discordState', device, cookieMessageConfig);
      setDiscordState(device);
    }
  }, [userFull]);

  useEffect(() => {
    if (!userFull)
      if (user?.details?.discordName)
        setUserFull(user?.details?.discordName + '#' + user?.details?.discordDiscriminator);
    if (user?.details?.steamId) setSteamUser('ID: ' + user?.details?.steamId);
    if (user?.details?.ownership?.includes('steam')) setSteamOwnership(true);
  }, [userFull]);

  useEffect(() => {
    if (error) {
      setNotice({ type: 'error', message: error?.toString() });
      cleanNotice();
    }
    getRefundEndDate();
  }, []);

  useEffect(() => {
    // start here to remove this section for steam backend call
    steamLoginURL();
    if (Object.keys(queryparams)?.length > 0 && !Object.keys(queryparams)?.includes('code') && success_linking_message)
      addSteamAccount();
  }, [success_linking_message]); // end here to remove this section for steam backend call

  const steamLoginURL = async () => {
    try {
      const data = await getSteamLoginURL(`https://${window.location.host}/account/linked`);
      setSteamUrl(data);
    } catch (error: any) {
      setNotice({ type: 'error', message: getPrismicError(errors, error.response.data) });
      setLoading(false);
      cleanNotice();
    }
  };
  const addSteamAccount = async () => {
    try {
      const data = await linkSteamAccount(`https://${window.location.host}/account/linked`, queryparams);
      setSteamUser('ID: ' + data.steamId);
      setNotice({ type: 'success', message: asText(success_linking_message) });
      cleanNotice();
    } catch (error: any) {
      setNotice({ type: 'error', message: getPrismicError(errors, error.response.data) });
      setLoading(false);
      cleanNotice();
    }
    setTimeout(() => {
      goto(getUrlFromEnglish('/account/linked'));
    }, 4000);
  };
  const removeSteamAccount = async () => {
    try {
      await unlinkSteamAccount();
      setNotice({ type: 'success', message: asText(success_unlinking_message) });
      cleanNotice();
      setSteamUser('');
    } catch (error: any) {
      setNotice({ type: 'error', message: getPrismicError(errors, error.response.data) });
      setLoading(false);
      cleanNotice();
    }
  };
  const setlinkAccount = async () => {
    try {
      setLoading(true);
      const result = await axios.post(
        `/api/discord/link-account?code=${code}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        },
      );
      setNotice({ type: 'success', message: asText(success_linking_message) });
      cleanNotice();
      setUserFull(result.data);
      delCookies('discordState');
    } catch (err: any) {
      setNotice({ type: 'error', message: err.response.data.message });
      setLoading(false);
      cleanNotice();
    }
    setTimeout(() => {
      goto(getUrlFromEnglish('/account/linked'));
    }, 4000);
  };
  const getRefundEndDate = async () => {
    try {
      const result = await axios.get(`/api/steam/refund-period-end-date`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setRefundEndDate(result?.data);
    } catch (err: any) {
      console.log(err.response.data.message);
    }
  };
  const unLinkAccount = async () => {
    try {
      setLoading(true);
      await axios.put(
        `/api/discord/unlink-account`,
        {},
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        },
      );
      setLoading(false);
      setUserFull('');
      setNotice({ type: 'success', message: asText(success_unlinking_message) });
      cleanNotice();
    } catch (err: any) {
      setNotice({ type: 'error', message: err.response.data.message });
      setLoading(false);
      cleanNotice();
    }
  };
  const cleanNotice = () => {
    setTimeout(() => {
      setNotice({ type: '', message: '' });
    }, 4000);
  };

  if (!contents || !failure_state_message || !success_linking_message || !success_unlinking_message) return <></>;
  const {
    results: [
      {
        data: { errors },
      },
    ],
  } = contents;
  return (
    <div className="account-security-container account-management-container account-linked-container">
      {loading && (
        <div className="loading">
          <LoadingComponent loading={loading} />
        </div>
      )}
      {!loading && (
        <>
          {notice?.type && (
            <div className={notice.type?.includes('success') ? 'account-notice' : 'account-notice error'}>
              <div />
              <p>{notice.message}</p>
            </div>
          )}
          <div className="account-security-title">
            <PrismicRichText render={page_title} />
          </div>
          <div className="account-security-desc">
            <PrismicRichText render={page_description} />
          </div>
          <div className="section-twofa">
            <div className="section-corners">
              <div className="section-padding">
                <div className="section-psw-title">
                  {!userFull && <PrismicRichText render={box_title} />}
                  {userFull && (
                    <p>
                      {asText(box_title) + ' ('}
                      <span>{userFull}</span>
                      {')'}
                    </p>
                  )}
                </div>
                <div className="section-psw-desc">
                  <PrismicRichText render={box_description} />
                </div>
                {userFull && (
                  <button
                    type="button"
                    className={'theme-button-spec-positive theme-button-unlink'}
                    onClick={async () => {
                      unLinkAccount();
                    }}
                  >
                    <div className="theme-button-container">
                      <div />
                      <span>{asText(button_label_linked)}</span>
                    </div>
                  </button>
                )}
                {!userFull && (
                  <a
                    href={`https://discord.com/api/oauth2/authorize?response_type=code&client_id=${remoteConfig.DISCORD_CLIENT_ID}&state=${discordState}&scope=identify&redirect_uri=${remoteConfig.ROOT_URL}/account/linked`}
                  >
                    <button type="button" className={'theme-button-spec-positive'}>
                      <div className="theme-button-container">
                        <div />
                        <span>{asText(button_label_not_linked)}</span>
                      </div>
                    </button>
                  </a>
                )}
              </div>
            </div>
          </div>
          <div className="section-twofa steam">
            <div className="section-corners">
              <div className="section-padding">
                <div className="section-psw-title">
                  {!steamUser && <PrismicRichText render={steam_box_title} />}
                  {steamUser && (
                    <p>
                      {asText(steam_box_title) + ' ('}
                      <span>{steamUser}</span>
                      {')'}
                    </p>
                  )}
                </div>
                <div className="section-psw-desc">
                  <PrismicRichText render={steam_box_description} />
                </div>
                {steamUser && !steamOwnership && (
                  <>
                    <button
                      type="button"
                      onClick={() => {
                        removeSteamAccount();
                      }}
                      disabled={Boolean(refundEndDate) && new Date(refundEndDate) > new Date()}
                      className={'theme-button-spec-positive theme-button-unlink steam-btn'}
                    >
                      <div className="theme-button-container">
                        <div />
                        <span>{asText(steam_button_label_linked)}</span>
                      </div>
                    </button>
                    {refundEndDate && new Date(refundEndDate) > new Date() && (
                      <div className="section-psw-desc notice-refund">
                        <PrismicRichText render={steam_refund_notice} />
                      </div>
                    )}
                  </>
                )}

                {!steamUser && (
                  <a href={steamUrl}>
                    <button type="button" className={'theme-button-spec-positive steam-btn'}>
                      <div className="theme-button-container">
                        <div />
                        <span>{asText(steam_button_label_not_linked)}</span>
                      </div>
                    </button>
                  </a>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default AccountLinked;
