import React, { useState } from 'react';
import Modal from 'react-modal';
import { asText } from '../../../../services/prismic/prismic.richtext.service';
export default function ReferalRewardBlockComponent({
  block: { reward_block_image, reward_block_title, reward_line, reward_modal_image },
  show,
  obtained,
}: any): JSX.Element {
  // here the empty div is important --> it's the corner border container
  const [imageModal, setImageModal] = useState(false);
  const closeModal = (): void => {
    setImageModal(false);
  };
  return (
    <section className="referral-block-reward">
      <div className="referral-block-reward-container">
        <Modal
          isOpen={imageModal}
          onRequestClose={closeModal}
          className="modal-content-referal"
          overlayClassName="modal-overlay"
        >
          <div className="popup-exit-icon" onClick={closeModal} />

          <div
            className="popup-image"
            style={{
              backgroundImage: `url(${reward_modal_image})`,
            }}
          ></div>

          <div className="popup-image-name">
            <div className="popup-image-name-line"></div>
            <p>{reward_block_title}</p>
          </div>
        </Modal>

        <div
          className="referral-block-picture"
          style={{ backgroundImage: `url(${reward_block_image})` }}
          onClick={(): void => {
            setImageModal(true);
          }}
        >
          <div className="placeholder">
            <div className="zoom-box" />
            {show && (
              <div className="obtained-notice">
                <div />
                <span>{asText(obtained)}</span>
              </div>
            )}
          </div>
        </div>
        <div className="referral-block-reward-content">
          <h4>{reward_block_title}</h4>
          <ul>
            {reward_line &&
              reward_line.map((line: string) => {
                const linesplit = line.split(':');
                return (
                  <li key={line}>
                    <span>{linesplit[0]}</span>
                    {linesplit[1]}
                  </li>
                );
              })}
          </ul>
          <div className="referral-block-reward-footer" />
        </div>
      </div>
    </section>
  );
}
