import React, { useState, useEffect } from 'react';
import BuyPagePackages from './buypage-one-blocks/buypage-packages';
import BuyPageInfo from './buypage-one-blocks/buypage-info';
import BuyPageLoginDisclaim from './buypage-one-blocks/buypage-LoginDisclaim';
import BuyPageTwo from './buypage-two-blocks/buypage-two.landingone';
import BuyPageSpecs from './buypage-one-blocks/buypage-specs';
import { usePlansAvailable } from '../../stores/selectors/plans-available.selector';
import { usePrismic } from '../../hooks/prismic.hook';
import { getPageID } from '../../services/i18n/i18n.url.service';
import { useForm } from 'react-hook-form';
import subscribeToNewsletter from '../../services/authentication/newsletter.service';
import { useAppDispatch } from '../../stores/app.store.config';
import qs from 'qs';
import { setCpmParameters } from '../../stores/slices/cpmstar.slice';
import PrismicRichText from '../../services/prismic/prismic.richtext.service';
import { AnalyticsComponent } from '../analytics/analytics.component';
import { useLocation } from 'react-router-dom';

const LandingOneBuyOne = (): JSX.Element => {
  const { search } = useLocation();

  const dispatch = useAppDispatch();

  useEffect(() => {
    const { ref, siteid } = qs.parse(search.slice(1));

    if (ref && siteid) {
      dispatch(setCpmParameters({ ref, siteid }));
    }
  }, []);

  const { handleSubmit, register, errors } = useForm();

  const onSubmit = async (values: Record<string, any>): Promise<void> => {
    const { email } = values;
    await subscribeToNewsletter(email);
  };

  const { pathname } = useLocation();

  const [limitHeader, setLimitHeader] = useState(true);
  const [popUp, setPopUp] = useState(false);
  const {
    contents: { limit, percentageUsed },
    hasErrors,
  } = usePlansAvailable();

  const {
    result: { problem, limit_not_reached, limit_reached, popup_header, click_here, stay_informed, join, max, sold },
    contents,
  } = usePrismic('buy_page');

  const {
    result: { email_placeholder, required },
  } = usePrismic('common');

  if (!contents) return <></>;

  const {
    results: [
      {
        data: { popup_disclaimer },
      },
    ],
  } = contents;

  const uid = getPageID(pathname) === 'buy-confirm' ? '2' : '1';

  if (uid === '1') {
    if (hasErrors) {
      return (
        <div className="errorMessage">
          <p>{problem}</p>
        </div>
      );
    }
    return (
      <>
        <AnalyticsComponent type="buy" />
        {limitHeader && limit && percentageUsed != 100 && (
          <div className={'limitHeader'}>
            <div className="displayMessage">
              <p>{limit_not_reached}</p>
            </div>

            <div className={'progressBar'}>
              <div className={'barLimit'} />
              <div className={'barUsed'} style={{ width: `${percentageUsed}%` }} />

              <p className="left">
                {percentageUsed}
                {sold}
              </p>
              <p className="right">{max}</p>
            </div>

            <div
              className="close"
              onClick={(): void => {
                setLimitHeader(false);
              }}
            />
          </div>
        )}
        {limitHeader && limit && percentageUsed === 100 && (
          <div className={' limitHeaderTwo limitHeader'}>
            <div className="displayMessage">
              <p>
                {limit_reached}, <span onClick={(): void => setPopUp(true)}>{click_here}</span>
                {stay_informed}
              </p>
            </div>

            <div
              className="close"
              onClick={(): void => {
                setLimitHeader(false);
              }}
            />
          </div>
        )}

        {popUp && (
          <>
            <div className={'parentBlur'} />

            <div className={'popUpWaitingList'}>
              <div className={'close'} onClick={(): void => setPopUp(false)} />

              <div className="footeremail">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <p className="popUpheader">{popup_header}</p>
                  <div className="util-input">
                    <div className="first" />
                    <input
                      type={'email'}
                      name={'email'}
                      className="util-input-text"
                      placeholder={`${email_placeholder}`}
                      ref={register({
                        required,
                      })}
                    />

                    <div className="last" />
                  </div>
                  <button type="submit">
                    <div className="icon-send"></div>
                    <span>{join}</span>
                  </button>
                  {errors.email && <p style={{ color: 'red' }}>{errors.email.message}</p>}
                  <PrismicRichText render={popup_disclaimer} className="disclaimer" />
                </form>
              </div>
              <p className="disclaimer"></p>
            </div>
          </>
        )}

        <div className={'util-main-padding backgroundBuyPage backgroundBuyPage_Mod'}>
          <BuyPagePackages />
          <BuyPageLoginDisclaim />
          <BuyPageSpecs />
        </div>

        <BuyPageInfo />
      </>
    );
  } else if (uid === '2') {
    return <BuyPageTwo />;
  } else return <> </>;
};

export default LandingOneBuyOne;
