import { createAsyncThunk } from '@reduxjs/toolkit';
import config from '../../../config';
import {
  KickstarterForm,
  KickstarterPhysicalRewardInfo,
} from '../../models/authentication/authentication.kickstarter.model';
import {
  getPhysicalRewards, 
  getPhysicalRewardsAllowed, 
  updatePhysicalRewards,
} from '../../services/authentication/authentication.kickstarter.service';

export const fetch = createAsyncThunk(
  'kickstarter/fetch',
  async (_, { rejectWithValue }): Promise<{ physicalRewards: KickstarterPhysicalRewardInfo | null } | any> => {
    try {
      const physicalRewards = await getPhysicalRewards();
      return physicalRewards;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ Code: err.response.status, ...err.response.data });
    }
  },
);
export const allowed = createAsyncThunk(
  'kickstarter/allowed',
  async (_, { rejectWithValue }): Promise< boolean | null | any> => {
    try {
      const allowed = await getPhysicalRewardsAllowed();
      return allowed;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ Code: err.response.status, ...err.response.data });
    }
  },
);

export const updateForm = createAsyncThunk(
  'kickstarter/updateForm',
  async (body: KickstarterPhysicalRewardInfo, { rejectWithValue }): Promise<KickstarterForm | any> => {
    try {
      const form = await updatePhysicalRewards(body);
      return form;
    } catch (err: any) {
      if (config.local) {
        console.log('error', err);
      }
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ Code: err.response.status, ...err.response.data });
    }
  },
);
