import { createSlice } from '@reduxjs/toolkit';
import { initialConfirmState } from '../../models/confirm/confirm.model';

const slice = createSlice({
  name: 'confirm',
  initialState: initialConfirmState,
  reducers: {
    setConfirm: (state: Record<string, any>, { payload }): void => {
      state.show = payload;
    },
  },
});

export const confirmReducer = slice.reducer;
export const setConfirmSlice = slice.actions.setConfirm;
