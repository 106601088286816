import React, { useEffect } from 'react';
import HomePageStart from './homepagesections/homepagestart';
import HomePageMiddle from './homepagesections/homepagemiddle';
import qs from 'qs';
import { useAppDispatch } from '../../../stores/app.store.config';
import { setCpmParameters } from '../../../stores/slices/cpmstar.slice';
import { usePrismic } from '../../../hooks/prismic.hook';
import { useLocation } from 'react-router-dom';

export default function LandingOneHomePage(): JSX.Element {
  const { search } = useLocation();

  const dispatch = useAppDispatch();

  useEffect(() => {
    const { ref, siteid } = qs.parse(search.slice(1));

    if (ref && siteid) {
      dispatch(setCpmParameters({ ref, siteid }));
    }
  }, [search]);

  const prismic = usePrismic('home_page');

  if (!prismic.result || Object.keys(prismic.result).length === 0) return <></>;

  const { background_video } = prismic.result;

  return (
    <div className={'HomeLandingOne'}>
      <div className="opacity">
        <video className="background-video" loop autoPlay muted>
          <source src={background_video} type="video/mp4" />
        </video>
      </div>
      <div className={'homePageParent'}>
        <HomePageStart />
        <HomePageMiddle />
      </div>
    </div>
  );
}
