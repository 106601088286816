import { createSlice } from '@reduxjs/toolkit';
import { initialPlanState } from '../../models/plan/plan.model';

const slice = createSlice({
  name: 'plan',
  initialState: initialPlanState,
  reducers: {
    setPlan: (state: Record<string, any>, { payload }): void => {
      state.contents = payload;
    },
  },
});

export const PlanReducer = slice.reducer;
export const { setPlan } = slice.actions;
