import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import Media from 'react-media';
import Carousel from 'react-multi-carousel';
import Popup from 'reactjs-popup';
import { usePrismic } from '../../../hooks/prismic.hook';
import { getPageID, getUrlFromEnglish } from '../../../services/i18n/i18n.url.service';
import PrismicRichText, { asText } from '../../../services/prismic/prismic.richtext.service';
import { goto } from '../../../stores/selectors/router.selector';
import LinkComponent from '../../link/link.component';
import NewsletterComponent from '../../newsletter/newsletter.component';
import ScrollAnimation from 'react-animate-on-scroll';
import { useLocation } from 'react-router-dom';

const BuildersComponent = (): JSX.Element | null => {
  const responsive = {
    desktop: {
      breakpoint: { max: 4000, min: 480 },
      items: 1,
    },

    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1,
    },
  };
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const closeModal = (): void => {
    setOpen(false);
  };
  const [offset, setOffset] = useState(0);
  useEffect(() => {
    function handleScroll() {
      setOffset(window.pageYOffset);
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const { contents: navItemsRaw } = usePrismic('pillar_template_common');

  const navItemsDoc = navItemsRaw?.results[0]?.data;

  const { contents: contentsRaw } = usePrismic('pillars_template');

  if (!contentsRaw) return <></>;

  const elements = contentsRaw?.results?.filter((item: any) => {
    return item?.data?.pillar_type?.includes(getPageID(pathname));
  })[0];

  if (!elements) return <></>;
  const {
    data: {
      video_presentation_link,
      background_presentation_image,
      video_link,
      video_label,
      background_page_image,
      parallax_images,
      body: slices,
      pillar_title,
      pillar_subtitle,
      pillar_intro_content,
      pillar_form_title,
      pillar_form_content,
      pillar_form_image,
      location_feedback,
      talents_label,
      swipe,
    },
  } = elements || {};

  const contentStyle = { background: '#ffd255' };
  const overlayStyle = { background: 'transparent' };
  const arrowStyle = { color: '#ffd255' };

  const renderSlices = (): JSX.Element[] | JSX.Element => {
    return slices?.map((slice: any, key: number) => {
      if (slice?.primary?.direction?.includes('Image Right') || slice?.primary?.direction?.includes('Image Left')) {
        return (
          <ScrollAnimation animateIn="fadeIn" key={key} animateOnce={true}>
            <div
              className={
                slice?.primary?.direction?.includes('Image Left')
                  ? 'carousel-component carousel-component-inverse '
                  : 'carousel-component'
              }
              key={key}
            >
              <div className="section-text">
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                  <h2>{asText(slice?.primary?.slice_title)}</h2>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                  <div className="slice-subtitle">
                    <PrismicRichText render={slice?.primary?.slice_subtitle}></PrismicRichText>
                  </div>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                  <PrismicRichText render={slice?.primary?.slice_description}></PrismicRichText>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                  <div className="talents-content">
                    <h3 className="slice-talents-title">{asText(talents_label)}</h3>
                    <div className="talents-container"> {renderTalents(slice?.items || [])}</div>
                  </div>
                </ScrollAnimation>
              </div>
              <div className="section-carousel">
                <Carousel
                  autoPlay={false}
                  autoPlaySpeed={5000}
                  transitionDuration={1000}
                  keyBoardControl={true}
                  responsive={responsive}
                  infinite={true}
                  itemClass="carousel-item-padding"
                >
                  {renderCarousel(slice?.items || [])}
                </Carousel>
              </div>
              <div
                className="section-carousel-mobile"
                style={{ gridTemplateColumns: `repeat(${slice?.items?.length},1fr)` }}
              >
                {renderCarouselMobile(slice?.items || [])}
              </div>
              <p className="carousel-swipe">{asText(swipe)}</p>
            </div>
          </ScrollAnimation>
        );
      } else if (
        slice?.primary?.direction?.includes('Text Centered') ||
        slice?.primary?.direction?.includes('Big Image')
      ) {
        const carouselItems = slice?.items.filter((item: Record<string, any>) =>
          item?.image_type?.includes('Carousel'),
        );
        return (
          <div className="text-centered-component" key={key}>
            <div className={carouselItems?.length === 1 ? 'image-grid only-one' : 'image-grid'}>
              {renderThreeImages(carouselItems)}
            </div>
            <p className={!slice?.primary?.direction?.includes('Big Image') ? 'carousel-swipe' : 'carousel-swipe-none'}>
              {asText(swipe)}
            </p>
            <div
              className="section-carousel-mobile"
              style={{ gridTemplateColumns: `repeat(${slice?.items?.length},1fr)` }}
            >
              {renderCarouselMobile(slice?.items || [])}
            </div>
            <div className="section-text">
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <h2>{asText(slice?.primary?.slice_title)}</h2>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <div className="slice-subtitle">
                  <PrismicRichText render={slice?.primary?.slice_subtitle}></PrismicRichText>
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <PrismicRichText render={slice?.primary?.slice_description}></PrismicRichText>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                <div className="talents-content">
                  <h3 className="slice-talents-title">{asText(talents_label)}</h3>
                  <div className="talents-container"> {renderTalents(slice?.items || [])}</div>
                </div>
              </ScrollAnimation>
            </div>
          </div>
        );
      }
    });
  };
  const renderShip = (speed: number, label: string): JSX.Element | null => {
    return (
      <div
        className="ship-label"
        // style={{
        //   transform: `translateY(-${(offset * speed) / 1920}vw)`,
        // }}
      >
        <div />
        <p className="label-text">{label}</p>
      </div>
    );
  };
  const renderCarouselMobile = (items: Array<Record<string, any>>): JSX.Element[] | JSX.Element => {
    return items
      ?.filter((item: Record<string, any>) => item?.image_type?.includes('Carousel'))
      ?.map((item: Record<string, any>, key: number) => {
        return (
          <div key={key} className="carousel-box-container">
            <div
              className="carousel-box"
              key={key}
              style={{ backgroundImage: `url(${item?.carousel_image?.url})` }}
            ></div>
            <p className="carousel-author-mobile">
              {asText(item?.image_title)}
              <span>{asText(item?.image_author)}</span>
            </p>
          </div>
        );
      });
  };
  const renderCarousel = (items: Array<Record<string, any>>): JSX.Element[] | JSX.Element => {
    return items
      ?.filter((item: Record<string, any>) => item?.image_type?.includes('Carousel'))
      ?.map((item: Record<string, any>, key: number) => {
        return (
          <div key={key}>
            <div className="carousel-box" style={{ backgroundImage: `url(${item?.carousel_image?.url})` }}></div>
            <div className="carousel-info-content">
              <div className="carousel-box-title-author">
                <p>
                  {asText(item?.image_title)}
                  <span>{asText(item?.image_author)}</span>
                </p>
              </div>
              {asText(item?.image_coordinates) && (
                <Popup
                  trigger={(): JSX.Element => (
                    <div className="carousel-box-coordinates">
                      <CopyToClipboard text={asText(item?.image_coordinates)}>
                        <div className="carousel-box-coordinates-wrapper">
                          <div className="icon icon-copy" style={{ cursor: 'pointer' }}>
                            <div></div>
                          </div>
                        </div>
                      </CopyToClipboard>
                    </div>
                  )}
                  position="top center"
                  closeOnDocumentClick
                  {...{ contentStyle, overlayStyle, arrowStyle }}
                >
                  <div className="link-container">
                    <p>{asText(location_feedback)}</p>
                  </div>
                </Popup>
              )}
            </div>
          </div>
        );
      });
  };
  const renderTalents = (items: Array<Record<string, any>>): JSX.Element[] | JSX.Element => {
    return items
      ?.filter((item: Record<string, any>) => item?.image_type?.includes('Talent'))
      ?.map((item: Record<string, any>, key: number) => {
        return (
          <div className="talents-box" key={key}>
            <div className="talents-box-image" style={{ backgroundImage: `url(${item?.carousel_image?.url})` }} />
            <div className="talents-box-title">
              <PrismicRichText render={item?.image_title}></PrismicRichText>
            </div>
          </div>
        );
      });
  };
  const renderThreeImages = (items: Array<Record<string, any>>): JSX.Element[] | JSX.Element => {
    return items?.map((item: Record<string, any>, key: number): JSX.Element => {
      return (
        <ScrollAnimation delay={(key / 2) * 1000} animateIn="fadeIn" key={key} style={{ zIndex: 3 }} animateOnce={true}>
          <div
            key={key}
            className={items?.length === 1 ? 'image complete' : 'image'}
            style={{ backgroundImage: `url(${item?.carousel_image?.url})` }}
          >
            {asText(item?.image_title) && (
              <div className="carousel-box-title-author">
                <p>
                  {asText(item?.image_title)}
                  <span>{asText(item?.image_author)}</span>
                </p>
              </div>
            )}
            {asText(item?.image_coordinates) && (
              <Popup
                trigger={(): JSX.Element => (
                  <div className="carousel-box-coordinates">
                    <CopyToClipboard text={asText(item?.image_coordinates)}>
                      <div className="carousel-box-coordinates-wrapper">
                        <div className="icon icon-copy" style={{ cursor: 'pointer' }}>
                          <div></div>
                        </div>
                      </div>
                    </CopyToClipboard>
                  </div>
                )}
                position="top center"
                closeOnDocumentClick
                {...{ contentStyle, overlayStyle, arrowStyle }}
              >
                <div className="link-container">
                  <p>{asText(location_feedback)}</p>
                </div>
              </Popup>
            )}
          </div>
        </ScrollAnimation>
      );
    });
  };
  return (
    <div className="builders-container">
      <div className="navbar-container">
        <div className="navbar-left">
          <div
            onClick={() => {
              goto(getUrlFromEnglish('/'));
            }}
          />
          <LinkComponent link={`${getUrlFromEnglish('/')}`} tabIndex={0} label="Go Back" />
        </div>
        <div className="navbar-right">
          <div className={getPageID(pathname) === 'pillar-builders' ? 'nav-link selected' : 'nav-link'}>
            {navItemsDoc?.nav_items[0]?.ready && (
              <>
                <LinkComponent
                  link={`${getUrlFromEnglish('/pillar/builders')}`}
                  tabIndex={0}
                  label={asText(navItemsDoc?.nav_items[0]?.nav_item_label)}
                />
              </>
            )}
            {!navItemsDoc?.nav_items[0]?.ready && (
              <>
                <a>{asText(navItemsDoc?.nav_items[0]?.nav_item_label)}</a>
                <p className="notice">{asText(navItemsDoc?.notice_label)}</p>
              </>
            )}
          </div>
          <div className={getPageID(pathname) === 'pillar-merchants' ? 'nav-link selected' : 'nav-link'}>
            {navItemsDoc?.nav_items[1]?.ready && (
              <>
                <LinkComponent
                  link={`${getUrlFromEnglish('/pillar/merchants')}`}
                  tabIndex={0}
                  label={asText(navItemsDoc?.nav_items[1]?.nav_item_label)}
                />
              </>
            )}
            {!navItemsDoc?.nav_items[1]?.ready && (
              <>
                <a>{asText(navItemsDoc?.nav_items[1]?.nav_item_label)}</a>
                <p className="notice">{asText(navItemsDoc?.notice_label)}</p>
              </>
            )}
          </div>
          <div className={getPageID(pathname) === 'pillar-fighters' ? 'nav-link selected' : 'nav-link'}>
            {navItemsDoc?.nav_items[2]?.ready && (
              <>
                <LinkComponent
                  link={`${getUrlFromEnglish('/pillar/fighters')}`}
                  tabIndex={0}
                  label={asText(navItemsDoc?.nav_items[2]?.nav_item_label)}
                />
              </>
            )}
            {!navItemsDoc?.nav_items[2]?.ready && (
              <>
                <a>{asText(navItemsDoc?.nav_items[2]?.nav_item_label)}</a>
                <p className="notice">{asText(navItemsDoc?.notice_label)}</p>
              </>
            )}
          </div>
          <div className={getPageID(pathname) === 'pillar-leaders' ? 'nav-link selected' : 'nav-link'}>
            {navItemsDoc?.nav_items[3]?.ready && (
              <>
                <LinkComponent
                  link={`${getUrlFromEnglish('/pillar/leaders')}`}
                  tabIndex={0}
                  label={asText(navItemsDoc?.nav_items[3]?.nav_item_label)}
                />
              </>
            )}
            {!navItemsDoc?.nav_items[3]?.ready && (
              <>
                <a>{asText(navItemsDoc?.nav_items[3]?.nav_item_label)}</a>
                <p className="notice">{asText(navItemsDoc?.notice_label)}</p>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="builders-intro">
        <div className="opacity"> </div>
        {video_presentation_link?.url && (
          <video className="background-video" loop autoPlay muted>
            <source src={video_presentation_link?.url} type="video/mp4" />
          </video>
        )}
        {!video_presentation_link?.url && (
          <div
            className="background-image"
            style={{
              backgroundImage: `url(${background_presentation_image?.url})`,
              width: '100%',
              zIndex: 1,
            }}
          ></div>
        )}

        <div className="builders-intro-title">
          <PrismicRichText render={pillar_title}></PrismicRichText>
        </div>

        <div className="builders-intro-desc">
          <PrismicRichText render={pillar_subtitle}></PrismicRichText>
        </div>

        <Popup modal closeOnDocumentClick={false} open={open}>
          <div className={'popUpContainer'}>
            <div className={'exitIcon'} onClick={closeModal} />
            <video controls autoPlay controlsList="nodownload">
              <source src={video_link?.url} type="video/mp4" />
              <track default kind="captions" srcLang="en" src="" />
            </video>
          </div>
        </Popup>
        {video_link?.url && (
          <button
            className="theme-button"
            onClick={() => {
              setOpen(true);
            }}
          >
            <div className="theme-button-container">
              <div />
              <span>{asText(video_label)}</span>
            </div>
          </button>
        )}
        {!video_link?.url && <div className="space-button"></div>}
      </div>
      <Media
        queries={{
          small: '(max-width: 479px)',
          large: '(min-width: 480px)',
        }}
      >
        {(matches): JSX.Element => (
          <>
            {matches.small && (
              <div className="wrapper">
                <div className="builders-initial-section">
                  <div className="builders-initial-text">
                    <PrismicRichText render={pillar_intro_content}></PrismicRichText>
                  </div>
                  <div
                    className="absolute-elements planet-left"
                    style={{
                      backgroundImage: `url(${parallax_images[0]?.parallax_image?.url})`,
                      // transform: `translateY(${(offset * 8) / 1920}vw)`,
                    }}
                  />
                  <div
                    className="absolute-elements ship-left"
                    style={{
                      backgroundImage: `url(${parallax_images[1]?.parallax_image?.url})`,
                      transform: `translateY(${-(offset * 5) / 1920}vw)`,
                    }}
                  />
                  <div
                    className="absolute-elements planet-right"
                    style={{
                      backgroundImage: `url(${parallax_images[2]?.parallax_image?.url})`,
                      transform: `translateY(${(offset * 5) / 1920}vw)`,
                    }}
                  />
                </div>
                {renderSlices()}
              </div>
            )}
            {matches.large && (
              <div
                className="wrapper"
                style={{
                  backgroundImage: `linear-gradient(0deg, rgba(12, 19, 21, 0) 60%, #0c1315),url(${background_page_image?.url})`,
                }}
              >
                <div className="builders-initial-section">
                  <div className="builders-initial-text">
                    <PrismicRichText render={pillar_intro_content}></PrismicRichText>
                  </div>
                  <div
                    className="absolute-elements planet-left"
                    style={{
                      backgroundImage: `url(${parallax_images[0]?.parallax_image?.url})`,
                      // transform: `translateY(${(offset * 8) / 1920}vw)`,
                    }}
                  />
                  <div
                    className="absolute-elements ship-left"
                    style={{
                      backgroundImage: `url(${parallax_images[1]?.parallax_image?.url})`,
                      transform: `translateY(${-(offset * 3) / 1920}vw)`,
                    }}
                  />
                  <div
                    className={
                      elements?.data?.pillar_type?.includes('pillar-merchants')
                        ? 'absolute-elements planet-right planet-right-merchants'
                        : 'absolute-elements planet-right'
                    }
                    style={{
                      backgroundImage: `url(${parallax_images[2]?.parallax_image?.url})`,
                      transform: `translateY(-${(offset * 3) / 1920}vw)`,
                    }}
                  />
                </div>
                {renderSlices()}
                <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                  <div className="builders-form-container">
                    <div className="builders-form">
                      <div
                        className="building-form-img-mobile"
                        style={{
                          backgroundImage: ` url(${pillar_form_image?.url})`,
                        }}
                      ></div>
                      <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                        <div className="builders-form-title">
                          <PrismicRichText render={pillar_form_title} />
                        </div>
                      </ScrollAnimation>
                      <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                        <div className="builders-form-description">
                          <PrismicRichText render={pillar_form_content} />
                        </div>
                      </ScrollAnimation>
                      <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                        <NewsletterComponent title=" " />
                      </ScrollAnimation>
                      <div
                        className="builders-form-ship"
                        style={{
                          backgroundImage: `url(${parallax_images[3]?.parallax_image?.url})`,
                        }}
                      />
                      {renderShip(8, asText(parallax_images[3]?.parallax_image_label))}
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
            )}
          </>
        )}
      </Media>
    </div>
  );
};
export default BuildersComponent;
