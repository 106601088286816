import { createSlice } from '@reduxjs/toolkit';
import { initialKickstarterState } from '../../models/authentication/authentication.kickstarter.model';
import { fetch, updateForm, allowed } from '../actions/kickstarter.actions';

const slice = createSlice({
  name: 'kickstarter',
  initialState: initialKickstarterState,
  reducers: {},
  extraReducers: {
    [fetch.fulfilled.type]: (state, { payload }): void => {
      if (payload) {
        state.physicalRewards = payload;
      }
      state.loading = false;
      state.error = null;
      state.success = false;
    },
    [fetch.pending.type]: (state): void => {
      state.loading = true;
      state.error = null;
      state.success = false;
    },
    [fetch.rejected.type]: (state, { payload }): void => {
      state.hasAccess = false;
      state.loading = false;
      state.error = { ...payload, from: 'fetch' };
      state.success = false;
    },
    [allowed.fulfilled.type]: (state, { payload }): void => {
      state.hasAccess = payload;
      state.loading = false;
      state.error = null;
    },
    [allowed.pending.type]: (state): void => {
      state.loading = true;
      state.error = null;
    },
    [allowed.rejected.type]: (state, { payload }): void => {
      state.hasAccess = false;
      state.loading = false;
      state.error = { ...payload, from: 'allowed' };
    },
    [updateForm.fulfilled.type]: (state): void => {
      state.loading = false;
      state.error = null;
      state.success = true;
    },
    [updateForm.pending.type]: (state): void => {
      state.loading = true;
      state.error = null;
      state.success = false;
    },
    [updateForm.rejected.type]: (state, { payload }): void => {
      state.loading = false;
      state.error = { ...payload, from: 'updateForm' };
      state.success = false;
    },
  },
});

export const kickstarterReducer = slice.reducer;
