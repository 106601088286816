export interface Uploads {
  canUploadAssets: boolean;
  pendingValidation: Record<string, any>;
  processingMaxAllowed: number;
  validated: Record<string, any>;
}

export interface AssetsState {
  uploads: Uploads;
  loading: boolean;
  error: any;
}

export const initialAssetsState: any = {
  uploads: {
    canUploadAssets: false,
    pendingValidation: [],
    processingMaxAllowed: 5,
    validated: [],
  },
  loading: false,
  error: null,
};
