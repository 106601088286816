import React from 'react';
import { useI18n, setLocale } from '../../../stores/selectors/i18n.selector';
import { getLocalizedUrl } from '../../../services/i18n/i18n.url.service';
import LinkComponent from '../../link/link.component';
import { AuthState } from '../../../models/authentication/auth.model';
import { useAuth } from '../../../stores/selectors/auth.selector';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const LanguageMenu = (): JSX.Element => {
  const { locale, locales } = useI18n();
  const { user }: AuthState = useAuth();
  const { pathname } = useLocation();
  const displayLanguage = (language: string): any => {
    const assoc: any = {
      'en-us': 'English - EN',
      'fr-fr': 'Français - FR',
      'de-de': 'Deutsch - DE',
    };
    return assoc[language];
  };

  const renderSwitch = (language: any): string => {
    const newLocale = language.substr(0, 2);
    const url = getLocalizedUrl({ pathname, newLocale });
    return url;
  };

  let listOfLanguages: (JSX.Element | undefined)[] = locales.map(
    (language: string, index: number): JSX.Element | undefined => {
      if (language !== locale) {
        const url = renderSwitch(language);
        return (
          <LinkComponent
            key={index}
            className="checkboxcontainer"
            tabIndex={0}
            link={url}
            forceLocale
            onClick={async (): Promise<void> => {
              setLocale(language);
              if (user) {
                try {
                  await axios({
                    method: 'put',
                    url: '/api/users/me/update-language',
                    headers: {
                      Authorization: `Bearer ${user.token}`,
                    },
                    params: {
                      lang: language?.split('-')[0],
                    },
                  });
                } catch (err) {
                  console.log(err);
                }
              }
              window.location.href = url;
            }}
          >
            <input id="language" type="checkbox" />
            {displayLanguage(language)}
          </LinkComponent>
        );
      }
      return undefined;
    },
  );

  listOfLanguages = listOfLanguages.filter((a) => a);

  return (
    <div className={'languagemenucontainer'}>
      <div className={'languagesubmenu'}>
        <h2 className="language-title-mobile">Select your language</h2>
        <div className={'languageselected'}>
          <LinkComponent className="checkboxcontainer" tabIndex={0} link={renderSwitch(locale)}>
            <input id="languageselected" className="selected" type="checkbox" />
            {displayLanguage(locale)}
          </LinkComponent>
        </div>
        <div className={'languages'}>{listOfLanguages}</div>
      </div>
    </div>
  );
};

export default LanguageMenu;
