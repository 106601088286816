import React from 'react';
import { useAppDispatch } from '../../stores/app.store.config';

import { usePrismic } from '../../hooks/prismic.hook';
import { LinkComponent } from '../link/link.component';
import NewsletterComponent from '../newsletter/newsletter.component';
import { openSimplePopup } from '../../stores/slices/consent.slice';

export default function Footer(): JSX.Element {
  const dispatch = useAppDispatch();

  const {
    result: {
      wishlist_label,
      wishlist_link,
      copyright,
      icon_title,
      cookie_preferences,
      advertisement_image,
      advertisement_link,
      footer_links = [],
      legal_links = [],
    },
  } = usePrismic('footer');

  if (!footer_links) return <div></div>;

  return (
    <div className={'footer'}>
      <div className={'footerIcon'}>
        <p>{icon_title}</p>
        <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/dualuniverse">
          <span className={'iconLogoTwitter '} />
        </a>
        <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/dualthegame/">
          <span className={'iconLogoInstagram '} />
        </a>
        <a target="_blank" rel="noopener noreferrer" href="https://facebook.com/dualuniverse">
          <span className={'iconLogoFacebook '} />
        </a>
        <a target="_blank" rel="noopener noreferrer" href="https://youtube.com/dualuniverse">
          <span className={'iconLogoYoutube '} />
        </a>
        <a target="_blank" rel="noopener noreferrer" href="https://twitch.tv/dualuniverse">
          <span className={'iconLogoTwitch '} />
        </a>
        <a target="_blank" rel="noopener noreferrer" href=" https://discord.gg/dualuniverse">
          <span className={'iconLogoDiscord '} />
        </a>
        <a target="_blank" rel="noopener noreferrer" href="https://www.reddit.com/r/DualUniverse/">
          <span className={'iconLogoReddit '} />
        </a>
        <a target="_blank" rel="noopener noreferrer" href="https://soundcloud.com/dualuniverse">
          <span className={'iconLogoCloud '} />
        </a>
        {wishlist_link && (
          <div className={'navbar-item'}>
            <LinkComponent className="navbar-play" id="navbar-play-fr" link={wishlist_link}>
              <div />
              <span>{wishlist_label}</span>
            </LinkComponent>
          </div>
        )}
      </div>
      <section className={'util-main-padding'}>
        <div className="footeremail">
          <NewsletterComponent />
        </div>
        <div className="footerlinks">
          {footer_links.map(
            ({ link, label, is_big }: { link: string; label: string; is_big: boolean }, key: number) => (
              <LinkComponent
                key={key}
                link={link}
                label={label}
                className={`linkblock${key % 2 === 0 ? 'left' : 'right'} importance${is_big ? 'big' : 'simple'}`}
              />
            ),
          )}
        </div>
        {advertisement_image && (
          <LinkComponent link={advertisement_link}>
            <div className="advertiser" style={{ backgroundImage: `url(${advertisement_image}` }} />
          </LinkComponent>
        )}
        <div className={'footerEnd'}>
          <div className={'footerEndLinks'}>
            {legal_links.map(({ link, label }: { link: string; label: string }, key: number) => (
              <LinkComponent link={link} label={label} key={key} />
            ))}
            <a onClick={() => dispatch(openSimplePopup())}>{cookie_preferences}</a>
            <a className="logo" href={`https://www.novaquark.com/`} rel="noopener noreferrer" target="_blank" />
          </div>
          <p>{copyright}</p>
        </div>
      </section>
    </div>
  );
}
