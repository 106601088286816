import React, { useEffect } from 'react';
import RichText, { asText } from '../../../services/prismic/prismic.richtext.service';
import { useLauncherNews } from '../../../stores/selectors/launcher-news.selector';
import { getUrlFromEnglish } from '../../../services/i18n/i18n.url.service';
import { getSlug } from '../../news/news.service';
import { Link, useParams } from 'react-router-dom';
import { usePrismic } from '../../../hooks/prismic.hook';

const LauncherDetailComponent = (): JSX.Element => {
  let {
    contents: { newsStoriesLauncher },
  } = useLauncherNews();

  const { uid } = useParams();
  const {
    result: { next, previous, back },
  } = usePrismic('launcher_page');
  useEffect(() => {
    document.onkeydown = function (e) {
      // keycode for F5 function
      if (e.keyCode === 116) {
        return false;
      }
    };
  }, []);
  if (!newsStoriesLauncher) return <></>;
  const pinned = newsStoriesLauncher.filter((elem: any) => {
    return elem.pinned;
  });
  const noPinned = newsStoriesLauncher.filter((elem: any) => {
    return !elem.pinned;
  });
  pinned.sort((a: any, b: any) => Date.parse(b.dateModified) - Date.parse(a.dateModified));
  newsStoriesLauncher = [...pinned, ...noPinned];
  const result =
    newsStoriesLauncher?.find((r: any) => {
      return getSlug(r) === uid;
    }) || newsStoriesLauncher[0];
  const index = newsStoriesLauncher?.findIndex((r: any) => getSlug(r) === uid);

  return (
    <div className="launcher-detail-container">
      <div
        className="launcher-intro"
        style={{
          backgroundImage: `url(${result?.image?.detail?.url})`,
        }}
      >
        <Link className="launcher-top-btn" to={getUrlFromEnglish('/launcher/news')}>
          <div className="icon-back"></div> <span>{back}</span>
        </Link>
      </div>

      <div className="launcher-content">
        <div className="launcher-content-title">
          <h1>{asText(result?.title)} </h1>
        </div>
        <div className="launcher-content-desc">
          <RichText render={result?.description} />
        </div>
        <div className="launcher-content-date">{result?.date?.toString()}</div>
        <div className="launcher-content-detail">
          <RichText render={result?.content} />
        </div>
        <div className="launcher-navigation">
          {newsStoriesLauncher[index - 1] && (
            <Link
              className="launcher-top-btn"
              to={`${getUrlFromEnglish('/launcher/news')}/${getSlug(newsStoriesLauncher[index - 1])}`}
            >
              <div className="nav-before">
                <div className="nav-before-box"></div>
                <p>{previous}</p>
              </div>
            </Link>
          )}
          {!newsStoriesLauncher[index - 1] && <div></div>}
          {newsStoriesLauncher[index + 1] && (
            <Link
              className="launcher-top-btn"
              to={`${getUrlFromEnglish('/launcher/news')}/${getSlug(newsStoriesLauncher[index + 1])}`}
            >
              <div className="nav-after">
                <p>{next}</p> <div className="nav-after-box"></div>
              </div>
            </Link>
          )}
          {!newsStoriesLauncher[index + 1] && <div></div>}
        </div>
      </div>
    </div>
  );
};
export default LauncherDetailComponent;
