import axios from 'axios';
import config from '../../../config';

export const userReferalInfo = async (token: string): Promise<any> => {
  try {
    const result = await axios.get(`/api/Recruitment/UserContext`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (err) {
    if (config.local) {
      console.log('error getting the reruiter and recruits', err);
    }
  }
};
export const sendInvitation = async (token: string, email: string): Promise<any> => {
  try {
    const result = await axios.post(
      `/api/Recruitment/invite/${email}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return result.data;
  } catch (err) {
    if (config.local) {
      console.log('error sending the invitation', err);
    }
  }
};
export const linkingRecruiter = async (token: string, recruiterId: string): Promise<any> => {
  try {
    const result = await axios.post(
      `/api/Recruitment/LinkRecruiter/${recruiterId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return result.data;
  } catch (err) {
    if (config.local) {
      console.log('error linking to a recruiter', err);
    }
  }
};
export const getRewards = async (token: string, userId: number): Promise<any> => {
  try {
    const result = await axios.get(`/api/inventory/rewards/${userId}/UnlockedPacks`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  } catch (err) {
    if (config.local) {
      console.log('error getting the rewards', err);
    }
  }
};
