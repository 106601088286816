import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import AppContainer from './app-container';
import './index.scss';
import Modal from 'react-modal';
import { createRoot } from 'react-dom/client';
// import { BrowserRouter } from 'react-router-dom';
import { history } from './_configurations/history';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
Modal.setAppElement('#root');

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <HistoryRouter history={history}>
    <AppContainer />
  </HistoryRouter>,
);
