import React, { useState } from 'react';
import { usePrismic } from '../../../hooks/prismic.hook';
import RichText, { asText } from '../../../services/prismic/prismic.richtext.service';
import Popup from 'reactjs-popup';
import YoutubeVideo from '../../partials/video-youtube.partial-component';

export default function ReferalPresentationComponent(): JSX.Element | null {
  const {
    data: { page_description, button_label, button_intro_link },
    result: { notice, page_title },
  } = usePrismic('recruit_a_friend');

  const [open, setOpen] = useState(false);
  const closeModal = (): void => {
    document.body.classList.remove('modal-open');
    setOpen(false);
  };
  return (
    <>
      <div className="notice-notlogin-referal">
        <p>{notice}</p>
      </div>
      <section className="referral-block-presentation">
        <div className={'content-container'}>
          <div className={'content-block'}>
            <h1>{page_title}</h1>
            <div className="description">
              <p className="mobile-description">{asText(page_description)}</p>

              <RichText render={page_description} />
            </div>
          </div>
          {button_intro_link?.url && (
            <button
              className="theme-button"
              onClick={(): void => {
                document.body.classList.add('modal-open');
                setOpen(true);
              }}
            >
              <div className="theme-button-container">
                <div />
                <span>{asText(button_label)}</span>
              </div>
            </button>
          )}
          <Popup modal closeOnDocumentClick={false} open={open}>
            <div className={'popUpContainer'}>
              <div className={'exitIcon'} onClick={closeModal} />
              <YoutubeVideo videoURL={asText(button_intro_link?.url)} size={'long'} />
            </div>
          </Popup>
        </div>
      </section>
    </>
  );
}
