import axios from 'axios';

export const retriveUserBackToGame = async (token: string): Promise<any> => {
  try {
    const result = await axios.get(`/api/users/me/check-back-to-game`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result?.data;
  } catch (err) {
    console.log(err);
  }
};
export const retriveTryGame = async (token: string): Promise<any> => {
  try {
    const result = await axios.get(`/api/users/me/check-try-game`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result?.data;
  } catch (err) {
    console.log(err);
  }
};
