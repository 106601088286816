import { createSlice } from '@reduxjs/toolkit';
import { initialAssetsState } from '../../models/assets/assets.model';
import { fetchUploads, uploadAsset, deleteUpload } from '../actions/assets.actions';

const slice = createSlice({
  name: 'assets',
  initialState: initialAssetsState,
  reducers: {
    resetError: (state): void => {
      state.error = null;
    },
  },
  extraReducers: {
    [fetchUploads.fulfilled.type]: (state, { payload }): void => {
      state.uploads = payload;
      state.loading = false;
      state.error = false;
    },
    [fetchUploads.pending.type]: (state): void => {
      state.loading = true;
      state.error = null;
    },
    [fetchUploads.rejected.type]: (state, { error }): void => {
      state.loading = false;
      state.error = error;
    },
    [uploadAsset.fulfilled.type]: (state, { payload }): void => {
      state.uploads = payload;
      state.loading = false;
      state.error = false;
    },
    [uploadAsset.pending.type]: (state): void => {
      state.loading = true;
      state.error = null;
    },
    [uploadAsset.rejected.type]: (state, { payload, error }): void => {
      state.loading = false;
      state.error = payload ? { ...payload, from: 'uploadAsset' } : error;
    },
    [deleteUpload.fulfilled.type]: (state, { payload }): void => {
      state.uploads = payload;
      state.loading = false;
      state.error = false;
    },
    [deleteUpload.pending.type]: (state): void => {
      state.loading = true;
      state.error = null;
    },
    [deleteUpload.rejected.type]: (state, { error }): void => {
      state.loading = false;
      state.error = error;
    },
  },
});

export const assetsReducer = slice.reducer;
export const { resetError } = slice.actions;
