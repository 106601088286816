import { AuthState } from '../../models/authentication/auth.model';
import { useSelector } from 'react-redux';
import { RootState } from '../../models/_root-state.model';
import { store } from '../app.store.config';
import { resetError as resetErrorAction } from '../slices/auth.slice';

export const useAuth = (): AuthState => {
  return useSelector((state: RootState) => state.authBranch);
};

export const resetError = (): void => {
  store.dispatch(resetErrorAction());
};
