import React, { useEffect, useState } from 'react';
import { usePrismic } from '../../../hooks/prismic.hook';
import ReferalLineComponent from './partials/reward-line.component';
import PrismicRichText, { asText } from '../../../services/prismic/prismic.richtext.service';
import { getRewards } from '../../../services/authentication/referal.service';
import { useAuth } from '../../../stores/selectors/auth.selector';

export default function ReferalRewardYourComponent(): JSX.Element | null {
  const {
    data: { rewards_title, rewards_description, obtained },
    result: { rewards_player_container, rewards_player_block },
  } = usePrismic('recruit_a_friend');
  const { user }: { user: Record<string, any> } = useAuth();
  const [countRewards, setCountRewards] = useState(Object);
  const nbBlocksByLines = 4;
  let count = 0;

  useEffect(() => {
    if (user) {
      getRewards(user.token, user.id).then((data) => {
        setCountRewards(data);
      });
    }
  }, [user]);

  return (
    <section className="referral-block-rewards-your">
      <div className="content-header-container">
        <h2>{asText(rewards_title)}</h2>
        <PrismicRichText render={rewards_description} />
      </div>
      <div className="content-rewards-container">
        {rewards_player_container &&
          rewards_player_container.map((element: any, key: number) => {
            const rewards = [];
            if (rewards_player_block) {
              for (let x = 0; x < nbBlocksByLines; x++) {
                rewards.push(rewards_player_block[count]);
                count++;
              }
              return (
                <ReferalLineComponent
                  key={key}
                  lines={element}
                  rewards={rewards}
                  countRewards={countRewards}
                  obtained={obtained}
                />
              );
            }
            return null;
          })}
      </div>
    </section>
  );
}
