import { format } from 'date-fns';
import { fr, de } from 'date-fns/locale';
import config from '../../../config';
import Case from 'case';
import { getShortLocale } from '../i18n/i18n.url.service';
import { asText } from '../prismic/prismic.richtext.service';

export const getDateLocale = (): Locale => {
  const locales: Record<string, Locale> = {
    fr,
    de,
  };
  const locale = locales[getShortLocale()];
  return locale;
};

export const getFormattedDate = (inputDate: string): string => {
  if (!inputDate) return '';
  let updatedDate = inputDate;
  try {
    const date = new Date(updatedDate);
    updatedDate = format(date, config.dateFormat, { locale: getDateLocale() });
  } catch (err) {
    try {
      updatedDate = inputDate.replace(/-/g, '/').replace('T', ' ');
      const date = new Date(updatedDate);
      updatedDate = format(date, config.dateFormat, { locale: getDateLocale() });
    } catch (err) {
      if (config.local) {
        console.log('error with date', err);
      }
    }
  }
  return updatedDate;
};

export const replaceLink = (url: string): string => url?.replace(config.prodRootURL, '');

export const toCamelCase = (input: any): any => {
  const output: any = {};
  let key: typeof input;
  for (key in input) {
    output[Case.camel(key)] = input[key];
  }
  return output;
};

export const displayDate = (d: string): string => {
  const date = new Date(d);
  return format(date, config.dateFormatWithTime, { locale: getDateLocale() });
};

export const getNbMonths = (obj: any): number => {
  return obj.name.split(' ')[0];
};

export const wait = async (ms: number): Promise<void> =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export const getRandomInt = (max: number): number => {
  return Math.floor(Math.random() * Math.floor(max));
};
export const getPrismicError = (errors: any, error: any): string => {
  const errorFound = errors?.find((e: any) => e.code === `${error?.code}`);
  return (errorFound && asText(errorFound?.message)) || '';
};
