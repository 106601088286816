import React, { useEffect, useState } from 'react';
import { usePrismic } from '../../../hooks/prismic.hook';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../../stores/selectors/auth.selector';
import { AuthState } from '../../../models/authentication/auth.model';
import PrismicRichText, { asText } from '../../../services/prismic/prismic.richtext.service';
import axios from 'axios';
import { useI18n } from '../../../stores/selectors/i18n.selector';
import { refreshToken } from '../../../stores/actions/auth.action';
import { useAppDispatch } from '../../../stores/app.store.config';
import Popup from 'reactjs-popup';
import Modal from 'react-modal';
import zxcvbn from 'zxcvbn';
import { getSeed2FA } from '../../../services/authentication/2fa.service';
import { getPrismicError } from '../../../services/utils/utils.service';
import QRCode from 'qrcode.react';
import AuthCode from 'react-auth-code-input';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
  contentStyle,
  overlayStyle,
  arrowStyle,
  translatedSuggestionsContent,
  translatedWarningContent,
  renderBars,
} from '../account-creation/psw-strengh.service';

const AccountSecurity = (): JSX.Element | null => {
  const {
    result: { status_weak, status_normal, status_strong, strength },
  } = usePrismic('account_creation');
  const { contents } = usePrismic('errors');
  const {
    data: {
      page_title,
      page_description,
      change_psw_title,
      change_psw_description,
      btn_save_psw_label,
      twofa_title,
      twofa_description,
      twofa_detail_title,
      btn_twofa_label,
      btn_twofa_label_desactivate,
      twofa_description_desactivation,
      twofa_detail_desc,
      twofa_request,
      copy_link_label,
      success_2fa_enabled,
      success_2fa_disabled,
    },
  } = usePrismic('account_security');

  const { contents: feedback_content } = usePrismic('account_creation_feedback');

  const { register, handleSubmit, watch, reset } = useForm();

  const pswWatch = watch('password');
  const confirmPswWatch = watch('confirmPassword');
  const pswretypeWatch = watch('pswretype');
  const pswOffWatch = watch('passwordOff');

  const { user }: AuthState = useAuth();
  const { locale } = useI18n();
  const dispatch = useAppDispatch();
  const [notice, setNotice] = useState({ type: '', message: '' });
  const [confirmModal, setConfirmModal] = useState(false);
  const [seedInfo, setSeedInfo] = useState(Object());
  const [secondBox, setSecondBox] = useState(false);
  const [twofaEnabled, setTwofaEnabled] = useState(false);
  const [code, setCode] = useState('');
  const handleOnChange = (res: string) => {
    setCode(res);
  };
  useEffect(() => {
    if (user?.details?.mfaEnabled) {
      setTwofaEnabled(true);
    }
  }, []);

  useEffect(() => {
    getSeed2FA(user.token).then((data) => {
      if (data?.seed) setSeedInfo(data);
    });
  }, [secondBox]);

  const rendertraslationStregth = (score: number): string => {
    switch (score) {
      case 0: {
        return status_weak;
      }
      case 1: {
        return status_normal;
      }
      case 2: {
        return status_normal;
      }
      case 3: {
        return status_strong;
      }
      default:
        return status_strong;
    }
  };
  const retrieveState = (watch: any): string => {
    if (!watch) {
      return 'icon-loading';
    } else if (watch && zxcvbn(watch).score === 0) return 'icon-fail';
    return 'icon-validate';
  };
  const { feedback } = feedback_content?.results?.[0]?.data || {};
  const score = zxcvbn(pswWatch || '').score;
  const warnings = zxcvbn(pswWatch || '').feedback.warning;
  const suggestions = zxcvbn(pswWatch || '').feedback.suggestions;

  const renderColumn = (
    name: string,
    label: string,
    defaultValue: string,
    inputWatch: string,
    icon: string,
    type: string,
    maxLength: number,
    minLength: number,
  ): JSX.Element => {
    return (
      <>
        <div className="tooltip-container">
          {!defaultValue?.includes('desactivation') && (
            <label className="label none" htmlFor={name}>
              {label}
            </label>
          )}
          {name?.includes('password') && !defaultValue?.includes('desactivation') && (
            <Popup
              open={Boolean(pswWatch) && suggestions.length > 0}
              trigger={(): JSX.Element => (
                <div className="tooltip-box">
                  <span className="buttoncustom">i</span>
                </div>
              )}
              {...{ contentStyle, overlayStyle, arrowStyle }}
              position="top center"
              className={suggestions.length === 0 ? 'custom-tooltip' : ''}
            >
              <div className="tooltip-msg">
                {translatedSuggestionsContent(suggestions, feedback).map((result: any, key: number): any => {
                  return <PrismicRichText render={result} key={key} />;
                })}
                <PrismicRichText render={translatedWarningContent(warnings, feedback)} />
              </div>
            </Popup>
          )}
        </div>
        <div className="input-container">
          <div className="input-container__row">
            <i className="iconWapper">
              <span className={icon}></span>
            </i>
            <input
              className="input-field"
              name={name}
              id={name}
              type={type}
              placeholder={!defaultValue?.includes('desactivation') ? '***********' : 'Enter your password'}
              minLength={minLength}
              maxLength={maxLength}
              ref={register({})}
            />
            <i className="iconWapper iconWapperRight">
              <span className={retrieveState(inputWatch)} />
            </i>
          </div>
          {name?.includes('password') && !defaultValue?.includes('desactivation') && (
            <div className="strenght-psw-container">
              <div className="strenght-psw-bars">{pswWatch ? renderBars(score) : renderBars(-1)}</div>
              <p className="strenght-psw-label">{pswWatch ? rendertraslationStregth(score) : strength}</p>
            </div>
          )}
        </div>
      </>
    );
  };

  const onSubmit = async (values: Record<string, any>): Promise<void> => {
    if (!code) {
      if (values.password !== values.confirmPassword) {
        setNotice({ type: 'error', message: `Passwords do not match` });
        cleanNotice();
        return;
      }
      if (!values.password) return;
      try {
        if (values.password) {
          await axios({
            method: 'put',
            url: '/api/users/me/update-password',
            data: {
              password: values.password,
              currentPassword: values.currentPassword,
            },
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
            params: {
              lang: locale?.split('-')[0],
            },
          });
          reset({ password: '', confirm_password: '' });
        }
        dispatch(refreshToken());
        setNotice({ type: 'success', message: 'Your password has been changed' });
        cleanNotice();
      } catch (err: any) {
        setNotice({ type: 'error', message: getPrismicError(errors, err.response.data) });
        cleanNotice();
      }
    } else {
      try {
        await axios.post(
          '/api/me/enable-mfa',
          { code },
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          },
        );
        dispatch(refreshToken());
        setTwofaEnabled(true);
        setNotice({ type: 'success', message: asText(success_2fa_enabled) });
        cleanNotice();
        // return result.data;
      } catch (err: any) {
        setNotice({ type: 'error', message: getPrismicError(errors, err.response.data) });
        cleanNotice();
      }
    }
  };
  const onTurnOffSubmit = async (values: Record<string, any>): Promise<void> => {
    if (values.passwordOff) {
      try {
        const result = await axios.post(
          '/api/me/disable-mfa',
          { password: values.passwordOff },
          {
            headers: {
              Authorization: `Bearer ${user?.token}`,
            },
          },
        );
        dispatch(refreshToken());
        setTwofaEnabled(false);
        setSecondBox(false);
        setNotice({ type: 'success', message: asText(success_2fa_disabled) });
        cleanNotice();
        return result.data;
      } catch (err: any) {
        setNotice({ type: 'error', message: getPrismicError(errors, err.response.data) });
        cleanNotice();
      }
    }
  };
  const cleanNotice = () => {
    setTimeout(() => {
      setNotice({ type: '', message: '' });
    }, 4000);
  };
  useEffect(() => {
    const canvas = document.getElementById('qrCanvas');
    if (canvas) {
      canvas.style.width = '100%';
      canvas.style.height = '100%';
    }
  }, [secondBox]);

  if (!feedback || !contents) return <></>;
  const {
    results: [
      {
        data: { errors },
      },
    ],
  } = contents;

  return (
    <div className="account-security-container">
      {notice?.type && (
        <div className={notice.type?.includes('success') ? 'account-notice' : 'account-notice error'}>
          <div />
          <p>{notice.message}</p>
        </div>
      )}

      <div className="account-security-title">
        <PrismicRichText render={page_title} />
      </div>
      <div className="account-security-desc">
        <PrismicRichText render={page_description} />
      </div>
      <div className="section-change-psw">
        <div className="section-corners">
          <div className="section-padding">
            <div className="section-psw-title">
              <PrismicRichText render={change_psw_title} />
            </div>
            <div className="section-psw-desc">
              <PrismicRichText render={change_psw_description} />
            </div>
            <form className="registration-form" id="edit-form-psw" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row">
                <div className="col col-6">
                  {renderColumn('password', 'password', '', pswWatch, 'icon-psw', 'password', 128, 1)}
                </div>
                <div className="col col-6">
                  {renderColumn(
                    'confirmPassword',
                    'confirm password',
                    '',
                    confirmPswWatch,
                    'icon-psw',
                    'password',
                    128,
                    1,
                  )}
                </div>
              </div>
              <button
                type={pswWatch ? 'button' : 'submit'}
                form="edit-form-psw"
                className="theme-button-spec-positive"
                onClick={() => {
                  if (pswWatch) setConfirmModal(true);
                  else setConfirmModal(false);
                }}
              >
                <div className="theme-button-container">
                  <div />
                  <span>{asText(btn_save_psw_label)}</span>
                </div>
              </button>
              <Modal
                isOpen={confirmModal}
                onRequestClose={() => {
                  setConfirmModal(false);
                }}
                className="modal-content"
                overlayClassName="modal-overlay"
              >
                <div className="modal-container-edition modal-content">
                  <div className="corners">
                    <label htmlFor="pswretype">
                      {'You are about to change your account password. Please input your current password to proceed'}
                    </label>
                    <input
                      className="input-field"
                      type="password"
                      name="pswretype"
                      id="pswretype"
                      ref={register({
                        // required: `${label} ${asText(error_required)}`,
                      })}
                    ></input>
                    <div className="group-button">
                      <button
                        type="button"
                        className="util-button-cancel"
                        onClick={() => {
                          setConfirmModal(false);
                        }}
                      >
                        <div />
                        <span>Cancel</span>
                      </button>
                      <button
                        className="util-button-continue"
                        type="button"
                        form="edit-form"
                        onClick={() => {
                          if (pswretypeWatch) {
                            setConfirmModal(false);
                            onSubmit({
                              password: pswWatch,
                              confirmPassword: confirmPswWatch,
                              currentPassword: pswretypeWatch,
                            });
                            reset({ pswretype: '' });
                          }
                        }}
                      >
                        <div />
                        <span>Confirm</span>
                      </button>
                    </div>
                  </div>
                </div>
              </Modal>
            </form>
          </div>
        </div>
      </div>
      {!twofaEnabled && (
        <div className="section-twofa">
          <div className={!secondBox ? 'section-corners' : 'section-corners open'}>
            <div className="section-padding">
              <div className="section-psw-title">
                <PrismicRichText render={twofa_title} />
              </div>
              <div className="section-psw-desc">
                <PrismicRichText render={twofa_description} />
              </div>
              <button
                type="button"
                form="edit-form"
                className="theme-button-spec-positive"
                onClick={() => {
                  setSecondBox(true);
                }}
              >
                <div className="theme-button-container">
                  <div />
                  <span>{asText(btn_twofa_label)}</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      )}
      {!twofaEnabled && secondBox && (
        <div className="section-twofa detail">
          <div className="section-corners">
            <div className="section-padding">
              <form id="enable-form" onSubmit={handleSubmit(onSubmit)} className="section-left">
                <div className="section-psw-title">
                  <PrismicRichText render={twofa_detail_title} />
                </div>
                <div className="section-psw-desc">
                  <PrismicRichText render={twofa_detail_desc} />
                </div>
                <div className="section-psw-code-mobile">
                  <Popup
                    trigger={(): JSX.Element => (
                      <div className="carousel-box-coordinates">
                        <p className="qr-text"> {seedInfo?.seed?.slice(0, 25) + '...'}</p>
                        <CopyToClipboard text={seedInfo?.seed}>
                          <p className="qr-link">COPY</p>
                        </CopyToClipboard>
                      </div>
                    )}
                    className="copy-coordinates-popup"
                    position="top center"
                    closeOnDocumentClick
                    {...{ contentStyle, overlayStyle, arrowStyle }}
                  >
                    <div className="link-container">
                      <p>{'Text copied!'}</p>
                    </div>
                  </Popup>
                </div>
                <div className="request-enter">
                  <PrismicRichText render={twofa_request} />
                </div>
                <AuthCode
                  length={6}
                  onChange={handleOnChange}
                  containerClassName="input-group"
                  allowedCharacters={'numeric'}
                  inputClassName="input"
                />
                <button type="submit" form="enable-form" className="theme-button-spec-positive">
                  <div className="theme-button-container">
                    <div />
                    <span>{asText(btn_twofa_label)}</span>
                  </div>
                </button>
              </form>
              <div className="section-right">
                <div className="qr-container">
                  <div className="qr-image" id="qr-image">
                    <QRCode value={seedInfo?.provisionUri} renderAs="canvas" size={188} id="qrCanvas" />
                  </div>
                  <Popup
                    trigger={(): JSX.Element => (
                      <div className="carousel-box-coordinates">
                        <CopyToClipboard text={seedInfo?.seed}>
                          <p className="qr-link">{asText(copy_link_label)}</p>
                        </CopyToClipboard>
                      </div>
                    )}
                    className="copy-coordinates-popup"
                    position="top center"
                    closeOnDocumentClick
                    {...{ contentStyle, overlayStyle, arrowStyle }}
                  >
                    <div className="link-container">
                      <p>{'Text copied!'}</p>
                    </div>
                  </Popup>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {twofaEnabled && (
        <div className="section-twofa turn-off">
          <div className="section-corners">
            <div className="section-padding">
              <div className="section-psw-title">
                <PrismicRichText render={twofa_title} />
              </div>
              <div className="section-psw-desc m-off">
                <PrismicRichText render={twofa_description_desactivation} />
              </div>
              <form
                className="registration-form"
                id="edit-form"
                onSubmit={handleSubmit(onTurnOffSubmit)}
                autoComplete="off"
              >
                <div className="row">
                  <div className="col col-6">
                    {renderColumn(
                      'passwordOff',
                      'password',
                      'desactivation',
                      pswOffWatch,
                      'icon-psw',
                      'password',
                      128,
                      1,
                    )}
                  </div>
                  <div className="col col-6">
                    <button type="submit" form="edit-form" className="theme-button-spec-positive">
                      <div className="theme-button-container">
                        <div />
                        <span>{asText(btn_twofa_label_desactivate)}</span>
                      </div>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default AccountSecurity;
