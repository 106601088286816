import axios from 'axios';

export const retriveUser = async (id: string, token: string): Promise<any> => {
  try {
    const result = await axios.get(`/api/users/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result?.data;
  } catch (err) {
    console.log(err);
  }
};
