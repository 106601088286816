import React, { useEffect, useState } from 'react';
import Media from 'react-media';
import 'react-multi-carousel/lib/styles.css';
import Popup from 'reactjs-popup';
import PrismicRichText, { asText } from '../../../services/prismic/prismic.richtext.service';
import { replaceLink } from '../../../services/utils/utils.service';
import { HomeBlockComponent, MenuBlock } from './home.block.component';
import Carousel from 'react-multi-carousel';
import LinkComponent from '../../link/link.component';
import YoutubeVideo from '../../partials/video-youtube.partial-component';
import ScrollAnimation from 'react-animate-on-scroll';

export default function HomePageMiddle(props: any): JSX.Element {
  const {
    result: { reviews, watch_button, watch_du_video, get_started_button, intro_video },
    data: {
      reboot_title,
      reboot_description,
      watch_trailer_label,
      watch_trailer_link,
      reboot_builder_button_label,
      reboot_merchant_button_label,
      reboot_fighter_button_label,
      reboot_leader_button_label,
    },
    contents,
  } = props.prismic;

  const {
    results: [
      {
        data: { body },
      },
    ],
  } = contents;

  const home_blocks = (body.find((b: any) => b.slice_type === 'home_blocks') || { items: [] }).items;

  const [modalVideo, setModalVideo] = useState('');

  const openModal = (link: string): void => {
    if (document.body.classList && document.body.classList.add) {
      document.body.classList.add('modal-open');
    }
    setModalVideo(replaceLink(link));
  };

  const closeModal = (): void => {
    if (document.body.classList && document.body.classList.remove) {
      document.body.classList.remove('modal-open');
    }
    setModalVideo('');
  };
  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        closeModal();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);
  const reviewsDisplayed = (reviews || []).map(
    ({ title, description }: { title: string; description: string }, index: number): JSX.Element => (
      <ScrollAnimation
        className="fullWidth"
        animateIn="fadeInUp"
        animateOnce={true}
        key={index}
        delay={(index * 400) / 2}
        duration={0.3}
      >
        <div className="contentbox" key={index}>
          <div className="squarebox">
            <div className="realcontent"> </div>
            <p className="title">{title}</p>
            <p className="description">{description}</p>
          </div>
        </div>
      </ScrollAnimation>
    ),
  );

  const SmallBlock = ({ block }: { block: MenuBlock }): JSX.Element => (
    <div className={'content'}>
      <p className="identifier">{asText(block.blade)}</p>
      <p className={'title'}>{asText(block.block_title)}</p>
      <p className={'description'}>{asText(block.description)}</p>
    </div>
  );

  const rebootSection = (): JSX.Element => {
    return (
      <div className="reboot-section">
        <div className="reboot-content">
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <div className="reboot-title">
              <PrismicRichText render={reboot_title}></PrismicRichText>
            </div>
          </ScrollAnimation>
          <div className="reboot-title mobile">
            <p>{asText(reboot_title)}</p>
          </div>
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={200} offset={100}>
            <div className="reboot-desc">
              <PrismicRichText render={reboot_description}></PrismicRichText>
            </div>
          </ScrollAnimation>
          <div className="reboot-desc mobile">
            <p>{asText(reboot_description)}</p>
          </div>
          <ScrollAnimation className="fullWidth" animateIn="fadeInUp" animateOnce={true} delay={400} offset={100}>
            <button
              className="theme-button btn-watch"
              onClick={() => {
                if (watch_trailer_link?.url) openModal(watch_trailer_link?.url);
              }}
            >
              <div className="theme-button-container">
                <div></div>
                <span>{asText(watch_trailer_label)}</span>
              </div>
            </button>
            <button
              className="theme-button  btn-watch"
              onClick={(): void => {
                document.body.classList.add('modal-open');
                openModal(intro_video);
              }}
            >
              <div className="theme-button-container">
                <div />
                <span>{watch_du_video}</span>
              </div>
            </button>
          </ScrollAnimation>
          <div className="btn-group-pillars">
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={200} offset={50} duration={0.3}>
              <a className="theme-button" href="#builders">
                <div className="theme-button-container">
                  <span>{asText(reboot_builder_button_label)}</span>
                </div>
              </a>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={400} offset={50} duration={0.3}>
              <a className="theme-button" href="#merchants">
                <div className="theme-button-container">
                  <span>{asText(reboot_merchant_button_label)}</span>
                </div>
              </a>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={600} offset={50} duration={0.3}>
              <a className="theme-button" href="#fighters">
                <div className="theme-button-container">
                  <span>{asText(reboot_fighter_button_label)}</span>
                </div>
              </a>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={800} offset={50} duration={0.3}>
              <a href="#leaders" className="theme-button">
                <div className="theme-button-container">
                  <span>{asText(reboot_leader_button_label)}</span>
                </div>
              </a>
            </ScrollAnimation>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="homePageMiddle">
      {modalVideo && (
        <Popup modal closeOnDocumentClick={false} open={true}>
          <div className={'popUpContainer'}>
            <div className={'exitIcon'} onClick={closeModal} />
            {!modalVideo?.includes('watch') && (
              <video controls autoPlay controlsList="nodownload">
                <source src={modalVideo} type="video/mp4" />
                <track default kind="captions" srcLang="en" src="" />
              </video>
            )}
            {modalVideo?.includes('watch') && <YoutubeVideo videoURL={modalVideo} size={'long'} />}
          </div>
        </Popup>
      )}

      <div className={'spacer'} />

      <Media
        queries={{
          small: '(max-width: 479px)',
          large: '(min-width: 480px)',
        }}
      >
        {(matches: any): JSX.Element => (
          <>
            {matches.large && (
              <>
                <div className="scrollSection">
                  <Carousel
                    autoPlay={true}
                    autoPlaySpeed={5000}
                    transitionDuration={1000}
                    keyBoardControl={true}
                    infinite={true}
                    itemClass="carousel-item-padding"
                    responsive={{
                      desktop: {
                        breakpoint: { max: 4000, min: 480 },
                        items: 3,
                      },
                    }}
                  >
                    {reviewsDisplayed}
                  </Carousel>
                </div>
                {rebootSection()}
                {home_blocks.map((block: MenuBlock, key: number) => (
                  <HomeBlockComponent
                    key={key}
                    index={key + 1}
                    block={block}
                    openModal={openModal}
                    reviewsDisplayed={key === 0 ? reviewsDisplayed : null}
                    prismic={props.prismic}
                  />
                ))}
              </>
            )}

            {Boolean(home_blocks.length && matches.small) && (
              <>
                <div className={'partOne'}>
                  <div className={'scrollSection'}>{reviewsDisplayed}</div>
                  <div className={'square'}>
                    <div className={'squareLineOne'} />
                    <div className={'squareLineTwo'} />
                  </div>
                  <SmallBlock block={home_blocks[0]} />
                </div>
                {rebootSection()}
                <div className={'partMobileHome'}>
                  {home_blocks.map(
                    (block: MenuBlock, key: number): JSX.Element => (
                      <React.Fragment key={key}>
                        <div className="imageData" style={{ backgroundImage: `url(${block.image.url}` }} />
                        <SmallBlock block={block} />
                        <div className="video-link">
                          {block.video_link?.url && (
                            <button
                              className="theme-button"
                              onClick={(): void => {
                                if (block.video_link?.url) openModal(block.video_link?.url);
                              }}
                            >
                              <div className="theme-button-container">
                                <div className="icon" />
                                <span>{watch_button}</span>
                              </div>
                            </button>
                          )}
                          <LinkComponent link={block?.pillar_link?.url || '/buy'}>
                            <button className="theme-button-spec-positive joinBeta green-gradient-btn">
                              <div className="theme-button-container">
                                <div />
                                <span>{get_started_button}</span>
                              </div>
                            </button>
                          </LinkComponent>
                        </div>
                      </React.Fragment>
                    ),
                  )}
                </div>
              </>
            )}
          </>
        )}
      </Media>
    </div>
  );
}
