import { useEffect, useState } from 'react';
import { useAppDispatch } from '../stores/app.store.config';
import { useContents } from '../stores/selectors/content.selector';
import { useI18n } from '../stores/selectors/i18n.selector';
import usePreview from '../services/prismic/prismic.preview.service';
import { fetchContents } from '../stores/actions/content.action';
import { PrismicService } from '../services/prismic/prismic.service';
import { useLandingBuyHome, useLandingPageService } from '../services/optimization/landingpagecheck';
import { useRemoteConfiguration } from '../stores/selectors/remote-configuration.selector';
import config from '../../config';

export interface UsePrismic {
  loading: boolean;
  hasErrors: boolean;
  result: Record<string, any>;
  contents: any;
  items: any;
  data: any;
}

export const usePrismic = (type: string): UsePrismic => {
  const dispatch = useAppDispatch();
  const { contents: contentsRaw, loading, hasErrors } = useContents();

  const contents = contentsRaw[type];
  const { locale } = useI18n();

  const preview = usePreview();

  const document = useLandingPageService();

  const buyone = useLandingBuyHome();

  const { remoteConfig, loaded } = useRemoteConfiguration();

  useEffect(() => {
    if (dispatch && loaded && config.nodeEnv !== 'test') {
      const fetchData = async (): Promise<void> => {
        if (document && buyone) {
          dispatch(fetchContents({ locale, type, preview, document, buyone }));
        } else if (document) {
          dispatch(fetchContents({ locale, type, preview, document }));
        } else {
          dispatch(fetchContents({ locale, type, preview }));
        }
      };
      if (!contents || !Object.keys(contents).length) {
        fetchData();
      }
    }
  }, [dispatch, locale, preview, type, document, buyone, loaded]);

  const [result, setResult] = useState<Record<string, any>>({});
  const [items, setItems] = useState({});
  useEffect(() => {
    if (!loading && !hasErrors && contents?.results?.[0] && remoteConfig && config.nodeEnv !== 'test') {
      const prismicService = new PrismicService(remoteConfig);
      setResult(prismicService.translateData(contents));
      setItems(prismicService.getItems(contents));
    }
  }, [contents, loading, hasErrors, remoteConfig]);

  let data = {};
  if (contents?.results?.[0]) {
    data = contents.results[0].data;
  }
  if (contents?.results?.length > 1) {
    data = contents.results.map((r: any) => ({ id: r.id, ...r.data }));
  }

  return { loading, hasErrors, result, contents, items, data };
};
