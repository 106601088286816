// import { PrismicDocument } from '../prismic-api-response.model';
import ApiSearchResponse from 'prismic-javascript/types/ApiSearchResponse';

import { StructuredTextFieldModel, ImageFieldModel } from '../prismic-fields.model';
import { Document } from 'prismic-javascript/types/documents';

export interface PrismicResponse extends ApiSearchResponse {
  results: ContentInterface[];
}

export interface ContentResultInterface {
  title: StructuredTextFieldModel[];
  body: StructuredTextFieldModel[];
  image: ImageFieldModel;
  meta_title?: string;
  meta_description?: string;
  canonical?: Record<any, any>;
}

export interface ContentInterface extends Document {
  data: ContentResultInterface;
}

export interface ContentState {
  loading: boolean;
  hasErrors: boolean;
  contents: any;
  error: any;
}

export const initialContentState: ContentState = {
  contents: { results: [] },
  hasErrors: false,
  loading: false,
  error: null,
};
