import axios from 'axios';
import config from '../../../../config';
import { User } from '../../../models/authentication/auth.model';

export const getUploads = async ({ user }: { user: User }): Promise<any> => {
  if (user) {
    try {
      const result = await axios.get(`/api/assets/player`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      return result.data;
    } catch (err) {
      if (config.local) {
        console.log('error with get uploads', err);
      }
      throw err;
    }
  }
  return [];
};

export const uploadFile = async (file: any, user: User, rejectWithValue: any): Promise<any> => {
  const formData = new FormData();
  formData.append('files', file);
  const opts = {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${user.token}`,
    },
  };
  if (file.size > 5000000) throw rejectWithValue({ code: 27, message: 'error_file_size' });

  const response = await axios.post(`/api/assets/player`, formData, opts);
  return response.data;
};

export const deleteFile = async (assetKey: string, user: User): Promise<boolean> => {
  try {
    const opts = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    await axios.delete(`/api/assets/player/${encodeURIComponent(assetKey)}`, opts);
  } catch (err) {
    if (config.local) {
      console.log('error with deleteFile', JSON.stringify(err));
    }
    return false;
  }
  return true;
};
