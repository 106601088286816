import React from 'react';
import { usePrismic } from '../../../hooks/prismic.hook';
import { asText } from '../../../services/prismic/prismic.richtext.service';

export default function NewsStatusComponent(): JSX.Element {
  const prismic = usePrismic('server_status');
  if (!prismic.items.server_status) return <></>;
  const {
    items: {
      server_status: {
        body: [{ items: status_news }],
      },
    },
  } = prismic;

  return (
    <section className="news-status-section">
      {status_news?.map(
        (
          { status_news_title, status_news_description, status_news_image, status_news_link, status_news_date }: any,
          key: number,
        ) => {
          return (
            <div className="server-status-news-container" key={key}>
              <a
                className="server-status-news-link"
                target="_blank"
                rel="noopener noreferrer"
                href={status_news_link?.url}
              >
                <div className="server-status-news-border-container">
                  <div className="server-status-news-corners">
                    <div className="server-status-news-content">
                      <div className="server-status-news-image-container">
                        <img className="server-status-news-image" src={status_news_image.url} alt="Article" />
                      </div>
                      <div className="server-status-news-container-text">
                        <p className="server-status-news-title">{asText(status_news_title)}</p>
                        <p className="server-status-news-desc">{asText(status_news_description)}</p>
                        <p className="server-status-news-date">{status_news_date}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          );
        },
      )}
    </section>
  );
}
