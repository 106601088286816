import React from 'react';
import { useNews } from '../../stores/selectors/news.selector';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import BlockComponent from '../news/news-sections/news-block.component';
import PressNavigationComponent from './press-sections/press-navigation';
import { usePrismic } from '../../hooks/prismic.hook';
import config from '../../../config';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { getSlug } from '../news/news.service';
import { getUrlFromEnglish } from '../../services/i18n/i18n.url.service';
import RichText, { asText } from '../../services/prismic/prismic.richtext.service';
import Scrollspy from 'react-scrollspy';
import LinkComponent from '../link/link.component';
import { useRemoteConfiguration } from '../../stores/selectors/remote-configuration.selector';

const PressContactComponent = (): JSX.Element | null => {
  const [message, setMessage] = useState('');
  const { handleSubmit, register, reset, errors } = useForm();
  const { contents: newsStories } = useNews();
  const [link, setLink] = useState('one');
  const { remoteConfig } = useRemoteConfiguration();

  const responsive = {
    desktop: {
      breakpoint: { max: 4000, min: 480 },
      items: 3,
    },

    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1,
    },
  };

  const {
    result: {
      page_title,

      press_releases_title,
      contact_title,
      menu_contact,
      menu_press_releases,
      menu_press_kit,

      error_about,
      error_name,
      error_email,
      error_message,
      label_who,
      label_about,
      label_name,
      label_email,
      label_message,
      send_button,
      send_success,
      send_loading,
      send_error,

      social_title,
      presskit_title,

      no_releases_error,

      press_kit_button,
      press_kit_button_link,
      community_title,
      community_description,
    },
    contents,
  } = usePrismic('contact');
  //dummie comment
  const {
    result: { required },
  } = usePrismic('common');

  if (!contents) return <></>;

  const {
    results: [
      {
        data: { page_description, sub_message, presskit_description, options, contacts },
      },
    ],
  } = contents;

  if (!newsStories) return null;

  const news = newsStories?.filter((result: any) => {
    return result.type === 'press release';
  });

  const onSubmit = async (values: Record<string, any>): Promise<void> => {
    const { senderName, senderEmail, subject, message } = values;

    try {
      const url = '/api/press';
      await axios.post(
        url,
        {
          senderName,
          senderEmail,
          subject,
          message,
        },
        {
          headers: { 'Content-Type': 'application/json' },
        },
      );
      reset();
      setMessage('loading');
      setTimeout(() => {
        setMessage('valid');
      }, 2000);
      setTimeout(() => {
        setMessage('');
      }, 5000);
    } catch (err) {
      setMessage('error');
    }
  };
  const renderPosts = (): JSX.Element[] | JSX.Element => {
    if (news.length === 0) return <p className="message">{no_releases_error}</p>;
    const myPosts: JSX.Element[] = news.map((result: any) => (
      <div key={getSlug(result)}>
        <div
          className="news-wapper-contact"
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(12, 19, 21, 0) 28%, #0c1315 67%),url(${result.image.url})`,
          }}
        >
          <LinkComponent link={`${getUrlFromEnglish('/contact')}/${getSlug(result)}`}>
            <article key={result.id} className="news-block">
              <div className="real-content">
                <div className="news-header">
                  <p> {result.type}</p>
                </div>

                <div className="news-content">
                  <BlockComponent
                    // route={`/news/${getSlug(result)}`}
                    result={result}
                    showContent={true}
                  />
                </div>
              </div>
            </article>
          </LinkComponent>
        </div>
      </div>
    ));
    return myPosts;
  };

  return (
    <div className="press-content-container">
      <div className="content-navigation">
        <div className="content-wrapper">
          <Scrollspy items={['intro', 'releases', 'download']} currentClassName="active">
            <a
              href="#intro"
              className={link === 'one' ? 'active' : ''}
              onClick={(): any => {
                setLink('one');
              }}
            >
              {menu_contact}
            </a>
            <a
              href="#releases"
              className={link === 'two' ? 'active' : ''}
              onClick={(): any => {
                setLink('two');
              }}
            >
              {menu_press_releases}
            </a>
            <a
              href="#download"
              className={link === 'three' ? 'active' : ''}
              onClick={(): any => {
                setLink('three');
              }}
            >
              {menu_press_kit}
            </a>
          </Scrollspy>
        </div>
      </div>
      <PressNavigationComponent />
      <div className="intro-form" id="intro">
        <div className="column-one">
          <h1>{page_title} </h1>
          <div className="subtitle">
            <RichText render={page_description} />
          </div>
          <div className="knowledge">
            <RichText render={sub_message} />
          </div>
        </div>
        <div className="column-two">
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className="row">
              <div className="input-group">
                <label htmlFor="">{label_who}</label>
                <div className="input-with-icons">
                  <div className="icon icon-comment"></div>
                  <select
                    name={'subject'}
                    ref={register({
                      required,
                    })}
                  >
                    {options.map(({ option }: any, key: number) => (
                      <option key={key} value={asText(option)}>
                        {asText(option)}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="input-group">
                <label htmlFor="">{label_about}</label>
                <div className={errors.about ? 'input-with-icons error' : 'input-with-icons'}>
                  <div className="icon icon-dots"></div>
                  <input
                    type="text"
                    name={'about'}
                    ref={register({
                      required,
                    })}
                  />
                  <div className={errors.about ? 'icon icon-reload icon-error' : 'icon icon-reload'}></div>
                </div>
                {errors.about && (
                  <>
                    <div className="arrow"></div>
                    <div className="yellow-notice">
                      <p> {error_about}</p>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="row">
              <div className="input-group">
                <label htmlFor="">{label_name}</label>
                <div className={errors.senderName ? 'input-with-icons error' : 'input-with-icons'}>
                  <div className="icon icon-user"></div>
                  <input
                    type="text"
                    name={'senderName'}
                    ref={register({
                      required,
                    })}
                  />
                  <div className={errors.senderName ? 'icon icon-reload icon-error' : 'icon icon-reload'}></div>
                </div>
                {errors.senderName && (
                  <>
                    <div className="arrow"></div>
                    <div className="yellow-notice">
                      <p> {error_name}</p>
                    </div>
                  </>
                )}
              </div>
              <div className="input-group">
                <label htmlFor="">{label_email}</label>
                <div className={errors.senderEmail ? 'input-with-icons error' : 'input-with-icons'}>
                  <div className="icon icon-email"></div>
                  <input
                    type="email"
                    name={'senderEmail'}
                    ref={register({
                      required,
                      pattern: config.validation.email,
                    })}
                  />
                  <div className={errors.senderEmail ? 'icon icon-reload icon-error' : 'icon icon-reload'}></div>
                </div>
                {errors.senderEmail && (
                  <>
                    <div className="arrow"></div>
                    <div className="yellow-notice">
                      <p> {error_email}</p>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="row complete">
              <div className="input-group">
                <label htmlFor="">{label_message}</label>
                <div>
                  <textarea
                    className={errors.message ? 'input-with-icons-big error' : 'input-with-icons-big'}
                    name={'message'}
                    ref={register({
                      required,
                    })}
                  />
                </div>
                {errors.message && (
                  <>
                    <div className="arrow"></div>
                    <div className="yellow-notice">
                      <p> {error_message}</p>
                    </div>
                  </>
                )}
              </div>
            </div>
            <button type="submit" className="theme-button">
              <div className="theme-button-container">
                {!message && <div className="icon-send"></div>}
                {message === '' && <span className="success">{send_button}</span>}
                {message === 'valid' && <div className="icon-send success"></div>}
                {message === 'loading' && <div className="icon-send loading"></div>}
                {message === 'error' && <div className="icon-send error"></div>}
                {message === 'valid' && <span className="success">{send_success}</span>}
                {message === 'loading' && <span className="loading">{send_loading}</span>}
                {message === 'error' && <span className="error">{send_error}</span>}
              </div>
            </button>
          </form>
          <div className="column-one-visible">
            <RichText render={sub_message} />
          </div>
        </div>
      </div>
      <div className="list-social">
        <div className="footerIcon">
          <h3>{social_title}</h3>
          <div>
            <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/dualuniverse">
              <span className="iconLogoTwitter " />
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/dualthegame/">
              <span className={'iconLogoInstagram '} />
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://facebook.com/dualuniverse">
              <span className={'iconLogoFacebook '} />
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://youtube.com/dualuniverse">
              <span className={'iconLogoYoutube '} />
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://twitch.tv/dualuniverse">
              <span className={'iconLogoTwitch '} />
            </a>
            <a target="_blank" rel="noopener noreferrer" href=" https://discord.com/invite/dualuniverse">
              <span className={'iconLogoDiscord '} />
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://www.reddit.com/r/DualUniverse/">
              <span className={'iconLogoReddit '} />
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://soundcloud.com/dualuniverse">
              <span className={'iconLogoCloud '} />
            </a>
          </div>
        </div>
      </div>

      <div className="list-emails">
        <h2>{contact_title}</h2>
        <div className="emails-blocks">
          {contacts.map(({ name, job_title, email }: any, key: number) => (
            <div key={key} className="block">
              <h5>{asText(name)}</h5>
              <p>{asText(job_title)}</p>
              <a href={`mailto:${asText(email)}`}>{asText(email)}</a>
            </div>
          ))}
        </div>
      </div>
      {/* <div className="press-release-image" /> */}

      <div className="carousel-press-releases" id="releases">
        <h2>{press_releases_title}</h2>
        <Carousel
          infinite={true}
          autoPlay={false}
          autoPlaySpeed={5000}
          transitionDuration={1000}
          keyBoardControl={true}
          responsive={responsive}
          itemClass="carousel-item-padding"
        >
          {renderPosts()}
        </Carousel>
      </div>

      <div className="download-press-kit">
        <div className="download-box-line"></div>
        <div className="download-content">
          <div className="black-box"></div> <div id="download"></div>
          <h2>{presskit_title}</h2>
          <RichText render={presskit_description} />
          <a href={press_kit_button_link}>
            <button className="theme-button">
              <div className="theme-button-container">
                <div className="icon-download"></div>
                <span>{press_kit_button}</span>
              </div>
            </button>
          </a>
        </div>
      </div>
      <div className="press-contact-footer">
        <div className="logo-image"></div>
        <a href={`${remoteConfig.COMMUNITY_URL}`} target="_blank" rel="noopener noreferrer">
          <div className="connect-comunity">
            <div className="corners"> </div>
            <div className="real-content">
              <div className="image-users"></div>
              <div className="text-wrapper">
                <p className="title">{community_title}</p>
                <p className="subtitle">{community_description}</p>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};
export default PressContactComponent;
