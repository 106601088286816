import React from 'react';
import ReferalPresentationComponent from './referal-blocks/referal-presentation.component';
import ReferalHowComponent from './referal-blocks/referal-how.component';
import ReferalRewardFriendComponent from './referal-blocks/referal-rewards-friends.component';
import ReferalRewardYourComponent from './referal-blocks/referal-rewards-your.component';
import ReferalFaqComponent from './referal-blocks/referal-faq.component';
import { usePrismic } from '../../hooks/prismic.hook';

export default function ReferalHomeComponent(): JSX.Element {
  const prismic = usePrismic('recruit_a_friend');

  if (!prismic.items.recruit_a_friend) return <></>;

  return (
    <section className="referal-container-section">
      <ReferalPresentationComponent />
      <ReferalHowComponent />
      <ReferalRewardFriendComponent />
      <ReferalRewardYourComponent />
      <ReferalFaqComponent />
    </section>
  );
}
