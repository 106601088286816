import * as Sentry from '@sentry/browser';
import { useEffect } from 'react';
import { useRemoteConfiguration } from '../../stores/selectors/remote-configuration.selector';

export const useSetupSentry = (): void => {
  const { remoteConfig } = useRemoteConfiguration();
  useEffect(() => {
    if (remoteConfig) {
      Sentry.init({
        dsn: remoteConfig.SENTRY_DNS,
        environment: remoteConfig.ENV,
        ignoreErrors: [
          'TypeError: Failed to fetch',
          'TypeError: NetworkError when attempting to fetch resource.',
          'TypeError: Cancelled',
          'ResizeObserver loop limit exceeded',
        ],
        beforeSend(event) {
          if (event.user) {
            // Don't send user's email address, ip or username
            delete event.user.ip_address;
            delete event.user.username;
            delete event.user.email;
          }
          return event;
        },
      });
    }
  }, [remoteConfig]);
};
