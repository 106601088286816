import { createAsyncThunk } from '@reduxjs/toolkit';
import { SignupResult, User } from '../../models/authentication/auth.model';
import { getUser } from '../../services/authentication/attributes.service';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { doRefreshToken, getAllPlans } from '../../services/authentication/signin.retrieval';
import { getGameAccess } from '../../services/authentication/authentication.gameaccess.service';
import { setTokenCookies } from '../../services/authentication/authentication.cookies.service';
import { retriveUser } from '../../services/retrieval/user.retrieval';

const serialize = (obj: any): any => JSON.parse(JSON.stringify(obj));

export const signIn = createAsyncThunk(
  'auth/signIn',
  async ({ email, password, code, device }: any, { rejectWithValue }): Promise<any> => {
    try {
      const result = await axios.post('/api/auth/login', {
        email,
        password,
        otp: code,
        device,
        source: 'WWW',
      });

      const { accessToken, communityId } = result.data;

      const decoded: Record<string, any> = jwt_decode(accessToken);

      const allPlans = await getAllPlans(decoded, accessToken);

      const gameTimeMonths = getGameAccess(accessToken);

      const details = await retriveUser(communityId, accessToken);

      result.data.token = accessToken;

      const userInfo = { ...result.data, ...decoded, allPlans, gameTimeMonths, details };

      return serialize(userInfo);
    } catch (err: any) {
      console.log(err);
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ Code: err.response.status, ...err.response.data });
    }
  },
);

export const ghostSignUp = createAsyncThunk(
  'auth/ghostSignUp',
  async ({ email, ref, siteid }: { email: string; ref?: string; siteid?: string }): Promise<SignupResult> => {
    const acquisitionGaUtm = window.location.search;
    try {
      const result = await axios.post('/api/register/ghost', {
        email,
        acquisitionCpmstarReferrer: ref,
        acquisitionCpmstarSiteId: siteid,
        acquisitionGaUtm,
      });

      const decoded: Record<string, any> = jwt_decode(result.data.token);

      return { ...result.data, ...decoded };
    } catch (error: any) {
      if (error.response && error.response.data) {
        return { error: error.response.data };
      }
      return null;
    }
  },
);

export const refreshToken = createAsyncThunk('auth/refreshToken', async (): Promise<User> => {
  const user = getUser();
  const { token, refreshToken } = await doRefreshToken(user.token, user.refreshToken);
  setTokenCookies({ token, refreshToken });
  //const decoded = await decodeJWT(token);
  const decoded: Record<string, any> = jwt_decode(token);
  const userInfo = {
    ...user,
    ...decoded,
    token,
    refreshToken,
  };

  const allPlans = await getAllPlans(decoded, token);

  const details = await retriveUser(decoded?.id, token);

  return { ...userInfo, allPlans, details };
});
