import { createSlice } from '@reduxjs/toolkit';
import { initialLiveopsState } from '../../models/liveops/liveops.model';
import { getServerStatus } from '../actions/liveops.actions';

const slice = createSlice({
  name: 'liveops',
  initialState: initialLiveopsState,
  reducers: {},
  extraReducers: {
    [getServerStatus.fulfilled.type]: (state, { payload }): void => {
      state.status = payload;
      state.loading = false;
      state.error = false;
    },
    [getServerStatus.pending.type]: (state): void => {
      state.loading = true;
      state.error = null;
    },
    [getServerStatus.rejected.type]: (state, { error }): void => {
      state.loading = false;
      state.error = error;
    },
  },
});

export const liveopsReducer = slice.reducer;
