import React from 'react';
import { ResendConfirm } from '../../buypage/buypage-three-blocks/resend-confirm-block/resend-confirm';

const AccountResendConfirmationComponent = (): JSX.Element | null => {
  return (
    <div className="account-resend-confirmation">
      <div className="container">
        <ResendConfirm />
      </div>
    </div>
  );
};

export default AccountResendConfirmationComponent;
