import { RichTextBlock, Link, PrismicDate } from 'prismic-reactjs';

export interface News {
  title: RichTextBlock[];
  type: string;
  image: Link;
  content: RichTextBlock[];
  date: PrismicDate;
  image_text: Link;
}

export interface NewsState {
  contents: News[];
  hasErrors: boolean;
  loading: boolean;
  error: Error | null;
}

export const initialNewsState: NewsState = {
  contents: [],
  hasErrors: false,
  loading: false,
  error: null,
};
