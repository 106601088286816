import { RichTextBlock, PrismicDate } from 'prismic-reactjs';

export interface LauncherNews {
  title: RichTextBlock[];
  image: any;
  description: RichTextBlock[];
  content: RichTextBlock[];
  date: PrismicDate;
}

export interface LauncherNewsState {
  contents: { newsStoriesLauncher: LauncherNews[] };
  hasErrors: boolean;
  loading: boolean;
  error: Error | null;
}

export const initialLauncherNewsState: LauncherNewsState = {
  contents: { newsStoriesLauncher: [] },
  hasErrors: false,
  loading: false,
  error: null,
};
