import { RootState } from '../models/_root-state.model';
import { initialAuthState } from '../models/authentication/auth.model';
import { initialRouterState } from '../models/router/router.model';
import { initial18nState } from '../models/i18n/i18n.model';
import { initialFormResponseState } from '../models/forms/forms.model';
import { initialContentState } from '../models/content/content.model';
import { initialCSRFTokenState } from '../models/forms/csrf.model';
import { initialPlanState } from '../models/plan/plan.model';
import { BetaKeysInitialState } from './beta-keys/keys.model';
import { initialAssetsState } from '../models/assets/assets.model';
import { initialPurchaseState } from '../models/purchase/purchase.model';
import { initialLiveopsState } from '../models/liveops/liveops.model';
import { initialCpmstarState } from '../models/cpmstar/cpmstar.model';
import { initialSubscriptionsState } from '../models/subscriptions/subscriptions.model';
import { initialConfirmState } from '../models/confirm/confirm.model';
import { initialKickstarterState } from '../models/authentication/authentication.kickstarter.model';
import { initialAnalyticsState } from '../models/analytics/analytics.model';
import { initialMediaState } from '../models/media/media.model';
import { initialPlansAvailableState } from './plans-available/plans-available.model';
import { initialNewsState } from './news/news.model';
import { initialLauncherNewsState } from './launcher-news/launcher-news.model';
import { initialReleaseState } from './releases/releases.model';
import { initialForgotState } from '../models/forgotPassword/forgot-password.model';
import { initialState as initialRemoteConfigurationState } from './remote-configuration/remote-configuration.model';

export const defaultStore: RootState = {
  router: initialRouterState,
  i18nBranch: initial18nState,
  formResponse: initialFormResponseState,
  consent: {},
  contentBranch: {
    ...initialContentState,
    contents: {
      results: [],
      tutorials_page: {
        page: 1,
        results_per_page: 20,
        results_size: 1,
        total_results_size: 1,
        total_pages: 1,
        next_page: null,
        prev_page: null,
        results: [
          {
            id: 'X-TU1xEAACAAAU2u',
            uid: null,
            type: 'tutorials_page',
            href: 'https://dualuniverse.cdn.prismic.io/api/v2/documents/search?ref=X-TU2REAACEAAU26&q=%5B%5B%3Ad+%3D+at%28document.id%2C+%22X-TU1xEAACAAAU2u%22%29+%5D%5D',
            tags: [],
            first_publication_date: '2020-12-24T17:50:17+0000',
            last_publication_date: '2020-12-24T17:50:17+0000',
            slugs: ['tutorials-page'],
            linked_documents: [],
            lang: 'en-us',
            alternate_languages: [],
            data: {
              component_title: [
                {
                  type: 'heading1',
                  text: 'Tutorials Page',
                  spans: [],
                },
              ],
              page_title: [
                {
                  type: 'heading1',
                  text: 'Tutorials',
                  spans: [],
                },
              ],
              background_image: {
                dimensions: {
                  width: 1920,
                  height: 1500,
                },
                alt: null,
                copyright: null,
                url: 'https://images.prismic.io/dualuniverse/9e28ac7c-d928-4e55-b62c-6fa064a3354e_image-79.jpg?auto=compress,format',
              },
            },
          },
        ],
        version: '6c549e9',
        license: 'All Rights Reserved',
      },
      buy_page: {
        page: 1,
        results_per_page: 100,
        results_size: 2,
        total_results_size: 2,
        total_pages: 1,
        next_page: null,
        prev_page: null,
        results: [
          {
            id: 'X7WfPhIAACEAhxgx',
            uid: null,
            url: null,
            type: 'buy_page',
            href: 'https://dualuniverse.cdn.prismic.io/api/v2/documents/search?ref=Y4nfBxAAACAAvGLB&q=%5B%5B%3Ad+%3D+at%28document.id%2C+%22X7WfPhIAACEAhxgx%22%29+%5D%5D',
            tags: ['website'],
            first_publication_date: '2020-11-18T22:25:05+0000',
            last_publication_date: '2022-09-28T14:08:31+0000',
            slugs: ['buy-page-main'],
            linked_documents: [],
            lang: 'en-us',
            alternate_languages: [
              { id: 'X7atjxIAACIAi9yv', type: 'buy_page', lang: 'de-de' },
              { id: 'X7asihIAACEAi9gV', type: 'buy_page', lang: 'fr-fr' },
            ],
            data: {
              component_title: [{ type: 'heading1', text: 'Buy Page (main)', spans: [] }],
              landing_page_id: 'main',
              landing_buy_page_id: 'buy',
              campaign_name: 'Release',
              page_title: [{ type: 'heading1', text: 'PLAY DUAL UNIVERSE FOR ONLY', spans: [] }],
              steam_btn_left_label: [{ type: 'paragraph', text: 'Buy on Steam', spans: [] }],
              steam_btn_left_link: {
                link_type: 'Web',
                url: 'https://store.steampowered.com/app/2000270/Dual_Universe/',
              },
              steam_btn_right_label: [{ type: 'paragraph', text: 'Try DU for free', spans: [] }],
              steam_btn_right_link: {
                link_type: 'Web',
                url: 'https://store.steampowered.com/app/2000270/Dual_Universe/',
              },
              month: [{ type: 'paragraph', text: 'month', spans: [] }],
              join: [{ type: 'paragraph', text: 'join', spans: [] }],
              sold: [{ type: 'paragraph', text: '% SOLD', spans: [] }],
              max: [{ type: 'paragraph', text: 'MAX', spans: [] }],
              click_here: [{ type: 'paragraph', text: 'click here', spans: [] }],
              stay_informed: [{ type: 'paragraph', text: 'If you want to stay informed', spans: [] }],
              available_soon: [{ type: 'paragraph', text: 'Available Soon', spans: [] }],
              existing_plan_disclaimer: [
                {
                  type: 'paragraph',
                  text: 'As a user with an existing plan, any changes to your plan will start once your plan expires',
                  spans: [],
                },
              ],
              limited_time_offer: [{ type: 'paragraph', text: '', spans: [] }],
              disclaimer_yellow: [
                {
                  type: 'paragraph',
                  text: 'All plans give you full access to the game.  Taxes are included. The game is available in English, French and German.',
                  spans: [{ start: 45, end: 63, type: 'strong' }],
                },
              ],
              why_header: [{ type: 'heading3', text: 'Why subscription?', spans: [] }],
              why_message: [
                {
                  type: 'paragraph',
                  text: 'We thought hard about what the best business model would be for our',
                  spans: [],
                },
                {
                  type: 'paragraph',
                  text: 'game. Due to significant server costs when building a vast, shared',
                  spans: [],
                },
                {
                  type: 'paragraph',
                  text: 'universe such as Dual Universe, and since we hope the game remains live',
                  spans: [],
                },
                {
                  type: 'paragraph',
                  text: 'for years to come, we must ensure a steady revenue stream. But we',
                  spans: [],
                },
                {
                  type: 'paragraph',
                  text: 'wanted this subscription model to be accessible to as many players as',
                  spans: [],
                },
                {
                  type: 'paragraph',
                  text: 'possible. This page combines the result of that long thought process. We',
                  spans: [],
                },
                {
                  type: 'paragraph',
                  text: 'hope you understand that this is the most balanced solution for an',
                  spans: [],
                },
                { type: 'paragraph', text: 'independent studio such as ours. Thank you for your support!', spans: [] },
              ],
              best_value: [{ type: 'heading6', text: 'BEST VALUE', spans: [] }],
              specs_header: [{ type: 'heading3', text: 'MINIMUM & RECOMMENDED SPECS', spans: [] }],
              bubble_windows11: [
                {
                  type: 'paragraph',
                  text: 'ABOUT WINDOWS 11: We have tested the Windows 11 OS for compatibility with Dual Universe.',
                  spans: [{ start: 0, end: 18, type: 'strong' }],
                },
              ],
              limit_not_reached: [
                {
                  type: 'paragraph',
                  text: 'We are currently accepting a limited number of new players in Dual Universe. Join now!',
                  spans: [],
                },
              ],
              limit_reached: [
                {
                  type: 'paragraph',
                  text: 'Sorry! We’ve maxed out the number of new players we are currently accepting in Dual Universe. We will add more room for new players soon',
                  spans: [],
                },
              ],
              popup_header: [{ type: 'heading1', text: 'JOIN THE WAITING LIST', spans: [] }],
              popup_disclaimer: [
                {
                  type: 'paragraph',
                  text: 'The data you provide will be handled in accordance with our Privacy Policy.',
                  spans: [
                    {
                      start: 60,
                      end: 74,
                      type: 'hyperlink',
                      data: { link_type: 'Web', url: 'https://www.dualuniverse.game/legal/privacy-policy' },
                    },
                  ],
                },
              ],
              choose_pack: [{ type: 'paragraph', text: 'Choose your pack', spans: [] }],
              confirm_pack: [{ type: 'paragraph', text: 'Confirm your selection', spans: [] }],
              checkout: [{ type: 'paragraph', text: 'Checkout', spans: [] }],
              continue_button: [{ type: 'paragraph', text: 'Continue', spans: [] }],
              recommended: [{ type: 'paragraph', text: 'Recommended', spans: [] }],
              limited_offer_label: [{ type: 'paragraph', text: 'Limited Time', spans: [] }],
              total: [{ type: 'paragraph', text: 'Total', spans: [] }],
              save: [{ type: 'paragraph', text: 'You save', spans: [] }],
              about_you_title: [
                { type: 'heading5', text: 'To continue your purchase, please enter your e-mail address.', spans: [] },
              ],
              about_you_description: [
                { type: 'paragraph', text: 'This e-mail will be used to log you in the game.', spans: [] },
              ],
              about_you_checkbox1: [
                {
                  type: 'paragraph',
                  text: 'I accept the End User License Agreement.',
                  spans: [
                    {
                      start: 13,
                      end: 39,
                      type: 'hyperlink',
                      data: { link_type: 'Web', url: 'https://www.dualuniverse.game/legal/eula' },
                    },
                  ],
                },
              ],
              about_you_checkbox2: [
                {
                  type: 'paragraph',
                  text: 'I accept that my statutory right of withdrawal ends 14 days from now or once I first connect to the game, whichever happens sooner.',
                  spans: [],
                },
              ],
              already_signed_in: [
                { type: 'paragraph', text: 'Already registered? Use the navbar to login.', spans: [] },
              ],
              error_email_already_used: [{ type: 'paragraph', text: 'Email is already used', spans: [] }],
              error_email_invalid_format: [{ type: 'paragraph', text: 'Invalid Email Address', spans: [] }],
              error_email_required: [{ type: 'paragraph', text: 'Email required', spans: [] }],
              error_created: [
                { type: 'paragraph', text: 'Account already created, please enter your password:', spans: [] },
              ],
              error_match: [{ type: 'paragraph', text: 'The email and the password do not match.', spans: [] }],
              upgrade_notice: [
                {
                  type: 'paragraph',
                  text: 'If you already have active game time and want to upgrade, a credit for your unspent game time will be added on the checkout page.',
                  spans: [],
                },
              ],
              email_tooltip: [{ type: 'paragraph', text: 'Your e-mail address*', spans: [] }],
              email_tooltip_description: [
                {
                  type: 'paragraph',
                  text: 'This email address will be used to create your account and log you in the game. Please make sure to use a valid email address, since it will be used to send you account-related emails, such as password changes.',
                  spans: [],
                },
              ],
              code_description: [{ type: 'paragraph', text: 'Were you recruited by a friend?', spans: [] }],
              recruit_tooltip: [
                {
                  type: 'paragraph',
                  text: 'If you received a Recruiter Code from a friend, please enter it here to get exclusive in-game rewards. Do not use this field to unlock game time if you have a Game Time code.',
                  spans: [],
                },
              ],
              code_placeholder: [{ type: 'paragraph', text: 'Enter your code….', spans: [] }],
              selection_title: [{ type: 'heading3', text: 'Your selection:', spans: [] }],
              product_title: [{ type: 'heading4', text: '{NB} month subscription', spans: [] }],
              product_description: [
                {
                  type: 'paragraph',
                  text: 'Explore Dual Universe with this {NB} months subscription plan, giving you full access to the game.',
                  spans: [],
                },
              ],
              total1: [{ type: 'paragraph', text: 'Total', spans: [] }],
              total_including: [{ type: 'paragraph', text: 'TOTAL (incl. TAXES)', spans: [] }],
              warning: [
                {
                  type: 'paragraph',
                  text: 'By clicking the CHECKOUT button, you acknowledge that you have read and agreed to the Terms and Conditions of the Dual Universe website and to the Privacy Policy of Novaquark. You consent that any orders received are sent with obligation of payment. Your email will be used to create an account and be sent any confirmations regarding these transactions.',
                  spans: [
                    {
                      start: 86,
                      end: 106,
                      type: 'hyperlink',
                      data: { link_type: 'Web', url: 'https://www.dualuniverse.game/legal/terms-and-conditions' },
                    },
                    {
                      start: 147,
                      end: 161,
                      type: 'hyperlink',
                      data: { link_type: 'Web', url: 'https://www.dualuniverse.game/legal/privacy-policy' },
                    },
                  ],
                },
              ],
              problem: [
                {
                  type: 'paragraph',
                  text: 'There was a problem displaying the products. Please refresh the page and try again.',
                  spans: [],
                },
              ],
              confirm_page_title: [{ type: 'heading4', text: 'You’re almost there', spans: [] }],
              notice_disabled_title: [{ type: 'paragraph', text: '', spans: [] }],
              notice_disabled_desc: [
                {
                  type: 'paragraph',
                  text: 'The Dual Universe team is preparing for the launch of the game on September 27. ',
                  spans: [],
                },
                { type: 'paragraph', text: 'The store is closed until then. ', spans: [] },
                { type: 'paragraph', text: 'In the meantime, please wishlist the game on Steam!', spans: [] },
              ],
              notice_disabled_button: [{ type: 'paragraph', text: 'Go to steam', spans: [] }],
              notice_disabled_button_link: {
                link_type: 'Web',
                url: 'https://store.steampowered.com/app/2000270/Dual_Universe/',
              },
              body: [
                {
                  primary: {},
                  items: [
                    {
                      product_id: '9648f0f0-66d8-4ed5-9aa7-8126ec089100',
                      product_name: [{ type: 'paragraph', text: '1 MONTH', spans: [] }],
                      product_note: [],
                      product_description1: [{ type: 'paragraph', text: '1 month subscription', spans: [] }],
                    },
                    {
                      product_id: 'c2b5a3fd-4056-448f-9914-f4c9b43023ee',
                      product_name: [{ type: 'paragraph', text: '3 MONTHS', spans: [] }],
                      product_note: [{ type: 'paragraph', text: 'Save $3', spans: [] }],
                      product_description1: [{ type: 'paragraph', text: '3 months subscription', spans: [] }],
                    },
                    {
                      product_id: '51312037-1205-4e81-b74a-61056faadd44',
                      product_name: [{ type: 'paragraph', text: '6 MONTHS', spans: [] }],
                      product_note: [{ type: 'paragraph', text: 'Save $12', spans: [] }],
                      product_description1: [{ type: 'paragraph', text: '6 months subscription', spans: [] }],
                    },
                    {
                      product_id: 'b8ce87b3-8dd1-474a-bfe7-d0308b9ec782',
                      product_name: [{ type: 'paragraph', text: '12 MONTHS', spans: [] }],
                      product_note: [{ type: 'paragraph', text: 'Save $36', spans: [] }],
                      product_description1: [{ type: 'paragraph', text: '12 months subscription', spans: [] }],
                    },
                  ],
                  id: 'products$41b451b2-b417-42f3-9169-e3e57415b68c',
                  slice_type: 'products',
                  slice_label: null,
                },
                {
                  primary: { section_header: [{ type: 'heading3', text: 'Minimum', spans: [] }] },
                  items: [
                    {
                      type: [{ type: 'heading5', text: 'CPU', spans: [] }],
                      description: [
                        {
                          type: 'paragraph',
                          text: 'Intel Core i5 6500 (3.2 GHz) / AMD Ryzen 5 2600 (3.4 GHz)',
                          spans: [],
                        },
                      ],
                    },
                    {
                      type: [],
                      description: [
                        {
                          type: 'paragraph',
                          text: 'To run Dual Universe, you will need a CPU with AVX instructions.',
                          spans: [],
                        },
                      ],
                    },
                    {
                      type: [{ type: 'heading5', text: 'RAM:', spans: [] }],
                      description: [{ type: 'paragraph', text: '8gb', spans: [] }],
                    },
                    {
                      type: [{ type: 'heading5', text: 'GPU', spans: [] }],
                      description: [
                        { type: 'paragraph', text: 'NVIDIA GeForce GTX 1060 (3 GB) / AMD Radeon RX 580', spans: [] },
                      ],
                    },
                    {
                      type: [{ type: 'heading5', text: 'VRAM:', spans: [] }],
                      description: [{ type: 'paragraph', text: '3gb (4gb for amd rx580 )', spans: [] }],
                    },
                    {
                      type: [{ type: 'heading5', text: 'OS', spans: [] }],
                      description: [{ type: 'paragraph', text: 'Windows 10 / 11', spans: [] }],
                    },
                    {
                      type: [{ type: 'heading5', text: 'DirectX', spans: [] }],
                      description: [{ type: 'paragraph', text: 'Version 11', spans: [] }],
                    },
                  ],
                  id: 'productspecsminimum$8aed0731-e047-4792-8f52-9b70ac81da39',
                  slice_type: 'productspecsminimum',
                  slice_label: null,
                },
                {
                  primary: { section_header: [{ type: 'heading3', text: 'RECOMMENDED', spans: [] }] },
                  items: [
                    {
                      type: [{ type: 'heading5', text: 'CPU:', spans: [] }],
                      description: [
                        {
                          type: 'paragraph',
                          text: 'Intel Core i7 7700 (3.6 GHz) / AMD Ryzen 7 1700 (3.00 GHZ)',
                          spans: [],
                        },
                      ],
                    },
                    {
                      type: [],
                      description: [
                        {
                          type: 'paragraph',
                          text: 'To run Dual Universe, you will need a CPU with AVX instructions.',
                          spans: [],
                        },
                      ],
                    },
                    {
                      type: [{ type: 'heading5', text: 'RAM:', spans: [] }],
                      description: [{ type: 'paragraph', text: '16gb', spans: [] }],
                    },
                    {
                      type: [{ type: 'heading5', text: 'GPU:', spans: [] }],
                      description: [
                        { type: 'paragraph', text: 'AMD RX 580 (8 GB) / NVIDIA GeForce GTX 1080 (8 GB)', spans: [] },
                      ],
                    },
                    {
                      type: [{ type: 'heading5', text: 'VRAM:', spans: [] }],
                      description: [{ type: 'paragraph', text: '8gb', spans: [] }],
                    },
                    {
                      type: [{ type: 'heading5', text: 'OS', spans: [] }],
                      description: [{ type: 'paragraph', text: 'Windows 10 / 11', spans: [] }],
                    },
                    {
                      type: [{ type: 'heading5', text: 'DirectX', spans: [] }],
                      description: [{ type: 'paragraph', text: 'Version 11', spans: [] }],
                    },
                  ],
                  id: 'productspecsminimum1$f181ddf0-30cb-4d37-979a-1226937d83f1',
                  slice_type: 'productspecsminimum1',
                  slice_label: null,
                },
                {
                  primary: {},
                  items: [
                    {
                      label: [{ type: 'paragraph', text: 'Privacy Policy', spans: [] }],
                      link: { link_type: 'Web', url: 'https://www.dualuniverse.game/legal/privacy-policy' },
                    },
                  ],
                  id: 'links$2c9136fe-1267-41d6-8cac-6616804b064f',
                  slice_type: 'links',
                  slice_label: null,
                },
              ],
            },
          },
        ],
        version: '0427058',
        license: 'All Rights Reserved',
      },
      site_map_page: {
        page: 1,
        results_per_page: 20,
        results_size: 1,
        total_results_size: 1,
        total_pages: 1,
        next_page: null,
        prev_page: null,
        results: [
          {
            id: 'X-NjNhEAACMA-tiT',
            uid: null,
            type: 'site_map_page',
            href: 'https://dualuniverse.cdn.prismic.io/api/v2/documents/search?ref=X-NjThEAACIA-tkK&q=%5B%5B%3Ad+%3D+at%28document.id%2C+%22X-NjNhEAACMA-tiT%22%29+%5D%5D',
            tags: [],
            first_publication_date: '2020-12-23T15:33:12+0000',
            last_publication_date: '2020-12-23T15:33:12+0000',
            slugs: ['site-map-page'],
            linked_documents: [],
            lang: 'en-us',
            alternate_languages: [
              {
                id: 'X-NjQREAACEA-tjL',
                type: 'site_map_page',
                lang: 'fr-fr',
              },
              {
                id: 'X-NjTBEAACMA-tj_',
                type: 'site_map_page',
                lang: 'de-de',
              },
            ],
            data: {
              component_title: [
                {
                  type: 'heading1',
                  text: 'Site Map Page',
                  spans: [],
                },
              ],
              page_title: [
                {
                  type: 'heading1',
                  text: 'Sitemap',
                  spans: [],
                },
              ],
            },
          },
        ],
        version: '6c549e9',
        license: 'All Rights Reserved',
      },
      seo_meta: {
        page: 1,
        results_per_page: 20,
        results_size: 1,
        total_results_size: 1,
        total_pages: 1,
        next_page: null,
        prev_page: null,
        results: [
          {
            id: 'X5graBEAACAArEVP',
            uid: null,
            type: 'seo_meta',
            href: 'https://dualuniverse.cdn.prismic.io/api/v2/documents/search?ref=X9oo2xIAACcA7ErJ&q=%5B%5B%3Ad+%3D+at%28document.id%2C+%22X5graBEAACAArEVP%22%29+%5D%5D',
            tags: [],
            first_publication_date: '2020-10-27T14:15:07+0000',
            last_publication_date: '2020-10-27T14:15:07+0000',
            slugs: ['seo'],
            linked_documents: [],
            lang: 'en-us',
            alternate_languages: [
              { id: 'X5griBEAAB8ArEXn', type: 'seo_meta', lang: 'fr-fr' },
              { id: 'X5gsehEAAB0ArEqJ', type: 'seo_meta', lang: 'de-de' },
            ],
            data: {
              component_title: [{ type: 'heading1', text: 'SEO', spans: [] }],
              body: [
                {
                  slice_type: 'seo_meta_page',
                  slice_label: null,
                  items: [
                    {
                      link: { link_type: 'Web', url: 'https://www.dualuniverse.game/' },
                      title: [
                        {
                          type: 'heading6',
                          text: 'Dual Universe - Play The Best Space MMO Building Game Online',
                          spans: [],
                        },
                      ],
                      description: [
                        {
                          type: 'paragraph',
                          text: 'Dual Universe is a Continuous Single-Shard sandbox MMORPG taking place in a vast fully editable Sci-Fi universe, focusing on emergent gameplay with multi-player driven in-game economy, politics, trade and warfare.',
                          spans: [],
                        },
                      ],
                    },
                  ],
                  primary: {},
                },
              ],
            },
          },
        ],
        version: '6c549e9',
        license: 'All Rights Reserved',
      },
      footer: {
        page: 1,
        results_per_page: 20,
        results_size: 2,
        total_results_size: 2,
        total_pages: 1,
        next_page: null,
        prev_page: null,
        results: [
          {
            id: 'X4YbkhEAACMAmq7y',
            uid: null,
            type: 'footer',
            href: 'https://dualuniverse.cdn.prismic.io/api/v2/documents/search?ref=X9oo2xIAACcA7ErJ&q=%5B%5B%3Ad+%3D+at%28document.id%2C+%22X4YbkhEAACMAmq7y%22%29+%5D%5D',
            tags: [],
            first_publication_date: '2020-10-13T21:26:44+0000',
            last_publication_date: '2020-12-11T16:05:33+0000',
            slugs: ['footer'],
            linked_documents: [],
            lang: 'en-us',
            alternate_languages: [
              { id: 'X5c4IREAACAAqAIj', type: 'footer', lang: 'de-de' },
              { id: 'X5cqYxEAAB8Ap8NL', type: 'footer', lang: 'fr-fr' },
            ],
            data: {
              component_title: [{ type: 'heading1', text: 'Footer', spans: [] }],
              landing_page_id: 'main',
              icon_title: [{ type: 'heading3', text: 'Follow Dual Universe', spans: [] }],
              newsletter_disclaimer: [
                {
                  type: 'paragraph',
                  text: 'The information you provide is handled in accordance with our ',
                  spans: [],
                },
                {
                  type: 'paragraph',
                  text: 'Privacy Policy',
                  spans: [
                    {
                      start: 0,
                      end: 14,
                      type: 'hyperlink',
                      data: { link_type: 'Web', url: 'https://www.dualuniverse.game/legal/privacy-policy' },
                    },
                  ],
                },
              ],
              copyright: [{ type: 'paragraph', text: '© 2020 - All rights reserved.', spans: [] }],
              newsletter_success: [{ type: 'paragraph', text: 'Thank you! Please check your emails', spans: [] }],
              body: [
                {
                  slice_type: 'footer_links',
                  slice_label: null,
                  items: [
                    {
                      label: [{ type: 'paragraph', text: 'Press & Contact Us', spans: [] }],
                      link: { link_type: 'Web', url: 'https://www.dualuniverse.game/contact' },
                      is_big: false,
                    },
                    {
                      label: [{ type: 'paragraph', text: 'Jobs', spans: [] }],
                      link: { link_type: 'Web', url: 'https://www.welcometothejungle.com/en/companies/novaquark/jobs' },
                      is_big: false,
                    },
                    {
                      label: [{ type: 'paragraph', text: 'Streamers & Content Creators', spans: [] }],
                      link: { link_type: 'Web', url: 'https://www.dualuniverse.game/content-creators' },
                      is_big: false,
                    },
                    {
                      label: [{ type: 'paragraph', text: 'Support', spans: [] }],
                      link: { link_type: 'Web', url: 'https://support.dualthegame.com/' },
                      is_big: false,
                    },
                    {
                      label: [{ type: 'paragraph', text: 'Play Dual Universe Now', spans: [] }],
                      link: { link_type: 'Web', url: 'https://www.dualuniverse.game/buy' },
                      is_big: true,
                    },
                    {
                      label: [{ type: 'paragraph', text: 'Partners', spans: [] }],
                      link: { link_type: 'Web', url: 'https://www.dualuniverse.game/partners' },
                      is_big: false,
                    },
                  ],
                  primary: {},
                },
              ],
            },
          },
        ],
        version: '6c549e9',
        license: 'All Rights Reserved',
      },
      legal: {
        page: 1,
        results_per_page: 20,
        results_size: 5,
        total_results_size: 5,
        total_pages: 1,
        next_page: null,
        prev_page: null,
        results: [
          {
            id: 'X5cokhEAACAAp7r_',
            uid: null,
            type: 'legal',
            href: 'https://dualuniverse.cdn.prismic.io/api/v2/documents/search?ref=X9oo2xIAACcA7ErJ&q=%5B%5B%3Ad+%3D+at%28document.id%2C+%22X5cokhEAACAAp7r_%22%29+%5D%5D',
            tags: [],
            first_publication_date: '2020-10-26T19:50:45+0000',
            last_publication_date: '2020-12-07T15:21:06+0000',
            slugs: ['terms-and-conditions'],
            linked_documents: [],
            lang: 'en-us',
            alternate_languages: [
              { id: 'X5cp4hEAACAAp8D4', type: 'legal', lang: 'fr-fr' },
              { id: 'X5c24REAAB8Ap_x2', type: 'legal', lang: 'de-de' },
            ],
            data: {
              title: [{ type: 'heading1', text: 'Terms and conditions', spans: [] }],
              slug: 'terms-and-conditions',
              content_before: [
                { type: 'paragraph', text: 'Terms and conditions of use', spans: [] },
                {
                  type: 'paragraph',
                  text: 'Latest update: August 27, 2020',
                  spans: [{ start: 0, end: 14, type: 'strong' }],
                },
                {
                  type: 'paragraph',
                  text: 'Welcome to www.dualuniverse.game (the “Website”). The Website is owned by Novaquark, Inc. a business corporation having its head office at 606 Cathcart Street, Suite 810, Montréal, Quebec, Canada  H3B 1K9, a subsidiary of Novaquark SAS, a French corporation registered under number 799 791 926, having its head office at 43 Rue Camille Desmoulins 92130 Issy-Les-Moulineaux, France (“NOVAQUARK,” “We,” “Us,” “Our” or the “Company”). In these terms and conditions of use (the “Terms of Use”), “You,” “Your” or “the User” refers to the person who uses or visits Our Website. NOVAQUARK offers Users a platform on which they can contribute to the development of an original video game: Dual Universe is a civilization-building massively multiplayer online role-playing game (MMORPG) taking place in a unique science fiction universe shared and played by all players simultaneously (the “Video Game”).  ',
                  spans: [
                    { start: 39, end: 46, type: 'strong' },
                    { start: 396, end: 398, type: 'strong' },
                    { start: 402, end: 404, type: 'strong' },
                    { start: 408, end: 411, type: 'strong' },
                    { start: 421, end: 428, type: 'strong' },
                    { start: 475, end: 487, type: 'strong' },
                    { start: 492, end: 495, type: 'strong' },
                    { start: 499, end: 503, type: 'strong' },
                    { start: 509, end: 517, type: 'strong' },
                    { start: 882, end: 892, type: 'strong' },
                  ],
                },
                { type: 'heading2', text: 'GENERAL TERMS AND CONDITIONS OF USE', spans: [] },
                {
                  type: 'o-list-item',
                  text: 'By accessing the Website or using the services provided on the Website (the “Website Services”), You agree to be bound by these Terms of Use. Please read these Terms of Use carefully.',
                  spans: [{ start: 77, end: 93, type: 'strong' }],
                },
                {
                  type: 'o-list-item',
                  text: 'If You do not accept all of the terms and conditions set out herein, do not use the Website or Website Services.',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'Applicable legislation in Your jurisdiction may require You to be of a certain age to enter into legally binding agreements such as these Terms of Use. If You are under the required age, You are neither authorized to consult the Website nor use the Website Services, unless Your parent or legal guardian has read and accepted these Terms of Use. ',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'We may at any time amend any part of these Terms of Use by adding or removing content. We will notify You of the proposed amendments by publishing an amended version of these Terms of Use including the date of update. We will include a link to the previous version of the Terms of Use under the date of update. ',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'The amendments will be effective 30 days after the date on which the amended version is published. Prior to that date, the previous version of the Terms of Use will continue to apply.',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'If You do not agree with the amendments, You may terminate these terms by ceasing to visit the Website and to use the Website Services at any time within 30 days. If the amendments increase Your obligations under these Terms of Use, or reduce Our obligations under these Terms of Use, You may terminate these terms during the 30-day notice period and also within 30 days of the amendments coming into force. There is no cost or penalty associated with terminating these Terms of Use as a result of an amendment. If You do not stop visiting the Website and using the Website Services during the applicable cancellation period, then by Your continued use of the Website and/or Website Services, You will be deemed to have accepted the proposed amendments. ',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'We may amend or delete any content provided on the Website and restrict access to any part of the Website at any time, at Our sole discretion and without notice. We reserve the right, at Our sole discretion, to take necessary technological, maintenance or security measures that may affect the accessibility of the Website, including shutting down the Website.',
                  spans: [],
                },
                { type: 'heading2', text: 'USER-GENERATED CONTENT', spans: [] },
                {
                  type: 'o-list-item',
                  text: 'Use of this Website by Users may result in the creation of User-generated content (“UGC”), for example, avatars or images created from game screenshots.',
                  spans: [{ start: 84, end: 87, type: 'strong' }],
                },
                {
                  type: 'o-list-item',
                  text: 'The UGC includes the User’s account avatars, and any other unique nicknames, handles, forum posts, chat messages, exchanges with customer service, communications, images, sounds, videos, suggestions, ideas, notes, comments, game knowledge or concepts, or any other information regarding the Website Services. ',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'By creating the UGC, You grant NOVAQUARK, its licensors and licensees a non-exclusive, royalty-free, worldwide, transferable, and possibly sub-licensable licence, for the term of protection of intellectual property that may exist on Your UGC, to use, reproduce, modify, adapt, perform, display, distribute and otherwise exploit and/or delete the UGC (or any part thereof) in any manner NOVAQUARK deems appropriate. In addition, You grant a personal, non-transferable licence to all Users of the Website Services for use of the UGC when using the Website Services, including all rights to reproduce, distribute, display, execute or otherwise license the UGC on a worldwide basis and for their term of protection.',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'You also waive any moral rights that may apply to Your UGC. For all countries that do not allow the waiver of moral rights, You consent to the use of Your UGC on the Website or in connection with the Website Services, and agree that such use (or any other related or ancillary use) does not constitute a violation of Your moral rights.',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'By creating UGC, You are stating that the UGC is not otherwise protected by copyright, moral rights, trademarks or other intellectual property rights, unless You are the owner of such rights or have a transferable licence to such rights. In all cases, You declare that You are entitled to generate such UGC without infringing the rights of a third party.',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'You acknowledge that You must not generate prohibited content (“PC”). PC includes any UGC that violates the Code of Conduct and includes, but is not limited to, the following: nudity, hate speech, self-harm, dangerous organizations, bullying and harassment, sexual exploitation and violence, criminal activity, violence and discussions about real-world politics or real-world religions.',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'NOVAQUARK reserves the exclusive and final right to review, delete, block, modify, move or disable UGC for any reason whatsoever. Under French law, NOVAQUARK has the status of host within the meaning of article 6 of the Loi pour la Confiance dans l’Économie numérique du 21 juin 2004 (Act respecting trust in the digital economy of June 21, 2004) related to User-Generated Content.',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'If NOVAQUARK determines that UGC violates the provisions of these Terms of Use, NOVAQUARK may take steps to suspend or close a User’s account or otherwise restrict a User from using the Website Services and/or associated User account, including access to the Game.',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'You agree to report any Generated Content that You believe to be in violation of the provisions of these General Terms and Conditions.',
                  spans: [],
                },
                { type: 'heading2', text: 'WEBSITE ACCESS AND CODE OF CONDUCT', spans: [] },
                {
                  type: 'o-list-item',
                  text: 'If You do not comply with all of the Terms of Use, We may terminate said Terms of Use and Your authorization to use this Website will be revoked automatically and immediately. In the event of termination, You must stop using the Website immediately.',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'By using the Website, You also expressly accept and agree to follow Our Code of Conduct for Users. The Code of Conduct for Users can be found at the following link: https://www.dualuniverse.game/legal/code-of-conduct.',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'If You do not comply with the Code of Conduct for Users, We may terminate these Terms of Use and Your authorization to use this Website will be revoked automatically and immediately. In the event of termination, You must stop using the Website immediately. ',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'You agree to comply with all reasonable instructions that We may give You regarding Your use of the Website.',
                  spans: [],
                },
                { type: 'heading2', text: 'REGISTRATION', spans: [] },
                {
                  type: 'o-list-item',
                  text: 'Some features of the Website will only work if You create a user account with Us. Your registration is governed by the following terms. ',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'You warrant that (a) all information that You provide in order to register is true and accurate; and You agree to (b) maintain the accuracy of such information.',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'Users register by entering their personal information: first name, surname, email address and nickname.',
                  spans: [],
                },
                { type: 'heading2', text: 'NICKNAME AND PASSWORD', spans: [] },
                {
                  type: 'o-list-item',
                  text: 'These credentials are personal and confidential. They can only be modified at the User’s request.',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'The User is solely responsible for the use of the credentials and will endeavour to keep them secret and not disclose them to anyone, in any form and for any reason whatsoever.',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'The User will be entirely responsible for the use of their credentials by third parties, or for any actions or statements made through their personal account, whether authorized or not, and will compensate NOVAQUARK for all claims in this regard.',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'In addition, given that NOVAQUARK has no obligation and no technical means to verify the identity of persons registering on the Website, We are not responsible for persons posing as a User. If the User has reason to believe that someone has fraudulently used their credentials or account, they must inform NOVAQUARK immediately at the following address: https://support.dualthegame.com/hc/en-us/requests/new.',
                  spans: [
                    {
                      start: 354,
                      end: 407,
                      type: 'hyperlink',
                      data: {
                        link_type: 'Web',
                        url: 'https://www.google.com/url?q=https://support.dualthegame.com/hc/en-us/requests/new&sa=D&ust=1605631330284000&usg=AFQjCNE2seN3WJwKLXSn-n4WVEy6U3kyOw',
                        target: '_blank',
                      },
                    },
                  ],
                },
                {
                  type: 'o-list-item',
                  text: 'In the event that the User is informed of access to another User’s account, the User must immediately advise NOVAQUARK using the Website’s contact form.',
                  spans: [],
                },
                { type: 'heading2', text: 'ORDERS PLACED ON THE WEBSITE AND OTHER TRANSACTIONS', spans: [] },
                {
                  type: 'o-list-item',
                  text: 'The Website allows Users to place orders on the Website or third party websites to purchase services, such as a subscription to the Game, or other types of services that Novaquark may offer.',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'Any order, purchase or other transaction is subject to the applicable terms and conditions, including the terms and conditions of any payment processor or authorized third party reseller of NOVAQUARK. Said terms and conditions apply in addition to these Terms.',
                  spans: [],
                },
                { type: 'heading2', text: 'OTHER WEBSITES', spans: [] },
                {
                  type: 'o-list-item',
                  text: 'Our Website may contain links to other websites owned by Us and/or links to third party websites that are not under Our control. We make no warranties or representations of any kind about any other website that You may access through this Website or any services provided by such third party websites. Third party websites are not approved, controlled, verified or endorsed by Us, and You agree that We are not responsible for the content, accuracy, compliance with applicable laws or accessibility of any information, data, advice or statements, or the quality of any products or services available on such websites. Links do not necessarily imply that We are, or that Our Website is, affiliated or associated with such third party websites.',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'If You decide to visit any other website, You do so at Your own risk. In addition, the use of any other website may be subject to Your acceptance of other terms and conditions of use, which We suggest You read carefully before proceeding.',
                  spans: [],
                },
                { type: 'heading2', text: 'INTELLECTUAL PROPERTY', spans: [] },
                {
                  type: 'o-list-item',
                  text: 'The intellectual property rights of the Website, and all content, materials and information contained in or appearing on the Website, are the property of NOVAQUARK and will remain Our property or that of Our licensors.',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'You may view, download and print any content, materials and information made available to You on the Website subject to the following terms and conditions:\n(a) The Website and Website Services may only be used for personal, non-commercial purposes (unless NOVAQUARK has given its prior consent for other uses);\n(b) Unless We expressly give Our prior written consent, the Website, content, materials and information may not be reproduced or included in any other work or publication in any medium, except in the case of content that is clearly intended to be shared, such as advertising information, press releases, social media content, etc.;\n(c) Except in situations expressly limited by law, neither the Website nor Website Services may be modified, decompiled, disassembled, altered in any way or reverse engineered in any way;\n(d) Unless We expressly give Our prior written consent, the content, materials and information may not be sold to a third party or otherwise marketed;\n(e) You may not delete any copyright or other proprietary notices included on the Website.',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'If You wish to provide Us with comments regarding Your gaming experience or share Your experience with the Website or the Game, You agree that by submitting Your comments or suggestions, You agree to grant NOVAQUARK a non-exclusive, royalty-free, worldwide, transferable licence to any right, title or interest You may hold for Your comments and suggestions for their entire term of protection. You also waive any moral rights that may apply. For all countries that do not allow the waiver of moral rights, You consent to the use of Your suggestions or comments in the Game or in the advertising and promotion of the Game, and agree that such use (or any other related or ancillary use) does not constitute a violation of Your moral rights.',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'If You have any concerns or questions about any content that appears on Our Website (including if You believe that any content posted on the Website has violated Your intellectual property rights), please contact Us using the Website’s contact form.',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'Notice of Infringement: Novaquark complies with applicable intellectual property laws worldwide. If You believe that Your intellectual property rights have been infringed by the actions of a User of the Website or the Game, please contact Us through the appropriate channels, including through Our registered Digital Millennium Copyright Act (DMCA) agent.',
                  spans: [{ start: 0, end: 24, type: 'strong' }],
                },
                {
                  type: 'o-list-item',
                  text: 'Policy on Repeated Infringement: NOVAQUARK does not tolerate the Website being used to infringe the intellectual property rights of third parties. Pursuant to the policy on repeated copyright infringement, NOVAQUARK will close Your account if You repeatedly infringe the copyright held by third parties. This automatically revokes the User’s right to access the Website and terminates any licence granted under these Terms.',
                  spans: [{ start: 0, end: 31, type: 'strong' }],
                },
                { type: 'heading2', text: 'LIMITATION OF LIABILITY', spans: [] },
                {
                  type: 'paragraph',
                  text: 'CLAUSE 9.1 DOES NOT APPLY TO QUEBEC CONSUMERS.',
                  spans: [{ start: 0, end: 46, type: 'strong' }],
                },
                {
                  type: 'o-list-item',
                  text: 'To the maximum extent permitted by applicable laws, You agree that We are not responsible for: (a) any indirect losses, claims or damage, or any punitive, special, incidental or consequential damage of any kind (including, but not limited to, loss of savings, loss or corruption of data); or (b) any loss of profit (direct or indirect), in each case, whether or not it arises from a breach of contract or tort liability (including negligence) or strict liability, or is related to (i) any use of the Website or its content that has been introduced to it or associated with it; (ii) any failure or delay in the use of any component of the Website or any service, including, but not limited to, any unavailability of the Website or the services, regardless of the length of the period of unavailability; or (iii) any use of, or reliance on, any information, materials, software, products, services and related graphics obtained through the Website, even if We have been advised of the possibility of such loss or damage.',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'You agree to compensate Us for any claims or lawsuits that may arise from Your use of the Website or any violation of these Terms of Use by You, including a breach of the Code of Conduct.',
                  spans: [],
                },
                { type: 'heading2', text: 'TERMINATION', spans: [] },
                {
                  type: 'o-list-item',
                  text: 'We may shut down the Website or terminate Your registration on the Website at any time, at Our sole discretion and for any reason whatsoever. You will be informed of these decisions within a reasonable time.',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'You have the right to terminate Your registration on the Website at any time by contacting the NOVAQUARK team. However, You should know that if You close Your user account, You will lose the ability to log in to the Game. You will also lose access to any digital items or services associated with Your account, without the right to a refund or exchange. ',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'Termination of Your registration, regardless of the cause, will not affect the rights and obligations accrued by You or Us at the time of such termination, except with respect to Your access to the Game and the other consequences described in the previous article.',
                  spans: [],
                },
                { type: 'heading2', text: 'DATA PROTECTION AND CONFIDENTIALITY ', spans: [] },
                {
                  type: 'paragraph',
                  text: 'We will only use the personal information that We may collect about You in accordance with Our Privacy Policy. By accepting these Terms of Use and by using the Website, You also expressly accept and agree to Our Privacy Policy.',
                  spans: [
                    {
                      start: 212,
                      end: 226,
                      type: 'hyperlink',
                      data: { link_type: 'Web', url: 'https://www.dualuniverse.game/legal/privacy-policy' },
                    },
                  ],
                },
                { type: 'heading2', text: 'GENERAL', spans: [] },
                {
                  type: 'o-list-item',
                  text: 'Any failure or delay on Our part to assert Our rights under these Terms of Use will not be deemed to be a waiver of such rights or any other rights unless We acknowledge and agree to such a waiver in writing.',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'These Terms of Use set out all of Our obligations and responsibilities regarding the Website and supersede any prior agreements that may exist between Us and You.',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'These Terms of Use and the Privacy Policy are governed by the laws and statutes of the Province of Quebec, as well as *applicable* Canadian federal laws. Any dispute in connection with these Terms of Use or the Website must be submitted to the exclusive jurisdiction of the courts in the judicial district of Montréal.',
                  spans: [],
                },
              ],
              content_after: [],
            },
          },
          {
            id: 'X5co-REAAB4Ap7zb',
            uid: null,
            type: 'legal',
            href: 'https://dualuniverse.cdn.prismic.io/api/v2/documents/search?ref=X9oo2xIAACcA7ErJ&q=%5B%5B%3Ad+%3D+at%28document.id%2C+%22X5co-REAAB4Ap7zb%22%29+%5D%5D',
            tags: [],
            first_publication_date: '2020-10-26T19:52:28+0000',
            last_publication_date: '2020-12-07T15:19:51+0000',
            slugs: ['code-of-conduct'],
            linked_documents: [],
            lang: 'en-us',
            alternate_languages: [
              { id: 'X5cpnREAACAAp7-7', type: 'legal', lang: 'fr-fr' },
              { id: 'X5c2shEAAB8Ap_ud', type: 'legal', lang: 'de-de' },
            ],
            data: {
              title: [{ type: 'heading1', text: 'Code of Conduct', spans: [] }],
              slug: 'code-of-conduct',
              content_before: [
                { type: 'paragraph', text: 'Code of conduct', spans: [] },
                {
                  type: 'paragraph',
                  text: 'Latest update: August 2020',
                  spans: [{ start: 0, end: 15, type: 'strong' }],
                },
                {
                  type: 'paragraph',
                  text: 'When using the Game, You agree to comply with all applicable acts and regulations, as well as the rules established by NOVAQUARK that govern the use of the Game (hereinafter the “Code of Conduct”). ',
                  spans: [{ start: 179, end: 194, type: 'strong' }],
                },
                {
                  type: 'paragraph',
                  text: 'You must refrain from engaging in any behaviour that could harm NOVAQUARK’s image and/or reputation, that could harm one or more other Users or have a negative impact on their gaming experience, or that is detrimental to the proper functioning of the Game.',
                  spans: [],
                },
                { type: 'paragraph', text: 'Moreover, You must refrain, in particular, from:', spans: [] },
                {
                  type: 'o-list-item',
                  text: 'Choosing a username (hereinafter “Display Name”) that is offensive, defamatory, vulgar, obscene, sexually explicit, racist, sexist, xenophobic or otherwise objectionable. You cannot use a spelling mistake or alternative spelling to get around this restriction on the choice of names and nicknames;',
                  spans: [{ start: 34, end: 46, type: 'strong' }],
                },
                {
                  type: 'o-list-item',
                  text: 'Publishing, sending or communicating any content or taking any action in connection with the Game that is inappropriate, indecent, obscene, pornographic, offensive, illegal, harmful, threatening or abusive, involves harassment, or is defamatory, vulgar, violent, sexually explicit, objectionable or otherwise unacceptable on the basis of race, gender, ethics, politics, etc.;',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'Publishing, sending or communicating hate speech involving specific individuals or groups of individuals;',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'Attacking, in any manner, an ethnic group, religion, gender or sexual orientation or identity;  ',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'Harassing or threatening other users and interfering in any way with their use and full enjoyment of the Game;',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'Discussing in any way real-world politics or real-world religion (regardless of the nature or content of the discussion);',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'Posting or disseminating personal identification information about yourself or other Users (doxing);',
                  spans: [],
                },
                { type: 'o-list-item', text: 'Promoting any illegal activity;', spans: [] },
                {
                  type: 'o-list-item',
                  text: 'Publicly revealing private discussions between a User and a NOVAQUARK employee;',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'Impersonating another player, moderator, administrator, NOVAQUARK employee or one of NOVAQUARK’s partners. You must not give the impression that a comment or communication is coming from any of the persons listed;',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'Sending content that infringes or violates the rights of others, including copyright, patents, trademarks, trade secrets, publicity or privacy rights or other rights;',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'Sending or facilitating the transmission of any content that contains viruses, corrupted data, Trojan horses, keyloggers, worms, spyware, time bombs, cancelbots or any other programs or techniques designed to damage, interfere with in a harmful manner, intercept, extract, gain unauthorized access to, or stealthily expropriate any system or data;',
                  spans: [{ start: 94, end: 95, type: 'em' }],
                },
                {
                  type: 'o-list-item',
                  text: 'Using any unauthorized third party programs, including mods, hacks, cheat codes, scripts, bots, trainers and automation programs that interact with the Game in any way, for any purpose;',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'Spamming discussions, whether for personal or commercial purposes, by disrupting the flow of conversations with repeated messages;',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'Participating in any fraudulent action against any other User of the Game, including by means of scamming or social engineering;',
                  spans: [{ start: 115, end: 116, type: 'em' }],
                },
                {
                  type: 'o-list-item',
                  text: 'Using any part of the Game to gather or collect information about other persons, including, but not limited to, email addresses; and',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'Modifying, by any means, Game files or attempting to sell or otherwise monetize the Game or its components (e.g., virtual objects or any Game codes or scripts). ',
                  spans: [],
                },
                {
                  type: 'paragraph',
                  text: 'NOVAQUARK reserves the exclusive and final right to review, delete, block, edit, move or disable any content appearing in the Game that violates the Code of Conduct or its Policy on Repeated Infringement. ',
                  spans: [],
                },
                {
                  type: 'paragraph',
                  text: 'You agree to report any behaviour that You believe to be in violation of this Code of Conduct to:',
                  spans: [],
                },
                {
                  type: 'list-item',
                  text: 'Technical assistance at https://support.dualthegame.com/hc/en-us; or',
                  spans: [
                    {
                      start: 24,
                      end: 64,
                      type: 'hyperlink',
                      data: { link_type: 'Web', url: 'https://support.dualthegame.com/hc/en-us' },
                    },
                  ],
                },
                {
                  type: 'list-item',
                  text: 'One of the community managers on the forum by private message at https://board.dualthegame.com.',
                  spans: [
                    {
                      start: 65,
                      end: 94,
                      type: 'hyperlink',
                      data: { link_type: 'Web', url: 'https://board.dualthegame.com/' },
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  text: 'In the event of a violation of the Code of Conduct or of the other provisions of this EULA, NOVAQUARK may take steps to suspend or close a User’s account or otherwise restrict a User from using the Game and/or associated User account.',
                  spans: [],
                },
              ],
              content_after: [],
            },
          },
          {
            id: 'X5cijhEAAB0Ap5-J',
            uid: null,
            type: 'legal',
            href: 'https://dualuniverse.cdn.prismic.io/api/v2/documents/search?ref=X9oo2xIAACcA7ErJ&q=%5B%5B%3Ad+%3D+at%28document.id%2C+%22X5cijhEAAB0Ap5-J%22%29+%5D%5D',
            tags: [],
            first_publication_date: '2020-10-26T19:25:06+0000',
            last_publication_date: '2020-12-07T15:20:42+0000',
            slugs: ['cookie-policy'],
            linked_documents: [],
            lang: 'en-us',
            alternate_languages: [
              { id: 'X5c3FREAACAAp_1k', type: 'legal', lang: 'de-de' },
              { id: 'X5cpwxEAAB0Ap8Bo', type: 'legal', lang: 'fr-fr' },
            ],
            data: {
              title: [{ type: 'heading1', text: 'Cookie Policy', spans: [] }],
              slug: 'cookie-policy',
              content_before: [
                { type: 'paragraph', text: 'Cookie Policy', spans: [] },
                {
                  type: 'paragraph',
                  text: 'Latest update: August 2020',
                  spans: [{ start: 0, end: 13, type: 'strong' }],
                },
                { type: 'paragraph', text: 'Thank you for visiting us!', spans: [] },
                {
                  type: 'paragraph',
                  text: 'This Cookie Policy describes how we use cookies, along with how you can manage your preferences. This Cookie Policy applies to:',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'Your use of our website dualuniverse.game along with any related domains or sub-domains, such as dualthegame.com (the “Website”);',
                  spans: [{ start: 119, end: 126, type: 'strong' }],
                },
                { type: 'o-list-item', text: 'Your exchanges with us, such as through social media;', spans: [] },
                { type: 'o-list-item', text: 'Our marketing activities, including any newsletters.', spans: [] },
                {
                  type: 'paragraph',
                  text: 'We will use the term “Services” to refer to all of these applications together. This Cookie Policy does not apply to external services, applications and software that you may access through the Services. For instance, if you click on a link that brings you to another website, they may use cookies that are not covered in this Cookie Policy. We encourage you to read their respective cookie policies.',
                  spans: [{ start: 22, end: 30, type: 'strong' }],
                },
                {
                  type: 'paragraph',
                  text: 'The Services are provided by Novaquark, Inc., Novaquark, SAS and any of our affiliates (together, “Novaquark”). If you are located in the European Union, these entities are all joint-controllers of the personal data collected through cookies.',
                  spans: [{ start: 99, end: 108, type: 'strong' }],
                },
                {
                  type: 'paragraph',
                  text: 'This Cookie Policy should be read with our Privacy Policy, which contains more information on how we process personal data and on your rights with regards to such personal data.',
                  spans: [],
                },
                {
                  type: 'paragraph',
                  text: 'This Cookie Policy covers our use of interest-based advertising, which we conduct only on our publicly-facing Website (and not within our game Dual Universe!). As a participant of the Digital Advertising Alliance, we adhere to the Digital Advertising Alliance’s interest-based advertising principles by providing you enhanced notice, transparency and control of our digital marketing practices as stated at http://www.aboutads.info/principles.',
                  spans: [
                    {
                      start: 407,
                      end: 442,
                      type: 'hyperlink',
                      data: { link_type: 'Web', url: 'http://www.aboutads.info/principles' },
                    },
                  ],
                },
                { type: 'heading2', text: 'How can you contact us?', spans: [] },
                {
                  type: 'paragraph',
                  text: 'If you have any questions, comments or if you want to exercise your rights regarding cookies, you can contact us using this form, or by e-mail at privacy@novaquark.com. Otherwise, you can contact us by mail at the following address:',
                  spans: [],
                },
                {
                  type: 'preformatted',
                  text: 'Attention: Data Protection Officer\nNOVAQUARK\n43 rue Camille Desmoulins\n92130 Issy-les-MoulineauxFrance\nFrance',
                  spans: [],
                },
                { type: 'paragraph', text: 'You can also contact us by phone at +33 (0)9 67 48 04 04.', spans: [] },
                {
                  type: 'paragraph',
                  text: 'If you are located in North America, we also have a registered address at 606, rue Cathcart, Suite 810, Montréal, Québec, H3B 1K9, Canada.',
                  spans: [],
                },
                { type: 'heading2', text: 'What is a “cookie”?', spans: [] },
                {
                  type: 'paragraph',
                  text: '“Cookies” are small files or record-keeping devices that websites often store on a user’s computer in order to identify them as a unique customer and store personal preferences as well as technical information. Cookies can also include similar technologies such as web beacons, pixels and mobile advertising IDs. Our game Dual Universe does not collect any such cookies, however, we do collect player ID that are created for purposes such as to prevent cheating, troubleshoot bugs, etc. These player IDs are covered in the Privacy Policy and, therefore, are not covered herein. ',
                  spans: [{ start: 1, end: 8, type: 'strong' }],
                },
                {
                  type: 'paragraph',
                  text: 'Cookies are useful to us for many reasons such as to monitor traffic, improve the Website’s usability, provide you with some functionalities, secure our Services and to personalize the content of the Services for you. In our definition of cookies, we include other technologies with similar purposes, such as pixels, tags and web beacons. Cookies contain personal data such as IP addresses and other Electronic Data. If you want to know more about how we process the personal data included in cookies, please refer to our Privacy Policy.',
                  spans: [],
                },
                { type: 'heading2', text: 'Which cookies do we use, and for what purposes?', spans: [] },
                {
                  type: 'paragraph',
                  text: 'We use cookies to offer you the Services, to conduct interest-based advertising, to understand what pages and features are popular, to understand whether there are bugs in our Services, whether our newsletters are being opened, where our users come from, to enhance security and to offer you certain functionalities.',
                  spans: [],
                },
                { type: 'paragraph', text: 'There are 2 types of cookies: ', spans: [] },
                {
                  type: 'list-item',
                  text: 'First Party Cookies - First party cookies are those that are issued by us, and they can only be set or retrieved by us. They are used for purposes specific to us, such as to personalize the Website.',
                  spans: [{ start: 0, end: 22, type: 'strong' }],
                },
                {
                  type: 'list-item',
                  text: 'Third Party Cookies - Third party cookies are placed on our Services by other entities, such as to create new functionalities or conduct advertising.',
                  spans: [{ start: 0, end: 22, type: 'strong' }],
                },
                { type: 'paragraph', text: 'Cookies can also be retained for different lengths of time: ', spans: [] },
                {
                  type: 'list-item',
                  text: 'Session cookies - Session cookies are temporary. They are only stored on your device during your web session and are automatically deleted when you close your browser.',
                  spans: [{ start: 0, end: 17, type: 'strong' }],
                },
                {
                  type: 'list-item',
                  text: 'Persistent cookies: a persistent cookie is stored as a file on your computer for a period of time specified in the cookie, and may be used to track you across websites and services. These are generally third party cookies, such as Google Analytics cookies, as described below.',
                  spans: [{ start: 0, end: 19, type: 'strong' }],
                },
                {
                  type: 'paragraph',
                  text: 'Cookies can be classified based on their purposes. We used the following type of cookies based on their functions: ',
                  spans: [],
                },
                {
                  type: 'list-item',
                  text: 'Strictly essential cookies: These cookies are essential to enable you to use our Services or for securing our Services. These cookies do not require consent under European laws. If these cookies are removed, we would not be able to provide you with the Services such as the Website. For instance, we use csrftoken to protect our Website against cross-site request forgery attacks.',
                  spans: [{ start: 0, end: 26, type: 'strong' }],
                },
                {
                  type: 'list-item',
                  text: 'Functionality cookies: These cookies allow our Website to remember choices you make in order to customize your experience on our Website. For instance, we can use functionality cookies to remember passwords and keep you logged-in when you accidentally leave the authenticated portion of our Website. ',
                  spans: [{ start: 0, end: 22, type: 'strong' }],
                },
                {
                  type: 'list-item',
                  text: 'Measurement cookies: These cookies enable us to monitor and improve the performance of our Website. They allow us to gather data about visits to the Website (notably number of visitors, pages clicked on or length of time spent on the website). We use analytics companies to perform these services and these cookies may be set by a third-party company. ',
                  spans: [{ start: 0, end: 20, type: 'strong' }],
                },
                {
                  type: 'paragraph',
                  text: 'For instance, we use Google Analytics Demographics and Interest Reporting to better understand our Websites’ performance. Google Analytics uses third party cookies to get information about how you arrived on our Website, the search times which were leveraged, the time of your visit, etc. This data is leveraged by Google to create traffic data. We use this to understand our Services, and we cannot see what you are doing by isolating you or another user.',
                  spans: [],
                },
                {
                  type: 'list-item',
                  text: 'Targeting cookies: These cookies are used to deliver ads that are more relevant to you. Without these cookies, you would still get some ads, but they wouldn’t necessarily be based on your interests. For instance, the cookie “fr” is set by Facebook on our website to show relevant ads to you, on Facebook or elsewhere, as well as to improve them and measure their performance. This cookie is only installed for 2 months, and tracks your behavior across websites that you navigate whenever they have Facebook pixel or Facebook social plugin installed.',
                  spans: [{ start: 0, end: 18, type: 'strong' }],
                },
                { type: 'heading2', text: 'Do we conduct interest-based advertising?', spans: [] },
                {
                  type: 'paragraph',
                  text: 'Interest-based advertising is also referred to as targeted advertising or behavioral advertising. Simply put, we allow third parties such as Google and Facebook to collect information about your behavior through cookies, which they can use to present you with relevant advertising about our game Dual Universe.',
                  spans: [],
                },
                {
                  type: 'paragraph',
                  text: 'For instance, if you download many games online or if you participate in gamer forums on websites that have enabled Facebook to install their pixel or social plugin, then this information may be collected through cookies and Facebook may use this to present you with ads about Dual Universe. This information is collected through cookies that keep memories of your behavior and reports to Facebook. This information is associated with a token ID, and we cannot see how you navigate the Internet because we allow Facebook to install these cookies. Facebook generally converts your e-mail address to a unique ID which it uses to match with other unique numbers generating through e-mail addresses, including as part of real-time bidding for companies to purchase ads.',
                  spans: [],
                },
                {
                  type: 'paragraph',
                  text: 'We also use Google DoubleClick, which is installed for a year on your device, and which is used to present you with relevant ads by storing information about how you use our Website and any other ads before you visit our Website.',
                  spans: [],
                },
                {
                  type: 'paragraph',
                  text: 'We do thisconduct interest-based advertising so we can find our next players with relevant ads, but if you’re not comfortable with this, we understand! If you are located in the European Union, we will ask you to consent prior for installing these targeting cookies. You can also keep reading to find out how to opt-out of interest-based advertising, regardless of where you are located.',
                  spans: [],
                },
                { type: 'heading2', text: 'How can I opt-out of interest-based advertising?', spans: [] },
                {
                  type: 'paragraph',
                  text: 'You can opt-out of interest-based advertising occurring on our websites and web applications by visiting www.aboutads.info/choices or managing your cookie preferences. Keep reading below to understand how to manage your cookie preferences. ',
                  spans: [
                    {
                      start: 105,
                      end: 130,
                      type: 'hyperlink',
                      data: { link_type: 'Web', url: 'https://www.aboutads.info/choices' },
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  text: 'You can also visit the Digital Advertising Alliance of Canada for more information on the tools available to you to control your participation in interest-based advertising. There are good options for you to consider !',
                  spans: [],
                },
                { type: 'heading2', text: 'On what legal bases do we process cookies?', spans: [] },
                {
                  type: 'paragraph',
                  text: 'In the European Union, we are required to indicate the legal bases that we use to process your personal data, which include the personal data in cookies. The table below contains our explanations as it relates to cookies. If you are not in the European Union, these legal bases may not exist in your jurisdiction, however, we use other legal bases that are recognized, such as implied consent.',
                  spans: [],
                },
                {
                  type: 'list-item',
                  text: 'Type of cookies: strictly essential. \nLegal basis for processing: Legitimate Interests as our Services would not be available without these cookies, or we would not be able to protect our Services (and thus, your personal data!)',
                  spans: [
                    { start: 0, end: 17, type: 'strong' },
                    { start: 38, end: 66, type: 'strong' },
                  ],
                },
                {
                  type: 'list-item',
                  text: 'Type of cookies: functionality.\nLegal basis for processing: consent, performance of a contract (for instance, if you open an account with us,\nand then if you purchase the game or use our forums, we may need functionality cookies to allow you to do all this, in which case we are doing this based on our contract with you).',
                  spans: [
                    { start: 0, end: 16, type: 'strong' },
                    { start: 32, end: 60, type: 'strong' },
                  ],
                },
                {
                  type: 'list-item',
                  text: 'Type of cookies: measurement.\nLegal basis for processing: Legitimate Interests so that we can understand the performance of our Services, such as Website usage. We do not use analytics cookies for other purposes than to understand our audience. We believe the interests of our users do not override these interests, given the use that we make of measurement cookies (in aggregated for traffic purposes), the information we make available, and the possibility to easily control settings for such cookies, including through this tool specifically provided by Google for opting-out.',
                  spans: [
                    { start: 0, end: 16, type: 'strong' },
                    { start: 30, end: 57, type: 'strong' },
                    {
                      start: 521,
                      end: 579,
                      type: 'hyperlink',
                      data: { link_type: 'Web', url: 'https://tools.google.com/dlpage/gaoptout' },
                    },
                  ],
                },
                {
                  type: 'list-item',
                  text: 'Type of cookies: targeting.\nLegal basis for processing: consent',
                  spans: [
                    { start: 0, end: 17, type: 'strong' },
                    { start: 27, end: 56, type: 'strong' },
                  ],
                },
                { type: 'heading2', text: 'How can you manage my preferences regarding cookies?', spans: [] },
                {
                  type: 'paragraph',
                  text: 'Browsers and devices have tools that allow you to control cookies; you can block them, ensure that you are notified when you are subject to cookies and control the cookies already stored on your device. However, if you block all of the cookies, you may not be able to access all the functionalities of our Services.',
                  spans: [],
                },
                {
                  type: 'paragraph',
                  text: 'Depending on the browser that you are using, different instructions are applicable. Click on your browser to have more information:',
                  spans: [],
                },
                { type: 'list-item', text: 'Google Chrome', spans: [] },
                { type: 'list-item', text: 'Firefox', spans: [] },
                { type: 'list-item', text: 'Safari', spans: [] },
                { type: 'list-item', text: 'Microsoft Edge', spans: [] },
                {
                  type: 'paragraph',
                  text: 'If you want to opt-out from Google Analytics Demographics and Interest Reporting, you can do so by clicking here.',
                  spans: [],
                },
                { type: 'heading2', text: 'Do I have rights regarding cookies?', spans: [] },
                {
                  type: 'paragraph',
                  text: 'Yes! Please consult our Privacy Policy for a full description of your rights regarding personal data and how to exercise them. If you consented to the use of cookies, you can withdraw your consent at any time.',
                  spans: [],
                },
                { type: 'heading2', text: 'Can we modify this Cookie Policy?', spans: [] },
                {
                  type: 'paragraph',
                  text: 'Yes, we may need to update this Cookie Policy regularly to reflect our actual practices. You can find the latest update date above. If you are subscribed to our Services, we will inform you of any significant changes by e-mail. However, we invite you to consult this page regularly.',
                  spans: [],
                },
              ],
              content_after: [],
            },
          },
          {
            id: 'X5coaREAAB8Ap7o7',
            uid: null,
            type: 'legal',
            href: 'https://dualuniverse.cdn.prismic.io/api/v2/documents/search?ref=X9oo2xIAACcA7ErJ&q=%5B%5B%3Ad+%3D+at%28document.id%2C+%22X5coaREAAB8Ap7o7%22%29+%5D%5D',
            tags: [],
            first_publication_date: '2020-10-26T19:50:04+0000',
            last_publication_date: '2020-12-07T15:20:27+0000',
            slugs: ['eula'],
            linked_documents: [],
            lang: 'en-us',
            alternate_languages: [
              { id: 'X5c2_BEAAB8Ap_zz', type: 'legal', lang: 'de-de' },
              { id: 'X5cqKxEAAB4Ap8JM', type: 'legal', lang: 'fr-fr' },
            ],
            data: {
              title: [{ type: 'heading1', text: 'EULA', spans: [] }],
              slug: 'eula',
              content_before: [
                { type: 'paragraph', text: 'EULA', spans: [] },
                {
                  type: 'paragraph',
                  text: 'Latest update: August 2020 ',
                  spans: [
                    { start: 0, end: 14, type: 'strong' },
                    { start: 26, end: 27, type: 'em' },
                  ],
                },
                {
                  type: 'paragraph',
                  text: 'This End User Licence Agreement (hereinafter “EULA”) is an agreement between you (hereinafter “You”) and Novaquark, Inc., a business corporation having its head office located at 606 Cathcart Street, Suite 810, Montréal, Quebec, Canada  H3B 1K9 (hereinafter “NOVAQUARK”).',
                  spans: [
                    { start: 46, end: 50, type: 'strong' },
                    { start: 95, end: 98, type: 'strong' },
                    { start: 259, end: 268, type: 'strong' },
                  ],
                },
                {
                  type: 'paragraph',
                  text: 'DUAL UNIVERSE is a massively multiplayer online game developed by NOVAQUARK (hereinafter the “Game”). “Game” is a generic term that refers to all versions of the DUAL UNIVERSE Game.',
                  spans: [
                    { start: 94, end: 98, type: 'strong' },
                    { start: 103, end: 107, type: 'strong' },
                  ],
                },
                {
                  type: 'paragraph',
                  text: '“Beta Version” refers to the beta version of the Game, which is made available to the public (hereinafter the “User” or “Users”) for a limited time for testing purposes.',
                  spans: [
                    { start: 1, end: 13, type: 'strong' },
                    { start: 111, end: 115, type: 'strong' },
                    { start: 121, end: 126, type: 'strong' },
                  ],
                },
                {
                  type: 'paragraph',
                  text: 'PLEASE READ THIS EULA CAREFULLY BEFORE DOWNLOADING, INSTALLING, ACCESSING OR USING THE GAME. ANY USE OF THE GAME IS SUBJECT TO THE PROVISIONS OF THIS EULA. IF YOU DO NOT ACCEPT THIS EULA IN ITS ENTIRETY, DO NOT USE THE GAME. OTHERWISE, BY ACCEPTING IT, YOU AGREE TO FULLY COMPLY WITH IT.',
                  spans: [],
                },
                {
                  type: 'paragraph',
                  text: 'If You want to continue to play when a new version of the Game is released, You will need to accept the End User Licence Agreement applicable to that new version.',
                  spans: [],
                },
                {
                  type: 'heading2',
                  text: 'ELIGIBILITY TO ACCEPT THIS EULA',
                  spans: [{ start: 0, end: 31, type: 'strong' }],
                },
                {
                  type: 'paragraph',
                  text: 'Applicable legislation in your jurisdiction may require You to be of a certain age to enter into legally binding agreements such as the terms of this EULA. If You are underage, You are not permitted to use the Game unless your parent or legal guardian has read and accepted this EULA. Locally applicable legislation may also require that children under a certain age be supervised while using the Game. By using the Game, You warrant that You are of legal age to use the Game without supervision. If You are underage, your parent or legal guardian must warrant that your use of the Game will be supervised and monitored by said parent or legal guardian.',
                  spans: [],
                },
                {
                  type: 'heading2',
                  text: 'LICENSING AND ACCESS TO THE GAME',
                  spans: [{ start: 0, end: 32, type: 'strong' }],
                },
                {
                  type: 'o-list-item',
                  text: 'NOVAQUARK grants You a personal, non-commercial, non-transferable, non-exclusive, time-limited licence to install and/or use the Game only on a device owned and controlled by You, and only for your personal use, solely for the purpose of playing the Game, to the exclusion of all other uses. ',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'This EULA will expire when NOVAQUARK terminates it or when the Beta Version of the Game is replaced by a new version. Your right and capacity to continue to use the Game from that time onward will depend on your acceptance of the new EULA in effect at that time.',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'You are prohibited from decompiling, disassembling or reverse engineering the Game by any means, or altering, modifying, or creating a derivative work of the Game. ',
                  spans: [{ start: 82, end: 83, type: 'em' }],
                },
                {
                  type: 'o-list-item',
                  text: 'The granting of a licence under this EULA does not necessarily guarantee You a perpetual right to play the online Game. Your right to play the Game is dependent on the purchase of a subscription to the Game from NOVAQUARK or an authorized NOVAQUARK reseller. After your current subscription expires, You will no longer be able to access the online Game unless You purchase a new subscription. Some players who supported the Game early in its development may have obtained a perpetual subscription through their supporter pack.',
                  spans: [],
                },
                { type: 'heading2', text: 'OWNERSHIP/RIGHTS RESERVED', spans: [{ start: 0, end: 25, type: 'strong' }] },
                {
                  type: 'o-list-item',
                  text: 'The Game is made available to You under the licence described above in clause 2.1; it is not sold to You. This EULA does not provide You with any title or ownership of the Game or any of its individual components, and must not be interpreted as a sale, an assignment or any form of transfer of intellectual property rights in connection with the Game.',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'All rights, titles, interests and intellectual property rights related to the Game are owned and held by NOVAQUARK or its licensors. ',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'You may not remove or modify any copyright, trademark or other proprietary notices contained in the Game or accompanying material.',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'You may not remove or modify any technical protection measures or rights management information contained in the Game.',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'Policy on Repeated Infringement: NOVAQUARK does not tolerate the game being used to infringe the intellectual property rights of third parties. Pursuant to the policy on repeated copyright infringement, NOVAQUARK will close the account of a User who repeatedly infringes the copyright held by third parties. This automatically revokes the User’s right to access the Game and terminates any licence granted under this EULA.',
                  spans: [{ start: 0, end: 31, type: 'strong' }],
                },
                {
                  type: 'heading2',
                  text: 'RELEASE CANDIDATE VERSION ',
                  spans: [{ start: 0, end: 26, type: 'strong' }],
                },
                {
                  type: 'o-list-item',
                  text: 'The Beta Version is a release candidate version of the Game that is aimed at developing and improving the Game. ',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'You understand and accept that since the Beta Version is not a final version, You will not have continuous and permanent access to the Game. As a result, your active playing time will be limited. Moreover, You understand and accept that the Game experience of the Beta Version is limited.',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'You understand and accept that the Beta Version is available as is and may contain bugs, errors and other issues that could cause your system to malfunction or cause other failures, including loss of data.',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'NOVAQUARK reserves the right, at any time and without prior notice, to temporarily suspend access to the Game for technical reasons, including the need to update, maintain or improve the Game and, more generally, for all technical, organizational or expedient reasons. ',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'If You would like to give us feedback about your gaming experience or to share your experience, please submit it here:\nhttps://board.dualthegame.com/index.php?/forum/17-pre-Beta-feedback/.\nBy submitting your comments or suggestions regarding the Game, You agree to grant NOVAQUARK a non-exclusive, royalty-free, worldwide, transferable licence to any right, title or interest You may hold for your comments and suggestions for their entire term of protection. You also waive any moral rights that may apply. For all countries that do not allow the waiver of moral rights, You consent to the use of your suggestions or comments in the Game or in the advertising and promotion of the Game, and agree that such use (or any other related or ancillary use) does not constitute a violation of your moral rights.',
                  spans: [
                    {
                      start: 119,
                      end: 188,
                      type: 'hyperlink',
                      data: {
                        link_type: 'Web',
                        url: 'https://board.dualthegame.com/index.php?/forum/17-pre-alpha-feedback/',
                      },
                    },
                  ],
                },
                {
                  type: 'o-list-item',
                  text: 'All bugs should be reported here: https://support.dualthegame.com/hc/en-us.',
                  spans: [
                    {
                      start: 34,
                      end: 74,
                      type: 'hyperlink',
                      data: { link_type: 'Web', url: 'https://support.dualthegame.com/hc/en-us' },
                    },
                  ],
                },
                { type: 'heading2', text: 'CODE OF CONDUCT', spans: [{ start: 0, end: 15, type: 'strong' }] },
                {
                  type: 'paragraph',
                  text: 'When using the Game, You agree to comply with all applicable acts and regulations, as well as the rules established by NOVAQUARK that govern the use of the Game (hereinafter the “Code of Conduct”). ',
                  spans: [{ start: 179, end: 194, type: 'strong' }],
                },
                {
                  type: 'paragraph',
                  text: 'You must refrain from engaging in any behaviour that could harm NOVAQUARK’s image and/or reputation, that could harm one or more other Users or have a negative impact on their gaming experience, or that is detrimental to the proper functioning of the Game.\nMoreover, You must refrain, in particular, from:',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'Choosing a username (hereinafter “Display Name”) that is offensive, defamatory, vulgar, obscene, sexually explicit, racist, sexist, xenophobic or otherwise objectionable. You cannot use a spelling mistake or alternative spelling to get around this restriction on the choice of names and nicknames;',
                  spans: [{ start: 34, end: 46, type: 'strong' }],
                },
                {
                  type: 'o-list-item',
                  text: 'Publishing, sending or communicating any content or taking any action in connection with the Game that is inappropriate, indecent, obscene, pornographic, offensive, illegal, harmful, threatening or abusive, involves harassment, or is defamatory, vulgar, violent, sexually explicit, objectionable or otherwise unacceptable on the basis of race, gender, ethics, politics, etc.;',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'Publishing, sending or communicating hate speech involving specific individuals or groups of individuals;',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'Attacking, in any manner, an ethnic group, religion, gender or sexual orientation or identity;  ',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'Harassing or threatening other users and interfering in any way with their use and full enjoyment of the Game;',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'Discussing in any way real-world politics or real-world religion (regardless of the nature or content of the discussion);',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'Posting or disseminating personal identification information about yourself or other Users (doxing);',
                  spans: [],
                },
                { type: 'o-list-item', text: 'Promoting any illegal activity;', spans: [] },
                {
                  type: 'o-list-item',
                  text: 'Publicly revealing private discussions between a User and a NOVAQUARK employee;',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'Impersonating another player, moderator, administrator, NOVAQUARK employee or one of NOVAQUARK’s partners. You must not give the impression that a comment or communication is coming from any of the persons listed;',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'Sending content that infringes or violates the rights of others, including copyright, patents, trademarks, trade secrets, publicity or privacy rights or other rights;',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'Sending or facilitating the transmission of any content that contains viruses, corrupted data, Trojan horses, keyloggers, worms, spyware, time bombs, cancelbots or any other programs or techniques designed to damage, interfere with in a harmful manner, intercept, extract, gain unauthorized access to, or stealthily expropriate any system or data;',
                  spans: [{ start: 94, end: 95, type: 'em' }],
                },
                {
                  type: 'o-list-item',
                  text: 'Using any unauthorized third party programs, including mods, hacks, cheat codes, scripts, bots, trainers and automation programs that interact with the Game in any way, for any purpose;',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'Spamming discussions, whether for personal or commercial purposes, by disrupting the flow of conversations with repeated messages;',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'Participating in any fraudulent action against any other User of the Game, including by means of scamming or social engineering;',
                  spans: [{ start: 115, end: 116, type: 'em' }],
                },
                {
                  type: 'o-list-item',
                  text: 'Using any part of the Game to gather or collect information about other persons, including, but not limited to, email addresses; and',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'Modifying, by any means, Game files or attempting to sell or otherwise monetize the Game or its components (e.g., virtual objects or any Game codes or scripts). ',
                  spans: [],
                },
                {
                  type: 'paragraph',
                  text: 'NOVAQUARK reserves the exclusive and final right to review, delete, block, edit, move or disable any content appearing in the Game that violates the Code of Conduct or its Policy on Repeated Infringement. ',
                  spans: [],
                },
                {
                  type: 'paragraph',
                  text: 'You agree to report any behaviour that You believe to be in violation of this Code of Conduct to: Technical Assistance at https://support.dualthegame.com/hc/en-us; or one of the Community Managers on the forum by private message at https://board.dualthegame.com.',
                  spans: [
                    {
                      start: 122,
                      end: 162,
                      type: 'hyperlink',
                      data: { link_type: 'Web', url: 'https://support.dualthegame.com/hc/en-us' },
                    },
                    {
                      start: 232,
                      end: 261,
                      type: 'hyperlink',
                      data: { link_type: 'Web', url: 'https://board.dualthegame.com/' },
                    },
                  ],
                },
                {
                  type: 'paragraph',
                  text: 'In the event of a violation of the Code of Conduct or of the other provisions of this EULA, NOVAQUARK may take steps to suspend or close a User’s account or otherwise restrict a User from using the Game and/or associated User account.',
                  spans: [],
                },
                { type: 'heading2', text: 'USER-GENERATED CONTENT', spans: [{ start: 0, end: 22, type: 'strong' }] },
                {
                  type: 'o-list-item',
                  text: 'The use of the Game by Users results in the creation of user-generated content (hereinafter “Generated Content”), such as buildings or vehicles constructed using the Game’s tools.  ',
                  spans: [{ start: 93, end: 110, type: 'strong' }],
                },
                {
                  type: 'o-list-item',
                  text: 'By creating Generated Content, You grant NOVAQUARK, its licensors, licensees and any other persons designated by NOVAQUARK to have a non-exclusive, royalty-free, worldwide, transferable licence, for their term of protection, to use, reproduce, modify, adapt, execute, display, distribute and otherwise exploit and/or delete the Generated Content (or any part thereof) in any manner NOVAQUARK deems appropriate. In addition, You grant a personal, non-transferable licence to all Game Users for use of the Generated Content when using the Game, including all rights to reproduce, distribute, display, execute or otherwise license the Generated Content on a worldwide basis and for their term of protection.',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'You also waive any moral rights that may apply to your Generated Content. For all countries that do not allow the waiver of moral rights, You consent to the use of your Generated Content in the Game or in the advertising and promotion of the Game, and agree that such use (or any other related or ancillary use) does not constitute a violation of your moral rights.',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'By creating Generated Content, You are stating that the Content is not otherwise protected by copyright, moral rights, trademarks or other intellectual property rights, unless You are the owner of such rights or have a transferable licence to such rights. In all cases You declare that You are entitled to generate such Generated Content without infringing the rights of a third party.',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'You also acknowledge that You must not generate prohibited content (hereinafter “Prohibited Content”). Prohibited Content includes any Generated Content that violates the Code of Conduct and the rules set forth in clause 5.2, and includes, but is not limited to, the following matters: nudity, hate speech, self-harm, dangerous organizations, bullying and harassment, sexual exploitation and violence, criminal activity, violence and discussions about real-world politics or real-world religions.',
                  spans: [{ start: 81, end: 99, type: 'strong' }],
                },
                {
                  type: 'o-list-item',
                  text: 'NOVAQUARK reserves the exclusive and final right to review, delete, block, modify, move or disable Generated Content for any reason whatsoever, after prior notice. Under French law, NOVAQUARK has the status of host within the meaning of article 6 of the Loi pour la Confiance dans l’Économie numérique du 21 juin 2004 (Act for trust in the digital economy of June 21, 2004) related to User-Generated Content.',
                  spans: [{ start: 254, end: 317, type: 'em' }],
                },
                {
                  type: 'o-list-item',
                  text: 'If NOVAQUARK determines that Generated Content violates the provisions of this EULA, NOVAQUARK may take steps to suspend or close a User’s account or otherwise restrict a User from using the Game and/or associated User account.',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'You agree to report any Generated Content that You believe to be in violation of the provisions of this EULA.',
                  spans: [],
                },
                {
                  type: 'heading2',
                  text: 'INTERACTION WITH THIRD-PARTY SERVICES AND WEBSITES',
                  spans: [{ start: 0, end: 50, type: 'strong' }],
                },
                {
                  type: 'paragraph',
                  text: 'The Game may allow You to interact with third party websites, browsers and Internet services (hereinafter “Links”). The Links are not under the control of NOVAQUARK and NOVAQUARK is not responsible for the content of the Links. NOVAQUARK provides Links only for reasons of convenience, and the inclusion of a Link does not imply that NOVAQUARK endorses the Link or any association with its operators.',
                  spans: [{ start: 107, end: 112, type: 'strong' }],
                },
                { type: 'heading2', text: 'LIMITATION OF LIABILITY', spans: [{ start: 0, end: 23, type: 'strong' }] },
                {
                  type: 'paragraph',
                  text: 'CLAUSE 8.1 DOES NOT APPLY TO QUEBEC CONSUMERS.',
                  spans: [{ start: 0, end: 46, type: 'strong' }],
                },
                {
                  type: 'o-list-item',
                  text: 'You acknowledge that NOVAQUARK is not liable for (a) any indirect loss, claim or damage, or any punitive, special, incidental or consequential damage of any kind (including, but not limited to, indirect economic loss or data loss or corruption) or (b) any (direct or indirect) loss of profits, in all cases, whether it arises from a contract, tort liability (including negligence) or strict liability, or any other cause relating to or in connection with (i) any use of the Game or the application of this EULA or (ii) any unavailability of the Game regardless of the duration of such unavailability.',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'Due to the risks involved in the use of the Internet, NOVAQUARK cannot be held liable for any damage or viruses that may affect your devices or other property when You use the Game.',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'You agree to defend, indemnify and hold NOVAQUARK and its affiliates harmless from and against any and all liability, claims, losses, costs and expenses, including lawyers’ fees, arising out of (a) your use of the Game or activities related to the Game (including, but not limited to, the creation and use of Generated Content); (b) any violation of the EULA by You; or (c) any allegation that the Content You make available in the Game is an infringement of copyright, trade secrets, registered trademarks, intellectual property rights or any other third-party rights.  ',
                  spans: [],
                },
                { type: 'heading2', text: 'DATA PROTECTION', spans: [{ start: 0, end: 15, type: 'strong' }] },
                {
                  type: 'paragraph',
                  text: 'NOVAQUARK undertakes to use any personal data that may be collected about You only in accordance with the NOVAQUARK Privacy Policy, which You must read and formally accept before using the Game.',
                  spans: [
                    { start: 106, end: 130, type: 'strong' },
                    {
                      start: 106,
                      end: 130,
                      type: 'hyperlink',
                      data: { link_type: 'Web', url: 'https://www.dualthegame.com/en/privacy-policy/' },
                    },
                  ],
                },
                { type: 'heading2', text: 'GENERAL', spans: [{ start: 0, end: 7, type: 'strong' }] },
                {
                  type: 'paragraph',
                  text: 'CLAUSE 10.2 DOES NOT APPLY TO QUEBEC CONSUMERS.',
                  spans: [{ start: 0, end: 47, type: 'strong' }],
                },
                {
                  type: 'o-list-item',
                  text: 'In the event of a contradiction with versions in another language, only the French version of this EULA will be legally valid.',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'Any failure or delay on the part of NOVAQUARK to assert its rights under the terms of this EULA will not be deemed to be a waiver of such rights or any other rights unless NOVAQUARK acknowledges and agrees to such a waiver in writing.',
                  spans: [],
                },
                {
                  type: 'o-list-item',
                  text: 'This EULA supersedes and replaces any prior agreements or memoranda relating to the Game between NOVAQUARK and You, including, in particular, the EULA for the alpha version of the Game.',
                  spans: [],
                },
                {
                  type: 'heading2',
                  text: 'APPLICABLE LAWS AND PLACE OF JURISDICTION',
                  spans: [{ start: 0, end: 41, type: 'strong' }],
                },
                {
                  type: 'paragraph',
                  text: 'This EULA is governed by the laws of the Province of Quebec and the federal laws of Canada applicable therein. Any dispute in connection with this EULA or the Game must be submitted to the exclusive jurisdiction of the courts in the judicial district of Montréal.',
                  spans: [],
                },
              ],
              content_after: [],
            },
          },
          {
            id: 'X5cowhEAAB0Ap7vp',
            uid: null,
            type: 'legal',
            href: 'https://dualuniverse.cdn.prismic.io/api/v2/documents/search?ref=X9oo2xIAACcA7ErJ&q=%5B%5B%3Ad+%3D+at%28document.id%2C+%22X5cowhEAAB0Ap7vp%22%29+%5D%5D',
            tags: [],
            first_publication_date: '2020-10-26T19:51:33+0000',
            last_publication_date: '2020-12-07T15:21:47+0000',
            slugs: ['privacy-policy'],
            linked_documents: [],
            lang: 'en-us',
            alternate_languages: [
              { id: 'X5c2yREAAB4Ap_wJ', type: 'legal', lang: 'de-de' },
              { id: 'X5cqCxEAAB0Ap8HA', type: 'legal', lang: 'fr-fr' },
            ],
            data: {
              title: [{ type: 'heading1', text: 'Privacy Policy', spans: [] }],
              slug: 'privacy-policy',
              content_before: [
                { type: 'paragraph', text: 'Privacy Policy', spans: [] },
                {
                  type: 'paragraph',
                  text: 'Latest update: August 2020',
                  spans: [{ start: 0, end: 14, type: 'strong' }],
                },
                { type: 'paragraph', text: 'Thank you for your visit!', spans: [] },
                {
                  type: 'paragraph',
                  text: 'This Privacy Policy (“Privacy Policy”) describes how we use the information that we collect about you (“User” or “you”) and the choices that you make about the way your information is collected and used. In this Policy, “Novaquark,” “we,” “us” or “our“ refers to Novaquark, Inc., Novaquark SAS and our affiliates.',
                  spans: [{ start: 22, end: 36, type: 'strong' }],
                },
                { type: 'paragraph', text: 'This Policy applies to:', spans: [] },
                {
                  type: 'list-item',
                  text: 'The use of our dualuniverse.game website and any related domains or sub-domains, including dualthegame.com (the “Website”);',
                  spans: [{ start: 113, end: 120, type: 'strong' }],
                },
                {
                  type: 'list-item',
                  text: 'The use of the Dual Universe video game, including your interaction with other players and your actions within the video game (“Dual Universe”);',
                  spans: [{ start: 128, end: 141, type: 'strong' }],
                },
                {
                  type: 'list-item',
                  text: 'Your interaction with us, including your technical support requests, emails or messages on social media; and',
                  spans: [],
                },
                {
                  type: 'list-item',
                  text: 'Our marketing activities, in particular those done through the use of cookies or communication by email.',
                  spans: [],
                },
                {
                  type: 'paragraph',
                  text: 'In this Policy, we will use the term “Services” to refer to all such applications. This Policy does not apply to external services, applications and software that you may access through our Services, including third party websites, banner advertisements and links shared by us or our users. We encourage you to read the privacy policies of such third parties before using their services given that we have no control over the practices of third parties.',
                  spans: [{ start: 38, end: 46, type: 'strong' }],
                },
                { type: 'heading2', text: 'Contacting us', spans: [] },
                {
                  type: 'paragraph',
                  text: 'If you have any questions, comments or remarks, or if you wish to exercise your rights, you may contact us using the following form https://dualthegame.com/privacy or by email at privacy@novaquark.com. You may otherwise contact us by mail at the following address:',
                  spans: [],
                },
                {
                  type: 'preformatted',
                  text: 'Care of: Personal Data Protection Officer\nNOVAQUARK\n43 Rue Camille Desmoulins\n92130 Issy-les-Moulineaux\nFrance',
                  spans: [],
                },
                { type: 'paragraph', text: 'You may also contact us by phone at +33 (0)9 67 48 04 04.', spans: [] },
                {
                  type: 'paragraph',
                  text: 'Within the framework provided by European laws on the handling of personal data, Novaquark SAS and Novaquark Inc. are jointly responsible for data processing. Novaquark SAS also represents Novaquark Inc. in the European Union. NOVAQUARK SAS, with €162,451.51 in capital, is registered with the Paris Trade and Companies Register under number 799 791 926, and its head office is located at 43 Rue Camille Desmoulins, 92130 Issy-les-Moulineaux.',
                  spans: [],
                },
                {
                  type: 'paragraph',
                  text: 'Novaquark Inc. has a registered office at 606 Cathcart Street, Suite 810, Montréal, Quebec, H3B 1K9. ',
                  spans: [],
                },
                { type: 'heading2', text: 'The personal information that we handle', spans: [] },
                {
                  type: 'paragraph',
                  text: 'As part of the provision of our services, we collect the information about you described below, including, in particular, your activity in Dual Universe.',
                  spans: [],
                },
                {
                  type: 'list-item',
                  text: 'Identifying information\nYou may create an account with us to access certain features on our website and/or to play Dual Universe and download the game. An email address is required to create an account. \nShould you decide to join Dual Universe, we will collect your first name, last name, nickname, email address and password. Your nickname will be public and you may change it once every six (6) months, unless your rights permit otherwise. When your account is created, a numeric player ID will also be created to identify your account.\nWhen you create your profile, you may add additional and optional personal information, including a description of you, an avatar, your geographic location, and links to some of your social media accounts. You may include your shipping address so that we may send you physical items, such as rewards, as part of your use of Dual Universe. Depending on the preferences that you apply when creating your profile, some of this optional information and your username will be made public and shared with other users.\nIf you wish to use the forms on our website, you will be required to include your email address, information about your request as well as your nickname and any other requested mandatory information. You will also have the option of uploading files, which is information that we will process as well. \nYou may use our referral features to invite friends to play Dual Universe or try the beta version, in which case we process the information that you provide about such friends, namely their email addresses. We will not use this information for any other purpose.',
                  spans: [{ start: 0, end: 23, type: 'strong' }],
                },
                {
                  type: 'list-item',
                  text: 'Financial information\nWhen you sign up for Dual Universe, we will process your credit card number, its expiration date, the three-digit code on its back and your postal code. Such financial information will be processed by a subcontractor. You may save this information for future use. We cannot see or access your financial information, except what is identified in the “Transaction history“ category below in this section. ',
                  spans: [{ start: 0, end: 21, type: 'strong' }],
                },
                {
                  type: 'list-item',
                  text: 'Player profile information\nWhen you use the Dual Universe game, we will process information associated with your player profile, in particular your inventory, gaming experience and progress. We will also collect gaming statistics based on your usage, such as your habits and reactions.',
                  spans: [{ start: 0, end: 26, type: 'strong' }],
                },
                {
                  type: 'list-item',
                  text: 'Transaction history\nWhen you perform financial transactions, in particular those to obtain the Dual Universe game or items in the game, we will have access to your transaction history, including the package that you select to subscribe to the Dual Universe game, the date and amount of your payment, the payment method used, your country, your nickname, and, if applicable, the month and year that your credit card expires and the card issuer (Visa, MasterCard, etc.), as well as the first six and last four digits of your credit card.',
                  spans: [{ start: 0, end: 19, type: 'strong' }],
                },
                {
                  type: 'list-item',
                  text: 'Communication information\nWhen you use our Services, you may interact with us and other users in a variety of ways. When you interact with us, we will process any personal information that you choose to share. We will also process any information that you share when you use discussion forums, send us your suggestions, or use the chat feature available in Dual Universe. If you decide to join our forums on the platform Discord, you will need to connect your Dual Universe account with your Discord account in order for your nickname to be visible in these Discord forums. You will be able to change it in other forums.',
                  spans: [{ start: 0, end: 25, type: 'strong' }],
                },
                {
                  type: 'list-item',
                  text: 'Electronic information\nOur Services automatically process certain electronic information, including information about your use of our Website, including, but not limited to, your IP address (an IP address is a numeric address assigned to your computer by your internet service provider that can be used to identify your computer when you log on), browser type and screen resolution.\nWe use a subcontractor to prevent cheating in our Dual Universe game. Said subcontractor monitors your use of Dual Universe to identify suspicious events, in particular alterations to the memory allocated to the Dual Universe game and changes to files related to Dual Universe. To do so, said subcontractor collects electronic information about your use, for example, information about your operating system, your IP address, your Dual Universe usage strategies and habits, the electronic equipment used and the integrity of memory or files relevant to anti-cheating analyses, if necessary. All such information is encrypted when transferred to the subcontractor. The cheat monitoring system is only active when you play the game. Therefore, if the game is not open, no data is collected. ',
                  spans: [{ start: 0, end: 22, type: 'strong' }],
                },
                {
                  type: 'list-item',
                  text: 'Usage and performance information\nWe use cookies and third parties to obtain statistics about the use and performance of our Services, in particular the number of visits to a page on our Website, the time spent on our Website and the use of certain features by our users. Usage and performance information also includes bugs that may occur, electronic information, identifiers such as IP addresses and logs of events triggered by players in game.\nTo learn more about the use of our cookies, please read our Cookie Policy. Such information is aggregated to better understand traffic in general, not to track you individually. It does not allow us to identify you directly.',
                  spans: [{ start: 0, end: 33, type: 'strong' }],
                },
                {
                  type: 'list-item',
                  text: 'Social media information\nWe process personal information that is publicly visible during your interactions with us through social media, such as Twitter, Facebook and YouTube. This may include your name, telephone number and comments on our posts, or, in the case of contests, additional information as specified. Such information may be collected through our subcontractors, and may include electronic or purchase history information.',
                  spans: [{ start: 0, end: 24, type: 'strong' }],
                },
                {
                  type: 'list-item',
                  text: 'Employment information\nIf you apply for a job through our Website, you will be redirected to an external service that collects the personal information that we require. You will need to create an account or sign in with LinkedIn and provide the required information, including your name, resume and cover letter. In some jurisdictions and in accordance with applicable laws, we may request a police records check, in particular in terms of immigration.',
                  spans: [{ start: 0, end: 22, type: 'strong' }],
                },
                { type: 'heading2', text: 'How we use your personal information', spans: [] },
                {
                  type: 'paragraph',
                  text: 'We use your personal information for the purposes and under the legal authority listed in the table below.',
                  spans: [],
                },
                {
                  type: 'paragraph',
                  text: 'Such legal authority applies in the European Union and may not apply in your jurisdiction. Where applicable, we avail ourselves of valid legal authority to process personal information in your jurisdiction, and consent in particular. When we process your personal information for our legitimate interests, generally you may object if you have such a right under applicable law.',
                  spans: [],
                },
                {
                  type: 'paragraph',
                  text: 'Where consent is used, you have the right to withdraw such consent, though you may not be able to use certain parts of our Services after you withdraw your consent.',
                  spans: [],
                },
                {
                  type: 'paragraph',
                  text: 'Please refer to our Cookie Policy for information on managing your preferences with respect to cookies and tracking technologies.',
                  spans: [
                    {
                      start: 20,
                      end: 33,
                      type: 'hyperlink',
                      data: { link_type: 'Web', url: 'https://www.dualuniverse.game/legal/cookie-policy' },
                    },
                  ],
                },
              ],
              content_after: [
                {
                  type: 'paragraph',
                  text: 'We may also de-identify (anonymize) some of your personal information in order to aggregate it, in which case such information is no longer personal information and is not subject to this Privacy Policy.',
                  spans: [],
                },
                { type: 'heading2', text: 'How long we retain your personal information', spans: [] },
                {
                  type: 'paragraph',
                  text: 'The length of time we retain your personal information depends on the purpose for which we collected it. Generally, we retain your personal information only as long as necessary to process it for the purposes for which it was collected, as identified, or longer if required by applicable law. For example, we keep your player profile information for as long as you remain a player so that you may reuse your account. When you request deletion of your account or your account is deactivated, we will then delete it within a reasonable amount of time. If you are applying for a position, your employment information will be retained for a period of two (2) years from the time we last contact you.',
                  spans: [],
                },
                {
                  type: 'paragraph',
                  text: 'Regarding the length of time that we retain cookies, please refer to our Cookie Policy for more information.',
                  spans: [
                    {
                      start: 73,
                      end: 86,
                      type: 'hyperlink',
                      data: { link_type: 'Web', url: 'https://www.dualuniverse.game/legal/cookie-policy' },
                    },
                  ],
                },
                {
                  type: 'heading2',
                  text: 'What happens if you choose to not share your personal information',
                  spans: [],
                },
                {
                  type: 'paragraph',
                  text: 'You are under no obligation to share personal information with us, but we will not be able to provide the Services to you without your personal information.',
                  spans: [],
                },
                { type: 'heading2', text: 'Our procedure with respect to personal information from minors', spans: [] },
                {
                  type: 'paragraph',
                  text: 'Our Services are not available to children under the age of 16, and we do not voluntarily collect personal information from children under the age of 16. If we discover that one of the accounts belongs to a child under the age of 16, we will delete the account and all personal information related to that account. ',
                  spans: [],
                },
                { type: 'heading2', text: 'How we keep your personal information secure', spans: [] },
                {
                  type: 'paragraph',
                  text: 'We take reasonable steps to keep your personal information secure. The data centers used to process your payments are SOC-2, PCI DSS and ISO 27001 certified. Our payment provider performs regular penetration testing, and we have protections in place against distributed denial-of-service attacks. We also use encryption, and access to your personal information is limited to the employees who need to access it.',
                  spans: [],
                },
                {
                  type: 'paragraph',
                  text: 'It is, however, up to you to keep your passwords confidential and use secure networks to connect to Dual Universe.',
                  spans: [],
                },
                { type: 'heading2', text: 'Who we share your personal information with', spans: [] },
                {
                  type: 'paragraph',
                  text: 'We do not sell your personal information to third parties, but we do share it where necessary. The following is a list of categories of third parties with whom we share your personal information and an explanation as to why we share your personal information. Before we share your personal information, we ensure that the personal information practices of these third parties are adequate, and we enter into contracts to protect your personal information.',
                  spans: [],
                },
                {
                  type: 'list-item',
                  text: 'Information technology service providers\nIn order to provide the Services to you, we use third parties for our information technology infrastructure. For example, our Services are hosted on Amazon Web Services.',
                  spans: [{ start: 0, end: 40, type: 'strong' }],
                },
                {
                  type: 'list-item',
                  text: 'Support and incident management service providers\nIn order to address your help or support requests when using our Services, for example, to address a bug in Dual Universe, we use a computer system called Zendesk and external subcontractors for sorting and managing level 1 requests. These third parties process identifying and player profile information, for example, your language, avatar, social media accounts and whether you are logged into your player account.',
                  spans: [{ start: 0, end: 49, type: 'strong' }],
                },
                {
                  type: 'list-item',
                  text: 'Authorized global distributor\nWe use third parties to process your payments and manage your inventory. Xsolla is one example. For more information on how Xsolla processes personal information, please read its privacy policy.',
                  spans: [{ start: 0, end: 29, type: 'strong' }],
                },
                {
                  type: 'list-item',
                  text: 'Data and performance analysis service providers \nWe work with third parties such as Play in Lab or Sentry to identify, diagnose and optimize Dual Universe in order to prevent technical issues and monitor the performance of Dual Universe. You can read Sentry’s privacy policy here. We may also use data analysis service providers for our Website, in particular Google Analytics. Our use of cookies is further described this Cookie Policy.',
                  spans: [{ start: 0, end: 48, type: 'strong' }],
                },
                {
                  type: 'list-item',
                  text: 'Communication tool suppliers\nWe use suppliers to send you newsletters and push notifications or to manage our social media, such as for new posts. You can read MailChimp’s privacy policy here. We also use MailJet in order to send you emails and obtain relevant statistics. You can read MailJet’s privacy policy here.',
                  spans: [{ start: 0, end: 28, type: 'strong' }],
                },
                {
                  type: 'list-item',
                  text: 'Compliance and fraud prevention service providers\nWe use third parties such as Int3 Software AB to ensure that our Services are compliant and to monitor potential fraud, including cheating in Dual Universe. Int3 Software AB has access to your account identifier and the programs that you run while playing the game.',
                  spans: [{ start: 0, end: 49, type: 'strong' }],
                },
                {
                  type: 'list-item',
                  text: 'Discussion platforms\nFor example, you may access Discord through your account on our Website to interact with other players by linking your accounts. When you do so, electronic information and information from your player profile or your nickname will be sent to Discord.',
                  spans: [{ start: 0, end: 20, type: 'strong' }],
                },
                {
                  type: 'list-item',
                  text: 'Delivery partners\nIf you order merchandise, we will need to share your personal information with the entity used to deliver the merchandise.',
                  spans: [{ start: 0, end: 17, type: 'strong' }],
                },
                {
                  type: 'paragraph',
                  text: 'In the event of a request by government authorities or one arising from a subpoena or similar procedure, we may be obliged to transmit some of your personal information to meet our legal obligations. We ensure that requests are lawful before transferring such information, and we transfer only what is necessary to fulfill the request.',
                  spans: [],
                },
                {
                  type: 'paragraph',
                  text: 'In the event of business transactions, including an acquisition or sale of some or all of our assets, your personal information may be transferred to another entity that will be subject to this Privacy Policy. We may also transfer your personal information to one of our affiliates for the purpose of managing, administering and providing the Services.',
                  spans: [],
                },
                {
                  type: 'heading2',
                  text: 'Where we host your personal information and in which countries processing takes place',
                  spans: [],
                },
                {
                  type: 'paragraph',
                  text: 'Your player profile information is hosted in the United States, with redundancy in data centres in Canada and the European Union. We also have backups on servers in France and Canada. Personal information processed for commercial purposes in Canada is subject to an adequacy decision under European laws that allow us to transfer your personal information to that country, as its laws are deemed adequate by the competent authorities.',
                  spans: [],
                },
                {
                  type: 'paragraph',
                  text: 'However, our service providers may have data centres elsewhere in the world. We ensure that we have satisfactory mechanisms. In this case, we have appropriate safeguards in place, such as standard contractual clauses.',
                  spans: [],
                },
                {
                  type: 'paragraph',
                  text: 'Our third parties may process your personal information outside the European Union, in which case we ensure that mechanisms are in place to provide the required protection for personal information in accordance with applicable law.',
                  spans: [],
                },
                {
                  type: 'heading2',
                  text: 'Automated processing of personal information in decision-making',
                  spans: [],
                },
                {
                  type: 'paragraph',
                  text: 'We use automated processing of your personal information to make our Services compliant, and specifically to (a) prevent cheating; (b) prevent fraud; and (c) ensure that our Services are secure. We consider such processing to be necessary for the conclusion or performance of a contract with you. However, you have the right to obtain the intervention of an individual in order to validate the conclusions of such automated processing or challenge any decision resulting from such processing of your personal information. We allow all users to exercise these rights even if they are not specifically mentioned in applicable laws.',
                  spans: [],
                },
                {
                  type: 'heading2',
                  text: 'European Union – Your rights regarding your personal information',
                  spans: [],
                },
                {
                  type: 'paragraph',
                  text: 'If you are located in the European Union, the following rights generally apply, and may be subject to certain exceptions:',
                  spans: [],
                },
                {
                  type: 'list-item',
                  text: 'Right to access your data\nYou have the right to receive confirmation as to whether or not your personal data is processed and, once processed, you have the right to access such data. This right also includes the right to receive a copy of the processed data.',
                  spans: [{ start: 0, end: 25, type: 'strong' }],
                },
                {
                  type: 'list-item',
                  text: 'Right to request the rectification of your data if it is incorrect\nYou have the right to request that your data be changed, updated or supplemented if it is inaccurate, incorrect, incomplete or out of date. However, please note that you may make changes to much of your personal information yourself directly in your Dual Universe player account.',
                  spans: [{ start: 0, end: 66, type: 'strong' }],
                },
                {
                  type: 'list-item',
                  text: 'Right to erasure (right to be forgotten)\nYou have the right to request the erasure of your data only for the reasons provided for by applicable regulations, and in particular where:\n- The data is no longer necessary for the purposes for which it was collected or otherwise processed;\n- You withdraw your consent on which the processing is authorized and there is no legal authority for the processing;\n- You object to the processing and there are no overriding legitimate grounds for the processing;\n- You consider that your data has been processed unlawfully;\n- Your data must be deleted in order to comply with a legal obligation.',
                  spans: [{ start: 0, end: 40, type: 'strong' }],
                },
                {
                  type: 'list-item',
                  text: 'Right to restriction of processing of your data\nYou have the right to ask us to limit the use of your data only for the reasons provided for by applicable regulations, and in particular where:\n- You contest the accuracy of the data we process about you; or\n- You consider the processing to be unlawful and you object to the processing of your data.',
                  spans: [{ start: 0, end: 47, type: 'strong' }],
                },
                {
                  type: 'list-item',
                  text: 'Right to object to the processing by withdrawing your consent\nWhere we have obtained your consent to process your personal information for certain activities, with the mention that such withdrawal may not affect the lawfulness of the processing done in accordance with the consent given prior to such withdrawal. We will cease to carry out the specific activity that you have previously given your consent to, unless we consider that there is another reason to warrant that your data continue to be processed for this purpose, of which we will inform you as appropriate.',
                  spans: [{ start: 0, end: 61, type: 'strong' }],
                },
                {
                  type: 'list-item',
                  text: 'Right to data transfer\nYou have the right to retrieve your data provided to Novaquark, in a structured, commonly used, machine-readable format, for the purpose of transferring it to another data controller, for example for changing providers.',
                  spans: [{ start: 0, end: 22, type: 'strong' }],
                },
                {
                  type: 'list-item',
                  text: 'Right to file a complaint to the Commission Nationale de l’Informatique et des Libertés (CNIL) [French data protection agency] or your local authority if you feel that we have failed to meet our obligations in our management and processing of your personal data, you may at any time file a complaint or claim with the competent authorities. In France, the competent authority is the Commission Nationale de l’Informatique et des Libertés (CNIL). You may file a complaint online by clicking on the following link https://www.cnil.fr/fr/plaintes/internet.[French only]',
                  spans: [{ start: 0, end: 151, type: 'strong' }],
                },
                {
                  type: 'heading2',
                  text: 'Other jurisdictions – Your rights regarding your personal information',
                  spans: [],
                },
                {
                  type: 'paragraph',
                  text: 'Under applicable laws, you may have a number of rights with respect to your personal information, which generally include: ',
                  spans: [],
                },
                {
                  type: 'list-item',
                  text: 'Right to access your data\nYou have the right to receive confirmation as to whether or not your personal information is processed and, once processed, you have the right to access such information. This right also includes the right to receive a copy of the processed information.',
                  spans: [{ start: 0, end: 25, type: 'strong' }],
                },
                {
                  type: 'list-item',
                  text: 'Right to request rectification of your personal information\nYou have the right to request that your personal information be changed, updated or supplemented if it is inaccurate, incorrect, incomplete or out of date. However, please note that you may make changes to much of your personal information yourself directly on the Dual Universe Website.',
                  spans: [{ start: 0, end: 59, type: 'strong' }],
                },
                {
                  type: 'paragraph',
                  text: 'When exercising your rights, you may refer to local authorities for more information. For example, in Canada, the Office of the Privacy Commissioner of Canada has issued this guide for more information.',
                  spans: [],
                },
                {
                  type: 'paragraph',
                  text: 'If you are dissatisfied with the way in which we collect, use or disclose your personal information, you may file a complaint directly with your local authorities. In Canada, complaints are usually filed with the Office of the Privacy Commissioner of Canada. You may refer to the Guide to the PIPEDA Complaint Process published by the Office of the Privacy Commissioner of Canada to better understand the complaint process, or call 1-800-282-1376 (toll-free). The Office of the Privacy Commissioner of Canada also has an online complaint form on the protection of privacy. ',
                  spans: [],
                },
                { type: 'heading2', text: 'How to exercise your rights', spans: [] },
                {
                  type: 'paragraph',
                  text: 'You may exercise the aforementioned rights by sending a detailed written request to https://support.dualthegame.com or using the contact information in section 1 of this Privacy Policy. If possible, we ask that you sign in with your player account when you submit your requests, so that we may easily identify you without the need to provide further personal information.',
                  spans: [],
                },
                {
                  type: 'paragraph',
                  text: 'In order to prevent unauthorized sharing of your personal information, we will need to identify you. We will try to use the personal information at our disposal to identify you, but if doing so is not possible, we will ask for additional personal information, such as a piece of identification, in particular if you send your request by email rather than though a support ticket, which is the method that we recommend. This information will not be used for any other purpose.',
                  spans: [],
                },
                {
                  type: 'paragraph',
                  text: 'If your request is deemed admissible, we will answer you within one month of receiving your request to exercise your rights. If we need additional time due to the circumstances of your request, we will inform you within the initial one-month period. If we are unable to grant your request to exercise your rights, we will inform you within one month with the relevant information to this effect, as well as the contact information to file a complaint if you are not satisfied with our answer.',
                  spans: [],
                },
                {
                  type: 'paragraph',
                  text: 'Requests to exercise your rights are generally free of charge except in appropriate circumstances and as permitted by applicable law, including if your requests are manifestly unfounded or excessive. In this case, we may refuse to answer.',
                  spans: [],
                },
                { type: 'heading2', text: 'Changes to this Privacy Policy', spans: [] },
                {
                  type: 'paragraph',
                  text: 'We will be updating this Privacy Policy regularly to reflect our current practices. You can find the latest update date above. If you have subscribed to our Services, we will provide you with notice of changes to our Privacy Policy when we make changes that affect the way that we process your personal information and that apply you.  However, we invite you to consult this page regularly.',
                  spans: [],
                },
              ],
            },
          },
        ],
        version: '6c549e9',
        license: 'All Rights Reserved',
      },
      legal_page: {
        page: 1,
        results_per_page: 20,
        results_size: 1,
        total_results_size: 1,
        total_pages: 1,
        next_page: null,
        prev_page: null,
        results: [
          {
            id: 'X2ILohIAACkAdWSa',
            uid: null,
            type: 'legal_page',
            href: 'https://dualuniverse.cdn.prismic.io/api/v2/documents/search?ref=X9oo2xIAACcA7ErJ&q=%5B%5B%3Ad+%3D+at%28document.id%2C+%22X2ILohIAACkAdWSa%22%29+%5D%5D',
            tags: [],
            first_publication_date: '2020-09-16T14:33:46+0000',
            last_publication_date: '2020-11-13T13:31:08+0000',
            slugs: ['legal-page', 'novaquark-legal-policies', 'untitled-document'],
            linked_documents: [],
            lang: 'en-us',
            alternate_languages: [
              { id: 'X5cn1xEAAB4Ap7ef', type: 'legal_page', lang: 'de-de' },
              { id: 'X5cntxEAACAAp7cK', type: 'legal_page', lang: 'fr-fr' },
            ],
            data: {
              component_title: [{ type: 'heading1', text: 'Legal Page', spans: [] }],
              page_title: [{ type: 'heading1', text: 'Legal Policies', spans: [] }],
              pages: [
                {
                  page: {
                    id: 'X5cowhEAAB0Ap7vp',
                    type: 'legal',
                    tags: [],
                    slug: 'privacy-policy',
                    lang: 'en-us',
                    link_type: 'Document',
                    isBroken: false,
                  },
                },
                {
                  page: {
                    id: 'X5cokhEAACAAp7r_',
                    type: 'legal',
                    tags: [],
                    slug: 'terms-and-conditions',
                    lang: 'en-us',
                    link_type: 'Document',
                    isBroken: false,
                  },
                },
                {
                  page: {
                    id: 'X5cijhEAAB0Ap5-J',
                    type: 'legal',
                    tags: [],
                    slug: 'cookie-policy',
                    lang: 'en-us',
                    link_type: 'Document',
                    isBroken: false,
                  },
                },
                {
                  page: {
                    id: 'X5coaREAAB8Ap7o7',
                    type: 'legal',
                    tags: [],
                    slug: 'eula',
                    lang: 'en-us',
                    link_type: 'Document',
                    isBroken: false,
                  },
                },
                {
                  page: {
                    id: 'X5co-REAAB4Ap7zb',
                    type: 'legal',
                    tags: [],
                    slug: 'code-of-conduct',
                    lang: 'en-us',
                    link_type: 'Document',
                    isBroken: false,
                  },
                },
              ],
            },
          },
        ],
        version: '6c549e9',
        license: 'All Rights Reserved',
      },
      menu: {
        page: 1,
        results_per_page: 20,
        results_size: 1,
        total_results_size: 1,
        total_pages: 1,
        next_page: null,
        prev_page: null,
        results: [
          {
            id: 'X2JKUxIAACYAdoy5',
            uid: null,
            type: 'menu',
            href: 'https://dualuniverse.cdn.prismic.io/api/v2/documents/search?ref=X9oo2xIAACcA7ErJ&q=%5B%5B%3Ad+%3D+at%28document.id%2C+%22X2JKUxIAACYAdoy5%22%29+%5D%5D',
            tags: [],
            first_publication_date: '2020-09-16T17:24:43+0000',
            last_publication_date: '2020-11-23T23:03:34+0000',
            slugs: ['menu', 'the-game', 'untitled-document'],
            linked_documents: [],
            lang: 'en-us',
            alternate_languages: [
              { id: 'X3ToWhAAAMvwur0O', type: 'menu', lang: 'fr-fr' },
              { id: 'X3TwdhAAAFzwuuHL', type: 'menu', lang: 'de-de' },
            ],
            data: {
              title: [{ type: 'heading1', text: 'Menu', spans: [] }],
              play: [{ type: 'heading1', text: 'Play now', spans: [] }],
              login: [{ type: 'heading1', text: 'Log in', spans: [] }],
              logo: {
                dimensions: { width: 137, height: 80 },
                alt: null,
                copyright: null,
                url: 'https://prismic-io.s3.amazonaws.com/dualuniverse/f1abcdf2-fd01-4ead-9327-a09bb06651c0_Logo-DU.svg',
              },
              body: [
                { slice_type: 'main-links', slice_label: null, items: [{}], primary: { name: 'The Game', link: null } },
                {
                  slice_type: 'navbar_categories',
                  slice_label: null,
                  items: [
                    {
                      headercontent: 'Discover Dual Universe',
                      headerlink: 'https://www.dualuniverse.game/',
                      confirm_external_link: false,
                    },
                    {
                      headercontent: 'Vote on features',
                      headerlink: 'https://upvote.dualuniverse.game/',
                      confirm_external_link: false,
                    },
                    {
                      headercontent: 'Content Creator Program',
                      headerlink: 'https://www.dualuniverse.game/content-creators',
                      confirm_external_link: false,
                    },
                    { headercontent: 'DU Creators', headerlink: null, confirm_external_link: true },
                  ],
                  primary: { headername: 'The Game' },
                },
                { slice_type: 'main-links', slice_label: null, items: [{}], primary: { name: 'News', link: '/news' } },
                {
                  slice_type: 'main-links',
                  slice_label: null,
                  items: [{}],
                  primary: { name: 'Recruit a friend', link: '/recruit-a-friend' },
                },
                {
                  slice_type: 'main-links',
                  slice_label: null,
                  items: [{}],
                  primary: { name: 'Community', link: null },
                },
                {
                  slice_type: 'navbar_categories',
                  slice_label: null,
                  items: [
                    {
                      headercontent: 'Community site',
                      headerlink: 'https://community.dualthegame.com/',
                      confirm_external_link: false,
                    },
                    {
                      headercontent: 'Forums',
                      headerlink: 'https://board.dualthegame.com/',
                      confirm_external_link: false,
                    },
                    {
                      headercontent: 'Discord',
                      headerlink: 'https://discord.com/invite/dualuniverse',
                      confirm_external_link: false,
                    },
                  ],
                  primary: { headername: 'Community' },
                },
                { slice_type: 'main-links', slice_label: null, items: [{}], primary: { name: 'Help', link: null } },
                {
                  slice_type: 'navbar_categories',
                  slice_label: null,
                  items: [
                    {
                      headercontent: 'Support',
                      headerlink: 'https://support.dualthegame.com',
                      confirm_external_link: false,
                    },
                    {
                      headercontent: 'Server status',
                      headerlink: 'https://www.dualuniverse.game/server-status',
                      confirm_external_link: false,
                    },
                    {
                      headercontent: 'Press & Contact',
                      headerlink: 'https://www.dualuniverse.game/contact',
                      confirm_external_link: false,
                    },
                    {
                      headercontent: 'Content Creator Program',
                      headerlink: 'https://www.dualuniverse.game/content-creators',
                      confirm_external_link: false,
                    },
                  ],
                  primary: { headername: 'Help' },
                },
              ],
            },
          },
        ],
        version: '6c549e9',
        license: 'All Rights Reserved',
      },
      alert: {
        page: 1,
        results_per_page: 20,
        results_size: 1,
        total_results_size: 1,
        total_pages: 1,
        next_page: null,
        prev_page: null,
        results: [
          {
            id: 'X70RsRIAACIAqBRa',
            uid: null,
            type: 'alert',
            href: 'https://dualuniverse.cdn.prismic.io/api/v2/documents/search?ref=X9oo2xIAACcA7ErJ&q=%5B%5B%3Ad+%3D+at%28document.id%2C+%22X70RsRIAACIAqBRa%22%29+%5D%5D',
            tags: [],
            first_publication_date: '2020-11-24T13:59:16+0000',
            last_publication_date: '2020-11-24T14:34:45+0000',
            slugs: ['alert', '-initial-test-is-simply-dummy-text-of-the-printing-and-typesetting-industry.'],
            linked_documents: [],
            lang: 'en-us',
            alternate_languages: [],
            data: {
              component_title: [{ type: 'heading1', text: 'Alert', spans: [] }],
              messages: [
                {
                  message_content: [
                    {
                      type: 'paragraph',
                      text: '🩸 Initial Test is simply dummy text of the printing and typesetting industry.',
                      spans: [],
                    },
                  ],
                  message_shown: false,
                },
                { message_content: [{ type: 'paragraph', text: 'test2', spans: [] }], message_shown: false },
              ],
            },
          },
        ],
        version: '6c549e9',
        license: 'All Rights Reserved',
      },
      cookie_notice: {
        page: 1,
        results_per_page: 20,
        results_size: 1,
        total_results_size: 1,
        total_pages: 1,
        next_page: null,
        prev_page: null,
        results: [
          {
            id: 'X5g-bREAAB0ArJg4',
            uid: null,
            type: 'cookie_notice',
            href: 'https://dualuniverse.cdn.prismic.io/api/v2/documents/search?ref=X9oo2xIAACcA7ErJ&q=%5B%5B%3Ad+%3D+at%28document.id%2C+%22X5g-bREAAB0ArJg4%22%29+%5D%5D',
            tags: [],
            first_publication_date: '2020-10-27T15:36:16+0000',
            last_publication_date: '2020-11-12T20:13:41+0000',
            slugs: ['cookie-notice'],
            linked_documents: [],
            lang: 'en-us',
            alternate_languages: [
              { id: 'X5hG_REAACAArL7A', type: 'cookie_notice', lang: 'de-de' },
              { id: 'X5hHFREAACAArL83', type: 'cookie_notice', lang: 'fr-fr' },
            ],
            data: {
              component_title: [{ type: 'heading1', text: 'Cookie Notice', spans: [] }],
              description: [
                {
                  type: 'heading1',
                  text: 'By continuing to visit this website, you accept the use of cookies that will allow us, and our partners, to provide you with offers and contents matching your individual interests, to conduct analyses of the user traffic and performance of the website and to carry out statistics. ',
                  spans: [],
                },
              ],
              login: [{ type: 'paragraph', text: 'Make sure to accept cookies by pressing here', spans: [] }],
              link_label: [{ type: 'heading4', text: 'Learn More', spans: [] }],
              link: { link_type: 'Web', url: 'https://www.dualuniverse.game/legal/cookie-policy' },
              decline: [{ type: 'paragraph', text: 'Decline', spans: [] }],
              agree: [{ type: 'paragraph', text: 'I agree', spans: [] }],
              body: [],
            },
          },
        ],
        version: '6c549e9',
        license: 'All Rights Reserved',
      },
      legal_table: {
        page: 1,
        results_per_page: 20,
        results_size: 1,
        total_results_size: 1,
        total_pages: 1,
        next_page: null,
        prev_page: null,
        results: [
          {
            id: 'X70jwRIAACEAqFad',
            uid: null,
            type: 'legal_table',
            href: 'https://dualuniverse.cdn.prismic.io/api/v2/documents/search?ref=X9oo2xIAACcA7ErJ&q=%5B%5B%3Ad+%3D+at%28document.id%2C+%22X70jwRIAACEAqFad%22%29+%5D%5D',
            tags: ['legal'],
            first_publication_date: '2020-11-24T15:16:20+0000',
            last_publication_date: '2020-12-02T15:01:24+0000',
            slugs: ['legal-table'],
            linked_documents: [],
            lang: 'en-us',
            alternate_languages: [
              { id: 'X70pdRIAACEAqGUq', type: 'legal_table', lang: 'de-de' },
              { id: 'X70lUxIAACIAqFrJ', type: 'legal_table', lang: 'fr-fr' },
            ],
            data: {
              component_title: [{ type: 'heading1', text: 'Legal Table', spans: [] }],
              id: [{ type: 'paragraph', text: 'privacy-policy', spans: [] }],
              body: [
                {
                  slice_type: 'table',
                  slice_label: null,
                  items: [
                    { item: [{ type: 'paragraph', text: 'Type of information', spans: [] }] },
                    { item: [{ type: 'paragraph', text: 'Purpose of processing', spans: [] }] },
                    {
                      item: [
                        { type: 'paragraph', text: 'Legal authority for processing in the European Union', spans: [] },
                      ],
                    },
                  ],
                  primary: { row: [] },
                },
                {
                  slice_type: 'table',
                  slice_label: null,
                  items: [
                    {
                      item: [
                        {
                          type: 'paragraph',
                          text: 'Identifying information Electronic information Player profile information',
                          spans: [],
                        },
                      ],
                    },
                    {
                      item: [
                        {
                          type: 'paragraph',
                          text: 'In order to allow you to create a player profile for Dual Universe, install and use the Dual Universe game, and create an account on our Website.',
                          spans: [],
                        },
                      ],
                    },
                    {
                      item: [
                        {
                          type: 'paragraph',
                          text: 'Performance of a contract Consent regarding optional personal information',
                          spans: [],
                        },
                      ],
                    },
                  ],
                  primary: { row: [] },
                },
                {
                  slice_type: 'table',
                  slice_label: null,
                  items: [
                    { item: [{ type: 'paragraph', text: 'Identifying information', spans: [] }] },
                    { item: [{ type: 'paragraph', text: 'Consent management (legal documents, cookies)', spans: [] }] },
                    {
                      item: [
                        {
                          type: 'paragraph',
                          text: 'Performance of a contract Legitimate interest Performance of a legal obligation',
                          spans: [],
                        },
                      ],
                    },
                  ],
                  primary: { row: [] },
                },
                {
                  slice_type: 'table',
                  slice_label: null,
                  items: [
                    {
                      item: [
                        { type: 'paragraph', text: 'Identifying information Communication information', spans: [] },
                      ],
                    },
                    {
                      item: [{ type: 'paragraph', text: 'To reply to your messages, requests and emails', spans: [] }],
                    },
                    { item: [{ type: 'paragraph', text: 'Consent', spans: [] }] },
                  ],
                  primary: { row: [] },
                },
                {
                  slice_type: 'table',
                  slice_label: null,
                  items: [
                    {
                      item: [
                        {
                          type: 'paragraph',
                          text: 'Identifying information Player profile information Electronic information',
                          spans: [],
                        },
                      ],
                    },
                    {
                      item: [
                        {
                          type: 'paragraph',
                          text: 'To address your technical support and customer service requests',
                          spans: [],
                        },
                      ],
                    },
                    { item: [{ type: 'paragraph', text: 'Performance of a contract Consent', spans: [] }] },
                  ],
                  primary: { row: [] },
                },
                {
                  slice_type: 'table',
                  slice_label: null,
                  items: [
                    { item: [{ type: 'paragraph', text: 'Financial information', spans: [] }] },
                    { item: [{ type: 'paragraph', text: 'To enable you to complete purchases', spans: [] }] },
                    { item: [{ type: 'paragraph', text: 'Consent', spans: [] }] },
                  ],
                  primary: { row: [] },
                },
                {
                  slice_type: 'table',
                  slice_label: null,
                  items: [
                    { item: [{ type: 'paragraph', text: 'Financial information Identifying information', spans: [] }] },
                    {
                      item: [
                        {
                          type: 'paragraph',
                          text: 'For purposes of compliance with applicable laws, such as those respecting money laundering and fraud',
                          spans: [],
                        },
                      ],
                    },
                    {
                      item: [
                        {
                          type: 'paragraph',
                          text: 'Compliance with a legal obligation and legitimate interests to prevent abuse of our Services',
                          spans: [],
                        },
                      ],
                    },
                  ],
                  primary: { row: [] },
                },
                {
                  slice_type: 'table',
                  slice_label: null,
                  items: [
                    { item: [{ type: 'paragraph', text: 'Transaction history', spans: [] }] },
                    {
                      item: [
                        {
                          type: 'paragraph',
                          text: 'To better understand user preferences, have a better grasp of which items are popular and maintain your inventory',
                          spans: [],
                        },
                      ],
                    },
                    { item: [{ type: 'paragraph', text: 'Performance of a contract', spans: [] }] },
                  ],
                  primary: { row: [] },
                },
                {
                  slice_type: 'table',
                  slice_label: null,
                  items: [
                    {
                      item: [
                        { type: 'paragraph', text: 'Identifying information Social media information', spans: [] },
                      ],
                    },
                    {
                      item: [
                        {
                          type: 'paragraph',
                          text: 'In order to manage our social media accounts, including Twitch, and real-time activity management',
                          spans: [],
                        },
                      ],
                    },
                    { item: [{ type: 'paragraph', text: 'Consent', spans: [] }] },
                  ],
                  primary: { row: [] },
                },
                {
                  slice_type: 'table',
                  slice_label: null,
                  items: [
                    {
                      item: [
                        { type: 'paragraph', text: 'Player profile information Communication information', spans: [] },
                      ],
                    },
                    {
                      item: [
                        {
                          type: 'paragraph',
                          text: 'In order for you to use our forums and for moderating their content',
                          spans: [],
                        },
                      ],
                    },
                    { item: [{ type: 'paragraph', text: 'Consent', spans: [] }] },
                  ],
                  primary: { row: [] },
                },
                {
                  slice_type: 'table',
                  slice_label: null,
                  items: [
                    {
                      item: [
                        {
                          type: 'paragraph',
                          text: 'Player profile information Usage and performance information',
                          spans: [],
                        },
                      ],
                    },
                    {
                      item: [
                        {
                          type: 'paragraph',
                          text: 'To understand your habits and reactions, improve your gaming experience, detect anomalies and provision our servers according to traffic',
                          spans: [],
                        },
                      ],
                    },
                    {
                      item: [
                        {
                          type: 'paragraph',
                          text: 'Legitimate interest (improving your gaming experience when using Dual Universe)',
                          spans: [],
                        },
                      ],
                    },
                  ],
                  primary: { row: [] },
                },
                {
                  slice_type: 'table',
                  slice_label: null,
                  items: [
                    {
                      item: [
                        { type: 'paragraph', text: 'Communication information Player profile information ', spans: [] },
                      ],
                    },
                    {
                      item: [
                        {
                          type: 'paragraph',
                          text: 'To allow you to communicate and interact with other players in Dual Universe',
                          spans: [],
                        },
                      ],
                    },
                    { item: [{ type: 'paragraph', text: 'Performance of a contract', spans: [] }] },
                  ],
                  primary: { row: [] },
                },
                {
                  slice_type: 'table',
                  slice_label: null,
                  items: [
                    {
                      item: [
                        { type: 'paragraph', text: 'Electronic information Player profile information', spans: [] },
                      ],
                    },
                    {
                      item: [
                        {
                          type: 'paragraph',
                          text: 'To prevent cheating in Dual Universe, we use a third party, which has access to your player ID and the programs that you run when you play Dual Universe',
                          spans: [],
                        },
                      ],
                    },
                    {
                      item: [
                        {
                          type: 'paragraph',
                          text: 'Legitimate interests to ensure that our Services are secure and for fairness between players',
                          spans: [],
                        },
                      ],
                    },
                  ],
                  primary: { row: [] },
                },
                {
                  slice_type: 'table',
                  slice_label: null,
                  items: [
                    { item: [{ type: 'paragraph', text: 'Electronic information', spans: [] }] },
                    {
                      item: [
                        {
                          type: 'paragraph',
                          text: 'To ensure that our Services are secure and to correct technical issues',
                          spans: [],
                        },
                      ],
                    },
                    { item: [{ type: 'paragraph', text: 'Legitimate security and performance interests', spans: [] }] },
                  ],
                  primary: { row: [] },
                },
                {
                  slice_type: 'table',
                  slice_label: null,
                  items: [
                    { item: [{ type: 'paragraph', text: 'Usage and performance information', spans: [] }] },
                    {
                      item: [
                        {
                          type: 'paragraph',
                          text: 'We analyze usage, performance and electronic information in order to understand the behaviour of individuals relative to our social media and emails through our subcontractor MailChimp’s analyses',
                          spans: [],
                        },
                      ],
                    },
                    { item: [{ type: 'paragraph', text: 'Consent', spans: [] }] },
                  ],
                  primary: { row: [] },
                },
                {
                  slice_type: 'table',
                  slice_label: null,
                  items: [
                    {
                      item: [{ type: 'paragraph', text: 'Identifying information Electronic information', spans: [] }],
                    },
                    { item: [{ type: 'paragraph', text: 'To subscribe to our newsletters', spans: [] }] },
                    { item: [{ type: 'paragraph', text: 'Consent', spans: [] }] },
                  ],
                  primary: { row: [] },
                },
                {
                  slice_type: 'table',
                  slice_label: null,
                  items: [
                    {
                      item: [{ type: 'paragraph', text: 'Identifying information Electronic information', spans: [] }],
                    },
                    {
                      item: [
                        {
                          type: 'paragraph',
                          text: 'In order to send you non-marketing Dual Universe communications',
                          spans: [],
                        },
                      ],
                    },
                    { item: [{ type: 'paragraph', text: 'Performance of a contract', spans: [] }] },
                  ],
                  primary: { row: [] },
                },
                {
                  slice_type: 'table',
                  slice_label: null,
                  items: [
                    {
                      item: [{ type: 'paragraph', text: 'Identifying information Electronic information', spans: [] }],
                    },
                    {
                      item: [
                        {
                          type: 'paragraph',
                          text: 'To allow you to invite your friends to play Dual Universe or try the beta version',
                          spans: [],
                        },
                      ],
                    },
                    {
                      item: [
                        { type: 'paragraph', text: 'Legitimate interests for Dual Universe marketing', spans: [] },
                      ],
                    },
                  ],
                  primary: { row: [] },
                },
                {
                  slice_type: 'table',
                  slice_label: null,
                  items: [
                    { item: [{ type: 'paragraph', text: 'Employment information', spans: [] }] },
                    { item: [{ type: 'paragraph', text: 'To process your job applications', spans: [] }] },
                    { item: [{ type: 'paragraph', text: 'Consent', spans: [] }] },
                  ],
                  primary: { row: [] },
                },
                {
                  slice_type: 'table',
                  slice_label: null,
                  items: [
                    { item: [{ type: 'paragraph', text: 'Miscellaneous information', spans: [] }] },
                    {
                      item: [
                        {
                          type: 'paragraph',
                          text: 'We may use your personal information as necessary to comply with legal obligations, including requests from authorities, or to assert our rights',
                          spans: [],
                        },
                      ],
                    },
                    {
                      item: [
                        {
                          type: 'paragraph',
                          text: 'Legal obligation Legitimate interests to protect rights',
                          spans: [],
                        },
                      ],
                    },
                  ],
                  primary: { row: [] },
                },
              ],
            },
          },
        ],
        version: '6c549e9',
        license: 'All Rights Reserved',
      },
      common: {
        page: 1,
        results_per_page: 20,
        results_size: 1,
        total_results_size: 1,
        total_pages: 1,
        next_page: null,
        prev_page: null,
        results: [
          {
            id: 'X4WzxxEAADWjmQe9',
            uid: null,
            type: 'common',
            href: 'https://dualuniverse.cdn.prismic.io/api/v2/documents/search?ref=X9oo2xIAACcA7ErJ&q=%5B%5B%3Ad+%3D+at%28document.id%2C+%22X4WzxxEAADWjmQe9%22%29+%5D%5D',
            tags: [],
            first_publication_date: '2020-10-13T14:03:54+0000',
            last_publication_date: '2020-11-23T15:58:10+0000',
            slugs: ['common', 'mailmail.com'],
            linked_documents: [],
            lang: 'en-us',
            alternate_languages: [
              { id: 'X5c4shEAAB4AqAS9', type: 'common', lang: 'fr-fr' },
              { id: 'X5c47hEAAB8AqAXQ', type: 'common', lang: 'de-de' },
            ],
            data: {
              component_title: [{ type: 'heading1', text: 'Common', spans: [] }],
              email_placeholder: 'mail@mail.com',
              requires: [{ type: 'paragraph', text: 'Requires', spans: [] }],
              required: [{ type: 'paragraph', text: 'Required', spans: [] }],
              newsletter_title: [{ type: 'heading2', text: 'Subscribe to our newsletter', spans: [] }],
              newsletter_disclaimer: [
                {
                  type: 'paragraph',
                  text: 'The information you provide is handled in accordance with our ',
                  spans: [],
                },
                {
                  type: 'paragraph',
                  text: 'Privacy Policy',
                  spans: [
                    {
                      start: 0,
                      end: 14,
                      type: 'hyperlink',
                      data: { link_type: 'Web', url: 'https://www.dualuniverse.game/legal/privacy-policy' },
                    },
                  ],
                },
              ],
              continue: [{ type: 'paragraph', text: 'Continue', spans: [] }],
              cancel: [{ type: 'paragraph', text: 'Cancel', spans: [] }],
            },
          },
        ],
        version: '6c549e9',
        license: 'All Rights Reserved',
      },
      login: {
        page: 1,
        results_per_page: 20,
        results_size: 1,
        total_results_size: 1,
        total_pages: 1,
        next_page: null,
        prev_page: null,
        results: [
          {
            id: 'X3Xi5hAAAL8LvzZ0',
            uid: null,
            type: 'login',
            href: 'https://dualuniverse.cdn.prismic.io/api/v2/documents/search?ref=X9oo2xIAACcA7ErJ&q=%5B%5B%3Ad+%3D+at%28document.id%2C+%22X3Xi5hAAAL8LvzZ0%22%29+%5D%5D',
            tags: ['website'],
            first_publication_date: '2020-10-01T14:11:34+0000',
            last_publication_date: '2020-12-07T18:47:29+0000',
            slugs: ['login'],
            linked_documents: [],
            lang: 'en-us',
            alternate_languages: [
              { id: 'X5c5cREAACAAqAgw', type: 'login', lang: 'de-de' },
              { id: 'X5csPxEAAB4Ap8vD', type: 'login', lang: 'fr-fr' },
            ],
            data: {
              component_title: [{ type: 'heading1', text: 'Login', spans: [] }],
              email_placeholder: [{ type: 'heading1', text: 'Email Address', spans: [] }],
              forgot_password: [{ type: 'heading1', text: 'Forgot Password?', spans: [] }],
              resend_confirmation: [{ type: 'paragraph', text: 'Resend Confirmation', spans: [] }],
              login_button: [{ type: 'heading1', text: 'Log in', spans: [] }],
              other_login_method: [{ type: 'heading1', text: 'or', spans: [] }],
              facebook: [{ type: 'heading1', text: 'Sign in with Facebook', spans: [] }],
              google: [{ type: 'heading1', text: 'Sign in with Google', spans: [] }],
              create_account: [{ type: 'heading1', text: 'Create an Account', spans: [] }],
              error_generic: [{ type: 'heading1', text: 'Sorry there was an error with authentication.', spans: [] }],
              error_email: [{ type: 'heading1', text: 'Your email is in the wrong format.', spans: [] }],
              error_password: [{ type: 'heading1', text: 'The password is wrong.', spans: [] }],
              account_page_title: [{ type: 'heading1', text: 'My account details', spans: [] }],
              logout: [{ type: 'paragraph', text: 'Logout', spans: [] }],
              menu_links: [
                {
                  label: [{ type: 'paragraph', text: 'My Personal Account', spans: [] }],
                  link: {
                    link_type: 'Web',
                    url: 'https://community.dualthegame.com/accounts/profile',
                    target: '_blank',
                  },
                  is_download: false,
                },
                {
                  label: [{ type: 'paragraph', text: 'My Products', spans: [] }],
                  link: { link_type: 'Web', url: 'https://www.dualuniverse.game/account/products' },
                  is_download: false,
                },
                {
                  label: [{ type: 'paragraph', text: 'Download the Game', spans: [] }],
                  link: { link_type: 'Web', url: 'https://installer-prod.dualthegame.com/prod/dual-installer.exe' },
                  is_download: true,
                },
                {
                  label: [{ type: 'paragraph', text: 'Image Upload', spans: [] }],
                  link: { link_type: 'Web', url: 'https://www.dualuniverse.game/account/image-upload' },
                  is_download: false,
                },
              ],
            },
          },
        ],
        version: '6c549e9',
        license: 'All Rights Reserved',
      },
    },
  },
  forgotPasswordBranch: initialForgotState,
  authBranch: {
    ...initialAuthState,
    isAuthenticated: true,
    initialized: true,
    user: {
      id: '21368',
      token:
        'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjIxMzY4IiwiZGlzcGxheU5hbWUiOiJ5YW5pY2swMjAyIiwiZW1haWwiOiJ5bGFuZHJ5KzAyMDJAbm92YXF1YXJrLmNvbSIsImZpcnN0TmFtZSI6ImEiLCJsYXN0TmFtZSI6ImIiLCJ1c2VybmFtZSI6InlsYW5kcnkrMDIwMkBub3ZhcXVhcmsuY29tIiwiam9pbmVkQXQiOjE1OTkwNzM2NTYsInJlZmVycmVyIjowLCJQcml2YWN5UG9saWN5QWdyZWVtZW50Ijp0cnVlLCJyb2xlIjoiQWxwaGFBY2Nlc3MiLCJzdWIiOiIyMTM2OCIsImp0aSI6IjUyYTVhYjhjLTBmYjYtNGQyMy04ZjVmLWY2ZTUwZGM5MTk4NyIsImlhdCI6MTYwODE0NTE1MiwibmJmIjoxNjA4MTQ1MTUyLCJleHAiOjE2MDgxNDg3NTIsImlzcyI6Imh0dHBzOi8vYXBpLnByZXByb2Qubm92YXF1YXJrLmNvbSIsImF1ZCI6Imh0dHBzOi8vYXBpLnByZXByb2Qubm92YXF1YXJrLmNvbSJ9.FS6GKWnQp7KnvAlKFay-WX82qZFs3DBop7M1gQCFng_0xPfJGx7Rqh4GMqKoQdV5cAT7B2k4ixBF4uR1jXGJ-hj1yVGB_Y_keRG0TD2PeyASe6ZRnX6E6sQaMjr7CzUCbg6S-twXvDs6b7gyZbWU5L-i0Dt4SUQeTjoPeYAwh0oI8GHl9CcCAYrDi-n-iwaK8GAmR0QxiGXP0OEEGjwWkO34GoLqicWYD6CpSpg8dy7U6EqLYijg_irlOk3MDKQm76vaVX1Wh1SrZoQ5zhlLWYqI91vNMxVuUL4PfZcfT-Ks85U1UcoxmYsJng07Yeae_Zs5uJPnC7oFIadxsRurFmanmnCXelFcVpsFNtSp0iwCPPXVvZFs2Nk1cEtKD02aiQNAfORdkUk3pHzExYLN0U9wGJngC2QY4aLCdUqWnYOES4Wfz35TDYreIhjbm1P3yBOjTes5_F3VFKxnnS9Ti5HDcqJQv6lrP_c0xwzszGfGUejOHtAKkG-M3FBHi48ZZStaUZKhwyZf_egRVEB5i1RoQwF2ka950wJYA6gPRr6QJgygIHvcqskXg3W7BpzchY-WEkKtd5v7NFUrrjHseDqMfSV9FrWSt1kt9FGOGjzsIWRZCT3c7K-E5KnemZEKQ_UXZDMZBC7hSz46MbyvyT32ryJaF4mR8vDYqpItpMA',
    },
  },
  csrfToken: initialCSRFTokenState,
  plansAvailable: initialPlansAvailableState,
  planSelected: initialPlanState,
  news: initialNewsState,
  launcherNews: initialLauncherNewsState,
  releases: initialReleaseState,
  betaKeysBranch: BetaKeysInitialState,
  assetsBranch: initialAssetsState,
  purchaseBranch: initialPurchaseState,
  liveopsBranch: initialLiveopsState,
  cpmstar: initialCpmstarState,
  subscriptionsBranch: {
    ...initialSubscriptionsState,
  },
  confirmBranch: initialConfirmState,
  mediaBranch: initialMediaState,
  kickstarterBranch: initialKickstarterState,
  analyticsBranch: initialAnalyticsState,
  remoteConfigurationBranch: initialRemoteConfigurationState,
};
