export interface Key {
  id: string;
  owner: number;
  usedBy: string | null;
  usageTimestamp: number;
  recipient: string;
}

export interface GetKeys {
  keys: Key[];
  available: number;
}

export interface FetchKeys {
  keys: Key[];
  totals: {
    available: number;
    pending: number;
    gifted: number;
  };
}

export interface BetaKeysState {
  keys: Key[];
  totals: {
    available: number;
    pending: number;
    gifted: number;
  };
  success: number;
  loading: boolean;
  error: any;
}

export const BetaKeysInitialState: BetaKeysState = {
  keys: [],
  totals: {
    available: 0,
    pending: 0,
    gifted: 0,
  },
  success: 0,
  loading: false,
  error: null,
};
