import React, { useState } from 'react';
import { getPageID, getUrlFromEnglish } from '../../../services/i18n/i18n.url.service';

import LinkComponent from '../../link/link.component';
import { usePrismic } from '../../../hooks/prismic.hook';
import { useLocation } from 'react-router-dom';

export default function MediaNavBar(): JSX.Element {
  const { pathname } = useLocation();
  const {
    result: { all_media, cat_wallpapers, cat_screenshoots, cat_videos, cat_concept_art, cat_documents },
  } = usePrismic('media-page');
  const [showoptions, setshowoptions] = useState(false);

  return (
    <div className={'mediaNavBar'}>
      <div className="gridAlign">
        <LinkComponent
          className={getPageID(pathname) === 'media' ? 'selected' : ''}
          link={`${getUrlFromEnglish('/media')}`}
          tabIndex={0}
          label={all_media}
        />
        <LinkComponent
          className={getPageID(pathname) === 'screenshots' ? 'selected' : ''}
          link={`${getUrlFromEnglish('/media/screenshots')}`}
          tabIndex={0}
          label={cat_screenshoots}
        />
        <LinkComponent
          className={getPageID(pathname) === 'videos' ? 'selected' : ''}
          link={`${getUrlFromEnglish('/media/videos')}`}
          tabIndex={0}
          label={cat_videos}
        />
        <LinkComponent
          className={getPageID(pathname) === 'wallpapers' ? 'selected' : ''}
          link={`${getUrlFromEnglish('/media/wallpapers')}`}
          tabIndex={0}
          label={cat_wallpapers}
        />
        <LinkComponent
          className={getPageID(pathname) === 'concept-art' ? 'selected' : ''}
          link={`${getUrlFromEnglish('/media/conceptart')}`}
          tabIndex={0}
          label={cat_concept_art}
        />
        <LinkComponent
          className={getPageID(pathname) === 'documents' ? 'selected' : ''}
          link={`${getUrlFromEnglish('/media/document')}`}
          tabIndex={0}
          label={cat_documents}
        />
      </div>
      <div className="navbar-mobile">
        <a
          onClick={(): void => {
            setshowoptions(!showoptions);
          }}
        >
          <p>{pathname.split('/').reverse()[0]}</p>
          <div></div>
        </a>
      </div>
      {showoptions && (
        <div className="navbar-mobile-options">
          <div>
            <LinkComponent link={`${getUrlFromEnglish('/media')}`} tabIndex={0} label={all_media} />
            <LinkComponent link={`${getUrlFromEnglish('/media/screenshots')}`} tabIndex={0} label={cat_screenshoots} />
            <LinkComponent link={`${getUrlFromEnglish('/media/wallpapers')}`} tabIndex={0} label={cat_wallpapers} />
            <LinkComponent link={`${getUrlFromEnglish('/media/videos')}`} tabIndex={0} label={cat_videos} />
            <LinkComponent link={`${getUrlFromEnglish('/media/conceptart')}`} tabIndex={0} label={cat_concept_art} />
            <LinkComponent link={`${getUrlFromEnglish('/media/document')}`} tabIndex={0} label={cat_documents} />
          </div>
        </div>
      )}
    </div>
  );
}
