import React, { useEffect, useState } from 'react';
import NavBarComponent from './features/navbar/navbar.component';
import TwoFAReminderModal from './features/navbar/navbarsubcomponents/2fa-remindermodal';
import RouterComponent from '../app/features/router/router.component';
import withIntl from './services/i18n/i18n.service';
import useFetchPlans from './services/plans/plans.retrieval';
import useSignIn from './services/authentication/signin.retrieval';
import useFetchNews from './services/retrieval/news.retrieval';
import useFetchConfiguration from './services/retrieval/configuration.retrieval';
import useLauncherNews from './services/launcher/launchernews.retrieval';
import useReleases from './services/launcher/releases.retrieval';
import Footer from './features/footer/footer';
// import RequireConsent from './features/consentManagement/requireConsent.component';
import MessagePopUp from '../app/features/messagePopUp/message.component';
import SeoMetaPartial, { SeoMetaPartialLanding } from './services/optimization/seo.meta.partial';
import { useLandingPageCheck, useLauncherCheck } from './services/optimization/landingpagecheck';
import Helmet from 'react-helmet';
import { useConfirm } from './stores/selectors/confirm.selector';
import PopupConfirm from './features/navbar/navbarsubcomponents/popupconfirm';
import config from '../config';
import useGetAssets from './services/media/media.retrieval';
import useFetchKickstarter from './services/authentication/kickstarter.retrieval';
import useAnalyticsOptimizeService from './services/optimization/analytics.optimize.service';
import { useSetupSentry } from './services/sentry/sentry.setup';
// import { ServiceType } from './models/consent/consent.model';
import CookiesPopUp from './features/consentManagement/popup/cookiesPopup.component';
import { AuthState } from './models/authentication/auth.model';
import { useAuth } from './stores/selectors/auth.selector';
import { getCookies } from './services/authentication/authentication.cookies.service';
import { retriveUserBackToGame } from './services/retrieval/user-back-to-game.retrieval';
import BackToGameModal from './features/navbar/navbarsubcomponents/back-to-gamemodal';
import { useI18n } from './stores/selectors/i18n.selector';
import { getLocalizedUrl } from './services/i18n/i18n.url.service';
import { useLocation } from 'react-router-dom';

function AppComponent(): JSX.Element {
  useFetchPlans();
  useSignIn();
  useLauncherNews();
  useReleases();
  useGetAssets();
  useFetchKickstarter();
  useAnalyticsOptimizeService();
  useSetupSentry();
  const landingPage = useLandingPageCheck();
  const launcherPage = useLauncherCheck();
  const { show } = useConfirm();
  const { remoteConfig, loaded } = useFetchConfiguration();
  const { hash } = window.location;
  const [twofaStatus, setTwofaStatus] = useState('');
  const [openReminder, setOpenReminder] = useState(true);
  const [isEligible, setIsElegible] = useState(false);
  const { locale } = useI18n();
  const [menuClicked, setMenuClicked] = useState(-1);
  const { pathname, search } = useLocation();

  useFetchNews();
  const { user }: AuthState = useAuth();
  useEffect(() => {
    if (user && !twofaStatus) {
      setTwofaStatus(user.details?.mfaEnabled);
    }
  }, [twofaStatus, user]);

  useEffect(() => {
    if (user) {
      retriveUserBackToGame(user.token).then((response) => {
        if (response) {
          setIsElegible(response.isEligible);
          if (!response.isEligible) setOpenReminder(true);
        }
      });
      if (user?.details?.language && locale?.split('-')[0] !== user?.details?.language) {
        const newLocale = user?.details?.language;
        const newUrl = getLocalizedUrl({ pathname, newLocale });
        window.location.href = search ? newUrl + search : newUrl;
      }
    }
  }, [user]);

  if (!loaded && config.nodeEnv !== 'test') return <></>;

  if (hash) {
    const id = hash.replace('#', '');
    const element = document.getElementById(id);
    if (element) element.scrollIntoView();
  }
  const repoNameArray = /([^/]+)\.cdn.prismic\.io\/api/.exec(remoteConfig.PRISMIC_ENDPOINT);
  const repoName: string = repoNameArray ? repoNameArray[1] : 'false';
  //dummie commit
  return (
    <>
      <section className={'appContainer'}>
        <Helmet>
          <script async defer src={`//static.cdn.prismic.io/prismic.js?repo=${repoName}&new=true`} />
        </Helmet>
        {!landingPage && <SeoMetaPartial />}
        {landingPage && <SeoMetaPartialLanding />}
        {!launcherPage && !landingPage && <MessagePopUp />}
        {!landingPage && !launcherPage && <NavBarComponent menuClicked={menuClicked} setMenuClicked={setMenuClicked} />}
        <RouterComponent menuClicked={menuClicked} setMenuClicked={setMenuClicked} />
        {show && <PopupConfirm />}
        {user && !twofaStatus && openReminder && !getCookies('reminder2FA') && !pathname?.includes('contest') && (
          <TwoFAReminderModal
            setOpenReminder={setOpenReminder}
            menuClicked={menuClicked}
            setMenuClicked={setMenuClicked}
          />
        )}
        {user && isEligible && (
          <BackToGameModal setIsElegible={setIsElegible} menuClicked={menuClicked} setMenuClicked={setMenuClicked} />
        )}
        {!launcherPage && !landingPage && <Footer />}
        {!launcherPage && <CookiesPopUp />}
        {/* {!config.local && !launcherPage && (
          <RequireConsent type={ServiceType.Functional}>
            <Helmet> */}
              {/*eslint-disable-next-line react/no-unescaped-entities*/}
              {/* <script>window._peq = window._peq || []; window._peq.push(['init']);</script>
                <script src="https://clientcdn.pushengage.com/core/ba533e90c013a7ed7fd42902a5f8a554.js" async></script> */}
              {/*Optim Monster Code */}
              {/* {!config.local && (
                <script
                  src="https://a.omappapi.com/app/js/api.min.js"
                  data-account="4239"
                  data-user="20253"
                  async
                ></script>
              )}
            </Helmet>
          </RequireConsent>
        )} */}
        {/* {landingPage && <LandingOneFooter />} */}
      </section>
    </>
  );
}

const AppWithIntl = withIntl(AppComponent);

const App = AppWithIntl;

export default App;
