import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../../../stores/app.store.config';
import { usePrismic } from '../../../hooks/prismic.hook';
import {
  KickstarterPhysicalRewardInfo,
  STATUE_GENDERS_ARRAY,
  TSHIRT_SIZES_ARRAY,
} from '../../../models/authentication/authentication.kickstarter.model';
import { getUrlFromEnglish } from '../../../services/i18n/i18n.url.service';
import { fetch, updateForm } from '../../../stores/actions/kickstarter.actions';
import { useKickstarter } from '../../../stores/selectors/kickstarter.selector';
import { goto } from '../../../stores/selectors/router.selector';
import LoadingComponent from '../../loading/loading.component';
import PrismicRichText, { asText } from '../../../services/prismic/prismic.richtext.service';
import LinkComponent from '../../../features/link/link.component';

const AccountKickstarterComponent = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { handleSubmit, register, errors } = useForm();

  const {
    data: {
      page_title,
      page_description,
      submit,
      tshirt_size,
      supporter_tshirt_size,
      statue_gender,
      success_message,
      no_editable_desc,
      name_key_relation,
      download,
    },
  } = usePrismic('account_kickstarter');

  const { loading, error, hasAccess, physicalRewards, success } = useKickstarter();

  const [notice, setNotice] = useState({ type: '', message: '' });

  const cleanNotice = () => {
    setTimeout(() => {
      setNotice({ type: '', message: '' });
    }, 4000);
  };
  const onSubmit = async (values: KickstarterPhysicalRewardInfo): Promise<void> => {
    dispatch(updateForm(values));
    setTimeout(() => {
      dispatch(fetch());
    }, 2000);
  };

  useEffect(() => {
    dispatch(fetch());
  }, [dispatch]);

  useEffect(() => {
    if (success) {
      setNotice({ type: 'success', message: success_message });
      cleanNotice();
    }
    if (error) {
      setNotice({ type: 'error', message: error?.message });
      cleanNotice();
    }
  }, [success, error]);

  const displayListItem = (label: string): JSX.Element => {
    return (
      <div className="physical-item">
        <div className="account-kickstarter-desc">
          <p>{label}</p>
        </div>
      </div>
    );
  };
  const displaySelectField = (
    label: string,
    name: string,
    values: any[],
    disabled = false,
    defaultValue: any,
  ): JSX.Element => {
    return (
      <div className="input-group-code">
        <label className="">{label}</label>
        <div className="">
          <select
            className="select-field"
            name={name}
            id={name}
            disabled={disabled}
            defaultValue={defaultValue}
            ref={register({})}
          >
            {values.map(({ key, value }: { key: string; value: string }) => (
              <option key={`option_${key}`} value={key}>
                {value}
              </option>
            ))}
          </select>
        </div>
        <i className="iconWapper iconWapperRight">
          {errors[name] ? <span className="icon-loading" /> : <span className="icon-validate" />}
        </i>
        {errors[name] && <div className="error">{errors[name].message}</div>}
      </div>
    );
  };
  const hasOtherItems = () => {
    return (
      physicalRewards?.arkshipPassengerCard ||
      physicalRewards?.toteBag ||
      physicalRewards?.poster ||
      physicalRewards?.metallicBox ||
      physicalRewards?.artbook ||
      physicalRewards?.usbKey ||
      physicalRewards?.collectorBox ||
      physicalRewards?.ost ||
      physicalRewards?.digitalArtbook
    );
  };
  const translateApiKey = (key: string) => {
    return name_key_relation?.filter((item: any) => {
      return item.key === key;
    })[0];
  };
  useEffect(() => {
    if (!loading && !hasAccess) {
      goto(getUrlFromEnglish('/account/products'));
    }
  }, [loading, hasAccess]);

  if (loading && !hasAccess) return <LoadingComponent loading />;

  if (!page_description) return <></>;
  return (
    <div className="account-prod-container account-kickstarter">
      {notice?.type && (
        <div className={notice.type?.includes('success') ? 'account-notice' : 'account-notice error'}>
          <div />
          {notice.type?.includes('success') && <PrismicRichText render={notice.message} />}
          {notice.type?.includes('error') && <p>{notice.message}</p>}
        </div>
      )}
      <div className="account-kickstarter-title">
        <p>{asText(page_title)}</p>
      </div>
      <div className="account-kickstarter-desc">
        <PrismicRichText render={page_description} />
      </div>
      {(physicalRewards?.statueAvailable ||
        physicalRewards?.shirtAvailable ||
        physicalRewards?.supporterShirtAvailable) && (
        <form className="registrationForm" onSubmit={handleSubmit(onSubmit)}>
          {physicalRewards?.shirtAvailable && (
            <>
              {displaySelectField(
                asText(tshirt_size),
                'tshirtSize',
                TSHIRT_SIZES_ARRAY,
                true,
                physicalRewards?.tshirtSize || physicalRewards?.tshirtSize === 0 ? physicalRewards?.tshirtSize : 6,
              )}
            </>
          )}
          {physicalRewards?.supporterShirtAvailable && (
            <>
              {displaySelectField(
                asText(supporter_tshirt_size),
                'supporterTshirtSize',
                TSHIRT_SIZES_ARRAY,
                false,
                physicalRewards?.supporterTshirtSize || physicalRewards?.supporterTshirtSize === 0
                  ? physicalRewards?.supporterTshirtSize
                  : 6,
              )}
            </>
          )}
          {physicalRewards?.statueAvailable && !physicalRewards?.statueGender?.includes('B') && (
            <>
              {displaySelectField(
                asText(statue_gender),
                'statueGender',
                STATUE_GENDERS_ARRAY,
                true,
                physicalRewards?.statueGender || 'N',
              )}
            </>
          )}
          {physicalRewards?.statueAvailable && physicalRewards?.statueGender?.includes('B') && (
            <>
              {displaySelectField(
                'Statue',
                'statueGender',
                [{ key: 'B', value: 'Both' }],
                true,
                physicalRewards?.statueGender,
              )}
            </>
          )}
          <button
            disabled={!physicalRewards?.supporterShirtAvailable}
            type="submit"
            id="formButton"
            className="theme-button"
          >
            <div className="theme-button-container">
              <div />
              <span> {asText(submit)}</span>
            </div>
          </button>
          <LoadingComponent loading={loading} />
        </form>
      )}
      {hasOtherItems() && (
        <>
          <div className="line"></div>
          <div className="account-kickstarter-desc title">
            <PrismicRichText render={no_editable_desc} />
          </div>
          <div className="non-editable-fields">
            {physicalRewards?.arkshipPassengerCard &&
              displayListItem(translateApiKey('arkshipPassengerCard')?.display_name)}
            {physicalRewards?.toteBag && displayListItem(translateApiKey('toteBag')?.display_name)}
            {physicalRewards?.poster && displayListItem(translateApiKey('poster')?.display_name)}
            {physicalRewards?.metallicBox && displayListItem(translateApiKey('metallicBox')?.display_name)}
            {physicalRewards?.artbook && displayListItem(translateApiKey('artbook')?.display_name)}
            {physicalRewards?.usbKey && displayListItem(translateApiKey('usbKey')?.display_name)}
            {physicalRewards?.collectorBox && displayListItem(translateApiKey('collectorBox')?.display_name)}
            {physicalRewards?.ost && (
              <div className="digital-group">
                {displayListItem(translateApiKey('ost')?.display_name)}
                <div className="account-kickstarter-desc">
                  <LinkComponent
                    className="digital-group-link"
                    label={asText(download)}
                    link={translateApiKey('ost')?.link?.url}
                  ></LinkComponent>
                </div>
              </div>
            )}
            {physicalRewards?.digitalArtbook && (
              <div className="digital-group">
                {displayListItem(translateApiKey('digitalArtbook')?.display_name)}
                <div className="account-kickstarter-desc">
                  <LinkComponent
                    className="digital-group-link"
                    label={asText(download)}
                    link={translateApiKey('digitalArtbook')?.link?.url}
                  ></LinkComponent>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default AccountKickstarterComponent;
