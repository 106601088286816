import { I18nState } from '../../models/i18n/i18n.model';
import { useSelector } from 'react-redux';
import { RootState } from '../../models/_root-state.model';
import { store } from '../app.store.config';
import { setLocale as setLocaleAction } from '../slices/i18n.slice';

export const useI18n = (): I18nState => useSelector((state: RootState) => state.i18nBranch);

export const setLocale = (locale: string): void => {
  store.dispatch(setLocaleAction(locale));
};
