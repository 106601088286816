export interface KickstarterPhysicalRewardInfo {
  arkshipPassengerCard: boolean;
  tshirtSize: number;
  shirtAvailable: boolean;
  statueGender: string;
  statueAvailable: boolean;
  poster: boolean;
  toteBag: boolean;
  artbook: boolean;
  metallicBox: boolean;
  collectorBox: boolean;
  usbKey: boolean;
  supporterTshirtSize: number;
  supporterShirtAvailable: boolean;
  ost: boolean;
  digitalArtbook: boolean;
}

export interface KickstarterForm {
  id_card_username: '';
  id_card_username_2: '';
  tshirt_size: 0;
  statue_gender: '';
  address_street: '';
  address_postcode: '';
  address_city: '';
  address_state: '';
  address_country: '';
  first_name: '';
  last_name: '';
}

export const defaultKickstarterForm: KickstarterForm = {
  id_card_username: '',
  id_card_username_2: '',
  tshirt_size: 0,
  statue_gender: '',
  address_street: '',
  address_postcode: '',
  address_city: '',
  address_state: '',
  address_country: '',
  first_name: '',
  last_name: '',
};

export const TSHIRT_SIZES: any = {
  0: 'S',
  1: 'M',
  2: 'L',
  3: 'XL',
  4: 'XXL',
  5: 'XXXL',
  6: '',
};

export const TSHIRT_SIZES_ARRAY = Object.keys(TSHIRT_SIZES).map((key) => ({ key, value: TSHIRT_SIZES[key] }));

export const STATUE_GENDERS: any = {
  F: 'Female',
  M: 'Male',
  N: '',
};

export const STATUE_GENDERS_ARRAY = Object.keys(STATUE_GENDERS).map((key) => ({ key, value: STATUE_GENDERS[key] }));

export interface KickstarterState {
  hasAccess: boolean;
  physicalRewards: KickstarterPhysicalRewardInfo | null;
  loading: boolean;
  error: Error | null;
  success: boolean;
}

export const initialKickstarterState: KickstarterState = {
  hasAccess: false,
  physicalRewards: null,
  loading: true,
  error: null,
  success: false,
};
