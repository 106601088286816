import PrismicRichText, { asText } from '../../services/prismic/prismic.richtext.service';
import { usePrismic } from '../../hooks/prismic.hook';
import React, { useEffect, useState } from 'react';
import { AuthState } from '../../models/authentication/auth.model';
import { useAuth } from '../../stores/selectors/auth.selector';
import { retriveTryGame } from '../../services/retrieval/user-back-to-game.retrieval';
import LoadingComponent from '../loading/loading.component';
import { goto } from '../../stores/selectors/router.selector';
import Media from 'react-media';
// import { useLocation } from 'react-router-dom';
// import qs from 'qs';
import { getUrlFromEnglish } from '../../services/i18n/i18n.url.service';

const ContestComponent = (props: any): JSX.Element | null => {
  // const { search } = useLocation();

  // const { source } = qs.parse(search.slice(1));
  const {
    data: {
      title,
      subtitle,
      intro_image,
      intro_logo,
      content,
      content_after,
      button_label,
      disclamer,
      non_eligible_message,
    },
    contents,
  } = usePrismic('contest');

  const { user }: AuthState = useAuth();
  const [isEligible, setIsElegible] = useState(-1);
  const [joinned, setJoinned] = useState(false); //dummie commit

  useEffect(() => {
    if (user && joinned) {
      retriveTryGame(user.token).then((response) => {
        if (response?.isEligible) {
          window.location.href = getUrlFromEnglish('/lottery/thankyou');
        } else {
          setIsElegible(0);
        }
      });
    }
  }, [user, joinned]);
  if (!contents) return <></>;

  return (
    <div className="contest-container">
      <div
        className="intro"
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(12, 19, 21, 0) 70%, #0c1315), url(${intro_image.url})`,
        }}
      >
        <div className="intro-pack">
          <div className="intro-logo" style={{ backgroundImage: `url(${intro_logo.url})` }} />
          <div className="intro-sub">
            <PrismicRichText render={subtitle} />
          </div>
          <div className="intro-title">
            <PrismicRichText render={title} />
          </div>
        </div>
      </div>
      <div className="content">
        <PrismicRichText render={content} />
      </div>
      <Media
        queries={{
          small: '(max-width: 479px)',
          large: '(min-width: 480px)',
        }}
      >
        {(matches): JSX.Element => (
          <button
            disabled={user && joinned}
            className="btn-participate"
            onClick={() => {
              if (matches.large) {
                if (!user) props.setMenuClicked(7);
              } else if (!user) goto('/login?source=contest');
              setJoinned(true);
            }}
          >
            <div />
            <span>{asText(button_label)}</span>
          </button>
        )}
      </Media>

      <div className="loading">
        <LoadingComponent loading={isEligible === -1 && joinned} />
      </div>

      {user && isEligible === 0 && joinned  && (
        <div className="notice ">
          <PrismicRichText className="not-elegible" render={non_eligible_message} />
        </div>
      )}
      <div className="disclamer">
        <PrismicRichText render={disclamer} />
      </div>
      <div className="content">
        <PrismicRichText render={content_after} />
      </div>
    </div>
  );
};

export default ContestComponent;
