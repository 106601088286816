import { store } from '../../stores/app.store.config';
import { buildRoutesWithComponents } from '../../../_configurations/routesWithComponents';
import config from '../../../config';

export const getLocale = (): string => {
  const state = store.getState();
  return state.i18nBranch.locale;
};

export const getShortLocale = (from?: string): string => {
  const locale = from || getLocale();
  return locale.substr(0, 2);
};

export const getLocalizedUrl = ({
  pathname: pathnameRaw,
  newLocale,
  from,
}: {
  pathname: string;
  newLocale: string;
  from?: string;
}): string => {
  const pathname = (pathnameRaw || '').replace(config.prodRootURL, '');
  const shortLocale = getShortLocale(from);
  const routesWithComponents = buildRoutesWithComponents();
  const found = routesWithComponents.find((r: any) => r[shortLocale] === pathname);

  const url = found ? found[newLocale] : pathname;

  if (pathname.includes('/news/') || pathname.includes('/actualites/') || pathname.includes('/neuigkeiten/')) {
    return `${getLocalizedUrl({ pathname: '/news', newLocale, from: 'en' })}/${pathname
      .replace('/news/', '')
      .replace('/fr/actualites/', '')
      .replace('/de/neuigkeiten/', '')}`;
  }
  if (pathname.includes('/profile/')) {
    return `${getLocalizedUrl({ pathname: '/account/profile', newLocale, from: 'en' })}/${pathname
      .replace('/account/profile/', '')
      .replace('/fr/compte/profile/', '')
      .replace('/de/konto/profile', '')}`;
  }
  if (pathname.includes('/contact/') || pathname.includes('/kontakt/')) {
    return `${getLocalizedUrl({ pathname: '/contact', newLocale, from: 'en' })}/${pathname
      .replace('/fr/contact/', '')
      .replace('/contact/', '')
      .replace('/de/kontakt/', '')}`;
  }
  if (pathname.includes('/tutorials/') || pathname.includes('/tutoriels/')) {
    return `${getLocalizedUrl({ pathname: '/tutorials', newLocale, from: 'en' })}/${pathname
      .replace('/fr/tutoriels/', '')
      .replace('/de/tutorials/', '')
      .replace('/tutorials/', '')}`;
  }
  if (pathname.includes('/updates/')) {
    return `${getLocalizedUrl({ pathname: '/updates', newLocale, from: 'en' })}/${pathname
      .replace('/fr/updates/', '')
      .replace('/de/updates/', '')
      .replace('/updates/', '')}`;
  }
  if (pathname.includes('/landing/')) {
    return `${getLocalizedUrl({ pathname: '/landing', newLocale, from: 'en' })}/${pathname
      .replace('/fr/landing/', '')
      .replace('/de/landing/', '')
      .replace('/landing/', '')}`;
  }
  return url;
};

export const getUrlInEnglish = (pathname: string): string => {
  const from = getShortLocale();
  return getLocalizedUrl({ pathname, newLocale: 'en', from });
};

export const getUrlFromEnglish = (pathname: string): string => {
  const newLocale = getShortLocale();
  return getLocalizedUrl({ pathname, newLocale, from: 'en' });
};

const longLocales: Record<string, string> = {
  en: 'en-us',
  fr: 'fr-fr',
  de: 'de-de',
};

export const getLocaleFromUrl = (): string => {
  const locale = window.location.pathname.substr(0, 3).substr(1);
  const locales = ['en', 'fr', 'de'];
  return longLocales[locales.includes(locale) ? locale : 'en'];
};

export const getPageID = (url: string, language?: string): string => {
  const pathname = url.replace(config.prodRootURL, '');
  const shortLocale = getShortLocale(language);
  const routesWithComponents = buildRoutesWithComponents();
  if (
    pathname?.includes('account/profile') ||
    pathname?.includes('compte/profile') ||
    pathname?.includes('konto/profile')
  )
    return 'account-profile';
  return (routesWithComponents.find((o) => o[shortLocale] === pathname) || {}).id;
};

export const getEnglishRouteByID = (id: string): string => {
  const routesWithComponents = buildRoutesWithComponents();
  return routesWithComponents.find((r) => r.id === id)?.en;
};
