import React, { useState } from 'react';

import { useForm } from 'react-hook-form';
import resendConfirm from '../../../../services/authentication/resend-confirm';
import { usePrismic } from '../../../../hooks/prismic.hook';
import RichText from '../../../../services/prismic/prismic.richtext.service';
import Media from 'react-media';
import { useAuth } from '../../../../stores/selectors/auth.selector';

export const ResendConfirm = (): JSX.Element => {
  const { user } = useAuth();
  const {
    result: { page_title, resend_button, success_message, response_error },
    contents,
  } = usePrismic('account_resend_confirm');

  const {
    result: { email_placeholder, required },
  } = usePrismic('common');

  const { privacy_policy, privacy_mobile } = contents?.results?.[0]?.data || {};

  const { handleSubmit, register, errors } = useForm();
  const [notice, setNotice] = useState('');

  const onSubmit = async (values: Record<string, any>): Promise<void> => {
    const { email } = values;
    const response = await resendConfirm(email);
    if (response?.status === 204) setNotice('success');
    else setNotice('failure');
  };
  return (
    <div className="resend-confirm-container">
      <div className="corners">
        <div className="message-image-resend"></div>
        <div className="resend-confirm-title">{page_title}</div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="resend-input-box">
            <div className="email-icon" />
            <input
              placeholder={email_placeholder}
              type={'email'}
              name={'email'}
              id="email"
              className="util-input-text"
              defaultValue={user?.email}
              ref={register({
                required,
              })}
            />
            <div className="valid-icon" />
          </div>
          {errors.email && <p className="error-message">{errors.email.message}</p>}

          <button type="submit" className="theme-button btn-resend">
            <div className="theme-button-container">
              <div />
              <span>{resend_button}</span>
            </div>
          </button>

          {notice === 'success' && <div className="resend-notice">{success_message}</div>}
          {notice === 'failure' && <div className="error-message">{response_error}</div>}

          <Media
            queries={{
              small: '(max-width: 479px)',
              large: '(min-width: 480px)',
            }}
          >
            {(matches): JSX.Element => (
              <>
                {matches.large && (
                  <>
                    <div className="resend-privacy-policy">
                      <RichText render={privacy_policy} />
                    </div>
                  </>
                )}
                {matches.small && (
                  <>
                    <div className="resend-privacy-policy">
                      <RichText render={privacy_mobile} />
                    </div>
                  </>
                )}
              </>
            )}
          </Media>
        </form>
      </div>
    </div>
  );
};
