export interface MediaState {
  status: any;
  loading: boolean;
  error: any;
}

export const initialMediaState: MediaState = {
  status: {},
  loading: false,
  error: null,
};
