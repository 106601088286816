import config from '../../../../config';
import axios from 'axios';

export const getLocation = async (): Promise<any> => {
  let ipData = null;
  try {
    const ipResult = await axios.get(`/api/products/location`);
    ipData = ipResult.data;
  } catch (err) {
    if (config.local) {
      console.log('error with ipResult');
    }
  }
  return ipData;
};
