import { useEffect } from 'react';
import { useAppDispatch } from '../../stores/app.store.config';
import config from '../../../config';
import { RemoteConfigurationState } from '../../models/remote-configuration/remote-configuration.model';
import { useRemoteConfiguration } from '../../stores/selectors/remote-configuration.selector';
import { fetchRemoteConfiguration } from '../../stores/slices/remote-configuration.slice';

const useFetchConfiguration = (): RemoteConfigurationState => {
  const dispatch = useAppDispatch();

  const state = useRemoteConfiguration();

  useEffect(() => {
    if (!state.loaded && config.nodeEnv !== 'test') {
      dispatch(fetchRemoteConfiguration());
    }
  }, [dispatch, state]);

  return state;
};

export default useFetchConfiguration;
