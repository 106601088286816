import React, { useEffect } from 'react';
import { useI18n } from '../../../stores/selectors/i18n.selector';
import LinkComponent from '../../link/link.component';
import { LottiePlayer } from '@lottiefiles/lottie-player';
import lottie from '../../../../assets/lottie/logo.json';
import '@lottiefiles/lottie-player';
import { asText } from '../../../services/prismic/prismic.richtext.service';

export default function HomePageStart(props: any): JSX.Element {
  const { locale } = useI18n();

  const {
    result: {
      title_statement,
      title_quote,
      play_button,
      play_button_link,
      free_try_button_label,
      free_try_button_link,
    },
  } = props.prismic;

  // const [open, setOpen] = useState(false);
  const closeModal = (): void => {
    document.body.classList.remove('modal-open');
    // setOpen(false);
  };
  const renderSwitch = (language: any): string => {
    switch (language) {
      case 'fr-fr':
        return 'title title-fr';
      case 'de-de':
        return 'title title-de';
      default:
        return 'title';
    }
  };
  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        closeModal();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);

  useEffect(() => {
    const player = document.querySelector('lottie-player') as LottiePlayer;
    player.addEventListener('rendered', () => {
      player.load(lottie);
      player.play();
    });
  }, []);
  return (
    <div className={'homepagestart'}>
      <div className={'dualUniverseLogo'} />
      <div className="lottie-container">
        <lottie-player autoplay mode="normal" style={{ width: '100%' }}></lottie-player>
      </div>
      <p className={renderSwitch(locale)}>{title_statement}</p>
      <p className="explanation">{title_quote}</p>
      <div className={'actionButtons fullWidth'}>
        <LinkComponent link={play_button_link}>
          <button className="theme-button-spec-positive joinBeta green-gradient-btn">
            <div className="theme-button-container">
              <div />
              <span>{play_button}</span>
            </div>
          </button>
        </LinkComponent>
        <LinkComponent link={free_try_button_link}>
          <button className="theme-button-spec-positive joinBeta tryFree">
            <div className="theme-button-container">
              <div />
              <span>{asText(free_try_button_label)}</span>
            </div>
          </button>
        </LinkComponent>
      </div>
    </div>
  );
}
