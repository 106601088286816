import React from 'react';
import { usePrismic } from '../../../hooks/prismic.hook';
import { displayCurrency } from '../../../services/utils/currency.utils';

const BuyPageTotal = ({ amount, currency }: { amount: number; currency: string }): JSX.Element => {
  const {
    result: { total_including },
  } = usePrismic('buy_page');
  return (
    <div className="buypage-total-landingone">
      <div className="corners-bottom">
        <h3>{total_including}</h3>
        <span>{displayCurrency(amount, currency)}</span>
      </div>
    </div>
  );
};

export default BuyPageTotal;
