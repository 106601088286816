import { Link } from 'prismic-reactjs';
import React from 'react';
import config from '../../../config';
import { usePrismic } from '../../hooks/prismic.hook';
import PrismicRichText, { asText } from '../../services/prismic/prismic.richtext.service';
import LinkComponent from '../link/link.component';

const SitemapPageComponent = (): JSX.Element => {
  const {
    data: { page_title, background_image, body: sections = [] },
  } = usePrismic('site_map_page');

  return (
    <>
      <div
        className="site-map-bg"
        style={{
          backgroundImage: `linear-gradient(rgba(12, 19, 21, 0) 58%, rgba(12, 19, 21, 0.75) 70%, rgb(12, 19, 21) 85%),url('${background_image?.url}')`,
        }}
      ></div>
      <div className="site-map-page">
        {config.nodeEnv === 'test' && <div data-testid="site-map-title">{asText(page_title)}</div>}
        <PrismicRichText render={page_title} />
        {sections.map((section: any, key: number) => (
          <React.Fragment key={key}>
            <div className="section-title">
              <img src={section.primary.icon.url} alt="" />
              <PrismicRichText render={section.primary.section_title} />
            </div>
            <div className="section-links">
              {section.items.map((item: any, key: number) => (
                <div className="section-link" key={key}>
                  <LinkComponent link={Link.url(item.link)}>
                    <PrismicRichText render={item.link_label} />
                  </LinkComponent>
                </div>
              ))}
            </div>
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default SitemapPageComponent;
