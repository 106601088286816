import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../../config';
import { asText } from '../../services/prismic/prismic.richtext.service';
import { getNbMonths } from '../../services/utils/utils.service';

interface Parameter {
  usefulData: Record<string, any>;
  ipData: Record<string, any>;
  currency: string;
}

export const plansAvailableResponse = createAsyncThunk(
  'plan/plansAvailableResponse',
  async (parameter: Parameter): Promise<any> => {
    const { usefulData, ipData, currency } = parameter;

    const { campaign_name } = usefulData;

    const sliceData = usefulData.body[0]?.items;
    const minSpecsHeader = usefulData.body[1]?.primary.section_header;
    const goodSpecsHeader = usefulData.body[2]?.primary.section_header;
    const minSpecs = usefulData.body[1]?.items;
    const goodSpecs = usefulData.body[2]?.items;
    let campaign;
    const defaultCampaign = {
      id: 'b061a97f-a897-4b64-81d5-7ec6ee5b5d6f',
      consumed: null,
      limit: null,
    };

    try {
      const responseCampaign = await axios.get(`/api/products/campaigns?name=${campaign_name}&limit=1&sortBy=0`);
      campaign = responseCampaign.data?.[0] || defaultCampaign;
    } catch (err) {
      if (config.local) {
        console.log('error getting campaigns', err);
      }
      campaign = defaultCampaign;
    }
    const { id: campaignId, consumed, limit } = campaign;

    let rawProducts = [];
    try {
      const responseProducts = await axios.get(`/api/products/products?campaignId=${campaignId}`);
      rawProducts = responseProducts.data.sort((a: any, b: any) => getNbMonths(a) - getNbMonths(b));       
    } catch (err) {
      if (config.local) {
        console.log('error getting products', err);
      }
      rawProducts = [];
    }

    const products = rawProducts.map((obj: Record<string, any>) => {
      let pricings;
      if (currency) {
        pricings = (obj.pricings || []).filter((x: Record<string, any>) => x.currency === currency);
      } else {
        pricings = (obj.pricings || []).filter((x: Record<string, any>) => x.country === ipData.country_code);
      }

      if (pricings.length === 0) {
        pricings = (obj.pricings || []).filter((x: Record<string, any>) => x.isDefault === true);
      }

      if (pricings.length === 0) {
        pricings = [{}];
      }

      for (let i = 0; i < sliceData.length; i++) {
        if (sliceData[i].product_id == obj.id) {
          return {
            productId: obj.id,
            pricingId: pricings[0].id,
            type: obj.type,
            paymentProviderId: obj.paymentProviderId,
            templateId: obj.templateId,
            currency: pricings[0].currency,
            currencies: obj.pricings || [],
            price: (Math.round(pricings[0].price * 100) / 100).toFixed(2),
            country: pricings[0].country,
            planname: asText(sliceData[i].product_name),
            plandescription: asText(sliceData[i].product_description1),
            plansubscriptionfreemonths: sliceData[i].product_note ? asText(sliceData[i].product_note) : '',
          };
        }
      }

      return '';
    });

    const percentageUsed = (consumed / limit) * 100;

    return {
      ipData,
      campaignId,
      productApi: products,
      minSpecsHeader,
      goodSpecsHeader,
      minSpecs,
      goodSpecs,
      consumed,
      limit,
      percentageUsed,
    };
  },
);
