import React, { useEffect, useState } from 'react';
import LegalNavigationComponent from './legal-sections/legal-navigation.component';
import { usePrismic } from '../../hooks/prismic.hook';
import PrismicRichText, { asText, getHeadings } from '../../services/prismic/prismic.richtext.service';
import useLegal from '../../services/retrieval/legal.retrieval';
import { getUrlFromEnglish } from '../../services/i18n/i18n.url.service';
import Scrollspy from 'react-scrollspy';
import SeoMeta from '../../services/optimization/seo.meta.partial';
import { useLocation } from 'react-router-dom';

const LegalComponent = (): JSX.Element | null => {
  const [scrollPosition, setSrollPosition] = useState(0);
  const handleScroll = (): void => {
    const position = window.pageYOffset;
    setSrollPosition(position);
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return (): void => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const {
    data: { page_title },
  } = usePrismic('legal_page');
  const {
    data,
  } = usePrismic('table');
   
  const { pathname } = useLocation();
  const table = asText(data[0]?.id) == pathname?.split('/')[2] ? data[0]?.body : data[1]?.body;
  const { legalPages, legallinks, renderLegalLinks } = useLegal();
  const [listitem, setListItem] = useState('');
  const renderedPage = legalPages.length
    ? legallinks.find((l: any) => getUrlFromEnglish(l.link) === pathname) || null
    : null;
  const { title, content_before, content_after } = renderedPage || {};

  if (!renderedPage) return <></>;
  let headings = getHeadings(content_before, 0);
  const headings2 = getHeadings(content_after, 3);
  headings = [...headings, ...headings2];
  const items = headings.map((h) => `${h.id}`);

  const isPrivacyPage = (): boolean => {
    return (
      pathname.includes('privacy-policy') ||
      pathname.includes('politique-de-confidentialite') ||
      pathname.includes('datenschutzrichtlinie')
    );
  };

  return (
    <>
      <SeoMeta pageTitle={title} />
      <div className="legal-container">
        <div className="intro">
          <h1 data-testid="legal-title">{asText(page_title)}</h1>
        </div>

        <LegalNavigationComponent />
        <div className="flex-container">
          <div className={scrollPosition > 240 ? 'legal-options big' : 'legal-options'}>
            <div className="wrapper"></div>
            <div className="content">{renderLegalLinks(pathname)}</div>
          </div>
          <div className="flex-container-row">
            {headings.length > 0 && (
              <div>
                <Scrollspy
                  items={items}
                  componentTag="ol"
                  currentClassName="current"
                  scrolledPastClassName="past"
                  className="sticky-container table-of-contents"
                  onUpdate={(el: any): void => {
                    setListItem(el?.id);
                  }}
                  offset={150}
                >
                  {headings.map((heading: any, key: number) => (
                    <li key={key} className={`#${heading.id}` === listitem ? 'current' : ''}>
                      <a
                        key={key}
                        href={`#${heading.id}`}
                        onClick={(): void => {
                          setListItem(`#${heading.id}`);
                        }}
                      >
                        {heading.text}
                      </a>
                    </li>
                  ))}
                </Scrollspy>
              </div>
            )}
            <div data-testid="legal-content" className={`legal-content ${headings.length ? '' : 'full'}`}>
              {renderedPage && <PrismicRichText render={content_before} startingindex={0} />}
              {isPrivacyPage() && table && table?.length > 0 && (
                <table>
                  <tbody>
                    {table?.map((result: any, key: number) => {
                      return (
                        <tr key={key}>
                          {result?.items &&
                            result?.items.map((result: any, key: number) => {
                              return (
                                <td key={key}>
                                  <PrismicRichText render={result?.item} />
                                </td>
                              );
                            })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
              {renderedPage && <PrismicRichText render={content_after} startingindex={3} />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default LegalComponent;
