import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { EmailShareButton, FacebookShareButton, TwitterShareButton } from 'react-share';
import Popup from 'reactjs-popup';

const SocialLinks = ({ margin, prismic }: { margin?: boolean; prismic: any }): JSX.Element => {
  const url = window.location.href;

  if (!prismic) return <></>;

  const {
    result: { link_copied },
  } = prismic;

  const contentStyle = { background: '#ffd255' };
  const overlayStyle = { background: 'transparent' };
  const arrowStyle = { color: '#ffd255' };
  return (
    <div className={`list-social-media ${margin ? 'm-0' : ''}`}>
      <FacebookShareButton className="icon icon-facebook" url={window.location.href}>
        <></>
      </FacebookShareButton>
      <TwitterShareButton className="icon icon-twitter" url={window.location.href}>
        <></>
      </TwitterShareButton>
      <EmailShareButton className="icon icon-email" url={window.location.href}>
        <></>
      </EmailShareButton>
      <Popup
        trigger={(): JSX.Element => (
          <div>
            <CopyToClipboard text={url}>
              <i className="icon icon-copy" style={{ cursor: 'pointer' }}>
                <div></div>
              </i>
            </CopyToClipboard>
          </div>
        )}
        position="top center"
        closeOnDocumentClick
        {...{ contentStyle, overlayStyle, arrowStyle }}
      >
        <div className="link-container">
          <p>{link_copied}</p>
        </div>
      </Popup>
    </div>
  );
};

export default SocialLinks;
