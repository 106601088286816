import axios from 'axios';
import config from '../../../config';
import { User } from '../../models/authentication/auth.model';

export const retrieveGameTime = async (user: User, token: string): Promise<any> => {
  try {
    const { data } = await axios.get('/api/users/me/game-time', {
      headers: { Authorization: `Bearer ${token}` },
    });

    return data;
  } catch (err) {
    if (config.local) {
      console.log('error with retrieveGameTime', err);
    }
  }
};
