import React from 'react';
import LinkComponent from '../../link/link.component';
import { usePrismic } from '../../../hooks/prismic.hook';
import RichText from '../../../services/prismic/prismic.richtext.service';
const ConfirmLeave = ({ onClick }: any): JSX.Element => {
  const {
    result: { page_title, cancel, proceed },
    contents,
  } = usePrismic('confirm_redirection');

  const { description } = contents?.results?.[0]?.data || {};

  return (
    <>
      <div className="confirm-leave-container">
        <div className="corners"></div>
        <div className="confirm-leave-icon"></div>
        <h3 className="confirm-leave-title">{page_title}</h3>
        <div className="confirm-leave-desc">
          <RichText render={description} />
        </div>
        <div className="confirm-leave-cta">
          <button className="cancel theme-button" onClick={onClick}>
            <div className="theme-button-container">
              <div />
              <span>{cancel}</span>
            </div>
          </button>
          <LinkComponent link={'https://du-creators.org'} tabIndex={0}>
            <button className="proceed theme-button" onClick={onClick}>
              <div className="theme-button-container">
                <div />
                <span>{proceed}</span>
              </div>
            </button>
          </LinkComponent>
        </div>
      </div>
    </>
  );
};
export default ConfirmLeave;
