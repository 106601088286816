import { useEffect, useState } from 'react';
import { getSlug } from '../features/news/news.service';
import { Tutorial, TutorialCategory } from '../models/tutorials/tutorials.model';
import { asText } from '../services/prismic/prismic.richtext.service';
import { usePrismic } from './prismic.hook';

const matchTutorialsWithNext = (tutorials: Tutorial[]): Tutorial[] => {
  return (tutorials || []).map((t) => ({
    ...t,
    next_tutorials: t.next_tutorials.map((n) => ({
      tutorial_link: n.tutorial_link,
      tutorial: tutorials.find((t) => t.id === n.tutorial_link.id) || null,
    })),
  }));
};

export const useTutorials = (
  slug?: string,
): {
  loading: boolean;
  categories: TutorialCategory[];
  tutorials: Tutorial[];
  tutorial: Tutorial | null;
  category: TutorialCategory | null;
} => {
  const [categories, setCategories] = useState<TutorialCategory[]>([]);

  const { contents: tutorialsRaw } = usePrismic('tutorial');
  const { data: tutorialsPage } = usePrismic('tutorials_page');
  const { data: categoriesRaw } = usePrismic('tutorial_category');

  const tutorialsList: Tutorial[] =
    tutorialsRaw &&
    tutorialsRaw.results.length &&
    tutorialsRaw.results.map(({ data: t, id }: { data: Tutorial; id: string }) => ({ ...t, slug: getSlug(t), id }));

  const tutorials = matchTutorialsWithNext(tutorialsList);

  useEffect(() => {
    if (!categories.length && tutorials?.length && tutorialsPage.categories && categoriesRaw.length) {
      setCategories(
        tutorialsPage.categories.map(({ category: { id } }: { category: TutorialCategory }) => ({
          tutorials: tutorials
            .filter((t) => t.category?.id === id)
            .sort((a, b) => asText(a.title).localeCompare(asText(b.title))),
          ...categoriesRaw.find((c: TutorialCategory) => c.id === id),
        })),
      );
    }
  }, [tutorials, tutorialsPage, categoriesRaw]);

  const tutorial = (slug && tutorials?.find && tutorials.find((t: Tutorial) => asText(t.slug) === slug)) || null;

  const category = categories.find((c) => c.id === tutorial?.category.id) || null;

  const loading = !tutorials?.length;

  return { loading, categories, tutorials, tutorial, category };
};
