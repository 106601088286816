import React from 'react';
import HomePageStart from './homepagesections/homepagestart';
import HomePageMiddle from './homepagesections/homepagemiddle';
import HomePageBetaSocial from './homepagesections/homepagebetasocial';
import { usePrismic } from '../../hooks/prismic.hook';

export default function HomePage(): JSX.Element {
  const prismic = usePrismic('home_page');

  // useEffect(() => {
  //   const player = document.getElementById('video') as HTMLVideoElement;
  //   const opacity = document.getElementById('opacity') as HTMLElement;

  //   if (player) {
  //     setTimeout(() => {
  //       const startPlayPromise = player.play();
  //       opacity.appendChild(player);
  //       if (startPlayPromise !== undefined) {
  //         startPlayPromise
  //           .then(() => {
  //            return;
  //           })
  //           .catch((error) => {
  //             console.log(error);
  //           });
  //       }
  //     }, 2000);
  //   }
  // }, [prismic]);

  if (!prismic.result || Object.keys(prismic.result).length === 0) return <></>;

  const { background_video } = prismic.result;

  return (
    <div className={'Home'}>
      <div className="opacity" id="opacity">
        <video id="video" autoPlay className="background-video" playsInline loop muted>
          <source src={background_video} type="video/mp4" />
        </video>
      </div>
      <div className={'homePageParent'}>
        <HomePageStart prismic={prismic}/>
        <HomePageMiddle prismic={prismic}/>
        <HomePageBetaSocial prismic={prismic}/>
      </div>
    </div>
  );
}
