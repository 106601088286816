import React from 'react';
import { usePrismic } from '../../../hooks/prismic.hook';
import RichText from '../../../services/prismic/prismic.richtext.service';

export default function ReferalRewardFriendComponent(): JSX.Element | null {
  const {
    result: { rewards_friends_title, rewards_friends_subtitle, rewards_friends_list_title },
    contents: {
      results: [
        {
          data: { rewards_friends_description },
        },
      ],
    },
  } = usePrismic('recruit_a_friend');

  return (
    <section className="referral-block-rewards-friends">
      <h2 className="titleOnlyMobile">{rewards_friends_title}</h2>
      <div className={'content-container'}>
        <div className={'content-image-container'}> </div>
        <div className={'content-block-container'}>
          <div className={'content-block'}>
            <h2>{rewards_friends_title}</h2>
            <h5>{rewards_friends_subtitle}</h5>
            <p>{rewards_friends_list_title}</p>
            <RichText render={rewards_friends_description} />
            {/* <p>{result['reward-list-description']}</p> */}
          </div>
        </div>
      </div>
    </section>
  );
}
