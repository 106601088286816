import React from 'react';
import { usePrismic } from '../../../hooks/prismic.hook';
import PrismicRichText from '../../../services/prismic/prismic.richtext.service';

const ContestThankYouComponent = (): JSX.Element | null => {
  const {
    data: { thankyou_image, eligible_message, eligible_submessage },
    contents,
  } = usePrismic('contest');
  if (!contents) return <></>;
  return (
    <div
      className="contest-thankyou"
      style={{
        backgroundImage: `url(${thankyou_image.url})`,
      }}
    >
      <div className="notice">
        <PrismicRichText className="elegible" render={eligible_message} />
      </div>
      <div className="subnotice">
        <PrismicRichText className="elegible" render={eligible_submessage} />
      </div>
    </div>
  );
};
export default ContestThankYouComponent;
