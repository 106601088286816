import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../../config';
import { RemoteConfig } from '../../models/remote-configuration/remote-configuration.model';

export const fetchRemoteConfiguration = createAsyncThunk(
  'assets/fetchRemoteConfiguration',
  async (): Promise<RemoteConfig | null> => {
    try {
      const response = await axios.get(`/configuration`);
      return response.data as RemoteConfig | null;
    } catch (err: any) {
      if (config.local) {
        console.log('Error with fetchRemoteConfiguration ', err.response);
      }
      return null;
    }
  },
);
