import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { FormResponseInterface } from '../../models/forms/forms.model';

export const formResponse = createAsyncThunk(
  'forms/userInput',
  async (values: Record<string, any>): Promise<FormResponseInterface> => {
    const response = await axios.post('', values);
    return response.data;
  },
);
