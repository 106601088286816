import { createSlice } from '@reduxjs/toolkit';
import { initialForgotState, ForgotState } from '../../models/forgotPassword/forgot-password.model';
import { getForgotInfo } from '../actions/forgot-password.action';

const slice = createSlice({
  name: 'getForgotInfo',
  initialState: initialForgotState,
  reducers: {
    resetError: (state: ForgotState): void => {
      state.error = null;
    },
  },
  extraReducers: {
    [getForgotInfo.fulfilled.type]: (state, { payload }): void => {
      state.contents = payload;
      state.error = null;
    },
    [getForgotInfo.rejected.type]: (state, { error }): void => {
      state.error = error;
    },
  },
});

export const forgotPasswordReducer = slice.reducer;
export const { resetError } = slice.actions;
export { getForgotInfo };
