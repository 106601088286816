import { createSlice } from '@reduxjs/toolkit';
import { I18nState, initial18nState } from '../../models/i18n/i18n.model';

const slice = createSlice({
  name: 'i18n',
  initialState: initial18nState,
  reducers: {
    setLocale: (state: I18nState, { payload }): void => {
      state.locale = payload;
    },
  },
  extraReducers: {},
});

export const i18nSliceReducers = slice.reducer;
export const { setLocale } = slice.actions;
