import React from 'react';
import { usePrismic } from '../../../hooks/prismic.hook';
import FaqLineComponent from './partials/faq-line.component';

export default function ReferalFaqComponent(): JSX.Element | null {
  const prismic = usePrismic('recruit_a_friend');

  if (!prismic.items.recruit_a_friend) return <></>;

  const {
    result: { faq_title, faq_recruiter_label, faq_recruits_label },
    items: {
      recruit_a_friend: { faq_questions, faq_recruits_questions },
    },
  } = prismic;

  return (
    <>
      <div className="referral-block-faq-bg" />
      <section className="referral-block-faq">
        <h2>{faq_title}</h2>
        <h3>{faq_recruiter_label}</h3>
        {faq_questions &&
          faq_questions.map((element: any, id: number) => {
            return <FaqLineComponent key={id} line={element} id={id + 1} slice={1} />;
          })}
        <h3>{faq_recruits_label}</h3>
        {faq_recruits_questions &&
          faq_recruits_questions.map((element: any, id: number) => {
            return <FaqLineComponent key={id} line={element} id={id + 1} slice={2} />;
          })}
      </section>
    </>
  );
}
