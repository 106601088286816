import React from 'react';
import { useAuth } from '../../../stores/selectors/auth.selector';
import { usePrismic } from '../../../hooks/prismic.hook';

const BuyPageLoginDisclaim = (): JSX.Element => {
  const { isAuthenticated } = useAuth();

  const {
    result: { upgrade_notice },
  } = usePrismic('buy_page');

  return (
    <div className="containerLoginLandingOne">{isAuthenticated && <p className="notice">{upgrade_notice}</p>}</div>
  );
};

export default BuyPageLoginDisclaim;
