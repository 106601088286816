import React from 'react';
import { usePrismic } from '../../hooks/prismic.hook';
import PrismicRichText from '../../services/prismic/prismic.richtext.service';

const TutorialsAboutComponent = (): JSX.Element => {
  const {
    data: { about, about_image },
  } = usePrismic('tutorials_page');

  return (
    <div className="tutorials-about">
      <div className="content">
        <PrismicRichText render={about} />
      </div>
      <div className="triangle" />
      <div className="image" style={{ backgroundImage: `url(${about_image?.url})` }} />
    </div>
  );
};

export default TutorialsAboutComponent;
