import { buildRoutesWithComponents } from './routesWithComponents';
import { RouteModel } from '../app/models/router/route.model';

export const makeRoutes = (): RouteModel[] => {
  const locales = ['en', 'fr', 'de'];
  const routes: RouteModel[] = [];
  const routesWithComponents = buildRoutesWithComponents();
  for (const r of routesWithComponents || []) {
    for (const locale of locales) {
      routes.push({
        ...r,
        path: String(r[locale]),
        component: r.Component,
        public: !r.en.includes('/account/'),
        redirect: r.redirect,
      });
    }
  }
  return routes;
};
