import React, { useState, useEffect } from 'react';
import { useAppDispatch } from '../../../stores/app.store.config';
import { setPlan } from '../../../stores/slices/plan.slice';
import { useAuth } from '../../../stores/selectors/auth.selector';
import { usePlansAvailable } from '../../../stores/selectors/plans-available.selector';
import { displayCurrency } from '../../../services/utils/currency.utils';
import { getUrlFromEnglish } from '../../../services/i18n/i18n.url.service';
import config from '../../../../config';
import { Action, triggerAction, category } from '../../../services/optimization/ecommerce.service';
import { getConfirmPageUrl } from '../../../services/optimization/landingpagecheck';
import LinkComponent from '../../link/link.component';
import PrismicRichText from '../../../services/prismic/prismic.richtext.service';
import { usePrismic } from '../../../hooks/prismic.hook';

const BuyPagePackages = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const email = '';
  const {
    contents: { campaignId, percentageUsed, productApi: productsApiDataUnsorted = [] },
  } = usePlansAvailable();
  const [borderOne, setBorderOne] = useState(false);
  const [borderTwo, setBorderTwo] = useState(false);
  const [borderThree, setBorderThree] = useState(false);

  const { isAuthenticated }: { isAuthenticated: boolean } = useAuth();

  const existingProductId = false;

  const {
    result: {
      continue_button,
      choose_pack,
      confirm_pack,
      checkout,
      best_value,
      total,
      save,
      available_soon,
      header,
      existing_plan_disclaimer,
      limited_time_offer,
      month,
    },
    contents: contentsRaw,
  } = usePrismic('buy_page');

  const { disclaimeryellow } = contentsRaw?.results?.[0]?.data || {};

  const productsApiData = productsApiDataUnsorted
    .slice()
    .sort((a: Record<string, any>, b: Record<string, any>): number => a.price - b.price);

  useEffect(() => {
    if (productsApiData.length) {
      const currencyCode = productsApiData[0].currency;
      const products = productsApiData.map((p: any, index: number) => ({
        name: p.planname,
        id: p.productId,
        price: p.price,
        category,
        position: index + 1,
      }));
      triggerAction(Action.ProductImpression, { currencyCode, products });
    }
  }, [productsApiData]);

  if (!productsApiData.length) return <div></div>;

  const planIds = productsApiData.map(({ productId }: { productId: string }) => productId);
  const pricingId = productsApiData.map(({ pricingId }: { pricingId: string }) => pricingId);
  const planNames = productsApiData.map(({ planname }: { planname: string }) => planname);
  const planPrices = productsApiData.map(({ price }: { price: number }) => price);
  const planCurrency = productsApiData.map(({ currency }: { currency: string }) => currency);
  const planFreeTimes = productsApiData.map(({ plansubscriptionfreemonths }: { plansubscriptionfreemonths: string }) =>
    `${plansubscriptionfreemonths}`.toUpperCase(),
  );

  const paymentProviderId = productsApiData.map(
    ({ paymentProviderId }: { paymentProviderId: string }) => paymentProviderId,
  );

  const type = productsApiData.map(({ type }: { type: number }) => type);

  const [planIdOne, planIdTwo, planIdThree] = planIds;
  const [planNameOne, planNameTwo, planNameThree] = planNames;

  const [priceOne, priceTwo, priceThree] = planPrices;
  const [freeMonthOne] = planFreeTimes;

  const emptyFunction = (): void => {
    if (config.local) {
      console.log('empty function');
    }
  };

  const confirmPageURL = getUrlFromEnglish(getConfirmPageUrl());
  const priceForOneMonth = priceOne / planNameOne.split(' ')[0];
  return (
    <div className={'buypageparentlandingone'}>
      {isAuthenticated && existingProductId && <h3 style={{ color: 'orange' }}>{existing_plan_disclaimer}</h3>}
      {
        <div className="notice-notlogin">
          <p>{limited_time_offer}</p>
        </div>
      }

      <h2>{header}</h2>
      <div className={'buypage-packages'}>
        <div className={'textButtonContainer'}>
          <p className="bread-item active">{choose_pack}</p>
          <p className="bread-item">{confirm_pack}</p>
          <p className="bread-item">{checkout}</p>
          <div className="button-container"></div>
        </div>
        <div className={'image-container'}>
          {percentageUsed === 100 ? (
            <div className={' priceBox '}>
              <div className={'visibleBorder'}>
                <div className={'buyPage-logo buyPage-logo-1'} />
                <div className={'buyPage-time'}>{planNameOne}</div>
                <div className={'buyPage-price'}>{displayCurrency(priceOne, planCurrency[0])}</div>
                <div className={'buyPage-free'}>{freeMonthOne}</div>
                <button className=" theme-button buyButtonGray" onClick={emptyFunction}>
                  <div className="theme-button-container ">
                    <div />
                    <span>{available_soon}</span>
                  </div>
                </button>
              </div>
            </div>
          ) : (
            <LinkComponent link={confirmPageURL}>
              <div
                className={borderOne ? 'priceBox borderToggle' : 'priceBox'}
                onClick={(): void => {
                  setBorderOne(!borderOne);
                  setBorderTwo(false);
                  setBorderThree(false);

                  const currencyCode = productsApiData[0].currency;
                  const product = {
                    name: planNameOne,
                    id: planIdOne,
                    price: priceOne,
                    position: 1,
                    category,
                  };
                  triggerAction(Action.ProductClick, { product, currencyCode });
                  triggerAction(Action.AddToCart, { product, currencyCode });

                  dispatch(
                    setPlan({
                      planName: planNameOne,
                      campaignId,
                      productId: planIdOne,
                      pricingId: pricingId[0],
                      paymentProviderId: paymentProviderId[0],
                      price: priceOne,
                      type: type[0],
                      email,
                      currency: planCurrency[0],
                    }),
                  );
                }}
              >
                <div className={'visibleBorder'}></div>
                <div className="real-content">
                  <div className={'buyPage-logo buyPage-logo-1'} />
                  <div className={'buyPage-time'}>{planNameOne}</div>
                  <div className="price-box">
                    <p className={'buyPage-price'}>
                      {displayCurrency(priceOne, planCurrency[0])?.trim()}/{month}
                    </p>
                    <p className={'buyPage-total d-none'}>{freeMonthOne}</p>
                  </div>
                  <button className=" theme-button" onClick={emptyFunction}>
                    <div className="theme-button-container buyButtonAction">
                      <div />
                      <span>{continue_button}</span>
                    </div>
                  </button>
                </div>
              </div>
            </LinkComponent>
          )}

          {percentageUsed === 100 ? (
            <div className={' priceBox '}>
              <div className={'visibleBorder'}>
                <div className={'buyPage-logo buyPage-logo-1'} />
                <div className={'buyPage-time'}>{planNameOne}</div>
                <div className={'buyPage-price'}>{displayCurrency(priceOne, planCurrency[0])}</div>
                <div className={'buyPage-free'}>{freeMonthOne}</div>
                <button className=" theme-button buyButtonGray" onClick={emptyFunction}>
                  <div className="theme-button-container ">
                    <div />
                    <span>{available_soon}</span>
                  </div>
                </button>
              </div>
            </div>
          ) : (
            <LinkComponent link={confirmPageURL}>
              <div
                className={borderTwo ? 'priceBoxBest borderToggle' : 'priceBoxBest'}
                onClick={(): void => {
                  setBorderTwo(!borderTwo);
                  setBorderOne(false);
                  setBorderThree(false);

                  const currencyCode = productsApiData[0].currency;
                  const product = {
                    name: planNameTwo,
                    id: planIdTwo,
                    price: priceTwo,
                    position: 2,
                    category,
                  };
                  triggerAction(Action.ProductClick, { product, currencyCode });
                  triggerAction(Action.AddToCart, { product, currencyCode });

                  dispatch(
                    setPlan({
                      planName: planNameTwo,
                      campaignId,
                      productId: planIdTwo,
                      pricingId: pricingId[1],
                      paymentProviderId: paymentProviderId[1],
                      price: priceTwo,
                      type: type[1],
                      email,
                      currency: planCurrency[1],
                    }),
                  );
                }}
              >
                <button className={'buyPage-bestvalue'}> {best_value}</button>
                <div className={'visibleBorderBest'}></div>
                <div className="real-content">
                  <div className={'buyPage-logo buyPage-logo-2'} />
                  <div className={'buyPage-time'}>{planNameTwo}</div>
                  <div className="price-box">
                    <div className={'buyPage-price'}>
                      {displayCurrency(priceTwo / 6, planCurrency[1])?.trim()}/{month}
                    </div>
                    <div className={'buyPage-total'}>
                      {total?.concat(' ')}
                      {displayCurrency(priceTwo, planCurrency[1])}
                    </div>
                    <button className=" theme-button" onClick={emptyFunction}>
                      <div className="theme-button-container buyButtonAction">
                        <div />
                        <span>{continue_button}</span>
                      </div>
                    </button>

                    <div className={'buyPage-save'}>
                      {save?.concat(' ')}
                      {displayCurrency(priceForOneMonth * 6 - priceTwo, planCurrency[1])}
                    </div>
                  </div>
                </div>
              </div>
            </LinkComponent>
          )}

          {percentageUsed === 100 ? (
            <div className={' priceBox '}>
              <div className={'visibleBorder'}>
                <div className={'buyPage-logo buyPage-logo-1'} />
                <div className={'buyPage-time'}>{planNameOne}</div>

                <div className={'buyPage-price'}>{displayCurrency(priceOne, planCurrency[0])}</div>
                <div className={'buyPage-free'}>{freeMonthOne}</div>
                <button className=" theme-button buyButtonGray" onClick={emptyFunction}>
                  <div className="theme-button-container ">
                    <div />
                    <span>{available_soon}</span>
                  </div>
                </button>
              </div>
            </div>
          ) : (
            <LinkComponent link={confirmPageURL}>
              <div
                className={borderThree ? 'priceBox borderToggle' : 'priceBox'}
                onClick={(): void => {
                  setBorderThree(!borderThree);
                  setBorderOne(false);
                  setBorderTwo(false);

                  const currencyCode = productsApiData[0].currency;
                  const product = {
                    name: planNameThree,
                    id: planIdThree,
                    price: priceThree,
                    position: 3,
                    category,
                  };

                  triggerAction(Action.ProductClick, { product, currencyCode });
                  triggerAction(Action.AddToCart, { product, currencyCode });

                  dispatch(
                    setPlan({
                      planName: planNameThree,
                      campaignId,
                      productId: planIdThree,
                      pricingId: pricingId[2],
                      paymentProviderId: paymentProviderId[2],
                      price: priceThree,
                      type: type[2],
                      email,
                      currency: planCurrency[2],
                    }),
                  );
                }}
              >
                <div className={'visibleBorder'}></div>
                <div className="real-content">
                  <div className={'buyPage-logo buyPage-logo-3'} />
                  <div className={'buyPage-time'}>{planNameThree}</div>
                  <div className="price-box">
                    <div className={'buyPage-price'}>
                      {displayCurrency(priceThree / 12, planCurrency[2])?.trim()}/{month}
                    </div>
                    <div className={'buyPage-total'}>
                      {total?.concat(' ')}
                      {displayCurrency(priceThree, planCurrency[2])}
                    </div>
                    <button className=" theme-button" onClick={emptyFunction}>
                      <div className="theme-button-container buyButtonAction">
                        <div />
                        <span>{continue_button}</span>
                      </div>
                    </button>

                    <div className={'buyPage-save'}>
                      {save?.concat(' ')}
                      {displayCurrency(priceForOneMonth * 12 - priceThree, planCurrency[2])}
                    </div>
                  </div>
                </div>
              </div>
            </LinkComponent>
          )}
        </div>
        {disclaimeryellow && (
          <div className={'buyPage-disclaimer'}>
            <PrismicRichText render={disclaimeryellow} />
          </div>
        )}
      </div>
    </div>
  );
};

export default BuyPagePackages;
