const v = (variable: string, def?: string): string | void =>
  process.env[variable] || def || console.log(`missing environment variable : ${variable}`);

export default {
  local: process.env.NODE_ENV === 'development',
  consentCookieName: 'cookie_preference',
  rolesWithAccess: ['PrealphaAccess', 'AlphaAccess', 'BetaAccess', 'Subscriber', 'LifetimeSubscriber'],
  prodRootURL: 'https://www.dualuniverse.game',
  validation: {
    name: /.+/i,
    displayName: /^[a-zA-Z0-9-]{6,30}$/i,
    password: /^.{8,}$/i,
    code: /^[A-Z0-9-]+$/i,
    http: /http/g,
    email: /^\S+@\S+$/i,
  },
  nodeEnv: String(v('NODE_ENV', 'development')),
  dateFormat: 'PPPPp',
  dateFormatWithTime: "dd/MMM/yy HH:mm",
  europeanCountries: [
    'AT',
    'BE',
    'BG',
    'HR',
    'CY',
    'CZ',
    'DK',
    'EE',
    'FI',
    'FR',
    'DE',
    'EL',
    'HU',
    'IE',
    'IT',
    'LV',
    'LT',
    'LU',
    'MT',
    'NL',
    'PL',
    'PT',
    'RO',
    'SK',
    'SI',
    'ES',
    'SE',
    'NO',
    'IS',
    'LI',
  ],
  analytics: {
    cpmStar: {
      trackingPixelImage: {
        buy: 'https://ssl.playreg.net/orvkS44rWv90?subid=buy',
        confirm: 'https://ssl.playreg.net/orvkS44rWv90?subid=confirm',
      },
    },
  },
};
