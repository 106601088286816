import React, { useEffect, useState } from 'react';
import { usePrismic } from '../../../hooks/prismic.hook';
import { useForm } from 'react-hook-form';
import { useI18n } from '../../../stores/selectors/i18n.selector';
import axios from 'axios';
import config from '../../../../config';
import LinkComponent from '../../link/link.component';
import PrismicRichText, { asText } from '../../../services/prismic/prismic.richtext.service';
import { SnackBarComponent } from '../../snackBar/snackBar.component';
import { goto } from '../../../stores/selectors/router.selector';
import Popup from 'reactjs-popup';
import {
  contentStyle,
  overlayStyle,
  arrowStyle,
  translatedSuggestionsContent,
  translatedWarningContent,
  renderBars,
} from '../account-creation/psw-strengh.service';
import zxcvbn from 'zxcvbn';
import { getForgotInfo } from '../../../stores/actions/forgot-password.action';
import { useAppDispatch } from '../../../stores/app.store.config';
import { resetError } from '../../../stores/slices/forgot-password.slice';
import { useForgotPsw } from '../../../stores/selectors/forgot-password.selector';
import { getPrismicError } from '../../../services/utils/utils.service';
import { useParams } from "react-router-dom";

const AccountForgotComponent = (): JSX.Element | null => {
  const { key } = useParams();
  const dispatch = useAppDispatch();
  const { handleSubmit, register, errors, watch } = useForm();

  const { locale } = useI18n();
  const { contents, error } = useForgotPsw();

  const [showPswInput, setShowPswInput] = useState(false);
  const [openSnack, setOpenSnack] = useState({ status: false, message: '', type: '' });
  const {
    result: { status_weak, status_normal, status_strong, strength },
  } = usePrismic('account_creation');
  const { contents: prismicContent } = usePrismic('errors');
  const {
    data: {
      page_title,
      page_subtitle,
      page_description,
      input_label,
      input_placeholder,
      success_sent_message,
      failure_key_invalid,
      button_action,
      second_page_main_title,
      second_page_desc_simple,
      second_page_desc_complex,
      checkbox_label_psw,
      checkbox_label_2fa,
      button_page_two,
      success_message_two_complex,
      success_message_two_psw,
      success_message_two_2fa,
      password_dont_match,
      side_image,
    },
  } = usePrismic('account_forgot_password');

  const [enable2FA, setEnable2FA] = useState(false);

  const onSubmit = async (values: Record<string, any>): Promise<void> => {
    if (!key) {
      try {
        await axios.post(
          `/api/auth/me/reset-credentials/request?lang=${locale.substring(0, 2)}`,
          { email: values.email },
          {
            headers: { 'Content-Type': 'application/json' },
          },
        );
        setOpenSnack({ status: true, message: asText(success_sent_message), type: 'success' });
        cleanNotice();
      } catch (err: any) {
        const errorServer = err.response.status !== 429 ? err.response.data : { code: 31 };
        setOpenSnack({
          status: true,
          message: getPrismicError(errorsPrismic, errorServer),
          type: 'failure',
        });
        cleanNotice();
      }
    } else {
      if (values.password !== values.confirmPassword) {
        setOpenSnack({ status: true, message: asText(password_dont_match), type: 'failure' });
        cleanNotice();
        return;
      }
      try {
        await axios.put(
          `/api/auth/me/reset-credentials/${key}/confirm?lang=${locale.substring(0, 2)}`,
          { password: values.password, disableMFA: enable2FA },
          {
            headers: { 'Content-Type': 'application/json' },
          },
        );
        setOpenSnack({
          status: true,
          message:
            enable2FA && values?.password
              ? asText(success_message_two_complex)
              : values?.password
              ? asText(success_message_two_psw)
              : asText(success_message_two_2fa),
          type: 'success',
        });
        setTimeout(() => {
          goto('/');
        }, 3000);
      } catch (err: any) {
        const errorServer = err.response.status !== 429 ? err.response.data : { code: 429 };
        setOpenSnack({
          status: true,
          message: getPrismicError(errorsPrismic, errorServer),
          type: 'failure',
        });
        cleanNotice();
      }
    }
  };
  const emailWatch = watch('email');

  const re = new RegExp(config.validation.email);

  const pswWatch = watch('password');
  const confirmPswWatch = watch('confirmPassword');

  const { contents: feedback_content } = usePrismic('account_creation_feedback');
  const rendertraslationStregth = (score: number): string => {
    switch (score) {
      case 0: {
        return status_weak;
      }
      case 1: {
        return status_normal;
      }
      case 2: {
        return status_normal;
      }
      case 3: {
        return status_strong;
      }
      default:
        return status_strong;
    }
  };

  const { feedback } = feedback_content?.results?.[0]?.data || {};
  const score = zxcvbn(pswWatch || '').score;
  const warnings = zxcvbn(pswWatch || '').feedback.warning;
  const suggestions = zxcvbn(pswWatch || '').feedback.suggestions;

  const cleanNotice = () => {
    setTimeout(() => {
      setOpenSnack({ status: false, message: '', type: '' });
    }, 6000);
  };
  const renderColumn = (
    name: string,
    label: string,
    inputWatch: string,
    icon: string,
    type: string,
    maxLength: number,
    minLength: number,
  ): JSX.Element => {
    return (
      <>
        <div className="tooltip-container">
          <label className="label none" htmlFor={name}>
            {label}
          </label>

          {name?.includes('password') && (
            <Popup
              open={Boolean(pswWatch) && suggestions.length > 0}
              trigger={(): JSX.Element => (
                <div className="tooltip-box">
                  <span className="buttoncustom">i</span>
                </div>
              )}
              {...{ contentStyle, overlayStyle, arrowStyle }}
              position="top center"
              className={suggestions.length === 0 ? 'custom-tooltip' : ''}
            >
              <div className="tooltip-msg">
                {translatedSuggestionsContent(suggestions, feedback).map((result: any, key: number): any => {
                  return <PrismicRichText render={result} key={key} />;
                })}
                <PrismicRichText render={translatedWarningContent(warnings, feedback)} />
              </div>
            </Popup>
          )}
        </div>
        <div className="input-container">
          <div className={errors.email || errors.password ? 'input-container__row error' : 'input-container__row'}>
            <i className="iconWapper">
              <span className={icon}></span>
            </i>
            <input
              className="input-field"
              name={name}
              id={name}
              type={type}
              placeholder={name.includes('email') ? asText(input_placeholder) : '***********'}
              minLength={minLength}
              maxLength={maxLength}
              ref={register({
                required: `${label} ${'required'}`,
              })}
            />
            {name.toLocaleLowerCase().includes('email') ? (
              <i className="iconWapper iconWapperRight">
                {!inputWatch && !errors.email ? (
                  <span className="icon-loading" />
                ) : inputWatch?.match(re) && !errors.email ? (
                  <span className="icon-validate" />
                ) : (
                  <span className="icon-fail" />
                )}
              </i>
            ) : name.includes('confirmPassword') ? (
              <i className="iconWapper iconWapperRight">
                <span
                  className={
                    !confirmPswWatch && !errors.confirmPassword
                      ? 'icon-loading'
                      : confirmPswWatch !== pswWatch || errors.confirmPassword
                      ? 'icon-fail'
                      : 'icon-validate'
                  }
                />
              </i>
            ) : (
              <i className="iconWapper iconWapperRight">
                <span
                  className={
                    !pswWatch && !errors.password
                      ? 'icon-loading'
                      : (pswWatch && zxcvbn(pswWatch).score === 0) || errors.password
                      ? 'icon-fail'
                      : 'icon-validate'
                  }
                />
              </i>
            )}
            {(errors.email || errors.password) && (
              <>
                <div className="arrow"></div>
                <div className="yellow-notice">
                  <p> Please check your {name}</p>
                </div>
              </>
            )}
          </div>
          {name?.includes('password') && (
            <div className="strenght-psw-container">
              <div className="strenght-psw-bars">{pswWatch ? renderBars(score) : renderBars(-1)}</div>
              <p className="strenght-psw-label">{pswWatch ? rendertraslationStregth(score) : strength}</p>
            </div>
          )}
        </div>
      </>
    );
  };
  useEffect(() => {
    if (key) {
      // retrieveInfo();
      if (error) {
        goto('/forgot-password');
      } else {
        dispatch(getForgotInfo({ key, locale }));
      }
    }
  }, [dispatch, error]);
  useEffect(() => {
    if (!key && failure_key_invalid) {
      if (error) {
        setTimeout(() => {
          setOpenSnack({
            status: true,
            message: asText(failure_key_invalid),
            type: 'failure',
          });
          dispatch(resetError());
        }, 500);
      }
    }
  }, [failure_key_invalid, error]);

  if (!feedback || !input_placeholder || !prismicContent) return <></>;

  const {
    results: [
      {
        data: { errors: errorsPrismic },
      },
    ],
  } = prismicContent;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="account-forgot-container">
        <div className="message-content">
          <div className="message-title">
            <PrismicRichText render={page_title} />
          </div>
          {!key && (
            <>
              <div className="message-desc">
                <PrismicRichText render={page_subtitle} />
              </div>
              <div className="message-info">
                <PrismicRichText render={page_description} />
              </div>

              <div className="row">
                <div className="col col-6">
                  {renderColumn('email', asText(input_label), emailWatch, 'icon-email', 'email', 254, 0)}
                </div>
              </div>
              <button className="theme-button button-mobile" type="submit">
                <div className="theme-button-container">
                  <div className="send" />
                  <span>{asText(button_action)}</span>
                </div>
              </button>

              <button className="theme-button" type="submit">
                <div className="theme-button-container">
                  <div className="send" />
                  <span>{asText(button_action)}</span>
                </div>
              </button>
              {openSnack.status && (
                <SnackBarComponent message={openSnack.message} type={openSnack.type} setOpenSnack={setOpenSnack} />
              )}
              <div className="line"></div>
              <div className="section-back">
                <div className="icon-back" />
                <LinkComponent label=" Go to the Homepage" className="goback" link="/" />
              </div>
            </>
          )}
          {key && (
            <>
              <div className="message-desc">
                <PrismicRichText render={second_page_main_title} />
              </div>
              <div className="message-info secondpage">
                <p>
                  {contents?.mfaEnabled
                    ? asText(second_page_desc_complex)
                        ?.replace('<EMAIL_ADDRESS_HERE>', contents?.email)
                        ?.split('!')[0]
                        ?.concat('!')
                    : asText(second_page_desc_simple)
                        ?.replace('<EMAIL_ADDRESS_HERE>', contents?.email)
                        ?.split('!')[0]
                        ?.concat('!')}
                </p>
                <br />
                <p>
                  {contents?.mfaEnabled
                    ? asText(second_page_desc_complex)?.replace('<EMAIL_ADDRESS_HERE>', contents.email)?.split('!')[1]
                    : asText(second_page_desc_simple)?.replace('<EMAIL_ADDRESS_HERE>', contents.email)?.split('!')[1]}
                </p>
              </div>
              {contents.mfaEnabled && (
                <div className="checkboxcontainer checkbox">
                  <input
                    type="checkbox"
                    onClick={() => {
                      setShowPswInput(!showPswInput);
                    }}
                  />
                  <p>{asText(checkbox_label_psw)}</p>
                </div>
              )}
              {(showPswInput || !contents.mfaEnabled) && (
                <div className="row small">
                  <div className="col col-6">
                    {renderColumn('password', 'Password', pswWatch, 'icon-psw', 'password', 128, 1)}
                  </div>
                  <div className="col col-6">
                    {renderColumn(
                      'confirmPassword',
                      'Confirm password',
                      confirmPswWatch,
                      'icon-psw',
                      'password',
                      128,
                      1,
                    )}
                  </div>
                </div>
              )}
              {openSnack.status && (
                <SnackBarComponent message={openSnack.message} type={openSnack.type} setOpenSnack={setOpenSnack} />
              )}
              {contents?.mfaEnabled && (
                <div className="checkboxcontainer checkbox">
                  <input
                    type="checkbox"
                    name="enable2FA"
                    onClick={() => {
                      setEnable2FA(true);
                    }}
                  />
                  <p>{asText(checkbox_label_2fa)}</p>
                </div>
              )}
              <button className="theme-button second-page" type="submit">
                <div className="theme-button-container">
                  <div className="send" />
                  <span>{asText(button_page_two)}</span>
                </div>
              </button>
            </>
          )}
        </div>
        <div className="message-image" style={{ backgroundImage: `url(${side_image?.url})` }}></div>
        <div
          className="message-image image-mobile"
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, #0c1315 100%),url(${side_image?.url})`,
          }}
        ></div>
      </div>
    </form>
  );
};
export default AccountForgotComponent;
