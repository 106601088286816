import React from 'react';
import { usePrismic } from '../../../hooks/prismic.hook';
import { usePlan } from '../../../stores/selectors/plan.selector';

const BuyPageEnterCode = (): JSX.Element => {
  const { result } = usePrismic('buy_page');

  const { contents } = usePlan();

  return contents && contents.referallCode ? (
    <div className="buypage-entercode-landingone">
      <h6>{result.description}</h6>
      <div className="util-input">
        <div />
        <input
          type={'text'}
          className="util-input-text"
          placeholder={result.placeholder}
          value={contents.referallCode}
        />
        <div />
      </div>
    </div>
  ) : (
    <div className="buypage-entercode-landingone">
      <h6>{result.description}</h6>
      <div className="util-input">
        <div />
        <input type={'text'} className="util-input-text" placeholder={result.placeholder} />
        <div />
      </div>
    </div>
  );
};

export default BuyPageEnterCode;
