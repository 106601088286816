import React, { useState } from 'react';
import MediaNavBar from './medianavbar';
import { useMedia } from '../../../stores/selectors/media.selector';
import { displayName, subcategoriesList } from '../media.service';
import { usePrismic } from '../../../hooks/prismic.hook';
import { getThubmVideo } from '../media.service';
import { InfiniteScrollComponent } from './infiniteScroll.component';
import { useRemoteConfiguration } from '../../../stores/selectors/remote-configuration.selector';
import DownloadComponent from './download.component';

const DocumentComponent = (): JSX.Element => {
  const {
    status: { documents },
  } = useMedia();
  const {
    result: { cat_documents, media },
  } = usePrismic('media-page');
  const [imageModal, setimageModal] = useState('');
  const [amountDisplayed, setamountDisplayed] = useState(1);
  const closeModal = (): void => {
    setimageModal('');
  };
  const {
    remoteConfig: { ASSETS_MEDIA_PREFIX },
  } = useRemoteConfiguration();

  const documentNoThumb = documents?.filter((elem: string) => {
    return !elem.includes('thumbnail');
  });
  const [indexImageSelected, setIndexImageSelected] = useState(0);
  const renderDocument = (subcategory: string): JSX.Element | JSX.Element => {
    const items = documentNoThumb
      .filter((elem: string) => {
        return elem.includes(subcategory);
      })
      ?.map((result: string, key: number) => {
        return (
          <div className="document" key={key}>
            <div
              className="container"
              onClick={(): void => {
                setimageModal('documents');
                setIndexImageSelected(documentNoThumb?.indexOf(result));
              }}
              style={{
                backgroundImage: `url(${getThubmVideo(result, documents, ASSETS_MEDIA_PREFIX)})`,
              }}
            ></div>
            <div className="header">
              <p>{displayName(result)}</p>
            </div>
          </div>
        );
      });
    return items;
  };
  const renderSubcategories = (): JSX.Element[] | JSX.Element => {
    const categories = subcategoriesList(documents)?.map((elem: string, key: number) => {
      return (
        <div className={key % 2 === 0 ? 'categories-content' : 'categories-content alternate-bg'} key={key}>
          {key < amountDisplayed && (
            <>
              <div className="mediaHeader">
                <p className="category-name">{elem}</p>
              </div>
              <div className="document-grid">{renderDocument(elem)}</div>
            </>
          )}
        </div>
      );
    });
    return categories;
  };
  return (
    <div className="categories-container-custom">
      <MediaNavBar />
      <DownloadComponent
        imageModal={imageModal}
        listDownload={documentNoThumb}
        closeModal={closeModal}
        indexImageSelected={indexImageSelected}
        documents={documents}
        renderOptions={() => {
          return <></>;
        }}
      />
      <div className="categories-intro">
        <h2 className="categories-intro-title">{media}</h2>
        <h2 className="categories-intro-title-section">{cat_documents}</h2>
      </div>
      {renderSubcategories()}
      {amountDisplayed < documentNoThumb?.length && (
        <InfiniteScrollComponent
          renderSubcategories={renderSubcategories}
          amountDisplayed={amountDisplayed}
          setamountDisplayed={setamountDisplayed}
        />
      )}
    </div>
  );
};
export default DocumentComponent;
