import React from 'react';
import { usePrismic } from '../../hooks/prismic.hook';
import PrismicRichText from '../../services/prismic/prismic.richtext.service';
import LinkComponent from '../link/link.component';

const TutorialsOtherComponent = (): JSX.Element => {
  const {
    data: { other_title, links = [] },
  } = usePrismic('tutorials_page');

  return (
    <div className="tutorials-other">
      <PrismicRichText render={other_title} />
      <div className="links">
        {links.map((link: any, key: number) => (
          <LinkComponent className="item" link={link.link?.url} key={key}>
            <div className="corners"></div>
            <div className="square">
              <img className="icon" src={link.icon?.url} alt="" />
              <div className="side">
                <div className="label">
                  <PrismicRichText render={link.link_label} />
                </div>
                <div className="description">
                  <PrismicRichText render={link.link_description} />
                </div>
              </div>
            </div>
          </LinkComponent>
        ))}
      </div>
    </div>
  );
};

export default TutorialsOtherComponent;
