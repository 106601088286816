const currencies: any = {
  CAD: {
    label: 'CA$',
  },
  AUD: {
    label: 'AU$',
  },
  EUR: {
    label: '€',
    suffix: true,
  },
  USD: {
    label: 'US$',
  },
  GBP: {
    label: '£',
  },
};

export const displayCurrency = (amount: number, currencyCode: string): string => {
  if (!amount || !currencyCode) return '';
  const currency: any = currencies[currencyCode];
  const suffix = currency.suffix ? currency.label : '';
  const prefix = !currency.suffix ? currency.label : '';
  return `${prefix} ${Number(amount).toFixed(2)} ${suffix}`;
};
