import axios from 'axios';
import {
  KickstarterForm,
  KickstarterPhysicalRewardInfo,
} from '../../models/authentication/authentication.kickstarter.model';
import { toCamelCase } from '../utils/utils.service';
import { getUser } from './attributes.service';

export const getPhysicalRewards = async (): Promise<KickstarterPhysicalRewardInfo | null> => {
  const user = getUser();
  try {
    const { data } = await axios.get('/api/inventory/rewards/me/backer-rewards-info', {
      headers: { Authorization: `Bearer ${user.token}` },
    });

    return data;
  } catch (err) {
    return null;
  }
};
export const updatePhysicalRewards = async (
  body: KickstarterPhysicalRewardInfo,
): Promise<KickstarterPhysicalRewardInfo | null> => {
  const user = getUser();
  try {
    const { data } = await axios.put('/api/inventory/rewards/me/backer-rewards-info', body, {
      headers: { Authorization: `Bearer ${user.token}` },
    });

    return data;
  } catch (err) {
    return null;
  }
};
export const getPhysicalRewardsAllowed = async (): Promise<boolean | null> => {
  const user = getUser();
  try {
    const { data } = await axios.get('/api/inventory/rewards/me/backer-rewards-allowed', {
      headers: { Authorization: `Bearer ${user.token}` },
    });

    return data;
  } catch (err) {
    return null;
  }
};

export const getForm = async (): Promise<KickstarterForm> => {
  const user = getUser();
  const { data } = await axios.get('/api/kickstarter', {
    headers: { Authorization: `Bearer ${user.token}` },
  });
  return data;
};

export const postForm = async (body: KickstarterForm): Promise<KickstarterForm> => {
  const user = getUser();
  const { data } = await axios.post('/api/kickstarter', toCamelCase(body), {
    headers: { Authorization: `Bearer ${user.token}` },
  });

  return data;
};
