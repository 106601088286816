import React from 'react';

export const renderBars = (score: number): JSX.Element[] | JSX.Element => {
  const bars = [];
  for (let i = 0; i < 5; i++) {
    if (i > score) {
      bars.push(<div className="bar" key={i}></div>);
    } else {
      bars.push(<div className={'bar ' + renderColorClass(score)} key={i}></div>);
    }
  }
  return bars;
};
export const renderColorClass = (score: number): string => {
  switch (score) {
    case 0: {
      return 'weak';
    }
    case 1: {
      return 'normal';
    }
    case 2: {
      return 'normal';
    }
    case 3: {
      return 'strong';
    }
    default:
      return 'strong';
  }
};

export const translatedSuggestionsContent = (suggestions: string[], feedback: any): any => {
  return suggestions?.map((resultzx: any): any => {
    const response = feedback.filter((result: any) => result?.feedback_key === resultzx)[0]?.feedback_value;
    return response;
  });
};

export const translatedWarningContent = (warnings: string, feedback: any): any => {
  const response = feedback.filter((result: any) => result?.feedback_key === warnings)[0]?.feedback_value;
  return response;
};
export const contentStyle = { background: '#ffd255' };
export const overlayStyle = { background: 'transparent' };
export const arrowStyle = { color: '#ffd255' };
