import { useEffect } from 'react';
import { useAppDispatch } from '../../stores/app.store.config';
import { allowed } from '../../stores/actions/kickstarter.actions';
import { useAuth } from '../../stores/selectors/auth.selector';
import { useKickstarter } from '../../stores/selectors/kickstarter.selector';

const useFetchKickstarter = (): void => {
  const dispatch = useAppDispatch();
  const { user } = useAuth();
  const { error } = useKickstarter();

  useEffect(() => {
    if (user && !error) {
      // dispatch(fetch());
      dispatch(allowed())
    }
  }, [dispatch, user?.token]);
};

export default useFetchKickstarter;
