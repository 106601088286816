import React from 'react';
import { Provider } from 'react-redux';
import AppComponent from './app/app.component';
import { store } from './app/stores/app.store.config';
import { ParallaxProvider } from 'react-scroll-parallax';
const AppContainer = (): JSX.Element => (
  <Provider store={store}>
    <ParallaxProvider>
      <React.StrictMode>
        <AppComponent />
      </React.StrictMode>
    </ParallaxProvider>
  </Provider>
);

export default AppContainer;
