import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { usePrismic } from '../../../hooks/prismic.hook';
import config from '../../../../config';
import { goto } from '../../../stores/selectors/router.selector';
import { useAuth } from '../../../stores/selectors/auth.selector';
import RichText from '../../../services/prismic/prismic.richtext.service';
import { getUrlFromEnglish } from '../../../services/i18n/i18n.url.service';
import qs from 'qs';
import zxcvbn from 'zxcvbn';
import Popup from 'reactjs-popup';
import signup from '../../../services/authentication/signup';
import {
  contentStyle,
  overlayStyle,
  arrowStyle,
  translatedSuggestionsContent,
  translatedWarningContent,
  renderBars,
} from '../account-creation/psw-strengh.service';
import { useI18n } from '../../../stores/selectors/i18n.selector';
import { useAppDispatch } from '../../../stores/app.store.config';
import { signOut } from '../../../stores/slices/auth.slice';
import { useLocation, useParams } from 'react-router-dom';

const AccountCreationComponent = (): JSX.Element | null => {
  let listerrors: any = [];
  const [emailAddress, setEmail] = useState('');

  const { activationKey } = useParams();

  const [errorMessage, seterrorMessage] = useState('');

  const {
    result: {
      page_title,
      logo_description,
      first_name,
      last_name,
      display_name,
      email_label,
      password_label,
      repeat_password,
      recruiter_code,
      game_time_code,
      agreement,
      action_button,
      already_login,
      success,
      error_activation,
      error_sorry,
      error_required,
      error_invalid,
      error_invalid_display_name,
      error_invalid_password,
      error_passwords,
      error_server,
      error_unable,
      status_weak,
      status_normal,
      status_strong,
      strength,
    },
    contents,
  } = usePrismic('account_creation');
  const { legal_notice } = contents?.results?.[0]?.data || {};
  const { locale } = useI18n();
  const { contents: feedback_content } = usePrismic('account_creation_feedback');
  const { feedback } = feedback_content?.results?.[0]?.data || {};
  // dummie
  useEffect(() => {
    const getEmail = async (): Promise<void> => {
      if (activationKey) {
        try {
          const email = await axios.get(`/api/register/activate/${activationKey}`);
          setEmail(email.data.email);
        } catch (err) {
          seterrorMessage(error_activation);
        }
      }
    };
    getEmail();
  }, []);

  const auth: any = useAuth();

  const [signUpError, setSignUpError] = useState('');

  const { handleSubmit, register, errors, watch } = useForm();

  const [formResponse, setFormResponse] = useState('');

  const { pathname, search } = useLocation();
  const dispatch = useAppDispatch();
  const { code, recruiter, steam_session_ticket, steam_app_id, source } = qs.parse(search.slice(1));

  useEffect(() => {
    if (auth.user && !activationKey) {
      if (code) {
        goto(getUrlFromEnglish(`/account/products?code=${code}`));
      } else if (recruiter) {
        goto(getUrlFromEnglish(`/account/recruit-a-friend?recruiter=${recruiter}`));
      } else if (steam_session_ticket) {
        dispatch(signOut());
      } else {
        goto(getUrlFromEnglish(`/`));
      }
    }
  }, [auth]);

  useEffect(() => {
    if (!auth.user && steam_session_ticket && (pathname.includes('/de/') || pathname.includes('/fr/'))) {
      window.location.href = '/lp/signup' + search;
    }
  }, []);

  const onChangeError = (): void => {
    listerrors = null;
    setFormResponse('');
  };

  const onSubmit = async (values: Record<string, any>): Promise<void> => {
    const { firstName, lastName, displayName, email, password, retypePassword, gameCode, recruiterCode, newsletter } =
      values;

    if (password !== retypePassword) {
      setSignUpError('');
      return;
    } else {
      const acquisitionGaUtm = window.location.search;
      const input = {
        displayName,
        firstName,
        lastName,
        email,
        password,
        newsletter,
        activationKey: activationKey || undefined,
        entitlementCode: gameCode,
        acquisitionGaUtm,
        recruiterId: recruiterCode,
        language: locale?.split('-')[0],
        steamSessionTicket: steam_session_ticket,
        steamAppId: steam_app_id,
        source,
      };

      try {
        await signup(input);
        setFormResponse('success');
      } catch (err: any) {
        if (err.response.status === 500) setFormResponse('failureServer');
        else {
          setFormResponse('failure');
          listerrors = err.response.data.errors;
          if (listerrors) {
            listerrors.length === 0
              ? seterrorMessage(error_sorry)
              : seterrorMessage(
                  Object.keys(listerrors)
                    .map((key) => listerrors[key].join(', '))
                    .join(', '),
                );
          } else {
            seterrorMessage(error_sorry);
          }
        }
      }
    }
  };

  const firstName = watch('firstName');

  const displayName = watch('displayName');

  const lastName = watch('lastName');

  const email = watch('email');

  const passwordInputted = watch('password') || '';

  const retypePassword = watch('retypePassword');

  const gameCode = watch('gameCode');

  const rendertraslationStregth = (score: number): string => {
    switch (score) {
      case 0: {
        return status_weak;
      }
      case 1: {
        return status_normal;
      }
      case 2: {
        return status_normal;
      }
      case 3: {
        return status_strong;
      }
      default:
        return status_strong;
    }
  };

  const recruiterCode = watch('recruiterCode');

  const iconClass = (): string => {
    if ((!passwordInputted && !errors.password) || errors.password) {
      return 'icon-loading';
    } else if (passwordInputted && zxcvbn(passwordInputted).score === 0) return 'icon-fail';
    return 'icon-validate';
  };
  const score = zxcvbn(passwordInputted).score;

  const warnings = zxcvbn(passwordInputted).feedback.warning;
  const suggestions = zxcvbn(passwordInputted).feedback.suggestions;
  if (!feedback) return <></>;

  return (
    <>
      <div className="registration-container-bg"></div>
      <div className="registration-container">
        <div className="reg-main-box">
          <div className="reg-square-border">
            <div className="logo-container">
              <div></div>
              <p>{logo_description}</p>
            </div>
            <div className="reg-title">
              <h1>{page_title}</h1>
            </div>
            <form className="registrationForm" onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col col-6">
                  <label className="label" htmlFor="firstname">
                    {first_name}
                  </label>
                  <div className="input-container">
                    <div className="input-container__row">
                      <i className="iconWapper">
                        <span className="icon-profile"></span>
                      </i>
                      <input
                        className="input-field"
                        name="firstName"
                        id="firstName"
                        type="text"
                        maxLength={30}
                        ref={register({
                          required: `${first_name} ${error_required}`,
                          pattern: {
                            value: config.validation.name,
                            message: `${first_name} ${error_invalid}`,
                          },
                        })}
                      />

                      <i className="iconWapper iconWapperRight">
                        {(firstName === '' && !errors.firstName) || errors.firstName ? (
                          <span className="icon-loading" />
                        ) : (
                          <span className="icon-validate" />
                        )}
                      </i>
                    </div>
                    {errors.firstName && <div className="error">{errors.firstName.message}</div>}
                  </div>
                </div>

                <div className="col col-6">
                  <label className="label" htmlFor="lastName">
                    {last_name}
                  </label>
                  <div className="input-container">
                    <div className="input-container__row">
                      <i className="iconWapper">
                        <span className="icon-profile"></span>
                      </i>
                      <input
                        className="input-field"
                        name="lastName"
                        id="lastName"
                        type="text"
                        maxLength={30}
                        ref={register({
                          required: `${last_name} ${error_required}`,
                          pattern: {
                            value: config.validation.name,
                            message: `${last_name} ${error_invalid}`,
                          },
                        })}
                      />
                      <i className="iconWapper iconWapperRight">
                        {(lastName === '' && !errors.lastName) || errors.lastName ? (
                          <span className="icon-loading" />
                        ) : (
                          <span className="icon-validate" />
                        )}
                      </i>
                    </div>
                    {errors.lastName && <div className="error">{errors.lastName.message}</div>}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col col-6">
                  <label className="label" htmlFor="displayName">
                    {display_name}
                  </label>
                  <div className="input-container">
                    <div className="input-container__row">
                      <i className="iconWapper">
                        <span className="icon-profile"></span>
                      </i>
                      <input
                        className="input-field"
                        name="displayName"
                        id="displayName"
                        type="text"
                        maxLength={30}
                        minLength={6}
                        onChange={onChangeError}
                        ref={register({
                          required: `${display_name} ${error_required}`,
                          pattern: {
                            value: config.validation.displayName,
                            message: error_invalid_display_name,
                          },
                        })}
                      />
                      <i className="iconWapper iconWapperRight">
                        {(!displayName?.match(new RegExp(config.validation.displayName)) && !errors.displayName) ||
                        errors.displayName ? (
                          <span className="icon-loading" />
                        ) : (
                          <span className="icon-validate" />
                        )}
                      </i>
                    </div>
                    {errors.displayName && <div className="error">{errors.displayName.message}</div>}
                  </div>
                </div>

                <div className="col col-6">
                  <label className="label" htmlFor="email">
                    {email_label}
                  </label>
                  <div className="input-container">
                    <div className="input-container__row">
                      <i className="iconWapper">
                        <span className="icon-email"></span>
                      </i>
                      <input
                        className="input-field"
                        name="email"
                        id="email"
                        type="email"
                        maxLength={254}
                        onChange={onChangeError}
                        value={emailAddress ? emailAddress : undefined}
                        ref={register({
                          required: `${email_label} ${error_required}`,
                        })}
                      />
                      <i className="iconWapper iconWapperRight">
                        {(email === '' && !errors.email) || errors.email ? (
                          <span className="icon-loading" />
                        ) : (
                          <span className="icon-validate" />
                        )}
                      </i>
                    </div>
                    {errors.email && <div className="error">{errors.email.message}</div>}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col col-6">
                  <div className="tooltip-container">
                    <label className="label label-tooltip" htmlFor="password">
                      {password_label}
                    </label>
                    <Popup
                      open={Boolean(passwordInputted) && suggestions.length > 0}
                      trigger={(): JSX.Element => (
                        <div className="tooltip-box">
                          <span className="buttoncustom">i</span>
                        </div>
                      )}
                      {...{ contentStyle, overlayStyle, arrowStyle }}
                      position="top center"
                    >
                      <div className="tooltip-msg">
                        {translatedSuggestionsContent(suggestions, feedback).map((result: any, key: number): any => {
                          return <RichText render={result} key={key} />;
                        })}
                        <RichText render={translatedWarningContent(warnings, feedback)} />
                      </div>
                    </Popup>
                  </div>
                  <div className="input-container">
                    <div className="input-container__row">
                      <i className="iconWapper">
                        <span className="icon-psw"></span>
                      </i>

                      <input
                        className="input-field"
                        name="password"
                        id="password"
                        type="password"
                        onChange={onChangeError}
                        maxLength={128}
                        minLength={8}
                        ref={register({
                          required: `${password_label} ${error_required}`,
                          pattern: {
                            value: config.validation.password,
                            message: error_invalid_password,
                          },
                        })}
                      />
                      <i className="iconWapper iconWapperRight">
                        <span className={iconClass()} />
                      </i>
                    </div>
                    <div className="strenght-psw-container">
                      <div className="strenght-psw-bars">{passwordInputted ? renderBars(score) : renderBars(-1)}</div>
                      <p className="strenght-psw-label">
                        {passwordInputted ? rendertraslationStregth(score) : strength}
                      </p>
                    </div>
                    {errors.password && errors.password.message && (
                      <div className="error">{errors.password.message}</div>
                    )}
                  </div>
                </div>

                <div className="col col-6">
                  <label className="label" htmlFor="password">
                    {repeat_password}
                  </label>
                  <div className="input-container">
                    <div className="input-container__row">
                      <i className="iconWapper">
                        <span className="icon-psw"></span>
                      </i>
                      <input
                        className="input-field"
                        name="retypePassword"
                        id="retypePassword"
                        type="password"
                        maxLength={128}
                        minLength={8}
                        onChange={onChangeError}
                        ref={register({
                          required: `${repeat_password} ${error_required}`,
                          min: {
                            value: 8,
                            message: error_invalid_password,
                          },
                          validate: {
                            identicalToPassword: (value: string): boolean | string =>
                              passwordInputted === value || error_passwords,
                          },
                        })}
                      />
                      <i className="iconWapper iconWapperRight">
                        {passwordInputted !== retypePassword ? (
                          <span className="icon-fail" />
                        ) : (
                          <>
                            {(retypePassword === '' && !errors.retypePassword) || errors.retypePassword ? (
                              <span className="icon-loading" />
                            ) : (
                              <span className="icon-validate" />
                            )}
                          </>
                        )}
                      </i>
                    </div>
                    {errors.retypePassword && <div className="error">{errors.retypePassword.message}</div>}
                  </div>
                </div>
              </div>
              <div className="row mb-0">
                <div className="col col-6">
                  <label className="label none" htmlFor="recruiterCode">
                    {recruiter_code}
                  </label>
                  <div className="input-container">
                    <div className="input-container__row">
                      <i className="iconWapper">
                        <span className="icon-code"></span>
                      </i>
                      <input
                        className="input-field"
                        name="recruiterCode"
                        id="recruiterCode"
                        type="text"
                        onChange={onChangeError}
                        defaultValue={String(recruiter ? recruiter : '')}
                        ref={register({
                          pattern: {
                            value: config.validation.code,
                            message: `${recruiter_code} ${error_invalid}`,
                          },
                        })}
                      />
                      <i className="iconWapper iconWapperRight">
                        {(recruiterCode === '' && !errors.recruiterCode) || errors.recruiterCode ? (
                          <span className="icon-loading" />
                        ) : (
                          <span className="icon-validate" />
                        )}
                      </i>
                    </div>
                    {errors.recruiterCode && <div className="error">{errors.recruiterCode.message}</div>}
                  </div>
                </div>
                {!steam_session_ticket && (
                  <div className="col col-6">
                    <label className="label none" htmlFor="gameCode">
                      {game_time_code}
                    </label>
                    <div className="input-container">
                      <div className="input-container__row">
                        <i className="iconWapper">
                          <span className="icon-code"></span>
                        </i>
                        <input
                          className="input-field"
                          name="gameCode"
                          id="gameCode"
                          type="text"
                          maxLength={36}
                          minLength={36}
                          onChange={onChangeError}
                          defaultValue={String(code ? code : '')}
                          ref={register({
                            pattern: {
                              value: config.validation.code,
                              message: `${game_time_code} ${error_invalid}`,
                            },
                          })}
                        />
                        <i className="iconWapper iconWapperRight">
                          {(gameCode === '' && !errors.gameCode) || errors.gameCode ? (
                            <span className="icon-loading" />
                          ) : (
                            <span className="icon-validate" />
                          )}
                        </i>
                      </div>
                      {errors.gameCode && <div className="error">{errors.gameCode.message}</div>}
                    </div>
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col col-12">
                  <div className="checkBox">
                    <label className="none" htmlFor="newsletter">
                      {agreement}
                    </label>
                    <a className={'checkboxcontainer'}>
                      <input
                        name="newsletter"
                        id="newsletter"
                        type="checkbox"
                        onChange={onChangeError}
                        ref={register({})}
                      />
                    </a>
                  </div>
                </div>
              </div>
              {errors.newsletter && <div className="error">{errors.newsletter.message}</div>}
              <div className="policy">
                <RichText render={legal_notice} />
              </div>

              {formResponse === 'success' && (
                <div className="underlineBox">
                  <div className={'successIcon'} />
                  <div className="successText">{success}</div>
                </div>
              )}
              {formResponse === 'failure' && (
                <div className="underlineBox">
                  <div className={'failureIcon'} />
                  <div className="failureText">{errorMessage}</div>
                </div>
              )}
              {formResponse === 'failureServer' && (
                <div className="underlineBox">
                  <div className={'failureIcon'} />
                  <div className="failureText">{error_server}</div>
                </div>
              )}
              <div className="row">
                <div className="col col-12">
                  <button
                    type="submit"
                    id="formButton"
                    disabled={!formResponse && score === 0}
                    className={formResponse?.includes('failure') ? 'formButtonFailure' : 'formButton'}
                  >
                    <div className="iconSubmitWraper">
                      <i className={formResponse?.includes('success') ? 'icon-validate-white' : 'icon-profile'}></i>
                    </div>
                    <span className="submitText">
                      {formResponse?.includes('failure') ? error_unable : action_button}
                    </span>
                  </button>
                </div>
              </div>
              {signUpError.length > 0 ? signUpError : <div></div>}
            </form>
          </div>
        </div>
        {!steam_session_ticket && (
          <div className="text-end">
            <p>{already_login}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default AccountCreationComponent;
