import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { usePrismic } from '../../hooks/prismic.hook';
import PrismicRichText, { asText } from '../../services/prismic/prismic.richtext.service';
import LinkComponent from '../link/link.component';
import ScrollAnimation from 'react-animate-on-scroll';
import Carousel from 'react-multi-carousel';
import YoutubeVideo from '../partials/video-youtube.partial-component';
import { useParams } from 'react-router-dom';

const LandingComponent = (): JSX.Element | null => {
  const { uid } = useParams();
  const responsiveCarousel = {
    desktop: {
      breakpoint: { max: 4000, min: 480 },
      items: 1.4,
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1,
    },
  };
  const responsiveCarouselComplete = {
    desktop: {
      breakpoint: { max: 4000, min: 480 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1,
    },
  };

  const [open, setOpen] = useState({ type: '', link: '' });
  const closeModal = (): void => {
    setOpen({ type: '', link: '' });
  };

  const [showVideo, setShowVideo] = useState(false);

  const { contents: contentsRaw } = usePrismic('landing_template');

  useEffect(() => {
    let prevScrollpos = window.pageYOffset;
    window.onscroll = function () {
      const currentScrollPos = window.pageYOffset;
      const navbar = document.getElementById('navbar-container');
      if (navbar) {
        navbar.style.position = 'sticky';
        if (prevScrollpos > currentScrollPos) {
          navbar.classList.add('nav-scroll-down');
          navbar.classList.remove('nav-scroll-up');
        } else {
          navbar.classList.add('nav-scroll-up');
          navbar.classList.remove('nav-scroll-down');
        }
      }
      prevScrollpos = currentScrollPos;
    };
  }, []);

  if (!contentsRaw) return <></>;

  const elements = contentsRaw?.results?.filter((item: any) => {
    return item?.data?.landing_type?.includes(uid);
  })[0];

  if (!elements) window.location.href = '/';
  const {
    data: {
      landing_title,
      landing_subtitle,
      landing_intro_bg,
      button_intro_left_label,
      button_intro_left_link,
      button_intro_right_link,
      button_intro_right_label,
      landing_description,
      roadmap_image,
      body: slices,
    },
  } = elements || {};

  const renderCarousel = (items: []): JSX.Element[] | JSX.Element => {
    const results = items?.map((item: any, key: number) => {
      return (
        <ScrollAnimation animateIn="fadeInUp" animateOnce={true} key={key} delay={(key * 500) / 2} duration={2}>
          <div className="image-container">
            <div
              className="image"
              style={{
                backgroundImage: `url(${item?.box_image?.url})`,
              }}
            ></div>
          </div>
          <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
            <div className="statement">
              <div className={'line'} />
              <p>{asText(item?.box_title)}</p>
            </div>
          </ScrollAnimation>
        </ScrollAnimation>
      );
    });
    return results;
  };
  const renderCarouselComplete = (items: []): JSX.Element[] | JSX.Element => {
    const results = items?.map((item: any, key: number) => {
      return (
        <ScrollAnimation animateIn="fadeInUp" animateOnce={true} key={key}>
          <div className="box-container" key={key}>
            <div className="box-grid inverse">
              <div
                className="box-grid-image"
                style={{
                  backgroundImage: `url(${item?.box_image?.url})`,
                }}
              ></div>
              <div className="box-content">
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                  <div className="box-content-title">
                    <PrismicRichText render={item?.box_title}></PrismicRichText>
                  </div>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={500}>
                  <div className="box-content-subtitle">
                    <PrismicRichText render={item?.box_subtitle}></PrismicRichText>
                  </div>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={1000}>
                  <div className="box-content-desc">
                    <PrismicRichText render={item?.box_content}></PrismicRichText>
                  </div>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={1500}>
                  {item?.button_link?.url && (
                    <button className="theme-button box-content-button">
                      <div className="theme-button-container">
                        <div></div>
                        <span>{asText(item?.button_label)}</span>
                      </div>
                    </button>
                  )}
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </ScrollAnimation>
      );
    });
    return results;
  };
  const renderTwoBox = (slice: any): JSX.Element[] | JSX.Element => {
    return slice?.items?.map((item: any, key: number) => {
      return (
        <ScrollAnimation animateIn="fadeInUp" animateOnce={true} key={key} offset={0} delay={(key * 1000) / 2}>
          <div className={key === 0 ? 'box-container' : 'box-container box-container-small'}>
            <div className="box-corners"></div>
            <div className={key === 0 ? 'box-grid' : 'box-grid box-grid-small'}>
              <div
                className={key === 0 ? 'box-grid-image' : 'box-grid-image box-grid-image-small'}
                style={{
                  backgroundImage: `url(${item?.box_image?.url})`,
                }}
              ></div>
              <div className="box-content">
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                  <div className="box-content-title">
                    <PrismicRichText render={item?.box_title}></PrismicRichText>
                  </div>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                  <div className="box-content-subtitle">
                    <PrismicRichText render={item?.box_subtitle}></PrismicRichText>
                  </div>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                  <div className={key === 0 ? 'box-content-desc' : 'box-content-desc box-content-desc-small'}>
                    <PrismicRichText render={item?.box_content}></PrismicRichText>
                  </div>
                </ScrollAnimation>
                {item?.button_link?.url && (
                  <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                    <LinkComponent link={item?.button_link?.url}>
                      <button className="theme-button box-content-button">
                        <div className="theme-button-container">
                          <div></div>
                          <span>{asText(item?.button_label)}</span>
                        </div>
                      </button>
                    </LinkComponent>
                  </ScrollAnimation>
                )}
              </div>
            </div>
          </div>
        </ScrollAnimation>
      );
    });
  };
  const renderThreeBox = (slice: any): JSX.Element[] | JSX.Element => {
    return slice?.items?.map((item: any, key: number) => {
      return (
        <ScrollAnimation animateIn="fadeInUp" animateOnce={true} key={key} offset={0} delay={(key * 1000) / 2}>
          <div className="box-container" key={key}>
            <div className="box-corners"></div>
            <div className="box-grid box-grid-small">
              <div
                className="box-grid-image box-grid-image-small"
                style={{
                  backgroundImage: `url(${item?.box_image?.url})`,
                }}
              ></div>
              <div className="box-content">
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                  <div className="box-content-title">
                    <PrismicRichText render={item?.box_title}></PrismicRichText>
                  </div>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                  <div className="box-content-subtitle">
                    <PrismicRichText render={item?.box_subtitle}></PrismicRichText>
                  </div>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                  <div className="box-content-desc box-content-desc-small">
                    <PrismicRichText render={item?.box_content}></PrismicRichText>
                  </div>
                </ScrollAnimation>
                {item?.button_link?.url && (
                  <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                    <LinkComponent link={item?.button_link?.url}>
                      <button className="theme-button box-content-button">
                        <div className="theme-button-container">
                          <div></div>
                          <span>{asText(item?.button_label)}</span>
                        </div>
                      </button>
                    </LinkComponent>
                  </ScrollAnimation>
                )}
              </div>
            </div>
          </div>
        </ScrollAnimation>
      );
    });
  };
  const renderTwoBigCards = (slice: any): JSX.Element[] | JSX.Element => {
    return slice?.items?.map((item: any, key: number) => {
      return (
        <ScrollAnimation animateIn="fadeInUp" animateOnce={true} key={key} offset={0} delay={(key * 1000) / 2}>
          <div
            className="box-container"
            key={key}
            style={{
              backgroundImage: `linear-gradient(to bottom, rgba(22, 32, 36, 0), rgba(22, 32, 36, 0) 51%, #162024 80%, #162024), url(${item?.box_image?.url})`,
            }}
          >
            <div className="box-grid box-grid-small">
              <div className="box-content">
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                  <div className="box-content-title">
                    <PrismicRichText render={item?.box_title}></PrismicRichText>
                  </div>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={500}>
                  <div className="box-content-subtitle">
                    <PrismicRichText render={item?.box_subtitle}></PrismicRichText>
                  </div>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={1000}>
                  <div className="box-content-desc box-content-desc-small">
                    <PrismicRichText render={item?.box_content}></PrismicRichText>
                  </div>
                </ScrollAnimation>

                <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={1000}>
                  {item?.button_link?.url && (
                    <LinkComponent link={item?.button_link?.url}>
                      <button className="theme-button box-content-button">
                        <div className="theme-button-container">
                          <div></div>
                          <span>{asText(item?.button_label)}</span>
                        </div>
                      </button>
                    </LinkComponent>
                  )}
                  <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={1000}>
                    {key === 0 && !item?.button_link?.url && (
                      <button
                        className="theme-button box-content-button"
                        onClick={() => {
                          setOpen({ type: 'image', link: 'dummie' });
                        }}
                      >
                        <div className="theme-button-container">
                          <div></div>
                          <span>{asText(item?.button_label)}</span>
                        </div>
                      </button>
                    )}
                  </ScrollAnimation>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </ScrollAnimation>
      );
    });
  };
  const renderSlices = (): JSX.Element[] | JSX.Element => {
    return slices?.map((slice: any, key: number) => {
      if (
        slice?.primary?.type?.includes('One Box Image Left') ||
        slice?.primary?.type?.includes('One Box Image Right')
      ) {
        return (
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true} offset={0} key={key}>
            <div className="box-container">
              <div className="box-corners"> </div>
              <div className={slice?.primary?.type?.includes('One Box Image Left') ? 'box-grid' : 'box-grid inverse'}>
                <div
                  className="box-grid-image"
                  style={{
                    backgroundImage: `url(${slice?.items[0]?.box_image?.url})`,
                  }}
                ></div>
                <div className="box-content">
                  <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                    <div className="box-content-title">
                      <PrismicRichText render={slice?.items[0]?.box_title}></PrismicRichText>
                    </div>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={500}>
                    <div className="box-content-subtitle">
                      <PrismicRichText render={slice?.items[0]?.box_subtitle}></PrismicRichText>
                    </div>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={1000}>
                    <div className="box-content-desc">
                      <PrismicRichText render={slice?.items[0]?.box_content}></PrismicRichText>
                    </div>
                  </ScrollAnimation>
                  {slice?.items[0]?.button_link?.url && (
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={1500}>
                      <LinkComponent link={slice?.items[0]?.button_link?.url}>
                        <button className="theme-button box-content-button">
                          <div className="theme-button-container">
                            <div></div>
                            <span>{asText(slice?.items[0]?.button_label)}</span>
                          </div>
                        </button>
                      </LinkComponent>
                    </ScrollAnimation>
                  )}
                </div>
              </div>
            </div>
          </ScrollAnimation>
        );
      } else if (slice?.primary?.type?.includes('Two Box')) {
        return (
          <div className="box-container-two" key={key}>
            {renderTwoBox(slice)}
          </div>
        );
      } else if (slice?.primary?.type?.includes('Three Box')) {
        return (
          // <div>
          //   <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          //     <div className="section-title">
          //       <PrismicRichText render={slice?.primary?.title}></PrismicRichText>
          //     </div>
          //   </ScrollAnimation>
          //   <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          //     <div className="section-subtitle">
          //       <PrismicRichText render={slice?.primary?.subtitle}></PrismicRichText>
          //     </div>
          //   </ScrollAnimation>
          <div className="box-container-two box-container-three" key={key}>
            {renderThreeBox(slice)}
          </div>
          // </div>
        );
      } else if (slice?.primary?.type?.includes('Two Big Cards')) {
        return (
          <div className="box-container-two box-container-two-equals" key={key}>
            {renderTwoBigCards(slice)}
          </div>
        );
      } else if (slice?.primary?.type?.includes('Carousel Three Shown')) {
        return (
          <div className="carousel-container" key={key}>
            <Carousel
              infinite={true}
              autoPlay={false}
              autoPlaySpeed={5000}
              transitionDuration={1000}
              keyBoardControl={true}
              responsive={responsiveCarousel}
              itemClass="carousel-item"
            >
              {renderCarousel(slice?.items)}
            </Carousel>
          </div>
        );
      } else if (slice?.primary?.type?.includes('Carousel One Focused')) {
        return (
          <div className="carousel-container carousel-container-complete" key={key}>
            <Carousel
              infinite={true}
              autoPlay={false}
              autoPlaySpeed={5000}
              transitionDuration={1000}
              keyBoardControl={true}
              responsive={responsiveCarouselComplete}
              itemClass="carousel-item"
              showDots={true}
            >
              {renderCarouselComplete(slice?.items)}
            </Carousel>
          </div>
        );
      } else if (slice?.primary?.type?.includes('Video')) {
        return (
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true} key={key}>
            <div className="landing-video-container">
              {!showVideo && (
                <div
                  className="video-front"
                  style={{
                    backgroundImage: `linear-gradient(to bottom, rgba(12, 19, 21, 0) 66%, #0c1315),url(${slice?.items[0]?.box_image?.url})`,
                  }}
                >
                  <div className="corners"></div>
                  <div
                    className="video-icon-container"
                    onClick={() => {
                      setShowVideo(true);
                    }}
                  ></div>
                  <div className="video-text video-title">
                    <PrismicRichText render={slice?.items[0]?.box_title}></PrismicRichText>
                  </div>
                  <div className="video-text video-subtitle">
                    <PrismicRichText render={slice?.items[0]?.box_subtitle}></PrismicRichText>
                  </div>
                </div>
              )}
              {showVideo && <YoutubeVideo videoURL={slice?.items[0]?.button_link?.url} size={''} />}
            </div>
          </ScrollAnimation>
        );
      }
    });
  };
  //   const renderNav = (): JSX.Element[] | JSX.Element => {
  //     return navItemsDoc?.nav_items?.map((item: any, key: number) => {
  //       return (
  //         <div
  //           key={key}
  //           className={asText(item?.nav_item_id)?.includes(uid) ? 'nav-link selected' : 'nav-link'}
  //         >
  //           <>
  //             <LinkComponent link={item?.nav_item_link?.url} tabIndex={0} label={asText(item?.nav_item_label)} />
  //             {item?.new_version && <p className="notice">{asText(navItemsDoc?.notice_label)}</p>}
  //           </>
  //         </div>
  //       );
  //     });
  //   };
  return (
    <div className="landing-container">
      <div className="landings-intro">
        <div className="opacity"> </div>

        <div
          className="background-image"
          style={{
            backgroundImage: `url(${landing_intro_bg?.url})`,
            width: '100%',
            zIndex: 1,
          }}
        ></div>

        <div className="landings-intro-number">
          <PrismicRichText render={landing_subtitle}></PrismicRichText>
        </div>

        <div className="landings-intro-title">
          <PrismicRichText render={landing_title}></PrismicRichText>
        </div>

        <div className="landings-intro-desc">
          <PrismicRichText render={landing_description}></PrismicRichText>
        </div>

        <Popup modal closeOnDocumentClick={false} open={open.link !== ''}>
          <div className={'popUpContainer'}>
            <div className={'exitIcon'} onClick={closeModal} />
            {open.type?.includes('video') && open.link && <YoutubeVideo videoURL={open.link} size={'long'} />}
            {open.type?.includes('image') && (
              <div className="road-map-img" style={{ backgroundImage: `url(${roadmap_image?.url})` }}></div>
            )}
          </div>
        </Popup>

        <div className="button-group">
          {button_intro_left_link?.url && (
            <button
              className="theme-button"
              onClick={() => {
                setOpen({ type: 'video', link: button_intro_left_link?.url });
              }}
            >
              <div className="theme-button-container">
                <div />
                <span>{asText(button_intro_left_label)}</span>
              </div>
            </button>
          )}
          <LinkComponent link={button_intro_right_link?.url} className={'wrapper-link'}>
            <button className="theme-button">
              <div className="theme-button-container">
                <div />
                <span>{asText(button_intro_right_label)}</span>
              </div>
            </button>
          </LinkComponent>
        </div>
      </div>
      {renderSlices()}
    </div>
  );
};
export default LandingComponent;
