import config from '../../../config';
import { Order } from '../../models/purchase/purchase.model';

// const pushDataLayer = (params: any): void => {
//   const dataLayer = (window as any).dataLayer || [];
//   dataLayer.push(params);
// };

export const category = 'Pack';

export enum Action {
  PaymentsMethodShown,
  PaymentMethod,
  ProductImpression,
  ProductClick,
  AddToCart,
  Purchase,
  NewsletterSubscribe,
}

type Product = {
  name: string;
  id: string;
  price: number;
  category: string;
  position: number;
};

const dataActions: Record<string, any> = [
  {
    action: Action.PaymentsMethodShown,
    data: {
      event: 'virtualPageView',
      page: {
        customURL: '/payment/methods',
      },
    },
  },
  {
    action: Action.PaymentMethod,
    data: {
      event: 'virtualPageView',
      page: {
        customURL: '/payment/method/paypal',
      },
    },
  },
  {
    action: Action.ProductImpression,
    data: {
      event: 'productView',
      ecommerce: {
        currencyCode: 'CAD', // Local currency is optional.
        impressions: [
          {
            name: 'xxxx', // Name or ID is required.
            id: '12345',
            price: '29.00',
            category,
            position: 1,
          },
          {
            name: 'xxxx',
            id: '123',
            price: '54.00',
            category,
            position: 2,
          },
        ],
      },
    },
  },
  {
    action: Action.ProductClick,
    data: {
      event: 'productClick',
      ecommerce: {
        click: {
          products: {
            name: 'xx', // Name or ID is required.
            id: '12345',
            price: '29.00',
            category: 'Pack',
            position: 1,
          },
        },
      },
      // eventCallback: function() {
      //     document.location = productObj.url;
      // },
    },
  },
  {
    action: Action.AddToCart,
    data: {
      event: 'addToCart',
      ecommerce: {
        currencyCode: 'CAD',
        add: {
          products: {
            name: 'xx', // Name or ID is required.
            id: '12345',
            price: '29.00',
            category,
            quantity: 1,
          },
        },
      },
    },
  },
  {
    action: Action.Purchase,
    data: {
      event: 'purchase',
      ecommerce: {
        purchase: {
          actionField: {
            id: '', // Transaction ID. Required for purchases and refunds.
            affiliation: 'Online Store',
            revenue: 0, // Total transaction value (incl. tax and shipping)
          },
          products: [
            {
              id: '',
              price: 0,
              category,
              quantity: 1,
            },
          ],
        },
      },
    },
  },
  {
    action: Action.NewsletterSubscribe,
    data: {
      event: 'newsletterSubscribe', // name your event
      category: 'Newsletter',
      action: 'Click on send',
      label: 'string', // optional
    },
  },
];

type TriggerActionData = {
  product?: Product;
  products?: Product[];
  currencyCode?: string;
  productId?: string;
  order?: Order;
  paymentMethod?: string;
};

export const triggerActionGTM = (action: Action, data?: TriggerActionData): void => {
  const dataAction = dataActions.find((d: any) => d.action === action).data;

  const { product, currencyCode, productId, order } = data || {};
  if (!dataAction) {
    if (config.local) {
      console.error('missig action data', action);
    }
  }
  if (!window?.gtag) return;
  switch (action) {
    case Action.AddToCart:
      dataAction.ecommerce.currencyCode = currencyCode;
      dataAction.ecommerce.add.products = product;
      window.gtag('event', 'add_to_cart', {
        currency: currencyCode,
        value: product?.price,
        items: [{ item_name: product?.name, quantity: 1, price: product?.price, item_id: productId }],
      });
      break;

    case Action.NewsletterSubscribe:
      window.gtag('event', 'newsletter_subscription', {});
      break;
    case Action.Purchase:
      if (order) {
        const price = order.shippingFees + order.price;
        dataAction.ecommerce.purchase.actionField.id = order.id;
        dataAction.ecommerce.purchase.actionField.revenue = price;
        dataAction.ecommerce.purchase.products[0].id = productId;
        dataAction.ecommerce.purchase.products[0].price = price;
        window.gtag('event', 'purchase', {
          currency: currencyCode,
          value: price,
          transaction_id: order?.id, // Transaction ID. Required for purchases and refunds.
          affiliation: 'Online Store',
          items: [{ item_name: product?.name, quantity: 1, price: price, item_id: productId }],
        });
      } else {
        if (config.local) {
          console.log('triggerAction error : missing order');
        }
      }

      break;

    default:
      break;
  }
};
export const triggerAction = (action: Action, data?: TriggerActionData): void => {
  const dataAction = dataActions.find((d: any) => d.action === action).data;

  const { order } = data || {};

  if (!dataAction) {
    if (config.local) {
      console.error('missig action data', action);
    }
  }
  switch (action) {
    case Action.NewsletterSubscribe:
      window.ga('send', 'event', 'Newsletter', 'Subscription', '');
      break;

    case Action.AddToCart:
      window.ga('send', 'event', 'Ecommerce', 'Add to cart', '');
      break;

    case Action.Purchase:
      if (order) {
        const price = order.shippingFees + order.price;
        window.ga('send', 'event', 'Ecommerce', 'Purchase', price);
        window.ga('require', 'ecommerce');
        window.ga('ecommerce:addTransaction', {
          id: order.id, // Transaction ID. Required.
          revenue: price, // Grand Total.
          affiliation: 'Online Store',
          currency: order.currency,
        });
        // window.ga('ecommerce:addItem', {
        //   id: productId, // Transaction ID. Required.
        //   price: price, // Unit price.
        //   quantity: 1,
        //   category: 'Pack',
        // });
        window.ga('ecommerce:send');
      } else {
        if (config.local) {
          console.log('triggerAction error : missing order');
        }
      }
      break;

    default:
      break;
  }
};
