import { createSlice } from '@reduxjs/toolkit';
import { initialCpmstarState } from '../../models/cpmstar/cpmstar.model';

const slice = createSlice({
  name: 'cpmstar',
  initialState: initialCpmstarState,
  reducers: {
    setCpmParameters: (state, { payload }): void => {
      state.content = payload;
    },
  },
  extraReducers: {},
});

export const cpmstarReducer = slice.reducer;

export const { setCpmParameters } = slice.actions;
