import axios from 'axios';
import config from '../../../../config';
import { getUser } from '../../authentication/attributes.service';

const SubscriptionStatus: any = {
  0: 'Active',
  1: 'Canceled',
  2: 'NonRenewing',
};

export const convertStatus = (subscriptions: any): any => {
  return { ...subscriptions, status: SubscriptionStatus[subscriptions.status] };
};

export const getOrdersService = async (subscriptionId: string): Promise<any> => {
  try {
    const user = getUser();
    const orders = await axios.get(`/api/orders?subscriptionId=${subscriptionId}&pageNumber=${1}&pageSize=${25}`, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
    return orders.data;
  } catch (err) {
    if (config.local) {
      console.log('error with getOrdersService', err);
      return null;
    }
  }
};

export const getSubscriptionsService = async (): Promise<any> => {
  try {
    const user = getUser();
    const result = await axios.get(`/api/orders/subscriptions/me/last-subscription`, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
    return convertStatus(result.data);
  } catch (err) {
    if (config.local) {
      console.log('error with getSubscriptionsService', err);
      return null;
    }
  }
};

export const getAllSubscriptionsService = async (): Promise<any> => {
  try {
    const user = getUser();
    const result = await axios.get(`/api/orders/subscriptions/me/subscriptions`, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
    return result.data;
  } catch (err) {
    if (config.local) {
      console.log('error with getAllSubscriptionsService', err);
      return null;
    }
  }
};

export const findCorrespondingSubscription = (product: any = {}, subscription: any): any => {
  if (!product?.order) return null;
  const { externalSubscriptionId } = product.order;
  return subscription?.paymentProviderSubscriptionId === externalSubscriptionId ? subscription : null;
};

const actionSubscription = async (order: any, action: string): Promise<any> => {
  try {
    const user = getUser();
    const result = await axios.post(
      `/api/orders/subscriptions/me/${action}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      },
    );
    return result.data;
  } catch (err) {
    if (config.local) {
      console.log(`error with ${action} subscription service`, err);
      return null;
    }
  }
};

export const cancelSubscriptionService = async (order: any): Promise<any> => actionSubscription(order, 'cancel');

export const renewSubscriptionService = async (order: any): Promise<any> => actionSubscription(order, 'renew');

export const initiateSteamPayment = async (
  productId: string,
  productDescription: string,
  language: string,
): Promise<any> => {
  const user = getUser();
  const result = await axios.post(
    `/api/orders/payment/steam-initialize`,
    { productId, productDescription, language },
    {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    },
  );
  return result.data;
};
export const finalizeSteamPayment = async (): Promise<any> => {
  const user = getUser();
  const result = await axios.post(
    `/api/orders/payment/steam-finalize`,
    {},
    {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    },
  );
  return result.data;
};
export const getSteamLoginURL = async (returnUrl: string): Promise<any> => {
  const user = getUser();
  const result = await axios.get(`/api/steam/login-url?returnUrl=${returnUrl}`, {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });
  return result.data;
};
export const unlinkSteamAccount = async (): Promise<any> => {
  const user = getUser();
  const result = await axios.put(
    `/api/steam/unlink-account`,
    {},
    {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    },
  );
  return result.data;
};
export const linkSteamAccount = async (returnUrl: string, openidAssertion: any): Promise<any> => {
  const user = getUser();
  const result = await axios.post(
    `/api/steam/link-account`,
    { returnUrl, openidAssertion },
    {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    },
  );
  return result.data;
};

export const getBuyPageStatus = async (): Promise<any> => {
  const result = await axios.get(`/api/orders/settings/buy-page-enabled`);
  return result.data;
};
