import { usePrismic } from '../../../hooks/prismic.hook';
import { signIn } from '../../../stores/actions/auth.action';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from '../../../stores/app.store.config';
import config from '../../../../config';
import LoadingComponent from '../../../features/loading/loading.component';
import { useAuth } from '../../../stores/selectors/auth.selector';
import LinkComponent from '../../link/link.component';
import PrismicRichText, { asText } from '../../../services/prismic/prismic.richtext.service';
import Popup from 'reactjs-popup';
import CopyToClipboard from 'react-copy-to-clipboard';
import { SnackBarComponent } from '../../../features/snackBar/snackBar.component';
import { resetError } from '../../../stores/slices/auth.slice';
import AuthCode from 'react-auth-code-input';
import { v4 as uuid } from 'uuid';
import { setCookies, getCookies } from '../../../services/authentication/authentication.cookies.service';
import { getPrismicError } from '../../../services/utils/utils.service';
import ResendEmailModal from './resendmodal';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { goto } from '../../../stores/selectors/router.selector';
interface Props {
  setMenuClicked?: (item: number) => void;
  menuClicked?: number;
  icon?: number;
  setIcon?(input: number): void;
}

const LoginModal = (props: Props): JSX.Element => {
  const { handleSubmit, register, errors, watch } = useForm();
  const { contents } = usePrismic('errors');
  const {
    data: {
      email_placeholder,
      login_button,
      random_images,
      coordinates_feedback,
      create_account,
      forgot_password,
      welcome_label,
      verify_label,
      verify_label_main,
      verify_description,
      verify_button_label,
      remember_device,
      error_invalid_code,
    },
  } = usePrismic('login');

  const dispatch = useAppDispatch();
  const { error, loading } = useAuth();
  const contentStyle = { background: '#ffd255' };
  const overlayStyle = { background: 'transparent' };
  const arrowStyle = { color: '#ffd255' };
  const [emailDefault, setDefaultEmail] = useState('');
  const [pswDefault, setDefaultPsw] = useState('');
  const [index, setIndex] = useState(0);
  const [view, setView] = useState('initial');
  const [result, setResult] = useState('');
  const [showResend, setShowResend] = useState(false);
  const [resendFeedback, setResendFeedback] = useState('');
  const { search } = useLocation();
  const { source } = qs.parse(search.slice(1));
  const { pathname } = useLocation();

  const handleOnChange = (res: string) => {
    setResult(res);
  };
  const onSubmit = async (values: Record<string, any>): Promise<void> => {
    const { email, password } = values;
    setDefaultEmail(email);
    setDefaultPsw(password);
    setResendFeedback('');
    const device = getCookies('deviceId');
    try {
      dispatch(signIn({ email, password, device }));
      if (source === 'contest') goto('/lottery');
    } catch (err: any) {
      if (config.local) {
        console.log('error with login', err);
      }
    }
  };
  const onSubmit2FA = async (values: Record<string, any>): Promise<void> => {
    let device = getCookies('deviceId');
    if (!device && values?.checkbox) {
      device = uuid();
      setCookies('deviceId', device, { maxAge: 90 * 24 * 3600 }); // 90 days
    }
    dispatch(signIn({ email: emailDefault, password: pswDefault, code: result, device }));
  };
  const randomImage = (): number => {
    return Math.floor(Math.random() * random_images?.length || 0);
  };

  useEffect(() => {
    setIndex(randomImage());
    dispatch(resetError());
  }, []);

  useEffect(() => {
    if (error && error.Code === 400 && error.code === 107) {
      if (view?.includes('initial')) {
        dispatch(resetError());
        setTimeout(() => {
          setView('2fa');
        }, 500);
      }
    } else if (error && error.Code === 400 && error.code === 109) {
      setShowResend(true);
    }
  }, [error]);

  const emailInput = watch('email');

  const pswInput = watch('password');

  const re = new RegExp(config.validation.email);

  if (!contents) return <></>;

  const {
    results: [
      {
        data: { errors: errorsPrismic },
      },
    ],
  } = contents;
  return (
    <div className="login-modal-container">
      <div className="corners">
        <div className="login-modal-left">
          {!loading && (
            <>
              <div className="intro">
                <div className="lock-icon"></div>
                <div className="content">
                  <p className="desc">
                    {view?.includes('initial') ? `${asText(welcome_label)}` : `${asText(verify_label)}`}
                  </p>
                  <h2 className="main">
                    {view?.includes('initial') ? `${asText(login_button)}` : `${asText(verify_label_main)}`}
                  </h2>
                </div>
              </div>
              {error && error.code === 107 && !view.includes('initial') && (
                <SnackBarComponent message={asText(error_invalid_code)} type="failure" />
              )}
              {error && error.code !== 107 && error.code !== 109 && (
                <SnackBarComponent message={getPrismicError(errorsPrismic, error)} type="failure" />
              )}
              {error && error.code === 109 && showResend && (
                <ResendEmailModal
                  setShowResend={setShowResend}
                  email={emailInput}
                  setResendFeedback={setResendFeedback}
                />
              )}
              {resendFeedback && <SnackBarComponent message={resendFeedback} type="success" />}
              {view?.includes('initial') && (
                <form id="login" onSubmit={handleSubmit(onSubmit)}>
                  <>
                    <div className="login">
                      {/* {error && <ErrorComponent error={error} />} */}
                      {/* <input type="hidden" name="token" value="placeholder" /> */}
                      <div
                        className={
                          errors.email || (emailInput && !re.test(emailInput)) ? 'util-input error' : 'util-input'
                        }
                      >
                        <div className="icon">
                          <span />
                        </div>
                        <input
                          name="email"
                          type="email"
                          className="util-input-text"
                          placeholder={`${asText(email_placeholder)}`}
                          ref={register({
                            required: 'Requires Email',
                            pattern: config.validation.email,
                          })}
                          defaultValue={emailDefault}
                        />
                        <div
                          className={
                            !errors?.email && !emailInput
                              ? 'icon icon-loading'
                              : !emailInput || !re.test(emailInput)
                              ? 'icon icon-error'
                              : 'icon icon-validate'
                          }
                        >
                          <span />
                        </div>
                        {errors.email && (
                          <>
                            <div className="arrow"></div>
                            <div className="yellow-notice">
                              <p> Please check your email</p>
                            </div>
                          </>
                        )}
                      </div>
                      <div className={errors.email ? 'util-input error' : 'util-input'}>
                        <div className="passwordlogo">
                          <span />
                        </div>
                        <input
                          name="password"
                          type="password"
                          className="util-input-text"
                          placeholder="**********"
                          ref={register({
                            required: 'Requires Password',
                            min: {
                              value: 8,
                              message: 'error_password',
                            },
                          })}
                        />
                        <div
                          className={
                            !errors?.password && !pswInput
                              ? 'icon icon-loading'
                              : !pswInput
                              ? 'icon icon-error'
                              : 'icon icon-validate'
                          }
                        >
                          <span />
                        </div>
                        {errors.password && (
                          <>
                            <div className="arrow"></div>
                            <div className="yellow-notice">
                              <p> Please check your password</p>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="links-group">
                      <LinkComponent
                        className="create-link problem"
                        link="/forgot-password"
                        label={asText(forgot_password)}
                        onClick={() => {
                          if (props.setMenuClicked) props.setMenuClicked(-1);
                          if (props.setIcon && props.icon) props.setIcon(-props.icon);
                          if (window.document.body.style.overflow === 'hidden')
                            window.document.body.style.overflow = 'scroll';
                        }}
                      />
                      <LinkComponent
                        className="create-link"
                        link={pathname === '/lottery' ? '/signup?source=contest' : '/signup'}
                        label={asText(create_account)}
                        onClick={() => {
                          if (props.setMenuClicked) props.setMenuClicked(-1);
                          if (props.setIcon && props.icon) props.setIcon(-props.icon);
                          if (window.document.body.style.overflow === 'hidden')
                            window.document.body.style.overflow = 'scroll';
                        }}
                      />
                    </div>
                    <button type="submit" className="theme-button">
                      <div className="theme-button-container">
                        <div />
                        <span>{asText(login_button)}</span>
                      </div>
                    </button>
                  </>
                </form>
              )}
              {view?.includes('2fa') && (
                <form id="login2fa" onSubmit={handleSubmit(onSubmit2FA)}>
                  <div className="twofa-container">
                    <div className="request-enter">
                      <PrismicRichText render={verify_description} />
                    </div>
                    <AuthCode
                      length={6}
                      onChange={handleOnChange}
                      containerClassName="input-group"
                      allowedCharacters="numeric"
                      inputClassName="input"
                    />
                    <div className="links-group">
                      <LinkComponent
                        className="create-link forgot"
                        link="/forgot-password"
                        label={asText(forgot_password)}
                        onClick={() => {
                          if (props.setMenuClicked) props.setMenuClicked(-1);
                          if (props.setIcon && props.icon) props.setIcon(-props.icon);
                          if (window.document.body.style.overflow === 'hidden')
                            window.document.body.style.overflow = 'scroll';
                        }}
                      />
                    </div>
                    <div className="checkboxcontainer checkbox">
                      <input type="checkbox" defaultChecked name="checkbox" ref={register({})} />
                      <p>{asText(remember_device)}</p>
                    </div>
                    <button form="login2fa" type="submit" className="theme-button">
                      <div className="theme-button-container">
                        <div />
                        <span>{asText(verify_button_label)}</span>
                      </div>
                    </button>
                  </div>
                </form>
              )}
            </>
          )}
          {loading && (
            <div className="loading">
              <LoadingComponent loading={loading} />
            </div>
          )}
        </div>
      </div>
      {random_images && (
        <div className="login-modal-right" style={{ backgroundImage: `url(${random_images[index]?.image.url})` }}>
          <div className="corners-right">
            {asText(random_images[index]?.author) && (
              <div className="carousel-box-title-author">
                <p>
                  {asText(random_images[index]?.title)}
                  <span>{asText(random_images[index]?.author)}</span>
                </p>
              </div>
            )}
            {asText(random_images[index]?.coordinates) && (
              <Popup
                trigger={(): JSX.Element => (
                  <div className="carousel-box-coordinates">
                    <CopyToClipboard text={asText(random_images[index]?.coordinates)}>
                      <div className="carousel-box-coordinates-wrapper">
                        <div className="icon icon-copy" style={{ cursor: 'pointer' }}>
                          <div></div>
                        </div>
                      </div>
                    </CopyToClipboard>
                  </div>
                )}
                className="copy-coordinates-popup"
                position="top center"
                closeOnDocumentClick
                {...{ contentStyle, overlayStyle, arrowStyle }}
              >
                <div className="link-container">
                  <p>{asText(coordinates_feedback)}</p>
                </div>
              </Popup>
            )}
            <div
              className="carousel-box-coordinates close"
              onClick={() => {
                if (props.setMenuClicked && props.menuClicked) props.setMenuClicked(-1 * Math.sign(props.menuClicked));
                dispatch(resetError());
              }}
            >
              <div className="carousel-box-coordinates-wrapper">
                <div className="icon icon-copy icon-close" style={{ cursor: 'pointer' }}>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginModal;
