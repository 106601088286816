import Cookies from 'universal-cookie';
import config from '../../../config';
import { TokenType } from './signin.retrieval';

export const cookieConfig = { path: '/', maxAge: 604800, secure: !config.local, sameSite: true }; //seven days
export const cookieMessageConfig = { path: '/', secure: !config.local, sameSite: true };
export const cookieDeviceConfig = { path: '/', maxAge: 2678400, secure: !config.local, sameSite: true };

export const setCookies = (message: string, value: string, config: any): void => {
  const cookies = new Cookies();
  cookies.set(message, value, config);
};
export const getCookies = (message: string): string => {
  const cookies = new Cookies();
  return cookies.get(message);
};

export const setCookiesDevice = (message: string, value: string): void => {
  const cookies = new Cookies();
  cookies.set(message, value, cookieDeviceConfig);
};
export const getCookiesDevice = (value: string): boolean => {
  const cookies = new Cookies();
  return cookies.get(value);
};

export const delCookies = (message: string): void => {
  const cookies = new Cookies();
  cookies.remove(message, { path: '/' });
};

export const getTokenCookies = (): TokenType => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  const refreshToken = cookies.get('refreshToken');
  return { token, refreshToken };
};

export const setTokenCookies = (opts: TokenType): void => {
  const cookies = new Cookies();
  const { token, refreshToken } = opts;
  cookies.set('token', token, cookieConfig);
  cookies.set('refreshToken', refreshToken, cookieConfig);
};

export const resetTokenCookies = (): void => {
  const cookies = new Cookies();
  cookies.remove('token', { path: '/' });
  cookies.remove('refreshToken', { path: '/' });
};

const getHashCode = (content: string): any => {
  return content.split('').reduce((a: any, b: any) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
};

export const setMessageCookies = (message: string, value = true): void => {
  const hashMessage = getHashCode(message);
  const cookies = new Cookies();
  cookies.set(hashMessage, value, cookieMessageConfig);
};

export const getMessageCookies = (message: string): boolean => {
  const hashMessage = getHashCode(message);
  const cookies = new Cookies();
  return cookies.get(hashMessage);
};
