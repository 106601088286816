import React from 'react';
import { usePrismic } from '../../../hooks/prismic.hook';
import { asText } from '../../../services/prismic/prismic.richtext.service';
import { useI18n } from '../../../stores/selectors/i18n.selector';
interface Props {
  server: any;
}

const ServerMaintenanceLogsComponent = ({ server: service }: Props): JSX.Element => {
  const {
    data: {
      maintenances_label,
      from_label,
      to_label,
      duration_label,
      maintenance_type_label,
      maintenance_desc_label,
      empty_logs,
      body,
    },
  } = usePrismic('server_status_logs');
  const { locale } = useI18n();
  //locale.substring(0, 2)
  const renderColumns = (): JSX.Element[] | JSX.Element => {
    const items = body?.filter((elem: any) => {
      return elem.primary?.server_type === service?.name;
    })[0]?.items;
    if (items?.length === 0) {
      return <p className="empty-logs">{asText(empty_logs)}</p>;
    } else
      return items?.map(
        (elem: any, key: number): JSX.Element => {
          const dateStart = new Date(elem.from_datetime);
          const dateEnd = new Date(elem.to_datetime);

          const duration = (dateEnd.getTime() - dateStart.getTime()) / 3.6e6;
          return (
            <div key={key} className={`${key % 2 === 0 ? 'row' : 'row none'} `}>
              <p className="up">
                {elem?.from_datetime &&
                  Intl.DateTimeFormat(locale?.includes('en-us') ? 'en-GB' : locale, {
                    dateStyle: 'full',
                    timeStyle: 'short',
                    timeZone: 'utc',
                  }).format(dateStart)}
                <span> UTC</span>
              </p>
              <p className="up">
                {elem?.to_datetime &&
                  Intl.DateTimeFormat(locale?.includes('en-us') ? 'en-GB' : locale, {
                    dateStyle: 'full',
                    timeStyle: 'short',
                    timeZone: 'utc',
                  }).format(dateEnd)}
                <span> UTC</span>
              </p>
              <p>{elem?.to_datetime && elem?.from_datetime && convertDate(duration)}</p>
              <p>{asText(elem.maintenance_type)}</p>
            </div>
          );
        },
      );
  };
  const convertDate = (time: number): string => {
    if (time) {
      if (time < 1) return (time * 60)?.toString()?.concat('min');
      else {
        const temp = time?.toString()?.split('.');
        temp[1] = (parseFloat('0.' + temp[1]) * 60)?.toString()?.split('.')[0]?.concat('min');

        return temp[1]?.includes('0min') ? temp[0] + 'h' : temp[0] + 'h ' + temp[1];
      }
    }
    return '';
  };
  const renderRowsMobile = (): JSX.Element[] | JSX.Element => {
    const items = body?.filter((elem: any) => {
      return elem.primary?.server_type === service?.name;
    })[0]?.items;
    return items?.map(
      (elem: any, key: number): JSX.Element => {
        const dateStart = new Date(elem.from_datetime);
        const dateEnd = new Date(elem.to_datetime);
        const duration = (dateEnd.getTime() - dateStart.getTime()) / 3.6e6;
        return (
          <div key={key} className={key % 2 !== 0 ? 'row-mobile' : 'row-mobile none'}>
            <div className="row-mobile-item">
              <h5>{asText(from_label)}</h5>
              <p>
                {elem?.from_datetime &&
                  Intl.DateTimeFormat(locale?.includes('en-us') ? 'en-GB' : locale, {
                    dateStyle: 'full',
                    timeStyle: 'short',
                    timeZone: 'utc',
                  }).format(dateStart)}
                <span> UTC</span>
              </p>
            </div>
            <div className="row-mobile-item alterante">
              <h5>{asText(to_label)}</h5>
              <p>
                {elem?.to_datetime &&
                  Intl.DateTimeFormat(locale?.includes('en-us') ? 'en-GB' : locale, {
                    dateStyle: 'full',
                    timeStyle: 'short',
                    timeZone: 'utc',
                  }).format(dateEnd)}
                <span> UTC</span>
              </p>
            </div>
            <div className="row-mobile-item">
              <h5>{asText(duration_label)}</h5>
              <p>{elem?.from_datetime && elem?.to_datetime && convertDate(duration)}</p>
            </div>
            <div className="row-mobile-item alterante">
              <h5>{asText(maintenance_type_label)}</h5>
              <p>{asText(elem.maintenance_type)}</p>
            </div>
          </div>
        );
      },
    );
  };
  return (
    <div className="server-logs-container border-maintenance">
      <section className={'server-shard-status-section border-maintenance'}>
        <div className={'top-container'}>
          <div className={'icon-maintenance'} />
          <div>
            <p className={'main-line-desc-title'}>{asText(maintenances_label) + ' - ' + service?.name}</p>
            <p className={'main-line-desc logs'}>{asText(maintenance_desc_label)}</p>
          </div>
        </div>
        <div className="server-header-tab">
          <h5 className="">{asText(from_label)}</h5>
          <h5 className="">{asText(to_label)}</h5>
          <h5 className="">{asText(duration_label)}</h5>
          <h5 className="">{asText(maintenance_type_label)}</h5>
        </div>
        {renderColumns()}
        <div className="server-header-tab-mobile">{renderRowsMobile()}</div>
      </section>
    </div>
  );
};
export default ServerMaintenanceLogsComponent;
