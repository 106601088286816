import React from 'react';
import Helmet from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { usePrismic } from '../../hooks/prismic.hook';
import { useRemoteConfiguration } from '../../stores/selectors/remote-configuration.selector';
import { replaceLink } from '../utils/utils.service';

export const SeoMetaPartial = ({ pageTitle }: { pageTitle?: string }): JSX.Element => {
  const {
    result: { seo_meta_page = [] },
  } = usePrismic('seo_meta');
  const { pathname } = useLocation();

  const {
    remoteConfig: { ROOT_URL: rootURL },
  } = useRemoteConfiguration();

  const {
    title,
    description = 'Dual Universe is a Continuous Single-Shard sandbox MMORPG taking place in a vast fully editable Sci-Fi universe, focusing on emergent gameplay with multi-player driven in-game economy, politics, trade and warfare.',
  } = seo_meta_page.find((s: any) => replaceLink(s.link || '') === pathname) || {};

  if (!title && !pageTitle) return <></>;

  const metaTitle = pageTitle ? `${pageTitle} | Dual Universe` : title;

  const canonical = `${rootURL}${pathname}`;

  return (
    <Helmet>
      <title>{metaTitle}</title>
      <meta name="description" content={pageTitle || description} />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:url" content={window.location.href} />
      <meta property="canonical" content={canonical} />
      <link rel="canonical" itemProp="url" href={canonical} />
    </Helmet>
  );
};

export const SeoMetaPartialLanding = ({ pageTitle }: { pageTitle?: string }): JSX.Element => {
  const {
    result: { seo_meta_page = [] },
  } = usePrismic('seo_meta_landing');
  const { pathname } = useLocation();

  const baseURL = 'https://www.dualuniverse.game';
  const {
    title,
    description = 'Dual Universe is a Continuous Single-Shard sandbox MMORPG taking place in a vast fully editable Sci-Fi universe, focusing on emergent gameplay with multi-player driven in-game economy, politics, trade and warfare.',
    cononicallink: canonical,
    metaname,
    metacontent,
  } = seo_meta_page.find((s: any) => (s.link || '').replace(baseURL, '') === pathname) || {};

  if (!title && !pageTitle) return <></>;

  const metaTitle = pageTitle ? `${pageTitle} | Dual Universe` : title;

  return (
    <Helmet>
      <title>{metaTitle}</title>
      <meta name="description" content={pageTitle || description} />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:url" content={window.location.href} />
      <meta property="canonical" content={canonical} />
      <link rel="canonical" itemProp="url" href={canonical} />
      <meta name={`${metaname}`} content={`${metacontent}`} />
    </Helmet>
  );
};

export default SeoMetaPartial;
