import React from 'react';
import { usePrismic } from '../../../hooks/prismic.hook';
import { asText } from '../../../services/prismic/prismic.richtext.service';
import { usePlansAvailable } from '../../../stores/selectors/plans-available.selector';

const BuyPageSpecs = (): JSX.Element => {
  const {
    result: { specs_header },
  } = usePrismic('buy_page');

  const { contents } = usePlansAvailable();

  const minSpecs: JSX.Element[] =
    contents && contents.minSpecs
      ? contents.minSpecs.map((obj: Record<string, any>, key: number) => (
          <React.Fragment key={key}>
            <p className={'heading'}>{asText(obj.type)}</p>
            <p className={'description'}>{asText(obj.description)}</p>
          </React.Fragment>
        ))
      : [];

  const goodSpecs: JSX.Element[] =
    contents && contents.minSpecs
      ? contents.goodSpecs.map((obj: Record<string, any>, key: number) => (
          <React.Fragment key={key}>
            <p className={'heading'}>{asText(obj.type)}</p>
            <p className={'description'}>{asText(obj.description)}</p>
          </React.Fragment>
        ))
      : [];

  return (
    <div className={'buypage-specs-landingone'} id="spec">
      <p className={'specsHeader'}>{specs_header}</p>
      <div className={'specContainer'}>
        <div className={'minimal'}>
          <p className={'childHeader'}>{asText(contents.minSpecsHeader)}</p>
          <div className={'gridContainer'}>{minSpecs}</div>
        </div>

        <div className={'recommended'}>
          <p className={'childHeader'}>{asText(contents.goodSpecsHeader)}</p>
          <div className={'gridContainer'}>{goodSpecs}</div>
        </div>
      </div>
    </div>
  );
};

export default BuyPageSpecs;
