import axios from 'axios';
import config from '../../../config';

export const getAssetsService = async (): Promise<any> => {
  try {
    const assets = await axios.get(`/api/assets/media`);
    return assets.data;
  } catch (err: any) {
    if (config.local) {
      console.log('Error with getAssetsService', err.response.data);
    }
  }
};
export const getAssetsByKeyService = async (key: string): Promise<any> => {
  try {
    const assets = await axios.get(`/api/assets/media/${key}`);
    return assets.data;
  } catch (err: any) {
    if (config.local) {
      console.log('Error with getAssetsByKeyService', err.response.data);
    }
  }
};
