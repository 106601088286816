import React, { useState, useEffect, useRef } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FileDrop } from 'react-file-drop';
import { useAppDispatch } from '../../../stores/app.store.config';

import { usePrismic, UsePrismic } from '../../../hooks/prismic.hook';
import UploadModal from './upload.modal.component';
import { AssetsState } from '../../../models/assets/assets.model';
import { useAssets } from '../../../stores/selectors/assets.selector';
import { fetchUploads, deleteUpload, uploadAsset } from '../../../stores/actions/assets.actions';
import { resetError } from '../../../stores/slices/assets.slice';
import { getPrismicError } from '../../../services/utils/utils.service';

const renderUploadForm = (
  dispatch: any,
  loadingZone: React.MutableRefObject<any>,
  prismic: UsePrismic,
  errors: any,
  assetsState: AssetsState,
): JSX.Element => {
  const { uploads, loading, error } = assetsState;
  const { pendingValidation, processingMaxAllowed } = uploads;
  const {
    drop_media,
    error_generic,
    explanation,
    limit_notice,
    loading_notice,
    title_new_upload,
    upload_new,
    under_review,
  } = prismic.result;

  const overLimit = pendingValidation.length >= processingMaxAllowed;

  const uploadPicture = async (files: any): Promise<void> => {
    if (overLimit) {
      return;
    }

    dispatch(uploadAsset(files));

    if (loadingZone && loadingZone.current) {
      window.scrollTo(0, loadingZone?.current?.offsetTop);
    }
  };

  const onDrop = (files: FileList | null): void => {
    uploadPicture(files);
  };

  const onFileChange = async (event: React.FormEvent<HTMLInputElement>): Promise<any> => {
    const { files } = event.currentTarget;
    uploadPicture(files);
  };

  return (
    <>
      <div className="uploadHeading">
        <div className="uploadTitle">
          <p className="left">{title_new_upload}</p>
          <p className="right">
            {pendingValidation.length}/{processingMaxAllowed} {under_review}
          </p>
        </div>
        <div className="line">
          <div className="yellowline" style={{ width: `${(pendingValidation.length / 5) * 100}%` }}></div>
        </div>
      </div>

      <div className="uploadIcon">
        <label htmlFor="upload" className={overLimit ? 'upload-disabled' : ''}>
          <FileDrop onDrop={onDrop}>
            <div className="uploadIconLink">
              <p className={'uploadMedia'}>{upload_new}</p>
              <div className={'uploadIconImage'} />
              <p className={'uploadExplanation'}>{drop_media}</p>
              <p className={'uploadLimit'}>{limit_notice}</p>
            </div>
            <input type="file" name="file" id="upload" accept=".jpg,.png" onChange={onFileChange} />
          </FileDrop>
        </label>
      </div>

      {error && (
        <div className="upload-warning">
          <span className="picture-icon" />
          <p className="error">{error_generic + ' ' + getPrismicError(errors, error?.response?.data || error)}</p>
          <button className="x-button" onClick={(): any => dispatch(resetError())} />
        </div>
      )}

      <div ref={loadingZone}>
        {loading && (
          <div className="upload-warning">
            <span className="picture-icon" />
            <p className="notice">{loading_notice}</p>
          </div>
        )}
      </div>

      <p className="imageExplanation">{explanation}</p>
    </>
  );
};

const AccountUpload = (): JSX.Element => {
  const [linkcopied, setlinkcopied] = useState(false);
  const [assetKey, setAssetKey] = useState('');
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchUploads());
  }, [dispatch]);

  const assetsState = useAssets();
  const { canUploadAssets, pendingValidation, validated } = assetsState.uploads;
  const prismic = usePrismic('account_image_upload');
  const { contents: errorsPrismic } = usePrismic('errors');
  const { notice_copied, notice_forbidden, page_title, title_new_upload, title_pending, title_approved } =
    prismic.result;

  const loadingZone = useRef<any>(null);

  const onDeleteImage = (): void => {
    dispatch(deleteUpload(assetKey));
    setAssetKey('');
  };
  if (!errorsPrismic) return <></>;
  const {
    results: [
      {
        data: { errors },
      },
    ],
  } = errorsPrismic;

  return (
    <div className="userInfo">
      <UploadModal isOpen={assetKey !== ''} onConfirm={onDeleteImage} onClose={(): void => setAssetKey('')} />

      <div className="mainHeading">
        <p className="imageHeader">{page_title}</p>
      </div>

      {canUploadAssets ? (
        renderUploadForm(dispatch, loadingZone, prismic, errors, assetsState)
      ) : (
        <>
          <div className="uploadHeading">
            <div className="uploadTitle">
              <p className="left">{title_new_upload}</p>
            </div>
          </div>
          <div className="uploadForbidden">
            <p className="error">{notice_forbidden}</p>
          </div>
        </>
      )}

      {pendingValidation.length > 0 && (
        <>
          <div className="reviewHeading">
            <p className="pendingReview">{title_pending}</p>
          </div>
          <div className="pendingImagesContainer">
            {pendingValidation.map(({ url }: { url: string }, index: number) => (
              <img className={'pendingReviewImages'} alt="" src={url} key={index} />
            ))}
          </div>
        </>
      )}

      {validated.length > 0 && (
        <>
          <div className="reviewHeading">
            <p className="pendingReview">{title_approved}</p>
          </div>
          <div className="pendingImagesContainer">
            {validated.map(({ url, key }: { url: string; key: string }, index: number) => (
              <div className="imagecontainer" key={index}>
                <img className={'pendingReviewImages'} alt="" src={url} />
                <div className="onhoverbox">
                  <p></p>
                  <div className="options">
                    <button onClick={(): void => setAssetKey(key)}>
                      <div className="trash"></div>
                    </button>
                    <CopyToClipboard
                      text={(url || '').replace('https://', '')}
                      onCopy={(): void => {
                        setlinkcopied(true);
                        setTimeout(() => setlinkcopied(false), 2000);
                      }}
                    >
                      <button>
                        <div className="copy"></div>
                      </button>
                    </CopyToClipboard>
                    {linkcopied && <p className="copied">{notice_copied}</p>}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default AccountUpload;
