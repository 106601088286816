export interface PurchaseState {
  order: any;
  loading: boolean;
  error: any;
}

export interface Order {
  price: number;
  shippingFees: number;
  currency: string;
  id: string;
  externalSubscriptionId: number
}

export const initialPurchaseState: PurchaseState = {
  order: null,
  loading: false,
  error: null,
};
