import React, { useEffect, useState } from 'react';
import { Collapse } from 'react-collapse';
import { Tutorial, TutorialCategory } from '../../models/tutorials/tutorials.model';
import { getUrlFromEnglish } from '../../services/i18n/i18n.url.service';
import PrismicRichText from '../../services/prismic/prismic.richtext.service';
import LinkComponent from '../link/link.component';

const TutorialsSideNavigation = ({
  categories,
  tutorial,
}: {
  categories: TutorialCategory[];
  tutorial: Tutorial | null;
}): JSX.Element => {
  const [openedID, setOpenedID] = useState('');

  const tutorialSlug = tutorial?.slug;

  useEffect(() => {
    if (tutorial && openedID !== tutorial?.category.id) {
      setOpenedID(tutorial?.category.id);
    }
  }, [categories, tutorial]);

  const open = (id: string): void => {
    if (id === openedID) {
      setOpenedID('');
    } else {
      setOpenedID(id);
    }
  };
  return (
    <div className="tutorials-side-navigation">
      <div className="corners">
        {categories.map((category: TutorialCategory, key: number) => (
          <div key={key}>
            <div
              className={`button ${category.id === openedID ? 'opened' : 'closed'}`}
              onClick={(): void => open(category?.id)}
            >
              <PrismicRichText render={category.category_title} />
            </div>
            <Collapse isOpened={category.id === openedID}>
              <ul className="tutorial-links">
                {category.tutorials.map((tutorial: Tutorial, key: number) => (
                  <div key={key} className={tutorial.slug === tutorialSlug ? 'active' : ''}>
                    <LinkComponent link={`${getUrlFromEnglish('/tutorials')}/${tutorial.slug}`}>
                      <div className="title">
                        <PrismicRichText render={tutorial.title} />
                      </div>
                    </LinkComponent>
                  </div>
                ))}
              </ul>
            </Collapse>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TutorialsSideNavigation;
