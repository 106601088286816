import React, { useState, useEffect } from 'react';
import PrismicRichText, { asText } from '../../../services/prismic/prismic.richtext.service';
import { usePrismic } from '../../../hooks/prismic.hook';
import { serverStatusService } from '../../../services/server-status/server-status.service';
import { useAuth } from '../../../stores/selectors/auth.selector';
import { AuthState } from '../../../models/authentication/auth.model';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useI18n } from '../../../stores/selectors/i18n.selector';
import LoadingComponent from '../../../features/loading/loading.component';

const AccountPreferences = (): JSX.Element => {
  const { register, handleSubmit, getValues, setValue } = useForm();
  const {
    data: {
      title,
      description,
      btn_label,
      disclamer,
      success_message,
      error_message,
      body: preferences,
      select_label,
      select_checkbox_label,
    },
  } = usePrismic('account_email_preferences');
  const { user }: AuthState = useAuth();
  const { locale } = useI18n();
  const [apiPreferences, setApiPreferences] = useState(Object);
  const [myPreferences, setMyPreferences] = useState(Object);
  const [notice, setNotice] = useState({ type: '', message: '' });
  const [initialUser, setInitialUser] = useState(false);
  const [toogle, setToogle] = useState(false);
  const getUserPreferences = async (email: string, token: string): Promise<any> => {
    try {
      const status = await axios.get(`/api/mailing/`, {
        params: { email },
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      return status.data;
    } catch (err) {
      setInitialUser(true);
      console.log(err);
    }
  };
  useEffect(() => {
    getUserPreferences(user.email, user.token).then((data) => {
      setMyPreferences(data);
    });
  }, []);
  useEffect(() => {
    serverStatusService.GetAllPreferences(user.token).then((data) => {
      if (data) {
        //swap to solve conflict between prsimic and the api
        const temp = data[1];
        data[1] = data[2];
        data[2] = temp;
      }
      setApiPreferences(data);
    });
  }, []);

  if (!myPreferences?.interestCategories && !initialUser) return <><LoadingComponent loading /></>;

  const isMyPreference = (interest: string): boolean => {
    for (let i = 0; i < myPreferences?.interestCategories?.length; i++) {
      for (let j = 0; j < myPreferences?.interestCategories[i]?.interests?.length; j++) {
        if (
          myPreferences.interestCategories[i]?.interests[j]?.subscribed &&
          myPreferences.interestCategories[i]?.interests[j]?.name === interest
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const renderPreferences = (): JSX.Element[] | JSX.Element => {
    const response = preferences
      ?.filter((elem: any) => {
        return asText(elem.primary.category_id)?.includes('Game Owner') && user?.allPlans?.length === 0 ? false : true;
      })
      .map((category: any, key: number) => {
        return (
          <div className="preferences-list-container" key={key}>
            <h3 className="category-title">{asText(category.primary.category_id)}</h3>
            {category?.items?.map((subcategory: any, key: number) => {
              return (
                <div className={key % 2 === 0 ? 'checkbox' : 'checkbox-transparent'} key={key}>
                  <div className="content">
                    <h5>{asText(subcategory.subcategory_title)}</h5>
                    <div className="description">
                      <PrismicRichText render={subcategory.subcategory_description} />
                    </div>
                  </div>
                  <a className={'checkboxcontainer'}>
                    <input
                      name={asText(subcategory.subcategory_id)}
                      id={asText(subcategory.subcategory_id)}
                      type="checkbox"
                      ref={register({})}
                      defaultChecked={
                        !asText(subcategory.subcategory_id).includes('Owns Game')
                          ? isMyPreference(asText(subcategory.subcategory_id))
                          : true
                      }
                      disabled={asText(subcategory.subcategory_id).includes('Owns Game')}
                    />
                  </a>
                </div>
              );
            })}
          </div>
        );
      });
    return response;
  };

  const onSubmit = async (values: Record<string, any>): Promise<void> => {
    const newPreferences = { emailAddress: user.email, language: locale?.split('-')[0], interestCategories: Array(0) };
    let temp;
    let lastId = '';

    for (const input in values) {
      for (let i = 0; i < apiPreferences?.length; i++) {
        temp = Array(0);
        for (let j = 0; j < apiPreferences[i]?.interests?.length; j++) {
          if (apiPreferences[i]?.interests[j]?.name === input) {
            temp.push({ id: apiPreferences[i]?.interests[j]?.id, subscribed: values[input] });
          }
        }
        if (temp?.length > 0) {
          if (apiPreferences[i]?.id !== lastId) {
            newPreferences?.interestCategories.push({ id: apiPreferences[i]?.id, interests: temp });
            lastId = apiPreferences[i]?.id;
          } else newPreferences?.interestCategories[i]?.interests?.push(temp[0]);
        }
      }
    }

    await updatePreferences(newPreferences, user.token);
  };
  const updatePreferences = async (preferences: any, token: string): Promise<any> => {
    try {
      const status = await axios.put(`/api/mailing/`, preferences, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      setNotice({ type: 'success', message: asText(success_message) });
      cleanNotice();
      return status.data;
    } catch (err) {
      setNotice({ type: 'error', message: asText(error_message) });
      cleanNotice();
    }
    window.scrollTo(0, 0);
  };

  const changeBoxes = () => {
    const names = getValues();
    if (!toogle) {
      for (const item in names) {
        setValue(item, true);
      }
      setToogle(true);
    } else {
      for (const item in names) {
        setValue(item, false);
      }
      setToogle(false);
    }
  };
  const cleanNotice = () => {
    setTimeout(() => {
      setNotice({ type: '', message: '' });
    }, 4000);
  };
  return (
    <div className="account-preferences-container">
      <div className="account-preferences-intro">
        <h2>{asText(title)}</h2>
        <button type="submit" form="preferences-form" className="theme-button-spec-positive">
          <div className="theme-button-container">
            <div />
            <span>{asText(btn_label)}</span>
          </div>
        </button>
      </div>
      <div className="account-preferences-desc">
        <PrismicRichText render={description} />
      </div>
      <div className="account-preferences-disclamer">
        <PrismicRichText render={disclamer} />
      </div>
      {notice?.type && (
        <div className={notice.type?.includes('success') ? 'account-notice' : 'account-notice error'}>
          <div />
          <p>{notice.message}</p>
        </div>
      )}
      <div className="preferences-all">
        <h2>{asText(select_label)}</h2>
        <div className="checkboxcontainer checkbox">
          <p>{asText(select_checkbox_label)}</p>
          <input
            type="checkbox"
            onClick={() => {
              changeBoxes();
            }}
          />
        </div>
      </div>
      <form id="preferences-form" onSubmit={handleSubmit(onSubmit)}>
        {renderPreferences()}
      </form>
    </div>
  );
};

export default AccountPreferences;
