import React, { useEffect, useState, useCallback } from 'react';
import NewsIntroComponent from './news-sections/news-intro.component';
import BlockComponent from './news-sections/news-block.component';
import BlockBigComponent from './news-sections/news-block-big';
import BlockNewsLetterComponent from './news-sections/news-newsletter.component';
import { useNews } from '../../stores/selectors/news.selector';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { usePrismic } from '../../hooks/prismic.hook';
import { getSlug } from './news.service';
import { getUrlFromEnglish } from '../../services/i18n/i18n.url.service';
import InfiniteScroll from 'react-infinite-scroll-component';
import LinkComponent from '../link/link.component';
import { useParams } from 'react-router-dom';
import BlockNewsLetterSignInComponent from './news-sections/newslettersignin.component';

const NewsComponent = (): JSX.Element | null => {
  const [notCarousel, setNotCarousel] = useState<number>(0);
  const responsive = {
    desktop: {
      breakpoint: { max: 4000, min: 480 },
      items: 3,
    },

    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1,
    },
  };

  const { uid } = useParams(); // inside your component

  const prismic = usePrismic('news_page');
  const {
    result: { loading_items, read },
  } = prismic;

  const isAll = !uid;
  const { contents: newsStories } = useNews();

  const [popup, setPopup] = useState(false);

  const [amountDisplayed, setamountDisplayed] = useState(5);

  const [results, setResults] = useState<any>([]);

  const manageListItems = (): any => {
    const resultsData = Array(0);
    for (const content in newsStories) {
      if (!newsStories[content].type?.includes('press release')) resultsData.push(newsStories[content]);
    }
    resultsData.splice(1, 0, {
      type: 'newsletter-box',
      title: 'newsletter',
      image: {
        url: 'https://images.prismic.io/novaquark/8017cfd9-811d-4bad-820f-23ce70ed8e30_Sans+titre-1.png?auto=compress,format',
      },
    });
    return resultsData;
  };
  useEffect(() => {
    const resultsData = manageListItems();

    const notCarousel = resultsData
      .filter((r: any) => !uid || getSlug(r) === uid)
      .filter((r: any) => !r.carousel).length;

    setNotCarousel(notCarousel);

    setResults(resultsData);
  }, [newsStories, uid]);

  const popUpChange = useCallback((): void => {
    setPopup(true);
  }, []);

  useEffect(() => {
    const overlay = document.getElementById('parentBlur');
    if (overlay) {
      overlay.addEventListener('click', function () {
        setPopup(false);
        window.document.body.style.overflowY = 'scroll';
      });
    }
  });

  const pagination = 5;

  const renderCarousel = (): JSX.Element[] | JSX.Element => {
    const myPosts: JSX.Element[] = results
      .filter((r: any) => !uid || getSlug(r) === uid)
      .filter((r: any) => r.carousel)
      .map((result: any) => (
        <div
          key={getSlug(result)}
          className="news-wapper"
          style={{
            backgroundImage: `linear-gradient(rgba(12, 19, 21, 0) 58%, rgba(12, 19, 21, 0.75) 70%, rgb(12, 19, 21) 85%),url(${result.image.url})`,
          }}
        >
          {result.type === 'newsletter' ? (
            <article className="news-block">
              <div className="corners"></div>
              {/* <div className="real-content"> */}
              <div className="content-newsletter-container">
                <BlockNewsLetterComponent popUpChange={popUpChange} />
              </div>
              {/* </div> */}
            </article>
          ) : (
            <LinkComponent link={`${getUrlFromEnglish('/news')}/${getSlug(result)}`}>
              <article key={result.id} className="news-block">
                <div className="corners"></div>
                {/* <div className="real-content"> */}
                <div className="news-header">
                  <p>{result.type}</p>
                </div>
                {isAll && (
                  <div className="news-content">
                    <BlockComponent
                      route={`${getUrlFromEnglish('/news')}/${getSlug(result)}`}
                      result={result}
                      showContent={true}
                      prismic={prismic}
                    />
                  </div>
                )}
                {/* </div> */}
              </article>
            </LinkComponent>
          )}
        </div>
      ));
    return myPosts;
  };
  const renderPosts = (initial: number, final: number, backgroundtype: number): JSX.Element[] | JSX.Element => {
    if (final > notCarousel) final = notCarousel;
    const myPosts: JSX.Element[] = results
      .filter((r: any) => !uid || getSlug(r) === uid)
      .filter((r: any) => !r.carousel)
      .slice(initial, final)
      .map((result: any, index: number) => (
        <div
          key={getSlug(result)}
          className="news-wapper"
          style={
            backgroundtype === 1 && index !== 0
              ? {
                  backgroundImage: `linear-gradient(rgba(12, 19, 21, 0) 58%, rgba(12, 19, 21, 0.75) 70%, rgb(12, 19, 21) 85%),url(${result.image.url})`,
                }
              : backgroundtype === 1 && index === 0
              ? {
                  backgroundImage: `linear-gradient(rgba(12, 19, 21, 0) 58%, rgba(12, 19, 21, 0.75) 70%, rgb(12, 19, 21) 85%),url(${result.image.url})`,
                }
              : backgroundtype === 2
              ? {
                  backgroundImage: `linear-gradient(
                            120deg,
                            #0c1315 45%,
                            rgba(12, 19, 21, 0.64) 30%,
                            rgba(12, 19, 21, 0.1) 63%,
                            rgba(12, 19, 21, 0) 66%
                        ),
                        url(${result.image.url})`,
                }
              : !result.type?.includes('newsletter-box')
              ? {
                  backgroundImage: `linear-gradient(rgba(12, 19, 21, 0) 58%, rgba(12, 19, 21, 0.75) 70%, rgb(12, 19, 21) 85%),url(${result.image.url})`,
                }
              : {
                  backgroundImage: `url(${result.image.url})`,
                }
          }
        >
          {result.type === 'newsletter-box' ? (
            <article className="news-block">
              <div className="corners"></div>
              {/* <div className="real-content"> */}
              <div className="content-newsletter-container">
                <BlockNewsLetterComponent popUpChange={popUpChange} />
              </div>
              {/* </div> */}
            </article>
          ) : (
            <LinkComponent link={`${getUrlFromEnglish('/news')}/${getSlug(result)}`}>
              <article key={result.id} className="news-block">
                <div className="corners"></div>
                {/* <div className="real-content"> */}
                {backgroundtype !== 2 ? (
                  <div className="news-header">
                    <p> {result.type}</p>
                  </div>
                ) : (
                  <></>
                )}
                {isAll &&
                  (backgroundtype !== 2 ? (
                    <div className="news-content">
                      <BlockComponent
                        route={`${getUrlFromEnglish('/news')}/${getSlug(result)}`}
                        result={result}
                        showContent={true}
                        prismic={prismic}
                      />
                    </div>
                  ) : (
                    <div className="news-content">
                      <BlockBigComponent result={result} prismic={prismic} />
                    </div>
                  ))}
                {/* </div> */}
              </article>
            </LinkComponent>
          )}
        </div>
      ));

    return myPosts;
  };
  const display = (amount: number): any => {
    const list = [];
    if (results) {
      for (let i = 0; i <= amount / 5; i++) {
        list.push(
          <div key={`post_${i}`}>
            <div className="row news-two-equals">{renderPosts(pagination * i, pagination * i + 2, 0)}</div>
            <div className="row news-not-equal">{renderPosts(pagination * i + 2, pagination * i + 4, 1)}</div>
            <div className="row news-complete">{renderPosts(pagination * i + 4, pagination * i + 5, 2)}</div>
          </div>,
        );
      }
    }
    return list;
  };

  const fetchMoreData = (): void => {
    setTimeout(() => {
      setamountDisplayed(amountDisplayed + 5);
    }, 500);

    display(amountDisplayed);
  };

  if (!newsStories || !newsStories.length) return <></>;

  return (
    <section className="news">
      {popup && (
        <>
          <div className={'parentBlur'} id="parentBlur" />
          <BlockNewsLetterSignInComponent setPopup={setPopup} />
        </>
      )}
      <NewsIntroComponent read={read} />
      <div className="news-container-carousel">
        <div className="news-carousel">
          <Carousel
            infinite={true}
            autoPlay={false}
            autoPlaySpeed={5000}
            transitionDuration={1000}
            keyBoardControl={true}
            responsive={responsive}
            itemClass="carousel-item-padding"
          >
            {renderCarousel()}
          </Carousel>
          ;
        </div>
      </div>
      {display(amountDisplayed)}
      {amountDisplayed + 8 < notCarousel && (
        <div className="news-load-container">
          <InfiniteScroll
            dataLength={amountDisplayed}
            next={(): void => fetchMoreData()}
            hasMore={true}
            loader={
              <a>
                <i className="load-more"></i>
                <p>{loading_items}</p>
              </a>
            }
          >
            <></>
          </InfiniteScroll>
        </div>
      )}
    </section>
  );
};

export default NewsComponent;
