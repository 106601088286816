import React from 'react';
import { usePrismic } from '../../hooks/prismic.hook';
import PrismicRichText from '../../services/prismic/prismic.richtext.service';

export const ErrorComponent = ({ error }: { error: any }): JSX.Element => {
  const { contents } = usePrismic('errors');

  if (!contents) return <></>;
  const {
    results: [
      {
        data: { errors },
      },
    ],
  } = contents;

  const errorFound = errors.find((e: any) => e.code === `${error.code || error.Code}`) || errors[0];

  return (
    <div className="error-component">
      <div className="error">
        <PrismicRichText render={errorFound.message} />
      </div>
    </div>
  );
};
