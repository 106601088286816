import axios from 'axios';
import { GetKeys, Key } from '../../../models/beta-keys/keys.model';
import { getUser } from '../../authentication/attributes.service';
import { getShortLocale } from '../../i18n/i18n.url.service';

export const getKeys = async (): Promise<GetKeys> => {
  const user = getUser();
  if (user) {
    const result = await axios.get(`/api/inventory/keys`, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
    return result.data;
  }
  return { keys: [], available: 0 };
};

export const sendKey = async (email: string): Promise<void> => {
  const user = getUser();
  const lang = getShortLocale();
  if (user) {
    const data = {
      recipient: email,
    };
    await axios.post(`/api/inventory/keys?lang=${lang}`, data, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
  }
};

export const cancelKey = async (key: string): Promise<void> => {
  const user = getUser();
  if (user) {
    await axios.delete(`/api/inventory/keys/${key}`, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
  }
};

export const activateKey = async (key: string): Promise<any> => {
  const user = getUser();
  if (user) {
    const data = {};
    const response = await axios.post(`/api/inventory/keys/${key}/activate`, data, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    });
    return response.data;
  }
  return null;
};

export const filterGiftedKeys = (keys: Key[]): Key[] => keys.filter((k: Key) => k.usedBy);

export const filterPendingKeys = (keys: Key[]): Key[] => keys.filter((k: Key) => !k.usedBy && !k.usageTimestamp);
