import { PrismicResponse } from '../prismic-api-response.model';

import { getLocaleFromUrl } from '../../services/i18n/i18n.url.service';

export type I18nInterface = PrismicResponse;
export interface I18nState {
  loading: boolean;
  hasErrors: boolean;
  locale: string;
  locales: string[];
  error: any;
}

export const initial18nState: I18nState = {
  locale: getLocaleFromUrl(),
  locales: ['en-us', 'fr-fr', 'de-de'],
  hasErrors: false,
  loading: true,
  error: null,
};
