import { asText } from '../../../services/prismic/prismic.richtext.service';

export const searchValueLang = (value: string, errors: Record<string, any>): string => {
  const element = errors?.find((elem: any) => {
    return asText(elem.error_key) === value;
  })?.error_value;

  return asText(element);
};
export const errorHandled = (
  errorsApi: Record<string, any>,
  errorsPrismmic: Record<string, any>,
): { type: string; message: string } => {
  let notice = [];

  for (const key in errorsApi) {
    // notice.push(errorsApi[key].toString());
    notice = errorsApi[key]?.map((elem: any) => {
      return searchValueLang(elem, errorsPrismmic);
    });
  }

  return { type: 'error', message: `${notice.toString()}` };
};
