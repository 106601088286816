import React, { useState, useEffect } from 'react';
import { usePrismic } from '../../../../hooks/prismic.hook';
import LinkComponent from '../../../link/link.component';
import { getUrlFromEnglish } from '../../../../services/i18n/i18n.url.service';
import BlockNewsLetterSignInComponent from '../newslettersignin.component';

const NavigationComponent = (): JSX.Element | null => {
  const [showoptions, setshowoptions] = useState(false);

  const [popup, setPopup] = useState(false);

  const page = window.location.href.includes('archives');

  const {
    result: { section_title, all_news, subscribe, archives },
    contents,
  } = usePrismic('news_page');

  useEffect(() => {
    const overlay = document.getElementById('parentBlur');
    if (overlay) {
      overlay.addEventListener('click', function () {
        setPopup(false);
        window.document.body.style.overflowY = 'scroll';
      });
    }
  });

  if (!contents) return <></>;

  return (
    <>
      {popup && (
        <>
          <section className="news">
            <div className={'parentBlur'} id="parentBlur" />
            <BlockNewsLetterSignInComponent setPopup={setPopup} />
          </section>
        </>
      )}
      <div className="navigation-block">
        <div className="news-detail-header">
          <LinkComponent link={getUrlFromEnglish('/news')} className="header-columnOne">
            {section_title}
          </LinkComponent>

          <div className="header-columnTwo">
            <LinkComponent className="button" link="/news">
              {all_news}
            </LinkComponent>
            <div
              className="header-columnTwo-link"
              onClick={(): void => {
                setPopup(true);
                window.document.body.style.overflowY = 'hidden';
              }}
            >
              {subscribe}
            </div>
            <LinkComponent link={getUrlFromEnglish('/news/archives')} className={`button ${page ? 'active' : ''}`}>
              {archives}
            </LinkComponent>
          </div>
        </div>
        <div className="navbar-mobile">
          <a
            onClick={(): void => {
              setshowoptions(!showoptions);
            }}
          >
            <p>{section_title}</p>
            <div></div>
          </a>
        </div>
        {showoptions && (
          <div className="navbar-mobile-options">
            <div>
              <LinkComponent link="/news">{all_news}</LinkComponent>
              <a
                className="header-columnTwo-link"
                onClick={(): void => {
                  setPopup(true);
                  window.document.body.style.overflowY = 'hidden';
                }}
              >
                {subscribe}
              </a>
              <LinkComponent link="/news/archives">{archives}</LinkComponent>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default NavigationComponent;
