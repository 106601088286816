import React, { useState, useEffect, useRef } from 'react';
import { usePrismic } from '../../../hooks/prismic.hook';
import RichText, { asText } from '../../../services/prismic/prismic.richtext.service';
import { useAuth } from '../../../stores/selectors/auth.selector';
import { usePurchase } from '../../../stores/selectors/purchase.selector';
import { getUrlFromEnglish } from '../../../services/i18n/i18n.url.service';
import { goto } from '../../../stores/selectors/router.selector';
import { updatePurchases } from '../../../services/authentication/signin.retrieval';
import { useAppDispatch } from '../../../stores/app.store.config';
import { killOrder } from '../../../stores/slices/purchase.slice';
import qs from 'qs';
import { finalizeSteamPayment } from '../../../services/api/orders/order.subscriptions.service';
import { refreshToken } from '../../../stores/actions/auth.action';
import LoadingComponent from '../../../features/loading/loading.component';
import { getPrismicError } from '../../../services/utils/utils.service';
import { useLocation } from 'react-router-dom';

const BuyPageThankyou = (): JSX.Element => {
  const { user } = useAuth();
  const { order, loading } = usePurchase();
  const { contents } = usePrismic('errors');
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const { source, product } = qs.parse(search.slice(1)) || 'novaquark';
  const [steamStatus, setSteamStatus] = useState({ status: '', message: '' });
  const [flag, setFlag] = useState(true);
  const [myDuPurchase, setMyDuPurchase] = useState(false);
  const hasRun = useRef(false);
  const hasRun2 = useRef(false);
  useEffect(() => {
    if (!user && !loading) {
      goto(getUrlFromEnglish('/'));
    }
    if (!hasRun.current && order && !loading && source !== 'steam') {
      setMyDuPurchase(isMyDuPurchase());
      updatePurchases(dispatch);
      dispatch(killOrder());
      hasRun.current = true;
    }
  }, [order]);
  useEffect(() => {
    if (!hasRun2.current && product === 'myDU') {
      dispatch(refreshToken());
      hasRun2.current = true;
    }
  }, [product]);

  const isMyDuPurchase = (): boolean => {
    return (
      order?.price == 34.99 ||
      order?.price == 29.99 ||
      order?.price == 42.99 ||
      order?.price == 24.99 ||
      order?.price == 44.99
    );
  };

  useEffect(() => {
    if (contents && source === 'steam' && flag) {
      finalizePayment();
      setFlag(false);
    }
  }, [contents]);

  const finalizePayment = async () => {
    try {
      setSteamStatus({ status: 'loading', message: 'Processing payment' });
      await finalizeSteamPayment();
      setSteamStatus({ status: '', message: '' });
      dispatch(refreshToken());
    } catch (error: any) {
      setSteamStatus({
        status: 'error',
        message: getPrismicError(contents?.results[0]?.data?.errors, error.response.data),
      });
    }
  };
  const [noticePopup, setNoticePopup] = useState(false);

  const {
    data: {
      download_button,
      support_title,
      support_description,
      discord_title,
      discord_description,
      forums_title,
      forums_description,
      resend_button,
      links_title,
      resend_notice,
      page_title,
      page_description,
      page_desc_mydu,
      content_2,
      content_3,
    },
  } = usePrismic('buy_page_thank_you');

  if (!download_button) return <></>;

  return (
    <div className="buypage-thankyou">
      {noticePopup && (
        <div className="notice">
          <p className="notice-title">{asText(resend_notice)}</p>
          <div
            className="notice-close"
            onClick={(): void => {
              setNoticePopup(false);
            }}
          ></div>
        </div>
      )}
      {steamStatus.status === 'error' && (
        <div className="notice error">
          <p className="notice-title error-title">{steamStatus.message}</p>
        </div>
      )}
      <LoadingComponent loading={steamStatus.status === 'loading'} />
      {!steamStatus.status && !(myDuPurchase || product === 'myDU') && (
        <>
          <div className="maintitle">
            <RichText render={page_title} />
          </div>
          <div className="thankyou-main-content">
            <div className="content-description">
              <RichText render={page_description} />
              {user && <p>{user.email}</p>}
            </div>
            <div className="second-content">
              <RichText render={content_2} />
            </div>
            <div className="content-marked">
              <RichText render={content_3} />
            </div>
            <div className="btn-box">
              <a href="https://installer-prod.dualthegame.com/prod/dual-installer.exe">
                <button className="download-game theme-button-spec-positive">
                  <div className="theme-button-container">
                    <div />
                    <span>{asText(download_button)}</span>
                  </div>
                </button>
              </a>
              <button
                className="resend theme-button-spec-positive"
                onClick={async (): Promise<void> => {
                  if (user) {
                    goto(getUrlFromEnglish('/resend-confirmation'));
                  }
                }}
              >
                <div className="theme-button-container">
                  <div />
                  <span>{asText(resend_button)}</span>
                </div>
              </button>
            </div>
          </div>
        </>
      )}
      {!steamStatus.status && (myDuPurchase || product === 'myDU') && (
        <>
          <div className="maintitle">
            <RichText render={page_title} />
          </div>
          <div className="thankyou-main-content">
            <div className="content-description">
              <RichText render={page_desc_mydu} />
            </div>
            <div className="btn-box">
              {/* <a href="https://installer-prod.dualthegame.com/prod/dual-installer.exe">
                <button className="download-game theme-button-spec-positive">
                  <div className="theme-button-container">
                    <div />
                    <span>{asText(download_button)}</span>
                  </div>
                </button>
              </a> */}
              <button
                className="resend theme-button-spec-positive"
                onClick={async (): Promise<void> => {
                  if (user) {
                    goto(getUrlFromEnglish('/resend-confirmation'));
                  }
                }}
              >
                <div className="theme-button-container">
                  <div />
                  <span>{asText(resend_button)}</span>
                </div>
              </button>
            </div>
          </div>
        </>
      )}
      <h3 className="thankyou-icons-links-title">{asText(links_title)}</h3>
      <div className="thankyou-icons-links">
        <a href="/medias/keymapping.zip">
          <div className="box">
            <div className="corners"></div>
            <div className="real-content">
              <div className="box-image box-image-support"></div>
              <div className="box-text">
                <p className="box-text-title">{asText(support_title)}</p>
                <p className="box-text-desc">{asText(support_description)}</p>
              </div>
            </div>
          </div>
        </a>
        <a href="https://discord.gg/dualuniverse" target="_blank" rel="noopener noreferrer">
          <div className="box">
            <div className="corners"></div>
            <div className="real-content">
              <div className="box-image box-image-discord"></div>
              <div className="box-text">
                <p className="box-text-title">{asText(discord_title)}</p>
                <p className="box-text-desc">{asText(discord_description)}</p>
              </div>
            </div>
          </div>
        </a>
        <a href="https://board.dualthegame.com/" target="_blank" rel="noopener noreferrer">
          <div className="box">
            <div className="corners"></div>
            <div className="real-content">
              <div className="box-image box-image-chat"></div>
              <div className="box-text">
                <p className="box-text-title">{asText(forums_title)}</p>
                <p className="box-text-desc">{asText(forums_description)}</p>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default BuyPageThankyou;
