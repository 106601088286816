import { createSlice } from '@reduxjs/toolkit';
import { Consent, ConsentState, ServiceType, loadFromCookie, saveToCookie } from '../../models/consent/consent.model';

function update(state: ConsentState, type: ServiceType, value: Consent) {
  if (type === ServiceType.Required) {
    throw new Error('By definition, it is impossible to opt-out of required cookies.');
  }
  if (state[type] !== value) {
    state[type] = value;
    // Changes will happen on next refresh
    // Maybe we should just refresh the page here
    // Or, better, show a message to the user (as he may be half-way in writing a message or anything else)
  }
}

const slice = createSlice({
  name: 'consent',
  initialState: loadFromCookie(),
  reducers: {
    openSimplePopup: (state: ConsentState): void => {
      state.showSimplePopup = true;
      state.showDetailedPopup = false;
    },
    openDetailedPopup: (state: ConsentState): void => {
      state.showSimplePopup = false;
      state.showDetailedPopup = true;
    },
    closePopups: (state: ConsentState): void => {
      state.showSimplePopup = false;
      state.showDetailedPopup = false;
    },
    setConsent: (state: ConsentState, { payload }): void => {
      update(state, payload.type, payload.value);
      saveToCookie(state);
    },
    setMultipleConsent: (state: ConsentState, { payload }): void => {
      for (const { type, value } of payload) {
        update(state, type, value);
      }
      saveToCookie(state);
    },
    acceptAll: (state: ConsentState): void => {
      state[ServiceType.Performance] = Consent.Agree;
      state[ServiceType.Functional] = Consent.Agree;
      state[ServiceType.Targeting] = Consent.Agree;
      state[ServiceType.Social] = Consent.Agree;
      state.showDetailedPopup = false;
      state.showSimplePopup = false;
      saveToCookie(state);
    },
    refuseAll: (state: ConsentState): void => {
      state[ServiceType.Performance] = Consent.Decline;
      state[ServiceType.Functional] = Consent.Decline;
      state[ServiceType.Targeting] = Consent.Decline;
      state[ServiceType.Social] = Consent.Decline;
      state.showDetailedPopup = false;
      state.showSimplePopup = false;
      saveToCookie(state);
    },
  },
  extraReducers: {},
});

export const consentReducer = slice.reducer;
export const {
  openSimplePopup,
  openDetailedPopup,
  closePopups,
  setConsent,
  setMultipleConsent,
  acceptAll,
  refuseAll,
} = slice.actions;
