import axios from 'axios';
import config from '../../../config';
import { store } from '../../stores/app.store.config';

export const getCertificate = async (): Promise<any> => {
  const state = store.getState();
  const defaultValue = state?.remoteConfigurationBranch?.remoteConfig?.CAPI_CERTIFICATE_B64;
  try {
    const response = await axios.get('/api/auth/certificate');
    return response?.data || defaultValue;
  } catch (err) {
    if (config.local) {
      console.log('error getting certificate', err);
    }
  }
  return defaultValue;
};
