import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getForgotInfo = createAsyncThunk(
  'getForgotInfo',
  async ({ key, locale }: { key: string; locale: string }, { rejectWithValue }): Promise<any> => {
    try {
      const result = await axios.get(`/api/auth/me/reset-credentials/${key}/info?lang=${locale.substring(0, 2)}`, {
        headers: { 'Content-Type': 'application/json' },
      });
      return result.data;
    } catch (err: any) {
      return rejectWithValue({ message: 'Invalid Key' });
    }
  },
);
