import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getUrlFromEnglish } from '../../../services/i18n/i18n.url.service';
import useLegal from '../../../services/retrieval/legal.retrieval';

import LinkComponent from '../../link/link.component';

const LegalNavigationComponent = (): JSX.Element | null => {
  const [showoptions, setshowoptions] = useState(false);
  const { legallinks, renderLegalLinks } = useLegal();
  const { pathname } = useLocation();
  const currentLegalLink = legallinks.find((l: any) => l.link === pathname);

  if (!currentLegalLink) return <div></div>;

  return (
    <div className="legal-navigation-block">
      <div className="navbar-mobile">
        <LinkComponent
          link={getUrlFromEnglish(currentLegalLink.link)}
          onClick={(): void => {
            setshowoptions(!showoptions);
          }}
        >
          <p>{currentLegalLink.title}</p>
          <div></div>
        </LinkComponent>
      </div>
      {showoptions && (
        <div className="navbar-mobile-options">
          <div>{renderLegalLinks()}</div>
        </div>
      )}
    </div>
  );
};
export default LegalNavigationComponent;
