import React, { useEffect, useState } from 'react';
import { goto } from '../../stores/selectors/router.selector';
import AccountUpload from './account-upload/account-upload';
import AccountRecruit from './account-recruit/account-recruit';
import AccountProducts from './account-products/account-products';
import AccountKickstarter from './account-kickstarter/account-kickstarter.component';
import AccountManagement from './account-management/account-management.component';
import AccountPTS from './account-pts/account-pts';
import AccountSecurity from './account-security/account-security.component';
import AccountLinked from './account-linked/account-linked.component';
import AccountDacs from './account-dacs/account-dacs.component';
import { useAuth } from '../../stores/selectors/auth.selector';
import { AuthState } from '../../models/authentication/auth.model';
import { getPageID, getUrlFromEnglish } from '../../services/i18n/i18n.url.service';
import LinkComponent from '../link/link.component';
import { usePrismic } from '../../hooks/prismic.hook';
import { asText } from '../../services/prismic/prismic.richtext.service';
import PrismicRichText from '../../services/prismic/prismic.richtext.service';
import { useSubscriptions } from '../../stores/selectors/subscriptions.selector';
import { getCookies, setCookies, cookieConfig } from '../../services/authentication/authentication.cookies.service';
import AccountPreference from './account-email-preferences/account-email-preferences.component';
import { getGametimeEligibility } from '../../services/api/identity/steam-service';
import { useLocation } from 'react-router-dom';
import { useKickstarter } from '../../stores/selectors/kickstarter.selector';
import AccountPayments from './account-payments/account-payments';
import AccountDownload from './account-download/account-download';
import AccountDownloadServer from './account-download-server/account-download-server';

const Account = (): JSX.Element => {
  const { pathname } = useLocation();

  const { user, initialized }: AuthState = useAuth();
  const { subscription } = useSubscriptions();
  const [elegibility, setElegibility] = useState(false);
  const { hasAccess } = useKickstarter();
  const isCancelled = (subscription: Record<string, any>): boolean =>
    ['NonRenewing', 'Canceled'].indexOf(subscription?.status) !== -1;

  // const hasGameTime = () => {
  //   const dateTime = new Date();
  //   const userGameTime = new Date(user?.allPlans[0]?.products[0]?.product?.gameTime);
  //   return userGameTime.getTime() > dateTime.getTime();
  // };

  const isExpiredSoon = (subscription: any): boolean => {
    const dateTime = new Date();
    const userGameTime = new Date(subscription?.dateNextCharge);
    return (
      subscription?.dateNextCharge &&
      userGameTime.getTime() > dateTime.getTime() &&
      userGameTime.getTime() - dateTime.getTime() <= 8.64e7
    );
  };

  const { contents } = usePrismic('login');
  const [noticeExp, setNoticeExp] = useState(false);
  const [noticeSteam, setNoticeSteam] = useState(false);

  useEffect(() => {
    if (initialized && !user) {
      goto(getUrlFromEnglish('/signup'));
    }
  }, [user, initialized]);

  useEffect(() => {
    if (isExpiredSoon(subscription)) {
      if (isCancelled(subscription)) setNoticeExp(true);
      else setNoticeExp(false);
    }
  }, [subscription]);

  useEffect(() => {
    if (user) {
      getGametimeSteam();
    }
  }, [user]);

  const getGametimeSteam = async () => {
    try {
      const data = await getGametimeEligibility();
      setElegibility(data);
      setNoticeSteam(true);
    } catch (error: any) {
      console.log(error?.response?.data);
    }
  };

  const [openMobileBox, setOpenMobileBox] = useState(false);

  if (!contents) return <></>;

  const {
    results: [
      {
        data: { account_page_title, menu_links, notice_expiration, notice_steam },
      },
    ],
  } = contents;

  const pageID = getPageID(pathname);
  const getIcon = (id: string) => {
    switch (id) {
      case 'account-preferences':
        return 'icon-preferences';
      case 'account-profile':
        return 'icon-profile';
      case 'account-products':
        return 'icon-code';
      case 'account-pts':
        return 'icon-pts';
      case 'account-recruit':
        return 'icon-raf';
      case 'account-upload':
        return 'icon-upload';
      case 'account-dacs':
        return 'icon-dacs';
      case 'account-download-server':
        return 'icon-myDU';
      case 'account-download':
        return 'icon-DU';
      default:
        return '';
    }
  };
  return (
    <div>
      <div className="account-container"></div>
      <div className={'accountupload'}>
        {noticeExp && !getCookies('accept-exp') && (
          <div className="notice_expiration">
            <div className="notice-icon-attention" />
            <PrismicRichText render={notice_expiration} />
            <div
              className="notice-icon-close"
              onClick={(): void => {
                setNoticeExp(false);
                setCookies('accept-exp', 'clicked', cookieConfig);
              }}
            />
          </div>
        )}
        {elegibility && noticeSteam && (
          <div className="notice_expiration">
            <div className="notice-icon-attention" />
            <PrismicRichText render={notice_steam} />
            <div
              className="notice-icon-close"
              onClick={(): void => {
                setNoticeSteam(false);
              }}
            />
          </div>
        )}
        <div className={'header'}>
          <p className={'title'}>{asText(account_page_title)}</p>
          <div className={'profileImageSectionMobile'}>
            <div className={'image'} />

            <div className={'contents'}>
              <p className={'name'}>{user?.displayName}</p>
              {/* <p className={'titleMobile'}>Player Title</p> */}
            </div>
          </div>
        </div>
        <div className={'box'}>
          <div className={'profileBorder'}>
            <div className={'userSelection'}>
              <div className={'profileImageSection'}>
                <div className={'icon-tool'} />
                <div className={'contents'}>
                  <p className="tag">Novean id:</p>
                  <p className={'name'}>{user?.displayName}</p>
                </div>
              </div>
              <div className="line" />
              {menu_links
                .filter((l: any) => !l.is_download)
                .filter((l: any) => hasAccess || !l.label[0].text.includes('Backer'))
                .map(({ link, label }: { link: any; label: any }, key: number) => (
                  <LinkComponent link={link.url} key={key} className="container-link">
                    <div className={pageID === getPageID(link.url, 'en') ? 'container selected' : 'container'}>
                      <div className={`icon ${getIcon(getPageID(link.url, 'en'))}`} />
                      <span className="underlineText"> {asText(label)}</span>
                    </div>
                  </LinkComponent>
                ))}
              <LinkComponent link="https://www.dualuniverse.game/account/download" className="container-link">
                <div className={pageID === 'account-download' ? 'container selected' : 'container download-yellow'}>
                  <div className={`icon ${getIcon('account-download')}`} />
                  <span className="underlineText"> {'Get Dual Universe'}</span>
                </div>
              </LinkComponent>
              <LinkComponent link="https://www.dualuniverse.game/account/download-server" className="container-link">
                <div
                  className={pageID === 'account-download-server' ? 'container selected' : 'container download-blue'}
                >
                  <div className={`icon ${getIcon('account-download-server')}`} />
                  <span className="underlineText"> {'Get myDU and server files'}</span>
                </div>
              </LinkComponent>
              <div className="menu-links-mobile">
                <a
                  className="underlineText"
                  onClick={() => {
                    setOpenMobileBox(!openMobileBox);
                  }}
                >
                  <p>{pathname.split('/').reverse()[0]}</p>
                  <div className={!openMobileBox ? 'navbar-mobile-open-icon' : 'navbar-mobile-close-icon'} />
                </a>
                {openMobileBox && (
                  <div className="menu-links-mobile-box">
                    {menu_links
                      .filter((l: any) => !l.is_download)
                      .map(({ link, label }: { link: any; label: any }, key: number) => {
                        if (!link.url.includes(pathname))
                          return (
                            <LinkComponent key={key} className="underlineText sublink" link={link.url}>
                              {asText(label)}
                            </LinkComponent>
                          );
                      })}
                  </div>
                )}
              </div>
            </div>
            <div className={'userInfo'}>
              {pageID === 'account-recruit' && <AccountRecruit />}
              {pageID === 'account-upload' && <AccountUpload />}
              {pageID === 'account-products' && (
                <AccountProducts elegibility={elegibility} setElegibility={setElegibility} />
              )}
              {pageID === 'account-backer' && <AccountKickstarter />}
              {pageID === 'account-profile' && <AccountManagement />}
              {pageID === 'account-pts' && <AccountPTS />}
              {pageID === 'account-preferences' && <AccountPreference />}
              {pageID === 'account-security' && <AccountSecurity />}
              {pageID === 'account-linked' && <AccountLinked />}
              {pageID === 'account-dacs' && <AccountDacs />}
              {pageID === 'account-payments' && <AccountPayments />}
              {pageID === 'account-download' && <AccountDownload />}
              {pageID === 'account-download-server' && <AccountDownloadServer />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
