import React, { useState } from 'react';
import MediaNavBar from './medianavbar';
import { usePrismic } from '../../../hooks/prismic.hook';
import { useMedia } from '../../../stores/selectors/media.selector';
import { getThubmVideo } from '../media.service';
import Popup from 'reactjs-popup';
import ReactPlayer from 'react-player';
import { InfiniteScrollComponent } from './infiniteScroll.component';
import { useRemoteConfiguration } from '../../../stores/selectors/remote-configuration.selector';

const VideoComponent = (): JSX.Element => {
  const {
    status: { videos },
  } = useMedia();
  const [imageModal, setimageModal] = useState('');
  const [indexImageSelected, setIndexImageSelected] = useState(0);
  const [amountDisplayed, setamountDisplayed] = useState(1);
  const closeModal = (): void => {
    setimageModal('');
  };
  const {
    result: { cat_videos, media },
  } = usePrismic('media-page');
  const {
    remoteConfig: { ASSETS_MEDIA_PREFIX },
  } = useRemoteConfiguration();

  const videoNoThumb = videos?.filter((elem: string) => {
    return !elem.includes('thumbnail');
  });
  const displayName = (uri: string): string => {
    return uri.includes('playlist') ? uri.split('/')[4].split('.')[0] : uri.split('/')[3].split('.')[0];
  };
  const subcategoriesList = (section: string[]): string[] => {
    const results = section?.map((elem: string) => {
      return elem.includes('playlist') ? elem.split('/')[3] : elem.split('/')[2];
    });
    const resultsUnique = results?.filter((item: string, index: number) => {
      return results.indexOf(item) === index && item !== '';
    });
    return resultsUnique;
  };
  const renderVideos = (category: string): JSX.Element[] | JSX.Element => {
    const items = videos
      ?.filter((elem: string) => {
        return !elem.includes('thumbnail') && elem.includes(category);
      })
      ?.map((elem: string, key: number): JSX.Element[] | JSX.Element => {
        return (
          <div className="categories-three" key={key}>
            <div className="categories-three">
              <div className="imageContainer">
                <div
                  className="image"
                  style={{
                    backgroundImage: `url(${getThubmVideo(elem, videos, ASSETS_MEDIA_PREFIX)})`,
                  }}
                  onClick={(): void => {
                    setIndexImageSelected(videoNoThumb?.indexOf(elem));
                    setimageModal('preview');
                  }}
                ></div>
                <div className="header">
                  <div className="header-icon"></div>
                  <p>{displayName(elem)}</p>
                </div>
              </div>
            </div>
          </div>
        );
      });
    return items;
  };
  const renderSubcategories = (): JSX.Element[] | JSX.Element => {
    const categories = subcategoriesList(videos)?.map((elem: string, key: number) => {
      return (
        <div className={key % 2 === 0 ? 'categories-content' : 'categories-content alternate-bg'} key={key}>
          {key < amountDisplayed && (
            <>
              <div className="mediaHeader">
                <p className="category-name">{elem}</p>
              </div>
              <div className="categories-grid">{renderVideos(elem)}</div>
            </>
          )}
        </div>
      );
    });
    return categories;
  };
  return (
    <div className="categories-container-custom">
      <MediaNavBar />
      <Popup modal closeOnDocumentClick={false} open={imageModal === 'preview'}>
        <div className="popup-component-preview">
          <div className="popup-container">
            <div className="popup-exit-icon" onClick={closeModal} />
            {videos && (
              <ReactPlayer
                className="react-player"
                playing={true}
                url={ASSETS_MEDIA_PREFIX.concat(videoNoThumb[indexImageSelected])}
                width="100%"
                height="100%"
                controls={true}
              />
            )}
            <div className="popup-image-name">
              <div className="popup-image-name-line"></div>
              {videos && <p>{displayName(videoNoThumb[indexImageSelected])}</p>}
            </div>
            <div className="popup-image-position">{`${indexImageSelected + 1}/${videoNoThumb?.length}`}</div>
            {indexImageSelected - 1 >= 0 && (
              <div
                className="popup-arrow-left"
                onClick={(): void => {
                  setIndexImageSelected(indexImageSelected - 1);
                }}
              ></div>
            )}
            {indexImageSelected + 1 < videoNoThumb?.length && (
              <div
                className="popup-arrow-right"
                onClick={(): void => {
                  setIndexImageSelected(indexImageSelected + 1);
                }}
              ></div>
            )}
          </div>
        </div>
      </Popup>

      <div className="categories-intro">
        <h2 className="categories-intro-title">{media}</h2>
        <h2 className="categories-intro-title-section">{cat_videos}</h2>
      </div>
      {renderSubcategories()}
      {amountDisplayed < videoNoThumb?.length && (
        <InfiniteScrollComponent
          renderSubcategories={renderSubcategories}
          amountDisplayed={amountDisplayed}
          setamountDisplayed={setamountDisplayed}
        />
      )}
    </div>
  );
};
export default VideoComponent;
