import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { usePrismic } from '../../hooks/prismic.hook';
import { Tutorial } from '../../models/tutorials/tutorials.model';
import PrismicRichText, { asText } from '../../services/prismic/prismic.richtext.service';
import { searchDocuments } from '../../services/prismic/prismic.search.service';
import { useI18n } from '../../stores/selectors/i18n.selector';
import LinkComponent from '../link/link.component';
import { getSlug } from '../news/news.service';

interface FormInput {
  [x: string]: any;
}

const TutorialSearchComponent = (): JSX.Element => {
  const {
    data: { search_placeholder },
  } = usePrismic('tutorials_page');
  const { handleSubmit, register, setValue, getValues } = useForm();

  const [results, setResults] = useState<Tutorial[]>([]);
  const { locale } = useI18n();

  const resetSearch = (): void => {
    setResults([]);
    setValue('search', '');
  };

  const resetSearchWithDelay = (): void => {
    setTimeout(() => {
      resetSearch();
    }, 100);
  };

  const onSubmit = ({ search }: FormInput): void => {
    if (search) {
      searchDocuments({ type: 'tutorial', locale, search }).then((r: Tutorial[]) => setResults(r));
    } else {
      resetSearch();
    }
  };

  const onClick = (): void => {
    const data: FormInput = getValues();
    onSubmit(data);
  };

  return (
    <div className="tutorials-search" onBlur={resetSearchWithDelay}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="util-input">
          <input
            type="text"
            name="search"
            className="util-input-text"
            placeholder={asText(search_placeholder)}
            ref={register}
          />
          <div className="magnifying-glass" onClick={onClick} />
        </div>
      </form>
      {Boolean(results.length) && (
        <div className="results">
          {results.map((tutorial: Tutorial, key: number) => (
            <LinkComponent key={key} link={`/tutorials/${getSlug(tutorial)}`} className="result" onClick={resetSearch}>
              <PrismicRichText render={tutorial.title} />
            </LinkComponent>
          ))}
        </div>
      )}
    </div>
  );
};

export default TutorialSearchComponent;
