import PrismicRichText, { asText } from '../../../services/prismic/prismic.richtext.service';
import React from 'react';
import { usePrismic } from '../../../hooks/prismic.hook';
import resendConfirm from '../../../services/authentication/resend-confirm';

const ResendEmailModal = (props: any): JSX.Element => {
  const {
    data: { page_title, page_description, success_message, resend_button },
  } = usePrismic('account_resend_confirm');

  if (!page_title) return <></>;
  return (
    <>
      <div className="popup-overlay-reminder"></div>
      <div className="login-modal-container reminder-container resend-container">
        <div className="corners">
          <div className="reminder-title">
            <PrismicRichText render={page_title} />
          </div>
          <div className="reminder-desc">
            <PrismicRichText render={page_description} />
          </div>
          <button
            className="theme-button btn-resend"
            onClick={async () => {
              const { email } = props;
              const response = await resendConfirm(email);
              if (response?.status === 204) {
                props.setResendFeedback(asText(success_message));
              } else props.setResendFeedback('');
              props.setShowResend(false);
            }}
          >
            <div className="theme-button-container">
              <div></div>
              <span>{asText(resend_button)}</span>
            </div>
          </button>
        </div>
        <div
          className="icon-close-reminder"
          onClick={() => {
            props.setShowResend(false);
          }}
        ></div>
      </div>
    </>
  );
};
export default ResendEmailModal;
