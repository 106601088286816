// -- Link resolution rules
// Manages the url links to internal Prismic documents
export const linkResolver = (doc: any): string => {
  switch (doc.type) {
    case 'news': {
      if (doc.uid) {
        return `/news/${doc.uid}`;
      }
      return '/news';
    }
    case 'buy_page': {
      return `buy/${doc.uid}`;
    }
    default: {
      return '/';
    }
  }
};
