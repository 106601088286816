export interface LiveopsState {
  status: any;
  loading: boolean;
  error: any;
}

export const initialLiveopsState: LiveopsState = {
  status: {},
  loading: false,
  error: null,
};
