import React, { useState } from 'react';
import Modal from 'react-modal';
import { useAppDispatch } from '../../../stores/app.store.config';
import { usePrismic } from '../../../hooks/prismic.hook';
import PrismicRichText, { asText } from '../../../services/prismic/prismic.richtext.service';
import { cancelSubscription, renewSubscription } from '../../../stores/actions/subscriptions.actions';
import { useSubscriptions } from '../../../stores/selectors/subscriptions.selector';
import LoadingComponent from '../../loading/loading.component';

const AccountProductsModalsComponent = ({ product, ownership }: { product: any; ownership: string }): JSX.Element => {
  const dispatch = useAppDispatch();

  const [modalCancel, setModalCancel] = useState<any | null>(null);

  const [modalRenew, setModalRenew] = useState<any | null>(null);

  const { loading } = useSubscriptions();

  const onClose = (): void => {
    setModalCancel(null);
    setModalRenew(null);
  };

  const cancel = (order: any): void => {
    dispatch(cancelSubscription(order));
    onClose();
  };

  const renew = (order: any): void => {
    dispatch(renewSubscription(order));
    onClose();
  };

  const isCancelled = (product: any): boolean =>
    ['NonRenewing', 'Canceled'].indexOf(product?.subscription?.status) !== -1;

  const {
    result: { keep_playing_button, cancel_renewal_button, renew_subscription_button, status_on, status_off },
    contents,
  } = usePrismic('account_products');

  const {
    results: [
      {
        data: { cancel_title, cancel_description, renew_description, autorenewal_message },
      },
    ],
  } = contents;

  return (
    <>
      <Modal
        isOpen={Boolean(modalCancel)}
        onRequestClose={onClose}
        className="modal-content products-cancel-renew"
        overlayClassName="modal-overlay"
      >
        <button onClick={onClose} className="x-button close-button" />
        &nbsp;
        <div className="renew-cancel-title">
          <PrismicRichText render={cancel_title} />
        </div>
        <div className="renew-cancel-dec">
          <PrismicRichText render={cancel_description} />
        </div>
        <div className="button-group">
          <button
            className="util-button-black width-auto"
            onClick={(): void => {
              cancel(modalCancel);
              const currentDate = new Date()
                .toLocaleDateString('en-US', { year: '2-digit', month: '2-digit' })
                .replace('/', '');
              window.open(`https://www.surveymonkey.com/r/DUEndOfSub?m=${currentDate}`);
            }}
          >
            <div />
            <span>{cancel_renewal_button}</span>
          </button>

          <button className="util-button-green" onClick={(): void => onClose()}>
            <div />
            <span>{asText(keep_playing_button)}</span>
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={Boolean(modalRenew)}
        onRequestClose={onClose}
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <div className="corners">
          <button onClick={onClose} className="x-button close-button" />
          &nbsp;
          <PrismicRichText render={renew_description} />
          <button className="util-button-continue" onClick={(): void => renew(modalRenew)}>
            <div />
            <span>{renew_subscription_button}</span>
          </button>
        </div>
      </Modal>

      <LoadingComponent loading={loading} />

      {!loading && ownership && (
        <>
          {!isCancelled(product) && product.orderId && (
            <button onClick={(): any => setModalCancel(product)}>
              <span>{cancel_renewal_button}</span>
              <span className="status-on">{status_on}</span>
            </button>
          )}
          {isCancelled(product) && !ownership?.includes('steam') && (
            <>
              <button
                disabled={!product.name?.includes('1 month subscription')}
                onClick={(): any => setModalRenew(product)}
              >
                <span>{renew_subscription_button}</span>
                <span className="status-off">{status_off}</span>
              </button>
              {!product.name?.includes('1 month subscription') && <PrismicRichText render={autorenewal_message} />}
            </>
          )}
        </>
      )}
    </>
  );
};

export default AccountProductsModalsComponent;
