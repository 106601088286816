import { createSlice } from '@reduxjs/toolkit';
import { initialSubscriptionsState } from '../../models/subscriptions/subscriptions.model';
import { getSubscriptions, cancelSubscription, renewSubscription } from '../actions/subscriptions.actions';

const slice = createSlice({
  name: 'subscriptions',
  initialState: initialSubscriptionsState,
  reducers: {},
  extraReducers: {
    [getSubscriptions.fulfilled.type]: (state, { payload }): void => {
      state.subscription = payload;
      state.loading = false;
      state.error = false;
    },
    [getSubscriptions.pending.type]: (state): void => {
      state.loading = true;
      state.error = null;
    },
    [getSubscriptions.rejected.type]: (state, { error }): void => {
      state.subscription = new Object();
      state.loading = false;
      state.error = error;
    },
    [cancelSubscription.fulfilled.type]: (state, { payload }): void => {
      state.subscription = payload;
      state.loading = false;
      state.error = false;
    },
    [cancelSubscription.pending.type]: (state): void => {
      state.loading = true;
      state.error = null;
    },
    [cancelSubscription.rejected.type]: (state, { error }): void => {
      state.loading = false;
      state.error = error;
    },
    [renewSubscription.fulfilled.type]: (state, { payload }): void => {
      state.subscription = payload;
      state.loading = false;
      state.error = false;
    },
    [renewSubscription.pending.type]: (state): void => {
      state.loading = true;
      state.error = null;
    },
    [renewSubscription.rejected.type]: (state, { error }): void => {
      state.loading = false;
      state.error = error;
    },
  },
});

export const subscriptionsReducer = slice.reducer;

export { getSubscriptions, cancelSubscription, renewSubscription };
