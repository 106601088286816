import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingComponent from '../../loading/loading.component';

interface Props {
  renderSubcategories: () => void;
  amountDisplayed: number;
  setamountDisplayed: (amount: number) => void;
}

export const InfiniteScrollComponent = (props: Props): JSX.Element => {
  const { renderSubcategories, amountDisplayed, setamountDisplayed } = props;
  const fetchMoreData = (): void => {
    setTimeout(() => {
      setamountDisplayed(amountDisplayed + 20);
    }, 500);

    renderSubcategories();
  };
  return (
    <>
      <InfiniteScroll
        dataLength={amountDisplayed}
        next={(): void => fetchMoreData()}
        hasMore={true}
        loader={
          <a className="center-x">
            <i className="load-more"></i>
            <LoadingComponent loading={true} />
          </a>
        }
      >
        <></>
      </InfiniteScroll>
    </>
  );
};
