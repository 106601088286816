export type CSRFToken = string;

export interface CSRFTokenState {
  token: string;
  loading: boolean;
  hasErrors: boolean;
  error: any;
}

export const initialCSRFTokenState: CSRFTokenState = {
  token: '',
  loading: false,
  hasErrors: false,
  error: null,
};
