import React, { useState, useEffect } from 'react';
import { usePrismic } from '../../../hooks/prismic.hook';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useAuth } from '../../../stores/selectors/auth.selector';
import PrismicRichText, { asText } from '../../../services/prismic/prismic.richtext.service';
import Popup from 'reactjs-popup';
import qs from 'qs';
import { userReferalInfo, getRewards } from '../../../services/authentication/referal.service';
import axios from 'axios';
import config from '../../../../config';
import { goto } from '../../../stores/selectors/router.selector';
import { getUrlFromEnglish } from '../../../services/i18n/i18n.url.service';
import { errorHandled } from '../account-management/error-service';
import { useLocation } from 'react-router-dom';

export default function AccountRecruit(): JSX.Element {
  const {
    loading,
    hasErrors,
    result: {
      page_title,
      status_title,
      status_cannot,
      status_desc,
      recruited,
      recruited_by,
      not_recruited,
      enter_recruiter_link,
      add_code,
      code_placeholder,
      start_title,
      start_label,
      link_title,
      link_copy,
      link_copied,
      how_title,
      send_title,
      send_button,
      rewards_title,
      rewards_unlocked,
      rewards_button,
    },
    data: { intro, how_description, send_warning, recruiter_notice },
  } = usePrismic('account_recruit_a_friend');
  const {
    data: { errors },
  } = usePrismic('account_management_errors');
  const {
    result: { rewards_player_block },
  } = usePrismic('recruit_a_friend');

  const { search } = useLocation();
  const { recruiter } = qs.parse(search.slice(1));

  const [recruitInfo, setRecruitInfo] = useState(Object);
  const [rewards, setRewards] = useState(Object);
  const { user }: { user: Record<string, any> } = useAuth();
  const [recruiterCode, setRecruiterCode] = useState<string>('');

  useEffect(() => {
    if (recruiter) {
      setRecruiterCode(String(recruiter ? recruiter : ''));
    }
  }, [recruiter]);

  const [linking, setLinking] = useState(false);
  useEffect(() => {
    userReferalInfo(user.token).then((data) => {
      setRecruitInfo(data);
    });
  }, [linking]);

  useEffect(() => {
    getRewards(user.token, user.id).then((data) => {
      setRewards(data);
    });
  }, []);

  const recruiterLink = user ? `${window.location.protocol}//${window.location.host}/signup?recruiter=${user.id}` : '';

  const [email, setEmail] = useState<string>('');
  const [notice, setNotice] = useState({ type: '', message: '' });

  const createRecruitment = async (): Promise<void> => {
    try {
      const result = await axios.post(
        `/api/Recruitment/invite/${email}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        },
      );
      setNotice({ type: 'success', message: `${'Your invitation has been sent'}` });
      cleanNotice();
      return result.data;
    } catch (err: any) {
      setNotice(errorHandled(err.response.data, errors));
      // setRecruiterCode('');
      cleanNotice();
      if (config.local) {
        console.log('error sending the invitation', err);
      }
    }
    setEmail('');
    window.scrollTo(0, 0);
  };
  const cleanNotice = () => {
    setTimeout(() => {
      setNotice({ type: '', message: '' });
    }, 4000);
  };
  const renderBlocks = (): JSX.Element[] | JSX.Element => {
    const result = rewards
      ?.slice(rewards[rewards.length - 5], rewards[rewards.length - 1])
      ?.reverse()
      ?.map((reward: any, index: number): JSX.Element[] | JSX.Element => {
        return (
          <div className="block-content" key={index}>
            <div style={{ backgroundImage: `url(${rewards_player_block[reward - 1]?.reward_block_image})` }}></div>
            <p>{rewards_player_block[reward - 1]?.reward_block_title}</p>
          </div>
        );
      });
    return result;
  };
  const contentStyle = { background: '#ffd255' };
  const overlayStyle = { background: 'transparent' };
  const arrowStyle = { color: '#ffd255' };

  if (loading || hasErrors || !intro || !rewards_player_block) return <div></div>;

  return (
    <div className="recruit-container">
      <div className="">
        <h1 className="title">{page_title}</h1>
        <div className="intro">
          <PrismicRichText render={intro} />
        </div>
      </div>
      {notice?.type && (
        <div className={notice.type?.includes('success') ? 'account-notice' : 'account-notice error'}>
          <div />
          <p>{notice.message}</p>
        </div>
      )}
      {recruitInfo['recruiter'] && (
        <>
          <div className="subtitle-block">
            <h2 className="subtitle">{status_title}</h2>
            <p className="sub-friend">{recruited}</p>
          </div>
          <div className="recruit-status">
            <div className="recruiter" style={{ backgroundImage: `url(${recruitInfo['recruiter']?.avatar})` }} />
            <div className="copy" />
            <div className="recruit" style={{ backgroundImage: `url(${recruitInfo?.avatar})` }} />
          </div>
          <div className="recruited-by">
            {asText(recruited_by)} <span>{recruitInfo['recruiter']?.displayName}</span>
          </div>
        </>
      )}

      {!recruitInfo['recruiter'] && recruitInfo['canBeRecruited'] && (
        <>
          <div className="subtitle-block">
            <h2 className="subtitle">{status_title}</h2>
            <p className="sub-friend">{not_recruited}</p>
          </div>
          <div className="input-group">
            <label className="">{enter_recruiter_link}</label>
            <div className="input-row">
              <div className="input-box">
                <div className="icon world-icon"></div>
                <input
                  type="text"
                  placeholder={code_placeholder}
                  defaultValue={
                    !recruiter
                      ? ''
                      : `${window.location.protocol}//${window.location.host}/signup?recruiter=${String(
                          recruiter ? recruiter : '',
                        )}`
                  }
                  onChange={({ target: { value } }): void => {
                    if (value?.split('=')?.length > 1) {
                      setRecruiterCode(value?.split('=')[1]);
                    } else setRecruiterCode('XXXXX');
                  }}
                />
              </div>
              <button
                className="theme-button"
                onClick={async () => {
                  try {
                    const result = await axios.post(
                      `/api/Recruitment/LinkRecruiter/${recruiterCode}`,
                      {},
                      {
                        headers: {
                          Authorization: `Bearer ${user.token}`,
                        },
                      },
                    );
                    window.location.reload();
                    cleanNotice();
                    return result.data;
                  } catch (err: any) {
                    setNotice(errorHandled(err.response.data, errors));

                    cleanNotice();
                    if (config.local) {
                      console.log('error linking to a recruiter', err);
                    }
                  }
                  setLinking(true);
                }}
              >
                <div className="theme-button-container">
                  <div className="send" />
                  <span>{add_code} </span>
                </div>
              </button>
            </div>
          </div>
          <div className="subtitle-info">
            <PrismicRichText render={recruiter_notice} />
          </div>
        </>
      )}

      {!recruitInfo['recruiter'] && !recruitInfo['canBeRecruited'] && (
        <>
          <div className="subtitle-block">
            <h2 className="subtitle">{status_title}</h2>
            <p className="sub-friend">{status_cannot}</p>
          </div>
          <p className="subtitle-cannot">{status_desc}</p>
        </>
      )}

      <div className="subtitle-block">
        <h2 className="subtitle">{start_title}</h2>
        <p className="sub-friend">
          {recruitInfo['recruits']?.length} {start_label}
        </p>
      </div>
      <div className="input-group">
        <label className="">{link_title}</label>
        <div className="input-row">
          <div className="input-box">
            <div className="icon world-icon"></div>
            <input type="text" value={recruiterLink} readOnly />
          </div>
          <Popup
            trigger={(): JSX.Element => (
              <div>
                <CopyToClipboard text={recruiterLink}>
                  <button className="theme-button">
                    <div className="theme-button-container">
                      <div className="copy" />
                      <span>{link_copy}</span>
                    </div>
                  </button>
                </CopyToClipboard>
              </div>
            )}
            position="top center"
            closeOnDocumentClick
            {...{ contentStyle, overlayStyle, arrowStyle }}
          >
            <div className="link-container">
              <p>{link_copied}</p>
            </div>
          </Popup>
        </div>
      </div>
      <div className="how">
        <h2 className="how-title">{how_title}</h2>
        <div className="how-title-desc">
          <PrismicRichText render={how_description} className="" />
        </div>
      </div>
      <div className="input-group">
        <label className="">{send_title}</label>
        <div className="input-row">
          <div className="input-box">
            <div className="icon email-icon"></div>
            <input
              type="text"
              placeholder="email@email.com"
              value={email}
              onChange={({ target: { value } }): void => setEmail(value)}
            />
          </div>
          <button disabled={email === ''} className="theme-button" onClick={createRecruitment}>
            <div className="theme-button-container">
              <div className="send" />
              <span>{send_button}</span>
            </div>
          </button>
        </div>
        <div className="warning">
          <PrismicRichText render={send_warning} />
        </div>
      </div>
      <div className="subtitle-block">
        <h2 className="subtitle">{rewards_title}</h2>
        <p className="sub-friend">
          {rewards?.length > 0 && (
            <span>
              {rewards[rewards.length - 1]} {rewards_unlocked}
            </span>
          )}
          {(!rewards || rewards?.length == 0) && <span>0 {rewards_unlocked}</span>}
        </p>
      </div>

      {/* <p className="reward-text-under">{rewards_none}</p> */}
      {rewards?.length > 0 && <div className="rewards-block">{renderBlocks()}</div>}
      <div className="reward-button-under">
        <button
          className=""
          onClick={() => {
            goto(getUrlFromEnglish('/recruit-a-friend'));
          }}
        >
          <span>{rewards_button}</span>
        </button>
      </div>
    </div>
  );
}
