import { createAsyncThunk } from '@reduxjs/toolkit';
import { FetchKeys } from '../../models/beta-keys/keys.model';
import {
  cancelKey,
  getKeys,
  sendKey,
  activateKey as activateKeyService,
  filterGiftedKeys,
  filterPendingKeys,
} from '../../services/api/inventory/keys.service';

const fetchKeys = async (): Promise<FetchKeys> => {
  const { keys, available } = await getKeys();
  const totals = {
    available,
    gifted: filterGiftedKeys(keys).length,
    pending: filterPendingKeys(keys).length,
  };

  return { keys, totals };
};

export const sendAvailableBetaKey = createAsyncThunk(
  'betaKeys/sendAvailableBetaKey',
  async ({ Email }: { Email: string }, { rejectWithValue }): Promise<any> => {
    try {
      await sendKey(Email);
      const data = await fetchKeys();
      return data;
    } catch (err: any) {
      return rejectWithValue({ Code: err.response.status, ...err.response.data });
    }
  },
);

export const fetchBetaKeys = createAsyncThunk(
  'betaKeys/fetchBetaKeys',
  async (arg, { rejectWithValue }): Promise<any> => {
    try {
      const data = await fetchKeys();
      return data;
    } catch (err: any) {
      return rejectWithValue({ Code: err.response.status, ...err.response.data });
    }
  },
);

export const cancelBetaKey = createAsyncThunk(
  'betaKeys/cancelBetaKey',
  async (key: string, { rejectWithValue }): Promise<any> => {
    let errorData;
    try {
      await cancelKey(key);
    } catch (err: any) {
      errorData = { Code: err.response.status, ...err.response.data, cancelKey: key };
    }
    try {
      const data = await fetchKeys();
      if (errorData) {
        const { keys, totals } = data;
        return rejectWithValue({ ...errorData, keys, totals });
      }
      return data;
    } catch (err: any) {
      return rejectWithValue({ Code: err.response.status, ...err.response.data });
    }
  },
);

export const activateKey = createAsyncThunk(
  'betaKeys/activateKey',
  async (key: string, { rejectWithValue }): Promise<any> => {
    try {
      const data = await activateKeyService(key);
      return data;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({ Code: err.response.status, ...err.response.data });
    }
  },
);
