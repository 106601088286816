export type User = any;

interface SignUpResultInterface {
  user?: any;
  error?: any;
}

export type SignupResult = SignUpResultInterface | null;

export interface AuthState {
  loading: boolean;
  user: User | null;
  signup: boolean;
  status: 'SUCCESS' | 'CODE_SENT' | 'PASSWORD_CHANGED' | '';
  error: any;
  isAuthenticated: boolean;
  initialized: boolean;
  email: string;
  lastPathName: string;
}

export const initialAuthState: AuthState = {
  user: null,
  signup: false,
  status: '',
  loading: false,
  error: null,
  isAuthenticated: false,
  initialized: false,
  email: '',
  lastPathName: '',
};
