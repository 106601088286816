import React, { useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useLauncherNews } from '../../stores/selectors/launcher-news.selector';
import { getSlug } from '../news/news.service';
import { getUrlFromEnglish } from '../../services/i18n/i18n.url.service';
import { asText } from '../../services/prismic/prismic.richtext.service';
import { Link, useParams } from 'react-router-dom';
import { usePrismic } from '../../hooks/prismic.hook';

const LauncherComponent = (): JSX.Element => {
  let {
    contents: { newsStoriesLauncher },
  } = useLauncherNews();

  const { loading, hasErrors } = useLauncherNews();
  const { uid } = useParams();
  const responsive = {
    desktop: {
      breakpoint: { max: 4000, min: 480 },
      items: 3,
    },

    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1,
    },
  };
  const {
    result: { loading_msg, error_msg },
  } = usePrismic('launcher_page');
  useEffect(() => {
    document.onkeydown = function (e) {
      // keycode for F5 function
      if (e.keyCode === 116) {
        return false;
      }
    };
  }, []);
  if (!newsStoriesLauncher) return <></>;
  const pinned = newsStoriesLauncher.filter((elem: any) => {
    return elem.pinned;
  });
  pinned.sort((a: any, b: any) => Date.parse(b.dateModified) - Date.parse(a.dateModified));
  const noPinned = newsStoriesLauncher.filter((elem: any) => {
    return !elem.pinned;
  });
  newsStoriesLauncher = [...pinned, ...noPinned];

  const renderCarousel = (): JSX.Element[] | JSX.Element => {
    if (loading) return <p>{loading_msg}</p>;
    if (hasErrors) return <p>{error_msg}</p>;

    const myPosts: JSX.Element[] = newsStoriesLauncher
      .filter((r: any) => !uid || getSlug(r) === uid)
      .map((result: any) => (
        <div
          key={getSlug(result)}
          className="launcher-news-block"
          style={{
            backgroundImage: `linear-gradient(rgba(12, 19, 21, 0) 58%, rgba(12, 19, 21, 0.75) 70%, rgb(12, 19, 21) 85%),url(${result?.image?.url})`,
          }}
        >
          <Link to={`${getUrlFromEnglish('/launcher/news')}/${getSlug(result)}`}>
            <article key={result.id} className="news-block">
              <div className="real-content">
                <div className="news-header">
                  <p>{result?.type}</p>
                </div>

                <div className="news-content">
                  <div className="news-content-detail">
                    <div className="linebefore"></div>
                    <p className="content">{asText(result?.title)} </p>
                    <div className="social-media-group">
                      <p className="date">{result?.date}</p>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </Link>
        </div>
      ));

    return myPosts;
  };
  return (
    <div className="launcher-container">
      <div className="launcher-carousel-news">
        {newsStoriesLauncher && (
          <Carousel
            showDots={true}
            autoPlay={false}
            autoPlaySpeed={5000}
            transitionDuration={1000}
            keyBoardControl={true}
            responsive={responsive}
            itemClass="carousel-item-padding"
          >
            {renderCarousel()}
          </Carousel>
        )}
      </div>
    </div>
  );
};
export default LauncherComponent;
